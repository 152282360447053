import React, { useEffect, useRef, useState } from 'react'
import { DROPDOWN_DATA, ROOMPAXDATA, ROUTE_PATHS, TRANSFER_INPUT_OBJECT } from '../../constants'
import DropdownsAddPax from '../Transfer/DropdownsAddPax'
import Multicalander from '../Excursion/Multicalander'
import BookingPaxUpdate from './BookingPaxUpdate'
import { useDispatch, useSelector } from 'react-redux'
import { ishotelListInfo } from '../../store/accomodationReducer'
import BookingPerson from '../Transfer/BookingPerson'
import MulticalanderTransfer from '../Excursion/MulticalanderTransfer'
import { addLists } from '../../store/transferReducer'

function BookingNewService({ newPax = true, pax = [],setFinalStep=undefined,notSelected=false, actions, service, setService }) {
    const {calendarDate:inputsDate }= useSelector((state) => state.accomodationreducer)
    const {transferUpdates} = useSelector(state => state.transfer_Global);
    const dispatch=useDispatch();


    const accomFilter=()=>(
        <>
        <Multicalander label="Arrival" label2={inputsDate.length === 0 ? "dd/mm/yyyy" : inputsDate[0]} rangeDate={inputsDate} month={2} page={1} />
        <Multicalander label="Departure" label2={inputsDate.length === 0 || inputsDate.length === 1 ? "dd/mm/yyyy" : inputsDate[1]} rangeDate={inputsDate} month={2} page={1} /> 
        </>
    )

    const transferFilter=()=>(
        <div className='transfer-filter'>
            <MulticalanderTransfer errmsg={false} label="Transfer Date" label2={'dd/mm/yyyy'} month={2} page={1} rangeDate={[]} rowid={0} calDate={transferUpdates[0].TransferDate} />
        </div>
    )
    
    const getFilter=()=>{
        if (service.label==="Accomodation") {
            return accomFilter();
        }
        if (service.label==="Transfer") {
            return transferFilter();
        }
    }

    const handleDropdownParent = (name, data) => {
        if (data.label==="Transfer" && transferUpdates.length===0) {
            dispatch(addLists({index:0,addInputs:TRANSFER_INPUT_OBJECT}))
        }
        setService(actions[data.label])
    };

    useEffect(()=>{
        dispatch(ishotelListInfo(false))
    },[])

    useEffect(()=>{
        setFinalStep&&setFinalStep(service.onNext)
    },[service,notSelected])
    return (
        <>
            <div className='dialogue-pax-selections-selector'>
                <div className='dialogue-pax-selections-selector-child'>
                    <DropdownsAddPax dropData={DROPDOWN_DATA} imgcount={1} name="Accommodation" center={true} handleDropdownParent={handleDropdownParent} />
                    {getFilter()}
                </div>
                {
                    newPax?
                    <BookingPerson cname={service.cname} paxInfo={service.paxInfo} scroll={false} isTransfer={service.cname===2}/>
                    :
                    <BookingPaxUpdate pax={pax} onSelectionChange={newPax?service.handleNewPax:service.handleExistingPax} notSelected={notSelected}/>
                }
            </div>
        </>
    )
}

export default BookingNewService
