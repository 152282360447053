import React from "react";
import { pluralize } from "../../constants";

function PaxDetails({firstName,lastName ,age,phoneNumber,label,numero}) {
  const renderAge=()=>{
    if (!age || age===0) {
      return null;
    }
    return (<span className="pax-recap-age">({age} {pluralize(age, "year", "years")} old)</span>)
  }
  return (
    <div className="pax-recap-details-container">
        <p className="pax-recap-label">{label} nb.{numero} {renderAge()}</p>
        <div className="pax-recap-row">
            <input className="input-name half" readOnly value={firstName}/>
            <input className="input-name half" readOnly value={lastName}/>
        </div>
    </div>
  );
}

export default PaxDetails;
