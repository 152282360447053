import { Slider } from '@mui/material'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { BUDGET_RANGE } from '../../../constants';
import { separateurMillier } from '../../../utility/globalFunc';
import VOLUME_IMG from "../../../Assets/images/accomodation/hotel-stock-volume.png";
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useDebounceLoad } from '../../../hooks/useDebounceLoad';

function PriceSlider({ currency = "EUR", onValueCommitted, onValueChange, defaultValues = [BUDGET_RANGE.MIN_PRICE, BUDGET_RANGE.MAX_PRICE], minDistance = undefined, disableSwap = false }) {
    const [value, setValue] = useState([])
    const [leftDisableWidth, setLeftDisableWidth] = useState(0);
    const [rightDisableWidth, setRightDisableWidth] = useState(0);
    const [isType, setIsType] = useState(false)
    const sliderRef=useRef(null);

    const closeInputRef = useRef(null)
    const timeoutRef = useRef(null);

    const debouncedSearch = useDebounceLoad(useCallback((searchTerm) => {
        onValueCommitted && onValueCommitted(searchTerm)
    }, []), 1000);

    const handleCommittedChange = (e, _) => {
        onValueCommitted && onValueCommitted(value)
    }

    const respectMinDist = (values) => {
        if (minDistance) { return values[1] - values[0] >= minDistance; }
        return true;
    }

    const handleChangeMinDistance = (event, newValue, activeThumb) => {

        if (!Array.isArray(newValue)) {
            return;
        }
        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
        } else {
            setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
        }
    };

    const handleSimpleChange = (e, value) => {
        e?.preventDefault();
        setValue(value);
    }

    const formatValueLabel = (x) => {
        // return getCurrency(currency) + " +" + separateurMillier(x,",")
        return separateurMillier(x, ",")
    }

    const debounceCommit = (newValue) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            onValueCommitted(newValue);
        }, 800);
    }; 

    useEffect(() => {        
        setValue(defaultValues);
    }, [defaultValues])

    const handleChange = (e, value, activeThumb) => {
        console.log("HUHU",value, activeThumb);
        
        if (minDistance) {
            handleChangeMinDistance(e, value, activeThumb);
            if (respectMinDist(value)) {

                setLeftDisableWidth(getWidthDisableLeft(value[0]));
                setRightDisableWidth(getWidthDisableRight(value[1]));
            }
        } else {
            handleSimpleChange(e, value);
        }
    }

    const getWidthDisableLeft = (value) => {
        const range = BUDGET_RANGE.MAX_PRICE - BUDGET_RANGE.MIN_PRICE;
        return (value - BUDGET_RANGE.MIN_PRICE) * 100 / range;
    }

    const getWidthDisableRight = (value) => {
        const range = BUDGET_RANGE.MAX_PRICE - BUDGET_RANGE.MIN_PRICE;
        return (BUDGET_RANGE.MAX_PRICE - value) * 100 / range;
    }

    const openBudgetInput = (e, name) => {
        e?.preventDefault()
        const updatedValue = [...value];

        if (name === "OPEN-INPUT") {
            setIsType((isType)=>!isType)
        }

        else {
            updatedValue[name] = Number(e.target.value)
            setValue(updatedValue)
            debouncedSearch(updatedValue)
        }

    }

    useOutsideClick(closeInputRef, () => {
        setIsType(false);
    });

    useEffect(() => {
        const handleClick = (event) => {
          const valueLabel = event.target.closest(".MuiSlider-valueLabel");
          if (valueLabel) {
            openBudgetInput(undefined,"OPEN-INPUT")
          }
        };
    
        const sliderElement = sliderRef.current;
        if (sliderElement) {
          sliderElement.addEventListener("click", handleClick);
        }
    
        return () => {
          if (sliderElement) {
            sliderElement.removeEventListener("click", handleClick);
          }
        };
      }, []);

    return (
        <div className='types-of-filter' ref={closeInputRef}>
            <div className='filter_type_name budget-edit'>Your budget </div>
            <div className='font-budget-info'>
                The filter price is based on your number of pax, stay duration, and the hotel's lowest room rates.
            </div>
            <div className='slider-container' ref={sliderRef}>
                <div className='volume-container'>
                    <div style={{ width: `${leftDisableWidth}%` }} className='disabled-volume'></div>
                    <div style={{ width: `${rightDisableWidth}%` }} className='disabled-volume right'></div>
                </div>
                <Slider
                    value={value}
                    onChangeCommitted={(e, value) => { handleCommittedChange(e, value) }}
                    onChange={handleChange}
                    min={BUDGET_RANGE.MIN_PRICE}
                    max={BUDGET_RANGE.MAX_PRICE}
                    step={minDistance / 2}
                    valueLabelDisplay='off'
                    sx={{color:"success.main"}}
                    valueLabelFormat={(val) =>
                        val === BUDGET_RANGE.MAX_PRICE ? `${val}+` : formatValueLabel(val)
                    }
                    disableSwap={disableSwap}
                />
            </div>

            {isType && <div className='range-input-box'>
                <input type="text" placeholder='Range From' onChange={(e) => { openBudgetInput(e, 0) }} value={value[0] || ""} />
                <input type="text" placeholder='Range To' onChange={(e) => { openBudgetInput(e, 1) }} value={value[1] || ""} />
            </div>}

          
              
                  
            <div className='range-box-min-max'>
                <div className='container' onClick={() => document.getElementById('min-input').focus()}>
                    <div>
                        <p className='font-range'>Min :</p>
                    </div>
                    <input
                        id="min-input"
                        type="number"
                        placeholder={BUDGET_RANGE.MIN_PRICE}
                        value={value[0]=== "" ? "" : value[0]}
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/^0+/, "");
                            const newValue = [inputValue ? Number(inputValue) : "", value[1]];
                            setValue(newValue);
                            if (inputValue) debounceCommit(newValue);
                        }}

                        className="hidden-input"
                        onFocus={() => value[0] === 0 && setValue(["", value[1]])}
                    />
                </div>
                <div className='container' onClick={() => document.getElementById('max-input').focus()}>
                    <div>
                        <p className='font-range' style={{
                            marginLeft: "11.5px",
                            width: value[1] >= BUDGET_RANGE.MAX_PRICE ? "61px" : "55px" // ou une autre valeur par défaut
                        }} >Max :</p>
                    </div>
                    <input
                        id="max-input"
                        type="number" 
                        placeholder={BUDGET_RANGE.MIN_PRICE}
                        max={BUDGET_RANGE.MAX_PRICE}
                        value={value[1]=== "" ? "" : value[1]}
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/^0+/, ""); 
                            const newValue = [value[0], inputValue ? Number(inputValue) : ""];
                            setValue(newValue);
                            if (inputValue) debounceCommit(newValue);
                        }}
                        className="hidden-input"
                        onFocus={() => value[1] === BUDGET_RANGE.MAX_PRICE && setValue([value[0], ""])}
                    />
                </div>
            </div>


                   


        </div>
    )
}

export default PriceSlider