import React, { useEffect, useState,useRef, Fragment } from 'react'
import { capitalize } from '@mui/material'
import { PAX_TYPE_ARRAY,capitalizeFirstLetter, getOrdinal } from '../../constants';

function BookingPaxSelector({room,isValid,isChosen,name,maxPax,appenddata,appendBabySeats,getRank,clientinfo,serviceIndice,notFilled,headerBookingPaxSelector,bookingData}) {
    const [matchPax,setMatchPax]=useState(false);
    const [isPopupOpen,setIsPopupOpen]=useState({popup:false,data:null});
    const [clients, setClients] = useState(clientinfo);
    const closeRef=useRef(null);
    const CLICK_PARAMS = ["parent","child","add","remove"]
    

    function getRequiredPax(){
        return clientinfo.reduce((paxType,client)=>{
            let clientType = capitalizeFirstLetter(client.type)+"Count";
            if (!paxType.hasOwnProperty(clientType)) {  
                paxType[clientType] = 1;  
            } else{
                paxType[clientType]+= 1;
            }
            return paxType;
        },{})
    }

    const doesMatchPax=()=>{
        const paxType=getRequiredPax();
        
        for (const [key,value] of Object.entries(paxType)) {
            if (room[key]!=value) {
                return false;
            }
        }
        return true;
    }

    useEffect(()=>{
        let match=doesMatchPax();
        if (match&&!isValid) {
            clientinfo.map((client)=>{
                appenddata(undefined,client,serviceIndice,maxPax);
            })
        }
        setMatchPax(match);
        
    },[])


   
    const getPaxChoiceClassName=(info,serviceIndice,notFilled)=>{
       
        
        if (isChosen(info, serviceIndice) && !info.babyseat) {
            return 'checked';
        }
        if (notFilled) {
            return 'not-filled'
        }
       if(info.babyseat){
              return 'seat-included'
       }
       
        return "";

    }

    const addOtherService=(e,info,serviceIndice,maxPax,params)=>{
        e.preventDefault();

        if(params === CLICK_PARAMS[0]){
            if(info.babySeatEnabled){
                appenddata(e, info, serviceIndice, maxPax)
                const updatedClients = clients.map(item =>item.id === info.id ? { ...item, babySeatEnabled: false } : item);
                setClients(updatedClients);
            } 
            else{appenddata(e, info, serviceIndice, maxPax)}
            
        }
        else{
            e.stopPropagation();
            let transferdata = bookingData.services.reservationTransfer
            setIsPopupOpen(prevState => ({
                ...prevState,
                popup: true,
                data:info,
            })); 
        }
        
       
    }



    const addBabySeats=(e,name)=>{
        e.preventDefault();
        if(name === CLICK_PARAMS[2]){
            let{reservationTransfer}=bookingData?.services
            let {id}=isPopupOpen.data
           
            let isValid =  reservationTransfer[serviceIndice].clientRefs.includes(id)
            !isValid && appenddata(e, isPopupOpen.data, serviceIndice, maxPax)
            appendBabySeats(e, isPopupOpen.data,serviceIndice);
            setIsPopupOpen(prevState => ({
                ...prevState,
                popup: false,
                data:null,
            }));
    
            const updatedClients = clients.map(item =>item.id === isPopupOpen.data.id ? { ...item, babySeatEnabled: true } : item);
            setClients(updatedClients);
        }
        else{noNeedBabySeat(e,CLICK_PARAMS[2])}
      
    }

    const noNeedBabySeat=(e,name)=>{
        e.preventDefault();
        if(name === CLICK_PARAMS[2]){
            setIsPopupOpen(prevState => ({
                ...prevState,
                popup: false,
            }));
            const updatedClients = clients.map(item =>item.id === isPopupOpen.data.id ? { ...item, babySeatEnabled: false } : item);
            setClients(updatedClients);
            notFilled && appenddata(e, isPopupOpen.data, serviceIndice, maxPax)
        }
        else{
            setIsPopupOpen(prevState => ({
                ...prevState,
                popup: false,
            }));
        }
        
    }

console.log("clients",clients);

        return (
        <div className={`service-box ${notFilled?"not-filled":""}`}>
           {headerBookingPaxSelector}
            <div className='service-instruction'>Please confirm the pax which applies on this service ({maxPax} Pax)<span className='important-asterisk'>*</span></div>
            <div className='service-pax-info-container'>
            {/* <div className={`service-pax-container ${matchPax?"match-pax":""}`}> */}
                <div className={`service-pax-container ${matchPax && name !== "transfer" ?"match-pax":""}`}>
                    {
                        clients.map((info, idinfo) => (
                            <div key={info.type+info.id+idinfo} className={`service-box-client ${getPaxChoiceClassName(info, serviceIndice,notFilled)}`} onClick={(e) => addOtherService(e, info, serviceIndice, maxPax,CLICK_PARAMS[0]) }>
                                
                                    <div className='service-pax-info'>
                                        <div >{capitalize(info.status)} no.{info.numero}</div>
                                        <div className='service-box-client-name'>{info.first_name ?info.first_name:"N/A" }</div>
                                            {/* {(name === "transfer" && (info.type === "infant" || info.type === "child")) && (
                                                <button 
                                                    className={`add-baby-seat ${!info.babySeatEnabled ? '':'selected'}` }
                                                    onClick={(e) => addOtherService(e,info,serviceIndice,maxPax,CLICK_PARAMS[1])}
                                                   
                                                >
                                                  {  !info.babySeatEnabled ? "Add baby seat":"Baby seat added"}
                                                </button>
                                            )} */}
                                    </div>
                                    <div className={`service-pax-rank ${notFilled?"not-filled":""}`}>
                                        {getRank(info, serviceIndice)}
                                    </div>
                               
                               
                                {/* <div className='seatAdded'>{info.babyseat && "+ 1 baby seat "}</div> */}
                            </div>
                        ))
                    }
                </div>
                <i className={`${isValid ? "icon-true-green" : "icon-question-mark-yellow"}`}></i>
                <div className={`toast-error-form ${notFilled ? 'show' : ''}`}>
                    <span>Please select the concerned pax for this service</span>
                </div>
            </div>
            <textarea placeholder='Do you have any remarks for this service?' className='service-box-remarks' />
           
            
           { isPopupOpen.popup && <div className='deletepopup_container'>
                <div className='addbaby-seat-parent' ref={closeRef}>
                    <p>Would you like to {isPopupOpen.data.babySeatEnabled ? `remove`:`add`} a baby seat for {isPopupOpen.data.type} no. {isPopupOpen.data.numero} ?</p>
                    {
                        !isPopupOpen.data.babySeatEnabled ?
                        <div> A baby seat is applicable for the vehicle you have selected, ensuring safety and comfort during the journey. This option is available for your convenience. Please confirm if you would like to add it.</div>
                        :<div>The baby seat option ensures safety and comfort during the journey, and its removal will affect this feature for {isPopupOpen.data.type} no. {isPopupOpen.data.numero}. Please confirm if you would like to proceed with removing the baby seat from your selection.</div>
                    }
                    <div className='button-block'>
                        <button className='add-baby-seat-cancel' onClick={(e)=>noNeedBabySeat(e,isPopupOpen?.data?.babySeatEnabled ? CLICK_PARAMS[3] :CLICK_PARAMS[2])}>No</button>
                        <button className='add-baby-seat' onClick={(e) => addBabySeats(e, isPopupOpen?.data?.babySeatEnabled ? CLICK_PARAMS[3] :CLICK_PARAMS[2])}> {isPopupOpen.data.babySeatEnabled ? `Remove baby seat`:`Add baby seat`}</button>
                    </div>
                </div>
    
            </div>}
        </div>
       
    )
}

export default BookingPaxSelector
