import { useState } from 'react';
import axios from 'axios';
import {userLogin,userLogout,loginLoading,addingAgency,listingAgency} from "../store/authentication.js"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { REFRESH_TOKEN_ENDPOINT,LOGIN_ENDPOINT,SWITCH_AGENCY,AGENCY_LIST } from '../services/apiEndpoints.js';
import {axiosLogin,axiosRequestBody1,axiosParamsAgency} from "../services/apiClient.js"
import {clearTransfer} from "../store/transferReducer.js";
import {clearexcursion} from "../store/excursionReducer.js";
import {clearPax,clearAll} from "../store/globalReducer.js";
import {clearAccom} from "../store/accomodationReducer.js";
import { MAUTOURCO_ID, ROUTE_PATHS } from '../constants.js';

const useAuthActions = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [errorMsg, setErrorMsg] =useState("");
    const [loading,setLoading] = useState(false)
    const [listAgencyData,setListAgencyData] = useState(null)
    let refresh_token = localStorage.getItem("refresh-token");
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    
    const login = async (sendobject,setLoginData) => {
      setLoading(true)
      try {
        //dispatch(loginLoading(true))
       
        const response = await axiosLogin("POST",LOGIN_ENDPOINT,sendobject);
        
        dispatch(userLogin(response.data))
        navigate(ROUTE_PATHS.ACCOMMODATION)
        setIsLoggedIn(true);
        response.data.agency_id === MAUTOURCO_ID ? localStorage.setItem("mtco-token", response.headers['access_token']) :localStorage.setItem("access-token", response.headers['access_token']);
        localStorage.setItem("refresh-token", response.headers['refresh_token']);
        localStorage.setItem("isLoggedIn", "true");
        setLoading(false)


        // dispatch(loginLoading(false))
      } catch (error) {
        console.log("error");
        
        if(error.response){
          setLoading(false)
          setIsLoggedIn(false)
          setErrorMsg(error.response.data.message)
          setLoginData({username:"",password:""})
          // dispatch(loginLoading(false))
        }
       else{
        console.log("error 2",error);
        setErrorMsg("Network Error")
        setLoginData({username:"",password:""})
        //dispatch(loginLoading(false))
        setLoading(false)
        
       }
        
      }
    };
  
    const logout = () => {
      // Clear token from storage if needed
      // For simplicity, we'll just set the login state to false here
      setIsLoggedIn(false);
    };

    const refreshToken=async (callbackSuccess)=>{
      try {
        const response = await axios.request({
          headers:{Authorization: `Bearer ${refresh_token}`},
          method:"POST",
          url:process.env.REACT_APP_API_URL+REFRESH_TOKEN_ENDPOINT,
        });
        // console.log("headers",response.headers);
        let access_token=response.headers['access_token'];
        localStorage.setItem("access-token", access_token);
        localStorage.setItem("refresh-token", response.headers['refresh_token']);
        callbackSuccess&&callbackSuccess(access_token);
      } catch (error) {
        // console.log(error);        
        if (error.response) {
          alert(error.response?.data.message||"");
        } else {
          alert("An unexpected error occurred");
        }
      }
    }

    const listAgency = async () => {
      setLoading(true)
      try {
        const response = await axiosParamsAgency("GET",AGENCY_LIST);
        setListAgencyData(response.data.result)
        dispatch(listingAgency(response.data.result))
      } catch (error) {
        
        if(error.response){
          setLoading(false)
          setIsLoggedIn(false)
          setErrorMsg(error.response.data.message)
        }
       else{
        console.log("error 2",error);
        setErrorMsg("Network Error")
        setLoading(false)
        
       }
        
      }
    };

    const switchAgency = async (sendobject,closePopup) => {

      setLoading(true)
      try {
        const response = await axiosParamsAgency("POST",SWITCH_AGENCY,sendobject);
        
        dispatch(addingAgency(response.data))
        localStorage.setItem("access-token", response.headers['access_token']);
        localStorage.setItem("refresh-token", response.headers['refresh_token']);
        closePopup();
        dispatch(clearTransfer())
        dispatch(clearPax())
        dispatch(clearAccom())
        dispatch(clearAll())
        navigate(ROUTE_PATHS.ACCOMMODATION)
       
        


      } catch (error) {
        
        if(error.response){
          setLoading(false)
          setIsLoggedIn(false)
          setErrorMsg(error.response.data.message)
          //setLoginData({username:"",password:""})
          // dispatch(loginLoading(false))
        }
       else{
        console.log("error 2",error);
        setErrorMsg("Network Error")
        //setLoginData({username:"",password:""})
        //dispatch(loginLoading(false))
        setLoading(false)
        
       }
        
      }
    };

  
    return {
      isLoggedIn,
      login,
      errorMsg,
      logout,
      loading,
      refreshToken,
      listAgencyData,
      listAgency,
      switchAgency
    };
};

export default useAuthActions;
