
import React, { useEffect, useState } from 'react';
import { Client_dropdown, DefaultDropdown } from '../../components/Quotations/small_dropdowns';
import {CLIENTDATA,SORT_DATES,FILE_STATUS,QUOTATION_TYPE,RESERVATION_TYPE} from "../../constants"


function Dropdownlist({setFilter, page}) {
    const setDateFilter=(item)=>{
        const updateFilter = setFilter ;
        updateFilter((prev) => ({ ...prev, sort_type: item?.value, page_num:1 }));

    }
    
    const setClientTypeFilter=(item)=>{
        // console.log("value:",value);
        setFilter((prev)=>{
           return {
            ...prev,
            client_type:item?.value,
           }
        });
    }

    const setFileStatusFilter=(item)=>{
        setFilter((prev)=>{
           return {
            ...prev,
            file_status:item,
           }
        });
    }

    return (
        <div className='dropdown_list_parent'>
            <DefaultDropdown data={SORT_DATES} label="Sort by date" defaultValue={SORT_DATES[0]} onChange={setDateFilter}/>
           {page === QUOTATION_TYPE && <DefaultDropdown data={CLIENTDATA} label="Client type" onChange={setClientTypeFilter}/>}
           {page === QUOTATION_TYPE && <DefaultDropdown data={FILE_STATUS} label="File status" onChange={setFileStatusFilter}/>}
        </div>
    );
}

export default Dropdownlist;