import React,{useContext, useEffect,useRef,useState} from 'react';
import TransferInputs from './TransferInputs.js';

import Footer from '../Transfer/Footer.js';
import Quotation from '../Transfer/Quotation.js';
import SelectCars from './SelectCars.js';
import Dropdowns from '../../components/Transfer/Dropdowns.js';
import {DROPDOWN_DATA, MORE_OPTIONS_MODE} from "../../constants.js";
import { SaveCotationInputs } from '../Transfer/SaveCotation.js';
import {Back} from '../MiniComponents/Atom.js';
import NavbarBlock from '../Transfer/NavbarBlock.js';
import Skeleton from '@mui/material/Skeleton';
import { openPopup } from "../../store/errorReducer.js";
import { useDispatch,useSelector } from "react-redux";
import B2BSkeleton from '../Accomodation/minicomponents/B2BSkeleton.js';
import VEHICLES_SKELETON from "../../Assets/images/skeleton/search-vehicule-transfert.png";
import { scrollToSection, scrollToSectionv1, scrollToTop } from '../../utility/globalFunc.js';
import { LayoutContext } from '../layout/MainLayout.js';


function MainTransfer() {
    const [popupOpen,setpopupOpen] = useState(false)
    const {loading}=useSelector((state) => state.transfer_Global)
    const {actionMode}=useSelector((state) => state.globalreducer)
    const [localloading,setlocalLoading] = useState(true)
    const serviceSelectorRef=useRef(null);
    const {footerRef,setShowLeftServiceSelector} = useContext(LayoutContext);
    

    useEffect(() => {
        let observer = null;
        console.log({ref:serviceSelectorRef.current});
        
        if (serviceSelectorRef.current) {

            const options = {
                root: null,
                threshold: 1,
            }
            observer = new IntersectionObserver((entries) => {
                let entry = entries[0];
                setShowLeftServiceSelector(!entry.isIntersecting)
            }, options)
            observer.observe(serviceSelectorRef.current);
        }
        return () => {
            if (observer != null) {
                observer.disconnect();
            }
            setShowLeftServiceSelector(false);
        }
    }, [serviceSelectorRef.current,loading])
    
    const { visitedTransferList: isVisited } = useSelector((state) => state.transfer_Global);
    useEffect(() => {
        if (!isVisited) {
            scrollToSectionv1('pickCarsText', 100, 500);
        }else{
            scrollToTop(0);
        }
    }, [])
    useEffect(() => {
        setTimeout(() => {
            setlocalLoading(false);
        }, 800); 
    }, [localloading]);

    let inputStyle = {"padding": "32px 14px 36px 35px"}
    
    const gridStyle = { "gridTemplateColumns": "152px 265px 332px 32px 343px", "display": "grid", "position": "relative", }
    return (
         !loading  ? 
            <div className='mainTransfer-parent'>

                <div className='transferLeft'>
                    <div>
                    {/* {
                        !loading   ?  */}
                        <>
                            {!actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING)&&<Dropdowns dropData={DROPDOWN_DATA} imgcount={1} name="Transfer" ref={serviceSelectorRef}/>}
                            <TransferInputs gridStyle={gridStyle} newStyle={inputStyle} scroll={false} />
                            <div style={{ marginTop: isVisited ? "100px" : "200px", float: "left" }}>
                                <SelectCars />
                            </div>
                            {/* <div className='fixedQuotation'><Quotation cname="Transfer" Infant={Infant} Adult={Adult} Teen={Teen} Child={Child}/></div> */}
                        </>
                        {/* : <B2BSkeleton imgUrl={VEHICLES_SKELETON}/>
                    } */}
                    </div>
                </div>
                { popupOpen ? <div className='openPopup_save'><SaveCotationInputs closepop={setpopupOpen}/></div>:null}
            </div>
            : 
            <div className='transferSkeletonLoad1'></div>
        
    );

}

export default MainTransfer;