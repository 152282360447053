import { useDispatch } from "react-redux";
import { resetAccomSearchResult, resetAllAccom } from "../store/accomodationReducer";
import { resetAllTransfer } from "../store/transferReducer";

const useResetSearch = () => {
    const dispatch = useDispatch();
    const resetAccomCache = () => {
        dispatch(resetAccomSearchResult())
    }

    const resetTransferCache = () => {
        // dispatch(resetTransferSearch())
    }

    const resetAllServices = () =>{
        dispatch(resetAllAccom())
        dispatch(resetAllTransfer())
    }


    return { resetAccomCache,resetAllServices };
}

export default useResetSearch;