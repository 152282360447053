import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { errorReducer } from './errorReducer.js';
import { accomodationData } from './accomodationReducer.js';
import { excursionData } from './excursionReducer.js';
import { transferData } from './transferReducer.js';
import { authentication } from './authentication.js';
import { globalUpdates } from './globalReducer.js';
import createMigrate from 'redux-persist/es/createMigrate';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

let NEW_VERSION = 13;
const migrations = {
  12: (state) => ({ ...state, device: undefined }),
  [NEW_VERSION]: (state) => ({ device: state.device }),
};

const persistConfig = {
  key: 'mautourco',
  storage,
  version: NEW_VERSION,
  timeout: null,
  migrate: createMigrate(migrations, { debug: false }),
  stateReconciler: autoMergeLevel2,
  blacklist: ['excursionData', 'transferData'], // Exclude large reducers from persistence
};

const rootReducer = combineReducers({
  [excursionData.name]: excursionData.reducer,
  [transferData.name]: transferData.reducer,
  [authentication.name]: authentication.reducer,
  [errorReducer.name]: errorReducer.reducer,
  [globalUpdates.name]: globalUpdates.reducer,
  [accomodationData.name]: accomodationData.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Custom serialization function for Redux DevTools
const customSerialize = (key, value) => {
  if (key === 'largeData') return '[Too Large]';
  return value;
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
      immutableCheck: false, // Disables ImmutableStateInvariantMiddleware for better performance
    }),
  devTools: process.env.NODE_ENV !== 'production' && {
    maxAge: 50, // Keep only the last 50 actions
    shouldRecordChanges: false, // Prevents excessive memory usage
    trace: false, // Disable traces for better performance
    serialize: { replacer: customSerialize },
  },
});

export const persistor = persistStore(store);
export default store;
