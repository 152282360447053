import { CircularProgress, Stack, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getBackgroundColor } from '../../constants';

const defaultStyle={
  height:6,
  border:'none',
  // width:"134px"
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 'calc(25% - 1px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // borderColor: 'green',
      ...defaultStyle,
      backgroundColor:"#D0E7E7",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      ...defaultStyle,
      backgroundColor:"#0F7173"
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const GreenStepper = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize:16,
  fontWeight:700,
  boxShadow: '0px 2px 5.5px 0px rgba(0, 0, 0, 0.18)',
  ...(ownerState.active && {
    border: '1px solid #0F7173',
    background: '#D0E7E7',
    color:"#0F7173",
  }),
  ...(ownerState.completed && {
    background: '#0F7173',
    color:"white",
    transition:"background-color 0.3s ease",
    cursor:"pointer",
    "&:hover":{
      background: '#033132',
    }
  }),
  ...(ownerState.disable&&{
    background: '#DDD',
  })
}));

function GreenStepIcon({ active, completed,disable, label,className}) {

  return (
    <GreenStepper ownerState={{ completed, active,disable }} className={className}>
      {/* {icons[String(props.icon)]} */}
      {label}
    </GreenStepper>
  );
}

function BookingStepper({steps,activeStep=1,setActiveStep,loading=false,disableButton=false}) {
  const [currentStep,setCurrentStep]=useState(null);
  const [containerAdditionalClass,setContainerAdditionalClass]=useState(null);
  const getActiveStep=()=>{
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].step===activeStep) {
        return steps[i];
      }
    }
    return null;
  }

  useEffect(()=>{
    let newstep=getActiveStep();
    setCurrentStep(newstep);
    setContainerAdditionalClass(newstep.containerAdditionalClass)
  },[steps,activeStep])

  const onClick=(step)=>{
    if(step<activeStep){
      setActiveStep&&setActiveStep(step);
    }
  }

  
  return (
    <>
    {steps?<div className={`stepper-container ${containerAdditionalClass || ""}`}>
    <Stack sx={{ width: '400px' }} spacing={4}>
      <Stepper activeStep={activeStep} alternativeLabel connector={<CustomConnector />}> 
        {
          steps.map((step,ind)=>(
            <Step key={step.label+ind}>
              <StepLabel StepIconProps={{active:step.step===activeStep,completed:activeStep>step.step,label:step.step,disable:activeStep<step.step}}  StepIconComponent={GreenStepIcon} onClick={()=>onClick(step.step)}>
                <span className={`label-step ${step.step===activeStep?"current":step.step<activeStep?"done":"disable"}`}>{step.label}</span>
              </StepLabel>
            </Step>
          ))
        }
      </Stepper>
      </Stack>
      <button onClick={currentStep?.onNext || (()=>{})} className={`next-step-button  ${loading ? 'loading disable' :''} ${disableButton ? 'disable':''}`}>
                      {!loading ? (
                       currentStep?.buttonLabel || "Next"
                      ) : (
                        <CircularProgress
                          color="secondary"
                          style={{ width: '20px', height: '20px', color: '#fff' }}
                        />
                      )}
      </button>
    </div>:null}
    </>
  )
}

export default BookingStepper
