import React, { useState,useEffect,useRef, forwardRef, useContext } from "react";
import downarr from "../../Assets/images/down1.png"
import whiteArrow from "../../Assets/images/whiteArrow.png"
import { useLocation, useNavigate } from 'react-router-dom';
import {useDispatch,useSelector } from "react-redux";
import {getPageName} from "../../store/globalReducer.js"
import chambre1 from "../../Assets/images/chambre1.png"
import chambre2 from '../../Assets/images/chambre2.png'
import chambre3 from '../../Assets/images/chambre3.png'
import chambre4 from '../../Assets/images/chambre4.png'
import selchambre1 from '../../Assets/images/selchambre1.png'
import selchambre2 from '../../Assets/images/selchambre2.png'
import selchambre3 from '../../Assets/images/selchambre3.png'
import selchambre4 from '../../Assets/images/selchambre4.png'
import useResetSearch from "../../hooks/useResetSearch.js";



const Dropdowns=forwardRef(({ dropData=[],moveLeft,imgcount,name,center=false},ref)=>{


let IMAGE_PATH = [chambre1,chambre2,chambre3,chambre4]
let SUB_IMAGE_PATH = [selchambre1,selchambre2,selchambre3,selchambre4]


  const [seldata, setselData] = useState({
    defaultValue: name,
    defaultImage:IMAGE_PATH[imgcount -1],
    defaultImage:chambre1,
    defaultArr: downarr,
    selectedImage: SUB_IMAGE_PATH[imgcount -1],
    selectedArr:whiteArrow,
    selected: false,
  });
  const [optionData, setOptionData] = useState(dropData);
  const [popupOpen, setPopupOpen] = useState(false);
  const [friendlyZone, setFriendlyZone] = useState(false);
  const inpref = useRef(null);
  const dropDownRef=useRef(null);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location=useLocation();
  const {resetAccomCache}=useResetSearch();

  useEffect(() => {
    if(popupOpen===true){
     setFriendlyZone(false)
     inpref.current.focus();
    }
   }, [popupOpen]);


  const handleDropdown = (e, name,data) => {
    e.preventDefault();
    const pathname = window.location.pathname;
    const isServicePage = optionData.some(item => 
      item.subpage && item.subpage.includes(pathname)
    );

    if (name === "popOpen") {
      setPopupOpen(!popupOpen);
      console.log("1");
      
    }
    
    else if (name === "popClose" && data.label.length && isServicePage ) { 
      dispatch(getPageName(data.label)) 
      setPopupOpen(false);
      setFriendlyZone(false)
      setselData((prevstate) => ({
            ...prevstate,
            defaultValue: "Accommodation",
            defaultImage: IMAGE_PATH+"chambre1.png",
            selectedImage:IMAGE_PATH+"wchambre1.png"
      })); 
      console.log("2");
      
      resetAccomCache();
      navigate(data.subpage[0]); 
    }
    else{
      console.log("3");
      
      navigate(data.path);
    }
    
  }

  const handleClick1 = (e,name) => {
    e.preventDefault();
    if(!friendlyZone && popupOpen)
        { 
          setPopupOpen(false);
        }
     if(friendlyZone){
      inpref.current.focus();
      
     }     
  }

  const mouseFunc = (e, name) => {
    if (name === "mouseEnter") {
      setFriendlyZone(true);
    }
    if (name === "mouseLeave") {
      setFriendlyZone(false);
    }
  }

  const getServiceBackground = (service) => {     
    return service.label !== seldata.defaultValue ?`url(${service.imagesrc})`:`url(${service.imagesrc2})`;
  }

  useEffect(()=>{
    const {pathname}=location;
    const index = dropData.findIndex((item)=>item.page===pathname||item.subpage.includes(pathname))
    
    if (index>=0) { 
      setselData(prev=>({
        ...prev,
        defaultValue:dropData[index].label,
        defaultImage:IMAGE_PATH[index],
        selectedImage:SUB_IMAGE_PATH[index]
      }))
    }
  },[location])
  
  return (
    <div id="selectDropdown" className={"selectDropdown "+(moveLeft ? "left120":"")+ (center?" flex-center":"")} ref={ref}>
      <div
        className={`selectboxDiv ${popupOpen ? "selectedbox":""}` }
        onClick={(e) => handleDropdown(e, "popOpen",{})}
        onBlur={(e) => handleClick1(e,"droppop")}
        ref={inpref}
        tabIndex="1"
        style={{backgroundImage:popupOpen ?
         `url(${seldata.selectedImage})`
        : `url(${seldata.defaultImage})`
        }}

      >
        {seldata.defaultValue}
      </div>



     
         <div>
          {popupOpen ?
            <div  className={`dropdown-option ${center?"center-self-absolute-x":""}`} ref={dropDownRef}  onMouseLeave={(e)=>{mouseFunc(e,"mouseLeave")}} onMouseEnter={(e)=>{mouseFunc(e,"mouseEnter")}}>
                {optionData.map((data, ind) => {
                  return (
                    <div key={ind} id={data.label + "_portal"} className="dropdown-optmenu">
                    
                        <div
                          className={data.label !== seldata.defaultValue ?"OptionValueBox dropValue":"OptionValueBox_sel" }
                          onClick={(e) => handleDropdown(e,"popClose",data)}
                        >
                          <span style={{ backgroundImage:getServiceBackground(data)}} className={"dropopt_img " +"dropopt_img_"+data.label}></span>
                          {data.label}
                        </div>
                      
                    </div>
                  );
                })}
            </div>:<></>
          }
        </div> 
    </div>
  );
})

export default Dropdowns;