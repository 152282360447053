import React,{useState,useEffect} from 'react';
import { Document, Page, Text, View,  StyleSheet, Image } from '@react-pdf/renderer';
import arrivalPlane from './img/arrival-plane.png';
import departurePlane from './img/departure-plane.png';
import accom from './img/room.png';
import transfer from './img/transfer.png';
import roomB from './img/roomB.png';
import night from './img/night.png';
import user from './img/user.png';
import meal from './img/meal.png';
import voiture from './img/voiture.png';
import baby from './img/baby.png';
import luggage from './img/luggage.png';
import arrow from './img/arrow.png';
import calendar from './img/calendar.png';
import logo from './img/logo.png';
import cocktail from './img/cocktail.png';
import arrowB from './img/arrowB.png';
import calendarB from './img/calendarB.png';
import tranfser from './img/transfer.png';
import logofooter from './img/logofooter.png';

import { calculateNights,  formatPrice, dateToConventionalFormat, getBeforeUnderscore, properCase,transformDate,docketTotalPrice,separateurMillier } from '../../../utility/globalFunc';
import { capitalizeFirstLetter, pluralize } from '../../../../src/constants';










// Register the Satoshi font
// Font.register({
//     family: 'Satoshi',
//     src: '/public/fonts/Satoshi-Regular.otf',
// });
// Create styles
const styles = StyleSheet.create({
    page: {
        paddingBottom: 20, // Ajoute un espace de plusieurs centimètres
        paddingTop: 8, // Facultatif
        paddingLeft: 4,
        paddingRight: 4,
        position: "relative", // Met le contenu au-dessus de l'image
        zIndex: 1,
    },
    backgroundImage: {
        position: "absolute",
        top: 400,
        right: 0,
        width: "30%",
        height: "40%",
        opacity: 0.8, // Ajuste la transparence si nécessaire
    },
    header: {
        display: 'flex',
        flexDirection: 'row', // Explicitly set to row
        width: '100%',
        justifyContent: 'space-between', // Space between the two sections
    },
    logoContainer: {
        display: "flex",
        flexDirection: "row", // Assurez-vous que la direction est horizontale
        justifyContent: "flex-end", // Place le contenu à droite
        alignItems: "center", // Centre verticalement
        width: "100%", // Assurez-vous que le conteneur occupe toute la largeur
        padding: 0, // Optionnel, ajoute un espacement interne           // Optionnel : ajoute de l'espace autour
    },
    logo:{
        width:112,
        // height:30
    },
    fontFile: {
        fontSize: 10,
        color: '#585858',
        fontWeight: '400',
        textDecoration: 'underline',
        textUnderlineOffset: '4px',
        marginBottom: '16px',
    },
    fontAgency:{
        color: '#0F7173',
        fontSize: 10,
        fontWeight: '500',
    },
    section:{
        display: 'flex',
        flexDirection: 'column',
    },
    fontQuotation: {
        color: '#FFA75D',
        fontSize: 12,
        fontWeight: '700',
        marginBottom: '5px',
    },
    documentDetail:{
        minWidth: '210px',
        height: 'auto',
        paddingVertical:14,
        paddingLeft:14,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '12px',
        backgroundColor: "#F9F8F8",
        border: '0.5px solid #E9E9E9',
        background: '#F9F8F8',
        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.5)',
        padding: '10px 1px 10px 10px',
        marginTop: '5px',
        container: {
            display: 'flex',
            flexDirection: 'row',
        },
        containerL: {
            //   backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
        containerR: {
            // backgroundColor: 'blue',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
        title: {
            color: '#0F7173',
            fontSize: 10,
            fontWeight: '700',
            marginBottom: '7px',
        },
        textStyle: {
            color: '#434343',
            // marginLeft: 10,
            // fontFamily: 'Satoshi',
            // fontFamily: 'Arial',
            fontSize: '10px',
            marginTop: '1px',
        },
        textStyleValue: {
            color: '#434343',
            marginLeft: 10,
            // fontFamily: 'Satoshi',
            // fontFamily: 'Arial',
            fontSize: '10px',
            marginTop: '1px',
        },
    },

/////////////////
    timeline: {
        position: 'relative',
        marginTop: '0px',
        // bottom:57,
        multiTimeLine: {
            display: 'flex',
            flexDirection: 'row',
            multiTimeLineRoot: {
                display: 'flex',
                position: 'relative',
            },
            multiTimeLineSeparatorRoot: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                arrivalTimelineTimeLine: {
                    position: 'relative',
                    top: 10,
                    width: 14,
                    height: 14,
                },
                depatureTimelineTimeLine: {
                    position: 'relative',
                    width: 14,
                    height: 14,
                    top: 10,
                }
            },
            MuiTimelineContentRoot: {
                display: 'flex',
                // marginHorizontal:5,
                flex: 1,
                flexDirection: 'column',
                textAlign: 'left',
                arrival: {
                    padding: 0,
                    paddingLeft: 10,
                    fontSize: 8,

                },
                arrivalLocation: {
                    marginTop: 15,
                    fontSize: 8,
                    paddingLeft: 10,
                }
            }
        },
        multiTimeLineConnector: {
            display: 'flex',
            width: 4,
            height: '20px',
            flexGrow: 1,
            backgroundColor: '#0f71734d',
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3,
            position: 'relative',
            top: 20,
        },
        multiTimeLineDepature: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 23,
        }
    },
    timelineService: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        MuiTimelineContentRoot: {
            display: 'flex',
            marginTop: '15px',
            flex: 1,
            flexDirection: 'column',
            textAlign: 'left',
            rowDate: {
                paddingLeft:10,
                paddingTop:10,
                display: 'flex',
                flexDirection: 'row', // Positionne l'image et le texte côte à côte
                alignItems: 'center', // Aligne verticalement au centre
            },
            rowPlace: {
                paddingLeft:10,
                paddingTop:10,
                display: 'flex',
                flexDirection: 'column', // Positionne l'image et le texte côte à côte
                alignItems: 'start', // Aligne verticalement au centre
            },
            service: {
                padding: 0,
                paddingLeft: 10,
                fontSize: 8,
                color: '#0f7173',
                fontWeight: '700'
            },
            supplement: {
                padding: 0,
                paddingLeft: 10,
                fontSize: 8,
                color: '#434343',
                fontWeight: '700'
            },
            accomserviceHotel: {
                padding: 0,
                paddingLeft: 10,
                fontSize: 8,
                color: '#0A0A0A',
                fontWeight: '700',
                paddingTop: 10
            },
            accomCalendarDate: {
                paddingTop: 10,
                color: '#0F7173',
                paddingLeft: 10,
                fontSize: 8,
            },
            night: {
                paddingTop: 5,
                marginBottom: 10,
                paddingLeft: 10,
                fontSize: 8,
            },
            imageArrow: {
                width: 9, // Largeur de l'image
                height: 9, // Hauteur de l'image
                marginVertical: 5, // Décalage vers le bas
                transform:"rotate(90deg)"
            },
        
        },
        multiTimeLineConnector: {
            display: 'flex',
            width: 4,
            flexGrow: 1,
            backgroundColor: '#0f71734d',
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3,
            position: 'relative',
            top: 23,
        },
        timelineRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 10,
            marginTop: 5,
        },
        imageContainerTimeline: {
            paddingTop: 2,
            marginRight: 5,
        },
        accomDetails: {
            marginTop: 3,
            fontEmptyRoom: {
                fontSize: 8,
                fontWeight: 100,
            },
            fontAdult: {
                fontSize: 8,
                fontWeight: 600,
                color: '#4A4A4A',
            },
        }
    },
////////////////
    containerTable: {
        width: "100%",
        background: "#FFFFFF",
        display: 'flex',
        flexDirection: 'column',
        pageBreakInside: 'avoid', 
        pageBreakBefore: 'always',
        breakAfter: 'always',
    },
    headerTable: {
        marginTop: "25px",
        font:{
            // fontFamily: 'Satoshi',
            fontSize: 8,
            fontWeight: 400,
            lineHeight: '21.6px',
            textAlign: 'left',
        },
        column: {
            flex: 1, // Taille par défaut des colonnes
            textAlign: 'left',
        },
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "space-between", // Adjust space between columns
        // alignItems: "center", // Align items vertically in the center
        // paddingLeft: 3,
        backgroundColor: "#EEEEEE", // Light gray background
        flexDirection: "row", // Organiser les colonnes côte à côte
        pageBreakInside: 'avoid', // Empêche la rupture de page à l'intérieur du tableau
        breakInside: 'avoid',  
        borderRadius: '9px',
        // pageBreakBefore: 'always',
    },
        bodyTable: {
            marginTop: 18,
            fontService: {
                fontSize: 8,
                fontWeight: 700,
                lineHeight: '18.9px',
                textAlign: 'left',
                color: '#0F7173'
            },
            width: "100%",
            height: "auto",
            border: "solid 1px grey",
            display: "flex",
            alignItems: 'stretch', // S'assurer que chaque colonne occupe toute la hauteur disponible
            flexDirection: "row", // Organiser les colonnes côte à côte
            pageBreakInside: 'avoid', // Empêche la rupture de page à l'intérieur du tableau
            breakInside: 'avoid',   // Assure que le contenu reste dans la même page
             // Assure que le contenu reste dans la même page
        },
    columnServiceH: {
        width: 180,
        // height:'fit',
        textAlign: 'left',
        paddingLeft: 15,
        paddingTop:10,
    },
    columnDetailsH: {
        width: 230,
        // height:'auto',
        textAlign: 'left',
        paddingRight: 10,
        paddingTop:10,
    },
    columnDateH: {
        width: 90,
        textAlign: 'left',
        paddingTop:10,
    },
    columnPaxH: {
        width: 80,
        textAlign: 'center',
        pageBreakInside: 'avoid', // Empêche la rupture de page à l'intérieur du tableau
        breakInside: 'avoid',
        paddingTop:10,
    },
    columnPriceH: {
        width: 130,
        paddingLeft: 40,
        textAlign: 'center',
        paddingTop:10,
    },
        columnService: {
        width: 180,
        // height:'fit',
        textAlign: 'left',
        paddingRight:10,
        paddingLeft: 8,

    },
    columnDetails: {
        width: 230,
        // height:'auto',
        textAlign: 'left',
        paddingRight: 10,
    },
    columnDate: {
        width: 90,
        textAlign: 'left',
    },
    columnPax: {
        width: 80,
        textAlign: 'center',
        pageBreakInside: 'avoid', // Empêche la rupture de page à l'intérieur du tableau
        breakInside: 'avoid',
    },
    columnPrice: {
        width: 130,
        paddingLeft: 20,
        textAlign: 'left',
    },
    noBreak:{
        pageBreakInside: 'avoid',
        breakInside: 'avoid',  
    },
    column: {
        pageBreakInside: 'avoid',
        breakInside: 'avoid',  
        flex: 1, // Each column takes equal width
        alignItems: "flex-start", // Align content to the left
        justifyContent: "center", // Center vertically
        padding: 5, // Optional padding for each column
        textAlign: "left", // Align text to the left
    },
    fontLabelService: {
        fontSize: 8,
        fontWeight: 700,
        marginTop:8,
        textAlign: 'left',
    },
    fontLabel: {
        fontSize: 8,
        fontWeight: 500,
        textAlign: 'left',
    },
    fontLabelTitle:{
        fontSize: 8,
        fontWeight: 'bold',
        marginVertical:5
    },
    fontDate:{
        // fontFamily: Satoshi,
        fontSize: 8,
        fontWeight: 400,
    },
    containerPax:{
        width: 'auto',
        height: 12,
        paddingHorizontal: '4px',
        borderRadius: 13,
        backgroundColor: "#0F7173",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        marginBottom: 4,
    },
    containerPaxEmpty:{
        width: 'auto',
        height: 12,
        paddingHorizontal: '4px',
        borderRadius: 13,
        borderWidth: 1,
        borderColor: '#0F7173',
        borderStyle: 'solid',
        backgroundColor: "white",
        // borderColor: "#0F7173",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        marginBottom:4,
    },
    fontWhite:{
        fontSize:8,
        color: 'white',
    },
    fontEmpty: {
        fontSize: 8,
        color: "#0F7173",
    },
    separateur: {
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
        height: 0, // Ajoutez cette ligne  
        marginVertical: 5,
    },  
    row: {
        display: 'flex',
        flexDirection: 'row', // Positionne l'image et le texte côte à côte
        alignItems: 'center', // Aligne verticalement au centre
    },
    rowMulti: {
        display: 'flex',
        flexDirection: 'row', // Positionne l'image et le texte côte à côte
        justifyContent: 'flex-start', // Aligne verticalement au centre
        // paddingBottom: 1,
        marginTop:1,
        // backgroundColor:"red"
    },
    containerClientType: {
        display: 'flex',
        flexDirection: 'row', // Positionne l'image et le texte côte à côte
        justifyContent: 'flex-start', // Aligne verticalement au centre
        // paddingBottom: 2,
        // marginTop: 
    },
    imageContainer: {
        marginRight: 4, // Espace entre l'image et le texte
    },
    image: {
        width: 10, // Largeur de l'image
        height: 10, // Hauteur de l'image
        marginBottom: 5, // Décalage vers le bas
    },
    imageN: {
        width: 7, // Largeur de l'image
        height: 7, // Hauteur de l'image
        marginBottom: 1, // Décalage vers le bas
    },
    imageM: {
        width: 6.5, // Largeur de l'image
        height: 6.5, // Hauteur de l'image
        marginTop: 2, // Décalage vers le bas
    },
    imageT: {
        width: 7, // Largeur de l'image
        height: 7, // Hauteur de l'image
        marginTop: 10, // Décalage vers le bas
        marginLeft:2
    },

    total: {
        display: "flex",
        // justifyContent: "flex-end",
        // marginTop:10,
        marginRight:25
    },
    totalFont:{
        textAlign:"right",
        marginBottom:8,
        marginTop: 8,
        fontSize: 11,
        fontWeight: 500,
    },
    separator: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 10,
        borderWidth: 0.2,
        borderColor: 'white', // Bordure rouge pour identifier la zone
    },
    dash: {
        width: 5,
        height: 0.1,
        backgroundColor: 'grey',
        marginHorizontal: 2,
        borderWidth: 1,
        // borderColor: 'blue', // Bordure bleue pour les tirets
    },
    containerFooter: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: 40, // Ajuste selon le besoin
        backgroundColor: "#F9A65A", // Couleur du footer (orange)
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingHorizontal: 20,
    },
    rowFooter: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    footerleft: {
        flex: 1,
    },
    footerright: {
        flex: 1,
        textAlign: "right",
    },
    logoFooter: {
        width: 70, 
    },
    fontfooter: {
        fontSize: 10,
        color: "white",
    },

    containerForm: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap', // Permet le passage à la ligne
        padding: 10,
        justifyContent: 'space-between',
    },

    containerRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    nomPrenom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '48%', // Pour s'assurer que 2 éléments tiennent sur une ligne
        marginBottom: 10, // Espacement entre les lignes
    },

    formAndLabel: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginRight: 10,
        marginBottom: 10,
    },

    form: {
        width: '100%',
        height: 25,
        borderRadius: 25,
        backgroundColor: '#F5F5F5',
        borderWidth: 1,
        paddingHorizontal: 5,
        paddingTop: 6,
        paddingLeft: 18,
        borderColor: '#DDDDDD'
    },

    fontForm: {
        // font- family: Satoshi;
        color: '#666666',
        fontSize: 8,
        fontWeight: 700,
    },
    fontLabelForm: {
        color: '#666666',
        fontSize: 8,
        fontWeight: 700,
        marginLeft: 8,
        marginBottom: 10,
    },
    recapFont:{
        color: '#3F7B9C',
        fontSize: 12,
        fontWeight: 700,
        marginBottom: 35,
    },
    notSaved:{
        color:"#979797",
        fontSize:10,
    },
    //cancellation Policy Style

    roomPolicy: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 25,
    },

    periodPolicy: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 8,
        marginTop: 8,
        maxWidth: 310,
        height: 25,
        borderRadius: 25,
        borderWidth: 1,
        paddingHorizontal: 5,
        paddingTop: 6,
        paddingLeft: 18,
        borderColor: '#FFAB63',
        // backgroundColor:"blue"
    },
    fontPolicy: {
        fontSize: 8,
        fontWeight: 'bold',
        marginRight: 30
    },
    arrowPolicy: {
        width: 7, // Largeur de l'image
        height: 7, // Hauteur de l'image
        marginTop: 2,
        marginHorizontal: 10
    },
    row2: {
        display: 'flex',
        flexDirection: 'row', // Positionne l'image et le texte côte à côte
        // alignItems: 'center', // Aligne verticalement au centre
    },
    pourcentagePolicy: {
        display: 'flex',
        flexDirection: 'column', // Positionne l'image et le texte côte à côte
        // alignItems: 'center', // Aligne verticalement au centre
    },
    sec: {
        display: 'flex',
        flexDirection: 'column',
        // marginVertical:30
    },
    fontPourcent: {
        fontSize: 10,
        fontWeight: "bold",
        // marginLeft: 8,
        marginVertical: 10,
    },
    fontDescPourcent: {
        fontSize: 8.5,
        // fontWeight: 700,
        // marginLeft: 8,
        marginBottom: 10,
    },
    imagePolicy: {
        width: 9, // Largeur de l'image
        height: 9, // Hauteur de l'image
        marginBottom: 1,
    },
    cancellationPolictyboxTypes: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#F9F8F8",
        borderRadius: 10,
        // borderWidth: 1,
        // borderStyle: 'solid',
        // borderColor: '#FFAB63',
        marginTop:20,
        padding: '15px',
        width: '275px',
        height: '88px',
        serviceType: {
            display: 'flex',
            flexDirection: 'row',
            gap: 5,
            height: '20px'
        },
        cancellationsericeImage: {
            position: 'relative',
            width: 12,
            height: 12,
        },
        cancellationserviceTextTitle: {
            fontSize: 10,
            fontWeight: 700,
            color: '#434343'
        },
        rowDate: {
            paddingTop: 10,
            display: 'flex',
            flexDirection: 'row', // Positionne l'image et le texte côte à côte
        },
        imageContainer: {
            marginRight: 5, // Espace entre l'image et le texte
        },
        imageN: {
            top: 1,
            width: 8, // Largeur de l'image
            height: 8, // Hauteur de l'image
            marginBottom: 1, // Décalage vers le bas
        },
        fontEmpty: {
            fontSize: 8,
            color: "#0F7173",
        },
        fontEmptyBold: {
            fontSize: 8,
            fontWeight: '800',
            color: "#0F7173",
        },
        destination: {
            display: 'flex',
            flexDirection: 'row',
            borderLeftWidth: 3,
            borderLeftColor: '#D9D9D9',
            paddingLeft: 4
        },
        timelineRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 10,
        },
        imageContainerTimeline: {
            paddingTop: 2,
            marginRight: 5,
        },
        fontLabel: {
            fontSize: 8,
            fontWeight: 500,
            textAlign: 'left',
        },
    },
    containerLine: {
        flexDirection: 'row',
        alignItems: 'center',
        // justifyContent: 'center',
        marginVertical: 10,
    },
    line: {
        width: 50, // Ajuster selon besoin
        borderBottomWidth: 1,
        borderBottomColor: '#888',
        borderBottomStyle: 'solid',
    },
    lineTotal: {
        width: "100%", // Ajuster selon besoin
        borderBottomWidth: 0.5,
        borderBottomColor: '#888',
        borderBottomStyle: 'solid',
    },
    lineBehind: {
        marginLeft: 25,
        width: "100%", // Ajuster selon besoin
        borderBottomWidth: 1,
        borderBottomColor: '#888',
        borderBottomStyle: 'solid',
    },
    text: {
        marginHorizontal: 10,
        fontSize: 8,
        color: '#888',
    },
    totalText:{
        fontSize: 10,
        color:'#000',
        marginBottom:1
    },

  


    ////////////// docket price
    docketPriceParent:{
        width: '100%',
        flexDirection: 'row', // Ensures horizontal layout
        justifyContent: 'space-between', // Spaces elements across the row
        alignItems: 'center', // Aligns items properly
        paddingHorizontal: 10, // Optional: Adds space from edges
        paddingVertical: 10,
    }

});






function getDocketPrice (data){   
   const docketPrice =  docketTotalPrice(data.services ? data.services : data)

    return (
      <View style={styles.docketPriceParent}>
        <View><Text style={styles.totalText}>Total:</Text></View>
        <View style={styles.priceList}>
          {docketPrice.map((prices, ind) => (
                <Text key={ind} style={styles.totalText}>{prices.currency}. {separateurMillier(prices.price, ',')}</Text>
          ))}
        </View>
      </View>
    );
};

function numberOfPax(service) {
  
    let pax = null;
    if (service.hasOwnProperty("HotelId")) {
        pax = service.Rooms.reduce(
            (acc, room) => {
                return {
                    AdultCount: acc.AdultCount + room.AdultCount,
                    ChildCount: acc.ChildCount + room.ChildCount,
                    InfantCount: acc.InfantCount + room.InfantCount,
                    TeenCount: acc.TeenCount + room.TeenCount,
                };
            },
            {
                AdultCount: 0,
                ChildCount: 0,
                InfantCount: 0,
                TeenCount: 0,
            }
    )}
    else{
        pax = service
    }

    return (
        <View>
            <View style={pax.AdultCount != 0 ? styles.containerPax : styles.containerPaxEmpty}>
                <Text style={pax.AdultCount != 0 ? styles.fontWhite : styles.fontEmpty}>
                    {`Adult${pax.AdultCount > 1 ? "s" : ""} ${pax.AdultCount}`}
                </Text>
            </View>

            <View style={pax.TeenCount != 0 ? styles.containerPax : styles.containerPaxEmpty}>
                <Text style={pax.TeenCount != 0 ? styles.fontWhite : styles.fontEmpty}>
                    {`Teen${pax.TeenCount > 1 ? "s" : ""} ${pax.TeenCount}`}
                </Text>
            </View>

            <View style={pax.ChildCount != 0 ? styles.containerPax : styles.containerPaxEmpty}>
                <Text style={pax.ChildCount != 0 ? styles.fontWhite : styles.fontEmpty}>
                    {`Child${pax.ChildCount > 1 ? "ren" : ""} ${pax.ChildCount}`}
                </Text>
            </View>

            <View style={pax.InfantCount != 0 ? styles.containerPax : styles.containerPaxEmpty}>
                <Text style={pax.InfantCount != 0 ? styles.fontWhite : styles.fontEmpty}>
                    {`Infant${pax.InfantCount > 1 ? "s" : ""} ${pax.InfantCount}`}
                </Text>
            </View>

        </View>
    )  
}
function checkIfBookingorQuotation(data){
    // console.log({data});
    
    if (data.quoteBookList.hasOwnProperty("Clients")){
         return (
             <View style={styles.containerForm}>
                 <Text style={styles.recapFont}> 1. Client information </Text>
                 {data.quoteBookList.Clients.map((item, index) => (
                     index % 2 === 0 ? ( // Si c'est un élément pair, on crée une nouvelle ligne
                         <View style={styles.containerRow} key={index}>
                             <View style={styles.nomPrenom}>
                                 <View style={styles.formAndLabel}>
                                     <Text style={styles.fontLabelForm}>{item.client_status} No.{index + 1}</Text>
                                     <View style={styles.form}>
                                         <Text style={styles.fontForm}>{item.name}</Text>
                                     </View>
                                 </View>
                                 <View style={styles.formAndLabel}>
                                     <View style={styles.form}>
                                         <Text style={styles.fontForm}>{item.surName}</Text>
                                     </View>
                                 </View>
                             </View>

                             {/* Vérification pour éviter l'erreur si c'est le dernier élément */}
                             {data.quoteBookList.Clients[index + 1] && (
                                 <View style={styles.nomPrenom}>
                                     <View style={styles.formAndLabel}>
                                         <Text style={styles.fontLabelForm}>
                                             {data.quoteBookList.Clients[index + 1].client_status} No.{index + 2}
                                         </Text>
                                         <View style={styles.form}>
                                             <Text style={styles.fontForm}>{data.quoteBookList.Clients[index + 1].name}</Text>
                                         </View>
                                     </View>
                                     <View style={styles.formAndLabel}>
                                         <View style={styles.form}>
                                             <Text style={styles.fontForm}>{data.quoteBookList.Clients[index + 1].surName}</Text>
                                         </View>
                                     </View>
                                 </View>
                             )}
                         </View>
                     ) : null
                 ))}
             </View>

         ) 
    }
    else{
       return (
           <View style={styles.timeline}>
               <View style={styles.timeline.multiTimeLine}>
                   <View style={styles.timeline.multiTimeLine.multiTimeLineSeparatorRoot}>
                       <Image src={arrivalPlane} style={styles.timeline.multiTimeLine.multiTimeLineSeparatorRoot.arrivalTimelineTimeLine} />
                       <View src={arrivalPlane} style={styles.timeline.multiTimeLineConnector} ></View>
                   </View>
                   <View style={styles.timeline.multiTimeLine.MuiTimelineContentRoot}>
                       <Text style={styles.timeline.multiTimeLine.MuiTimelineContentRoot.arrival} >Arrival</Text>
                       <Text style={styles.timeline.multiTimeLine.MuiTimelineContentRoot.arrivalLocation}>Sir Seewoosagur Ramgoolam International Airport</Text>
                   </View>
               </View>



               {
                   data?.allServices.map((service,indice) => (

                       service.hasOwnProperty("HotelId") ?
                           (<View style={styles.timelineService}>
                               <View style={styles.timeline.multiTimeLine.multiTimeLineSeparatorRoot}>
                                   <Image src={accom} style={styles.timeline.multiTimeLine.multiTimeLineSeparatorRoot.arrivalTimelineTimeLine} />
                                   <View style={styles.timelineService.multiTimeLineConnector} ></View>
                               </View>
                               <View style={styles.timelineService.MuiTimelineContentRoot}>
                                   <Text style={styles.timelineService.MuiTimelineContentRoot.service} >Accommodation</Text>
                                   <Text style={styles.timelineService.MuiTimelineContentRoot.accomserviceHotel} >{service.HotelName}</Text>



                                   {/* //ETO */}

                                   {service.Rooms.map((room, ind) => (
                                    <View >
                                        <View style={{marginTop:'10px'}} >
                                            <View style={styles.timelineService.timelineRow}>
                                                <View style={styles.timelineService.imageContainerTimeline}>
                                                    <Image style={styles.imageN} src={roomB} />
                                                </View>
                                                <Text style={styles.timelineService.accomDetails.fontAdult}>Room {ind + 1}</Text>
                                            </View>
                                            <View style={styles.timelineService.timelineRow}>
                                                <View style={styles.imageContainer}>
                                                    <Image style={styles.imageN} src={calendarB} />
                                                </View>
                                                <Text style={styles.fontEmpty}>{transformDate(room?.Dates[0])} </Text>
                                                <View style={styles.imageContainer}>
                                                    <Image style={styles.imageN} src={arrowB} />
                                                </View>
                                                <View style={styles.imageContainer}>
                                                    <Image style={styles.imageN} src={calendarB} />
                                                </View>
                                                <Text style={styles.fontEmpty}>{transformDate(room?.Dates[1])} </Text>
                                            </View>
                                        </View>
                                            <View style={styles.timelineService.timelineRow}>
                                                <View style={styles.timelineService.imageContainerTimeline}>
                                                    <Image style={styles.imageN} src={night} />
                                                </View>
                                                <Text style={styles.fontLabel}>{calculateNights(room.Dates[0], room.Dates[1])} nights</Text>
                                            </View>
                                                <View style={styles.timelineService.accomDetails}>


                                            <View style={styles.timelineService.timelineRow}>
                                                <Text style={styles.timelineService.accomDetails.fontEmptyRoom}><Text style={{ fontWeight: 600 }}>Client type</Text> | {capitalizeFirstLetter(room?.ClientType  || room?.ClientCategory, false)}</Text>
                                            </View>
                                            {room.AdultCount > 0 &&
                                                <View style={styles.timelineService.timelineRow}>
                                                    <View style={styles.timelineService.imageContainerTimeline}>
                                                        <Image style={styles.imageN} src={user} />
                                                    </View>
                                                    <Text style={styles.timelineService.accomDetails.fontEmptyRoom}>{`${room.AdultCount} ${pluralize(room.AdultCount, "Adult", "Adults")}`} </Text>
                                                </View>
                                            }
                                            {room.TeenCount > 0 &&
                                                <View style={styles.timelineService.timelineRow}>
                                                    <View style={styles.timelineService.imageContainerTimeline}>
                                                        <Image style={styles.imageN} src={user} />
                                                    </View>
                                                    <Text style={styles.timelineService.accomDetails.fontEmptyRoom}>{`${room.TeenCount} ${pluralize(room.TeenCount, "Teen", "Teens")}`} </Text>
                                                </View>
                                            }
                                            {room.ChildCount > 0 &&
                                                <View style={styles.timelineService.timelineRow}>
                                                    <View style={styles.timelineService.imageContainerTimeline}>
                                                        <Image style={styles.imageN} src={user} />
                                                    </View>
                                                    <Text style={styles.timelineService.accomDetails.fontEmptyRoom}>{`${room.ChildCount} ${pluralize(room.ChildCount, "Child", "Children")}`} </Text>
                                                </View>
                                            }
                                            {room.InfantCount > 0 &&
                                                <View style={styles.timelineService.timelineRow}>
                                                    <View style={styles.timelineService.imageContainerTimeline}>
                                                        <Image style={styles.imageN} src={user} />
                                                    </View>
                                                    <Text style={styles.timelineService.accomDetails.fontEmptyRoom}>{`${room.InfantCount} ${pluralize(room.InfantCount, "Infant", "Infants")}`} </Text>
                                                </View>
                                            }

                                            <View style={styles.timelineService.timelineRow}>
                                                <View style={styles.timelineService.imageContainerTimeline}>
                                                    <Image style={styles.imageN} src={roomB} />
                                                </View>
                                                <Text style={styles.fontLabel}>{room.RoomName}</Text>
                                            </View>
                                            <View style={styles.timelineService.timelineRow}>
                                                <View style={styles.timelineService.imageContainerTimeline}>
                                                    <Image style={styles.imageN} src={meal} />
                                                </View>
                                                <Text style={styles.fontLabel}><Text style={{ fontWeight: 600 }}>Meal Plan</Text> | {room.MealPlan}</Text>
                                            </View>
                                        </View>
                                       </View>
                                   ))}


                               </View>
                           </View>) :
                           // Tranfser accomodation
                           <View style={styles.timelineService}>
                               <View style={styles.timeline.multiTimeLine.multiTimeLineSeparatorRoot}>
                                   <Image src={tranfser} style={styles.timeline.multiTimeLine.multiTimeLineSeparatorRoot.arrivalTimelineTimeLine} />
                                   <View style={styles.timelineService.multiTimeLineConnector} ></View>
                               </View>
                               <View style={styles.timelineService.MuiTimelineContentRoot}>
                                   <Text style={styles.timelineService.MuiTimelineContentRoot.service} >Transfer</Text>
                                   <View style={styles.timelineService.MuiTimelineContentRoot.rowDate}>

                                       <View style={styles.imageContainer}>
                                           <Image style={styles.imageN} src={calendarB} />
                                       </View>
                                       <Text style={styles.fontEmpty}>{transformDate(service.TransferDate)} </Text>
                                   </View>
                                   <View style={styles.timelineService.MuiTimelineContentRoot.rowPlace}>
                                       <Text style={styles.fontEmpty}>{service.LocationFromName}</Text>
                                       <View style={styles.imageContainer}>
                                           <Image style={styles.timelineService.MuiTimelineContentRoot.imageArrow} src={arrowB} />
                                       </View>
                                       <Text style={styles.fontEmpty}>{service.LocationToName} </Text>

                                   </View>
                                   {/*  */}
                                   <View style={styles.timelineService.accomDetails}>
                                       <View style={styles.timelineService.timelineRow}>
                                           <View style={styles.timelineService.imageContainerTimeline}>
                                               <Image style={styles.imageN} src={user} />
                                           </View>
                                           <Text style={styles.timelineService.accomDetails.fontEmptyRoom}>{`${service.AdultCount} ${pluralize(service.AdultCount, "Adult", "Adults")}`} </Text>
                                       </View>
                                       {service.TeenCount > 0 &&
                                           <View style={styles.timelineService.timelineRow}>
                                               <View style={styles.timelineService.imageContainerTimeline}>
                                                   <Image style={styles.imageN} src={user} />
                                               </View>
                                               && <Text style={styles.timelineService.accomDetails.fontEmptyRoom}>{`${service.TeenCount} ${pluralize(service.TeenCount, "Teen", "Teens")}`} </Text>
                                           </View>
                                       }
                                       {service.ChildCount > 0 &&
                                           <View style={styles.timelineService.timelineRow}>
                                               <View style={styles.timelineService.imageContainerTimeline}>
                                                   <Image style={styles.imageN} src={user} />
                                               </View>
                                               && <Text style={styles.timelineService.accomDetails.fontEmptyRoom}>{`${service.ChildCount} ${pluralize(service.ChildCount, "Child", "Children")}`} </Text>
                                           </View>
                                       }
                                       {service.InfantCount > 0 &&
                                           <View style={styles.timelineService.timelineRow}>
                                               <View style={styles.timelineService.imageContainerTimeline}>
                                                   <Image style={styles.imageN} src={user} />
                                               </View>
                                               && <Text style={styles.timelineService.accomDetails.fontEmptyRoom}>{`${service.InfantCount} ${pluralize(service.InfantCount, "Infant", "Infants")}`} </Text>
                                           </View>
                                       }
                                       <View style={styles.timelineService.timelineRow}>
                                           <View style={styles.timelineService.imageContainerTimeline}>
                                               <Image style={styles.imageN} src={voiture} />
                                           </View>
                                           <Text style={styles.timelineService.accomDetails.fontEmptyRoom}>{service.VehicleTypeName} x{service.CarCount}</Text>
                                       </View>
                                   </View>
                                   {service?.LuggageTruck > 0 || service?.LuggageCar > 0 || service?.BabySeat > 0 &&
                                       <View style={styles.timelineService.accomDetails}>
                                           <Text style={styles.timelineService.MuiTimelineContentRoot.supplement} >Supplement :</Text>
                                           <View style={styles.timelineService.timelineRow}>
                                               <View style={styles.timelineService.imageContainerTimeline}>
                                                   <Image style={styles.imageN} src={luggage} />
                                               </View>
                                               <Text style={styles.timelineService.accomDetails.fontAdult}>Luggage truck {service.LuggageTruck > 1 ? `x${service.LuggageTruck}` : ''}</Text>
                                           </View>
                                           <View style={styles.timelineService.timelineRow}>
                                               <View style={styles.timelineService.imageContainerTimeline}>
                                                   <Image style={styles.imageN} src={luggage} />
                                               </View>
                                               <Text style={styles.timelineService.accomDetails.fontAdult}>Luggage Car {service.LuggageCar > 1 ? `x${service.LuggageCar}` : ''} </Text>
                                           </View>
                                           <View style={styles.timelineService.timelineRow}>
                                               <View style={styles.timelineService.imageContainerTimeline}>
                                                   <Image style={styles.imageN} src={baby} />
                                               </View>
                                               <Text style={styles.timelineService.accomDetails.fontAdult}>Baby seat {reservationTransfer.BabySeat > 1 ? `x${reservationTransfer.BabySeat}` : ''}</Text>
                                           </View>
                                       </View>
                                   }
                               </View>
                           </View>

                   ))
               }








               <View style={styles.timeline.multiTimeLineDepature}>
                   <View style={styles.timeline.multiTimeLine.multiTimeLineSeparatorRoot}>
                       <Image src={departurePlane} style={styles.timeline.multiTimeLine.multiTimeLineSeparatorRoot.depatureTimelineTimeLine} />
                   </View>
                   <View style={styles.timeline.multiTimeLine.MuiTimelineContentRoot}>
                       <Text style={styles.timeline.multiTimeLine.MuiTimelineContentRoot.arrival} >Depature</Text>
                       <Text style={styles.timeline.multiTimeLine.MuiTimelineContentRoot.arrivalLocation}>Sir Seewoosagur Ramgoolam International Airport</Text>
                   </View>
               </View>
           </View>
       )
    }
}

function isBreak(data){
    let newdata = data.allServices;
    if(newdata.length<=3){
        let hotelNumber = 0;
        newdata.map(rep=>{
            if(rep.hasOwnProperty("HotelId")){
                //mila sortena by date ra misy transfer avant quotation 
                // dia limitena ray ny room 
                hotelNumber += rep.Rooms.length
            }
        })
        if(hotelNumber>=2) return true;
        return false;
    }
    return true;
}

function getCancellationPolicy(data){
    return(
        <View break ={true} style={styles.section}>
            <View style={{padding:12}}>
                <Text style={styles.fontAgency}>Cancelation Policy</Text>
                { data.allServices.map((service)=>(
                    <View style={styles.section}>
                        <View style={styles.cancellationPolictyboxTypes}>
                            <View style={styles.cancellationPolictyboxTypes.serviceType}>
                                <Image src={service.hasOwnProperty("HotelId") ? accom : tranfser} style={styles.cancellationPolictyboxTypes.cancellationsericeImage} />
                                <Text style={styles.cancellationPolictyboxTypes.cancellationserviceTextTitle} >{service.hasOwnProperty("HotelId") ? "Accomodation" : "Transfer"}</Text>
                            </View>
                            <View style={styles.cancellationPolictyboxTypes.destination} >
                                {service.hasOwnProperty("HotelId") ?
                                    <>
                                        <Text style={styles.cancellationPolictyboxTypes.fontEmptyBold}>{service.HotelName} </Text>
                                    </>
                                    :
                                    <>
                                        <Text style={styles.cancellationPolictyboxTypes.fontEmptyBold}>{service.LocationFromName} </Text>
                                        <View style={styles.cancellationPolictyboxTypes.imageContainer}>
                                            <Image style={styles.cancellationPolictyboxTypes.imageN} src={arrowB} />
                                        </View>
                                        <Text style={styles.cancellationPolictyboxTypes.fontEmptyBold}>{service.LocationToName} </Text>
                                    </>    
                                }
                                
                            
                            </View>
                            <View style={styles.cancellationPolictyboxTypes.rowDate}>
                                {service.hasOwnProperty("HotelId") ?
                                    <>
                                        <View style={styles.cancellationPolictyboxTypes.imageContainer}>
                                            <Image style={styles.cancellationPolictyboxTypes.imageN} src={calendarB} />
                                        </View>
                                        <Text style={styles.cancellationPolictyboxTypes.fontEmpty}>{service.Rooms[0]?.Dates[0]}  </Text>
                                        <View style={styles.cancellationPolictyboxTypes.imageContainer}>
                                            <Image style={styles.cancellationPolictyboxTypes.imageN} src={arrowB} />
                                        </View>
                                        <View style={styles.cancellationPolictyboxTypes.imageContainer}>
                                            <Image style={styles.cancellationPolictyboxTypes.imageN} src={calendarB} />
                                        </View>
                                        <Text style={styles.cancellationPolictyboxTypes.fontEmpty}>{transformDate(service.Rooms[0]?.Dates[1])} </Text>

                                        <View style={styles.cancellationPolictyboxTypes.timelineRow}>
                                            <View style={styles.cancellationPolictyboxTypes.imageContainerTimeline}>
                                                <Image style={styles.cancellationPolictyboxTypes.imageN} src={night} />
                                            </View>
                                            <Text style={styles.cancellationPolictyboxTypes.fontLabel}>{calculateNights(service.Rooms[0].Dates[0], service.Rooms[0].Dates[1])} nights</Text>
                                        </View>
                                    </>
                                    : <>
                                        <View style={styles.cancellationPolictyboxTypes.imageContainer}>
                                            <Image style={styles.cancellationPolictyboxTypes.imageN} src={calendarB} />
                                        </View>
                                        <Text style={styles.cancellationPolictyboxTypes.fontEmpty}>{transformDate(service.TransferDate)} </Text>
                                    </>}
                            </View>
                        </View>
                        <View>
                            {service.hasOwnProperty("HotelId")?
                                service.Rooms.map(room=>
                                    <View>
                                        <View style={styles.roomPolicy}>
                                            <View style={styles.row}>
                                                <View style={styles.imageContainer}>
                                                    <Image style={styles.imagePolicy} src={roomB} />
                                                </View>
                                                <Text style={styles.fontLabel}>{room.RoomName}</Text>
                                            </View>
                                        </View>
                                        {
                                            room.cancellation_policy?.map(cancellation=>
                                                <View style={{paddingLeft:10}}>
                                                <View style={styles.periodPolicy}>
                                                    <View style={styles.row2}>
                                                        <Text style={styles.fontPolicy}>Policy period applies</Text>
                                                        <View style={styles.imageContainer}>
                                                            <Image style={styles.imageM} src={calendar} />
                                                        </View>
                                                        <Text style={styles.fontDate}>{transformDate(cancellation.ValidFrom)}</Text>

                                                        <Image style={styles.arrowPolicy} src={arrow} />
                                                        <View style={styles.imageContainer}>
                                                            <Image style={styles.imageM} src={calendar} />

                                                        </View>
                                                            <Text style={styles.fontDate}>{transformDate(cancellation.ValidTo)}</Text>
                                                    </View>
                                                </View>

                                                <View style={styles.pourcentagePolicy}>
                                                    <View style={styles.sec}>
                                                            <Text style={styles.fontPourcent} >{cancellation.Value}% of total price</Text>
                                                        <Text style={styles.fontDescPourcent} >{cancellation.Description}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        )}
                                    </View>
                                )
                                :
                                <View>
                                    <View style={styles.roomPolicy}>
                                        {/* <View style={styles.row}>
                                            <View style={styles.imageContainer}>
                                                <Image style={styles.imagePolicy} src={transfer} />
                                            </View>
                                            <Text style={styles.fontLabel}>{service.LocationToDetails}</Text>
                                        </View> */}
                                    </View>
                                    <View style={styles.pourcentagePolicy}>
                                        <View style={styles.sec}>
                                            <Text style={styles.fontDescPourcent} > 24 hours prior to the Transfer, you are not allowed to create, delete or modify any Transfer Quotes.</Text>
                                        </View>
                                    </View>
                                </View>
                            }
                        </View>
                    </View>))
                }
            </View>
        </View>
    )
}





export const MyDocument = ({data}) => (
    
    <Document style={styles.page}>
        <Page size="A4" style={styles.page}>
            <View fixed style={styles.containerLine}>
                <View style={styles.line} />
                <Text style={styles.text}>{data.subagency_name === null ? data.agency_name : data.subagency_name} {!data.quoteBookList.hasOwnProperty("Clients")?"Quotation":"Booking"}</Text>
                <View style={styles.lineBehind} />
            </View>
            <Image fixed src={cocktail} style={styles.backgroundImage} />

            <View  style={{paddingHorizontal:"8px"}}>
                <View style= {styles.header}>
                    
                    
                    <View style={styles.section}>
                        {
                            data.quoteBookList.hasOwnProperty("Clients") ?
                            (<View>
                                <Text style={styles.fontQuotation}>Booking N° {data.quoteBookList.IdBooking}</Text>
                                <Text style={styles.fontAgency}>{data.first_name}  {data.last_name}, {data.agency_name}</Text>
                            </View>):
                            (
                                <View>
                                    {!data.notSavedQuotation?
                                        (<Text style={styles.fontFile}>{data.quoteBookList.refCode}</Text>) :
                                        (<Text style={styles.fontFile}>File number: {data.file_name}</Text>)  
                                    }
                                    {/* <Text style={styles.fontFile}>File number: {data.file_name}</Text> */}
                                    <Text style={styles.fontQuotation}>{getBeforeUnderscore(data.quoteBookList.refCode)}{!data.notSavedQuotation && (<Text style={styles.notSaved}> (Not saved)</Text>)}</Text>
                                    <Text style={styles.fontAgency}>{data.first_name}  {data.last_name}, {data.agency_name}</Text>
                                </View>
                            )
                        }
                    </View>

                    
                    <View style={styles.section}>
                        <View style={styles.logoContainer}>
                            {/* <Text>TEST</Text> */}
                            <Image src={logo} style={styles.logo} />
                        </View>

                        <View style={styles.documentDetail}>
                            <Text style={styles.documentDetail.title}>Documents details</Text>
                            <View style={styles.documentDetail.container}>
                                <View style={styles.documentDetail.containerL}>
                                    {
                                        data.notSavedQuotation &&
                                        <Text style={styles.documentDetail.textStyle}> File number: </Text>
                                    }
                                    
                                    <Text style={styles.documentDetail.textStyle}> Agency: </Text>
                                    <Text style={styles.documentDetail.textStyle}> Client-Type: </Text>
                                    {data.quoteBookList.hasOwnProperty("Clients") &&
                                        <Text style={styles.documentDetail.textStyle}> Number of pax: </Text>
                                    }
                                </View>
                                <View style={styles.documentDetail.containerR}>
                                    {
                                        data.notSavedQuotation &&
                                        <Text style={styles.documentDetail.textStyleValue}> {data.file_name}</Text>
                                    }
                                    <Text style={styles.documentDetail.textStyleValue}> {data.subagency_name === null ? data.agency_name : data.subagency_name}</Text>
                                    <Text style={styles.documentDetail.textStyleValue}> {data.clientType}</Text>
                                    {data.quoteBookList.hasOwnProperty("Clients") &&
                                     <Text style={styles.documentDetail.textStyleValue}> 
                                            { data.quoteBookList.Clients.length }
                                     </Text>
                                    }
                                    </View>
                            </View>

                        </View>
                    </View>


                </View>
                {/* /////////////////////////////TIMELINE or Form  */}
                {checkIfBookingorQuotation(data)}

                {data.quoteBookList.hasOwnProperty("Clients") && <View style={{paddingHorizontal:8}}><Text style={styles.recapFont}>2. Booking resume</Text></View>}
                
                <View  style={styles.containerTable}>
                    {/* //////////Header Table//////////// */}
                    <View break={isBreak(data)} style={styles.headerTable}>
                            <View style={styles.columnServiceH}>
                                <Text style={styles.headerTable.font}>Item</Text>
                            </View>
                            <View style={styles.columnDetailsH}>
                                <Text style={styles.headerTable.font}>Details</Text>
                            </View>
                            <View style={styles.columnDateH}>
                                <Text style={styles.headerTable.font}>Date</Text>
                            </View>
                            <View style={styles.columnPaxH}>
                                <Text style={styles.headerTable.font}>Number of pax</Text>
                            </View>
                            <View style={styles.columnPriceH}>
                                <Text style={styles.headerTable.font}>Price</Text>
                            </View>
                    </View>

                    {/* //////////Body Table//////////// */}

                    {data.allServices.map((service, index) => {
                        return (
                            <View style={{marginBottom:15}}>
                                <View style={styles.noBreak}>
                                    <View style={styles.bodyTable}>
                                        {/* Service */}
                                        <View style={styles.columnService}>
                                            
                                        {("ServiceId" in service || "IdTransfer" in service) ? (
                                                // Transfer
                                                <View style={styles.section}>
                                                    <View style={styles.row}>
                                                        <View style={styles.imageContainer}>
                                                            <Image style={styles.image} src={transfer} />
                                                        </View>
                                                        <Text style={styles.bodyTable.fontService}>Transfer</Text>
                                                    </View>
                                                    
                                                    <View style={styles.row}>
                                                        <Text style={styles.fontLabelService}>{properCase(service.LocationFromName)}</Text>
                                                        <View style={styles.imageContainer}>
                                                            <Image style={styles.imageT} src={arrow} />
                                                        </View>
                                                    </View>
                                                    <Text style={styles.fontLabelService}>{properCase(service.LocationToName)}</Text>
                                                </View>
                                            ) : (
                                                // Accommodation
                                                <View style={styles.section}>
                                                    <View style={styles.row}>
                                                        <View style={styles.imageContainer}>
                                                            <Image style={styles.image} src={accom} />
                                                        </View>
                                                        <Text style={styles.bodyTable.fontService}>Accommodation</Text>
                                                    </View>
                                                        <Text style={styles.fontLabelService}>{properCase(service.HotelName)}</Text>
                                                </View>
                                            )}

                                        </View>
                
                                        {/* Details */}
                                        <View style={styles.columnDetails}>
                                            {/* Trasnfer */}
                                            {("ServiceId" in service || "IdTransfer" in service) ? (
                                                <View style={styles.section}>
                                                    <View style={styles.section}>
                                                        <View style={styles.row}>
                                                            <View style={styles.imageContainer}>
                                                                <Image style={styles.imageN} src={voiture} />
                                                            </View>
                                                            <Text style={styles.fontLabel}>{properCase(service.VehicleTypeName)}</Text>
                                                        </View>
                                                        
                                                        <View style={styles.row}>
                                                            <View style={styles.imageContainer}>
                                                                <Image style={styles.imageN} src={user} />
                                                            </View>
                                                        
                                                            {/* <Text style={styles.fontLabel}>Client type : STD</Text> */}
                                                        </View>
                                                    </View>
                                                    
                                                    {/* <View style={styles.section}>
                                                        <Text style={styles.fontLabelTitle} >Supplement :</Text>
                                                        <View style={styles.row}>
                                                            <View style={styles.imageContainer}>
                                                                <Image style={styles.imageN} src={luggage} />
                                                            </View>
                                                            <Text style={styles.fontLabel}>Luggage Truck</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <View style={styles.imageContainer}>
                                                                <Image style={styles.imageN} src={baby} />
                                                            </View>
                                                            <Text style={styles.fontLabel}>LUX* Grand-Baie</Text>
                                                        </View>
                                                    </View> */}
                                                </View>
                                                ) : (
                                                <View style={styles.section}>
                                                    <View style={styles.section}>
                    
                                                        {
                                                            service.Rooms.map((room, index)=>{
                                                                return(
                                                                    <View>
                                                                        <View>
                                                                            <View style={styles.row}>
                                                                                <View style={styles.imageContainer}>
                                                                                    <Image style={styles.imageN} src={night} />
                                                                                </View>
                                                                                    <Text style={styles.fontLabel}>{calculateNights(room.Dates[0], room.Dates[1]) } nights</Text>
                                                                            </View>
                                                                            <View style={styles.rowMulti}>
                                                                                <View style={styles.imageContainer}>
                                                                                    <Image style={styles.image} src={roomB} />
                                                                                </View>
                                                                                <Text style={styles.fontLabel}>
                                                                                    <Text style={styles.fontEmpty}>Room {index+1} :</Text> 
                                                                                    {properCase(room.RoomName)}
                                                                                </Text>
                                                                            </View>
                                                                        </View>
                                                                        <View style={styles.containerClientType}>
                                                                        <View style={styles.imageContainer}>
                                                                            <Image style={styles.image} src={user} />
                                                                        </View>
                                                                        <Text style={styles.fontLabel}>Client type : {capitalizeFirstLetter(room?.ClientType  || room?.ClientCategory, false)}</Text>
                                                                    </View>
                                                             </View>
                                                                )
                                                            })
                                                        }
                                                       
                                                    </View>
                                                        
                    
                                                    <View style={styles.section}>
                                                        <Text style={styles.fontLabelTitle}>Meal Plan :</Text>
                                                        {
                                                            service.Rooms.map((room, index) => {
                                                                return (
                                                                    <View style={styles.row}>
                                                                        <View style={styles.imageContainer}>
                                                                            <Image style={styles.imageM} src={meal} />
                                                                        </View>
                                                                        <Text style={styles.fontLabel}>
                                                                            <Text style={styles.fontEmpty}>Room {index + 1} :</Text> {room.MealPlan}
                                                                        </Text>
                                                                    </View>
                                                                )
                                                            })
                                                        }
                                                    </View>

                                                </View>
                                            )}
                                        </View>
                                        {/* Date */}
                                        <View style={styles.columnDate}>
                                            {/* transfer */}
                                            {("ServiceId" in service || "IdTransfer" in service) ? (
                                                <View style={styles.section}>
                                                    <View style={styles.row}>
                                                        <View style={styles.imageContainer}>
                                                            <Image style={styles.imageN} src={calendar} />
                                                        </View>
                                                        <Text style={styles.fontDate}>{dateToConventionalFormat(service.TransferDate)}</Text>
                                                    </View>
                                                </View>
                                            ):(
                                                
                                                service.Rooms?.map(room =>
                                                    <View style={styles.section}>
                                                        <View style={{marginBottom:8}}> 
                                                            {/* //daty static position */}
                                                            <View style={styles.row}>
                                                                <View style={styles.imageContainer}>
                                                                    <Image style={styles.imageN} src={calendar} />
                                                                </View>
                                                                    <Text style={styles.fontDate}>{dateToConventionalFormat(room.Dates[0])} </Text>
                                                                <View style={styles.imageContainer}>
                                                                    <Image style={styles.imageN} src={arrow} />
                                                                </View>
                                                            </View>
                                                            <View style={styles.row}>
                                                                <View style={styles.imageContainer}>
                                                                    <Image style={styles.imageN} src={calendar} />
                                                                </View>
                                                                    <Text style={styles.fontDate}>{dateToConventionalFormat(room.Dates[1])}</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                )
                                            )}
                                        </View>
                
                                        {/* Number Pax */}
                                        <View style={styles.columnPax}>
                                            {numberOfPax(service)}
                                        </View>
                
                                        {/* price */}
                                        <View style={styles.columnPrice}>
                                            {
                                                service.hasOwnProperty("HotelId") ? (
                                                    <Text style={styles.headerTable.font}>{service.Currency}. {service.Total}</Text>
                                                ):(
                                                    <Text style={styles.headerTable.font}>{service.Currency}. {service.TotalPrice}</Text>
                                                )
                                            }
                                        </View>
                
                                    </View>
                                    {/* <DashSeparator/> */}
                                </View>
                            
                                            
                            </View>

                        );
                    })}
                 
                        <View style={styles.lineTotal}/>
                            {getDocketPrice(data.quoteBookList)}
                        <View style={styles.lineTotal} />
                    <hr></hr>
                    {/* <DashSeparator/>   */}
                </View>


                {getCancellationPolicy(data)}
            </View>
            <View fixed style={{height:"25px"}}></View> 
            <View fixed style={styles.containerFooter}>
                <View style={styles.rowFooter}>
                    <View style={styles.footerleft}>
                        <Image src={logofooter} style={styles.logoFooter} />
                    </View>
                    <View style={styles.footerright}>
                        <Text
                            style={styles.fontfooter}
                            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                        />
                    </View>
                </View>
            </View>
        </Page>
    </Document>
);
