import React,{Fragment, useEffect, useState} from 'react'
import Booking_summary from '../../components/Quotations/Booking_summary';
import BookingPaxSelector from '../Booking/BookingPaxSelector.js';
import {getBeforeUnderscore, isServiceAccomodation, isServiceTransfer} from "../../utility/globalFunc.js"
import { getOrdinal } from '../../constants.js';
import { useBookingAccomPax, useBookingExcursionPax, useBookingTransferPax } from '../../hooks/useBookingPax.js';

const BookingServiceDetails = ({refCode,first_name,last_name,clientinfo,bookingData,services,setValidatedData,servicesData,servicePaxFilled}) => {
    const [, forceUpdate] = useState(0);  
    
    const handleForceUpdate = () => {  
        forceUpdate(prev => prev + 1);
    };  

    const {appendDataAccom,getRankAccom,isChosenAccom,isValidAccom,getMaxPaxAccom}=useBookingAccomPax({setValidatedData,handleForceUpdate,bookingData})
    const {isChosenTransfer,getRankTransfer,appendDataTransfer,appendBabySeats,isValidTransfer,getMaxPaxTransfer}=useBookingTransferPax({setValidatedData,handleForceUpdate,bookingData})
    const {}=useBookingExcursionPax({setValidatedData,handleForceUpdate,bookingData})
    const TRANSFER = "transfer";
    const ACCOMODATION = "accomodation";

    const headerBookingAccom = (room) =>{
        return (
            <>
            <div className='service-name'>Accommodation</div>
            <div className='service-item-name'>{room.hotelName}</div>
            <div className='service-room-name'>{room.RoomName}</div>
        </> 
        )
    }
    const headerBookingTransfer = (transfer) =>{
        return (
            <>
                <div className='service-name-transfer'>{`${transfer?.id}${getOrdinal(transfer?.id)}`} Transfer</div>
                <div className='service-item-name'>{transfer?.LocationFromName} <span className="date-arrow">→</span> {transfer?.LocationToName}</div>
                <div className='service-transfer-name'>Vehicle <span className='service-transfer-name-car'>{transfer?.CarCount}x {transfer?.VehicleTypeName} </span></div>
            </>
        )
    }

    let roomid=0,
    transfercount = 0;

    const renderBookingPaxSelectorAccom = (service) => {
          return service.Rooms.map((room, ind) => {
            const idRoom = roomid;
            const maxPax = getMaxPaxAccom(room);
            const valid = isValidAccom(idRoom, maxPax);
            const tempRoom={...room};
            tempRoom.hotelName = service.HotelName
            roomid++;
            return (
              <BookingPaxSelector
                key={`service-${ind}-${room.RoomName}`}
                room={tempRoom}
                name={ACCOMODATION}
                isValid={valid}
                isChosen={isChosenAccom}
                maxPax={maxPax}
                appenddata={appendDataAccom}
                getRank={getRankAccom}
                clientinfo={clientinfo}
                serviceIndice={idRoom}
                notFilled={servicePaxFilled.serviceInd.includes("room-"+idRoom) && !servicePaxFilled.filled}
                headerBookingPaxSelector={headerBookingAccom(room)}
              />
            );
          });
    }

    const renderBookingPaxSelectorTransfer = (service) => {
        const transferId=transfercount;
        transfercount += 1
        const serviceTemp={...service};
        serviceTemp.id = transfercount
        const maxPax = getMaxPaxTransfer(serviceTemp);
        const isValid=isValidTransfer(transferId,maxPax);



        return (
            <BookingPaxSelector
                key={`service-${serviceTemp.IdTransfer}-${transfercount}`}
                room={serviceTemp}
                name={TRANSFER}
                isValid={isValid}
                isChosen={isChosenTransfer}
                maxPax={maxPax}
                appenddata={appendDataTransfer}
                appendBabySeats={appendBabySeats}
                getRank={getRankTransfer}
                clientinfo={clientinfo}
                serviceIndice={transferId}
                notFilled={servicePaxFilled?.serviceInd.includes("transfer-"+transferId) && !servicePaxFilled?.filled}
                headerBookingPaxSelector={headerBookingTransfer(serviceTemp)} // need id so thats why i changed service ti serviceTemp
                bookingData={bookingData}
            />
        );
    };

  return (
    <div className='quote_input_parent' >
        <div className='quote_input_left'>
            <div className='quote_left_head'>
                 <p className='quote_left_headtxt0'>{getBeforeUnderscore (refCode)}</p>
                <div className='quote_left_headtxt1'>Booking n°{getBeforeUnderscore(refCode)}</div>
                <div className='quote_left_headtxt2'>{first_name}  {last_name}</div>
                <div className='quote_left_mandatory'>Mandatory fields* </div>
            </div>   
            <div style={{display:"flex",gap:"10px",flexDirection:"column"}}>
                {
                    services?.map((service, index) => {
                        if (isServiceAccomodation(service)) {
                            return renderBookingPaxSelectorAccom(service);
                        } else if (isServiceTransfer(service)) {
                            return renderBookingPaxSelectorTransfer(service);
                        }
                        return null;
                      })
                }

            </div>
        </div>   
    </div>
  )
}

export default BookingServiceDetails