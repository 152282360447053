import React, { createContext, useEffect, useRef, useState } from 'react'
import NavbarBlock from '../Transfer/NavbarBlock'
import Footer from '../Transfer/Footer'
import Quotation from '../Transfer/Quotation';
import { useElementsIntersection } from '../../hooks/useElementsIntersection';
import { INTERSECTION_OBSERVER_STEPS_FOOTER } from '../../constants';
import { useNavigate,useLocation } from 'react-router-dom';
const CONTEXT_DEFAULT_VALUE = {
  footerRef: null,
  showLeftServiceSelector: false,
  setShowLeftServiceSelector: null,
}

export const LayoutContext = createContext(CONTEXT_DEFAULT_VALUE);

function MainLayout({ children, footer = true, docket = false }) {
  const footerRef = useRef(null);
  const docketRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);
  const [showLeftServiceSelector, setShowLeftServiceSelector] = useState(false);

  const getFooterHeight = ({ ref2 }) => {
    let rect = ref2?.current?.getBoundingClientRect();
    let height = (rect.height || 0) + 10;
    setFooterHeight(height);
  }

  const isIntersectingFooter = useElementsIntersection(docketRef, footerRef, getFooterHeight, true);

  const layoutContextValue = {
    ...CONTEXT_DEFAULT_VALUE,
    footerRef,
    showLeftServiceSelector,
    setShowLeftServiceSelector
  }



  return (
    <>
      <NavbarBlock />
      <LayoutContext.Provider value={layoutContextValue}>
        {children}
      </LayoutContext.Provider>
      {docket && <Quotation ref={docketRef} isIntersectingFooter={isIntersectingFooter} additionalBottomDistance={footerHeight} showServiceSelector={showLeftServiceSelector} />}
      {footer && <Footer ref={footerRef} />}
    </>
  )
}

export default MainLayout
