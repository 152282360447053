import react, { useState,useEffect } from 'react';
import {addCriteria,updateRating,incPaginationNumber, setBudgetRange} from "../store/accomodationReducer.js"
import StarRatingMenu from '../components/Accomodation/minicomponents/StarRatingMenu.js';
import RegionList from '../components/Accomodation/minicomponents/RegionList.js';
import {useDispatch,useSelector } from 'react-redux';
import useHotelList from '../components/Accomodation/hooks/useHotelList.js';
import {addingPax,EXCURSION_FILTER_TYPES, FILTER_ORDER, FILTER_RESUME_ORDER, OFFER_FILTER_NAME, REGION_FILTER_NAME, ROUTE_PATHS, STAR_RATING_FILTER_NAME} from "../constants.js"
import {STARRATING,BUDGET_RANGE} from "../constants.js"
import { buildRoomPaxInfo, deepCompareArrays, getAccomInputObject, scrollToTop } from '../utility/globalFunc.js';
import OfferFilter from '../components/Accomodation/minicomponents/OfferFilter.js';

const useFilterItems =()=>{
    const [expandedCategories, setExpandedCategories] = useState([]);
    const {starRating,calendarDate:inputsDate,backendDate,roomPax,criteria,paginationPage,isOffer,budgetRange}= useSelector((state) => state.accomodationreducer)
    const {fetchHotelList,loading} = useHotelList();
    const dispatch = useDispatch();

   
    const reorderItems = (items=[], criteria) => {
      return [
        ...criteria.filter(c => items.some(item => item.id_tag === c.id_tag)),
        ...items.filter(item => !criteria.some(c => c.id_tag === item.id_tag))
      ];
    };
   
    const handleCheckboxChange = (e,tags,type,scrollRef) => {
      const {checked } = e.target;
      const {id_tag,name_tag}=tags
      scrollToTop();
      if (checked) {
        let obj ={"id_tag":id_tag, "name_tag":name_tag, "action":type}
        let addData = [...criteria, obj]
        callMyApi(addData)
        return  dispatch(addCriteria(addData));
      } else {
        console.log("tafiditra");
        
        let uprating =  starRating.map(star => {
            if (star.id === id_tag) {
                return { ...star, checked: false };
            }
            return star;
        });
        console.log(uprating);
        
        let updata = criteria.filter((item) => item.id_tag !== id_tag)
        dispatch(updateRating(uprating))
        callMyApi(updata,uprating)
        return dispatch(addCriteria(updata));
      }
    };

    const callMyApi = (data,uprating=undefined,offer,updatedBudget={"from":budgetRange[0],"to":budgetRange[1]}) => {
     
      const ratings = (uprating||starRating)?.filter(item => item.checked).map(item => item.rating);

      const clientsInfo = roomPax.map((item) => ({
              client_type: item.clientType[0].toUpperCase(),
              adult_count: item.adult,
              children:item.paxAge.flatMap(item =>
                  Object.entries(item)
                  .filter(([key, value]) => key !== 'id' && !isNaN(value))
                  .map(([key, value]) => Number(value))
              )
      }));

      const actionAND = data.map(item => item.action === "AND" ? item.id_tag : null).filter(id => id !== null);
      const actionOR = data.map(item => item.action === "OR" ? item.id_tag : null).filter(id => id !== null);
      updatedBudget = {
            from: updatedBudget.from === BUDGET_RANGE.MIN_PRICE ? BUDGET_RANGE.MIN_PRICE : updatedBudget.from,
            to: updatedBudget.to === BUDGET_RANGE.MAX_PRICE ? BUDGET_RANGE.MIN_PRICE : updatedBudget.to,
        };

      // SEND OBJECT FOR HOTEL LIST  

      let sendObject=getAccomInputObject({
        dates:backendDate,
        clientsInfo,
        offers:offer!=undefined?offer:isOffer,
        budgetRange:updatedBudget,
        star_rating:ratings,
        OR:actionOR,
        AND:actionAND
      })

      let redirect=ROUTE_PATHS.ACCOMMODATION_SELECT
       let module = 0
     // HOTEL LIST
     fetchHotelList(sendObject,redirect,module,1,true);
    }

    const toggleCategory = (category) => {
      // setExpandedCategories((prevExpandedCategories) => ({
      //   ...prevExpandedCategories,
      //   [category]: !prevExpandedCategories[category],
      // }));

      setExpandedCategories(prev => ({ ...prev, [category]: !prev[category] }));
    };

    const renderCheckboxes = (category,items,filterOptions,scrollRef) => {
   
      if (typeof category==="string") {
        if (category===OFFER_FILTER_NAME) {
          return <OfferFilter key={"offer-filter-component"} scrollRef={scrollRef}/>
        }
        return null;
      }
      
      let {name_tag_category,action_type}=category
      //console.log("expandedCategories:",expandedCategories,name_tag_category);

      const isExpanded = expandedCategories[name_tag_category];
      const reorderedItems = reorderItems(items, criteria);
      const itemsToShow = expandedCategories[name_tag_category] || undefined ? reorderedItems : reorderedItems.slice(0,3);
      
      const regionData = filterOptions?.find(({ name_tag_category }) => name_tag_category === "Region") || [];

      
      return (
        
        <div key={name_tag_category} className='types-of-filter'>
         { 
          name_tag_category === REGION_FILTER_NAME ?
            <div style={{float:"left",margin:"0px 0 48px",width:"100%"}} className=''>
              <div className='filter-type-name1'>{name_tag_category}</div>
              <RegionList data={regionData} onlySelectThree={true} type={regionData.action_type} scrollRef={scrollRef}/>
            </div>
          : 
          <>
            <div className='filter_type_name'>{name_tag_category}</div>
            {itemsToShow.map(item => (
              <div key={item.id_tag} className='filterbox_loop'>
                <label>
                  <input
                    type="checkbox"
                    value={item.id_tag}
                    checked={criteria.map((list) => list.id_tag).includes(item.id_tag)}
                    onChange={(e)=>handleCheckboxChange(e,item,action_type,scrollRef)}
                  />
                  {item.name_tag}
                </label>
              </div>
            ))}
            {reorderedItems.length > 3 && (
              <button onClick={() => toggleCategory(name_tag_category)} className={'show_more_button '+(isExpanded ? 'hide1' : 'show1')}>
                {isExpanded ? 'Hide filters' : 'Show all filters'}
               
              </button>
            )}
          </>
          
        }
       
          
        </div>
      );
    };


    const orderFilter=(filters,order)=>{
      //console.log("oreder",filters,order);
      
      let orderedFilter=[];
      order.forEach((filter_name)=>{
        const filter=filters.find((item)=>item.name_tag_category===filter_name);
        orderedFilter.push(filter||filter_name);
      })

     // console.log("orderedFilter",orderedFilter);
      
      return orderedFilter;
    }

    const getFilterResume=(filters)=>{
      return orderFilter(filters,FILTER_RESUME_ORDER);
    }



    const getOrderFilter=(filters,service)=>{
      let filter_type =  service ? FILTER_ORDER:FILTER_ORDER
     //console.log("filter_type",filter_type);
     
      return orderFilter(filters,filter_type);
    }

    const getCategoryName=(filter)=>{
      return typeof filter==='string'?filter:filter.name_tag_category;
    }

    const getComponent=(filter,categoryName,scrollRef,starRating,selectStarRating)=>{
      if (categoryName===REGION_FILTER_NAME) {
        return (
          <div style={{float:"left",width:"100%"}} className=''>
            <div className='filter-type-name1'>{categoryName}</div>
            <RegionList data={filter} onlySelectThree={true} type={filter.action_type} scrollRef={scrollRef}/>
          </div>
        )
      }
      if (categoryName===STAR_RATING_FILTER_NAME&&starRating) {
        return (
          <div style={{float:"left"}} className='hotel_star_rating'>
              <div className='filter_type_name'>Hotel star rating</div>
              <StarRatingMenu data={starRating} selectStarRating={selectStarRating} needApi={true} scrollRef={scrollRef}/>
            </div>
        )
      }
      if (categoryName===OFFER_FILTER_NAME) {
        return (<OfferFilter scrollRef={scrollRef}/>);
      }
      let {name_tag_category,action_type}=filter;
      
      const items=filter.tags;
      const reorderedItems = reorderItems(items, criteria);

      return (
        <div key={name_tag_category} className='types-of-filter'>
          <div className='filter_type_name'>{name_tag_category}</div>
          {reorderedItems.map(item => (
            <div key={item.id_tag} className='filterbox_loop'>
              <label>
                <input
                  type="checkbox"
                  value={item.id_tag}
                  checked={criteria.map((list) => list.id_tag).includes(item.id_tag)}
                  onChange={(e) => handleCheckboxChange(e, item, action_type, scrollRef)}
                />
                {item.name_tag}
              </label>
            </div>
          ))}
        </div>
      );
    }
 
    const renderFilterResume=(filter,scrollRef,starRating,selectStarRating)=>{
      let category=getCategoryName(filter);
      return (
        <div key={"resume"+category} className='types-of-filter'>
            {
              getComponent(filter,category,scrollRef,starRating,selectStarRating)
            }
            
        </div>
      );
    }

    const updateBudgetRange=(value)=>{
      if (deepCompareArrays(value,budgetRange)) {
        return;
      }
      dispatch(setBudgetRange({budgetRange:value}));
      let updatedBudget  = {
        from: value[0] === BUDGET_RANGE.MIN_PRICE ? BUDGET_RANGE.MIN_PRICE : value[0],
        to: value[1] === BUDGET_RANGE.MAX_PRICE ? BUDGET_RANGE.MIN_PRICE : value[1],
    };

      callMyApi(criteria,starRating,isOffer,updatedBudget);
    }
    return { renderCheckboxes,handleCheckboxChange,getFilterResume,renderFilterResume,getOrderFilter,callMyApi,updateBudgetRange};
}


export default useFilterItems;
