import React from 'react';
import {  AccomTableV1, AccomTableV2, DocketPrice, TransferTable } from './Atom.js';
import { formatPrice } from '../../utility/globalFunc.js';
import { useDispatch, useSelector } from 'react-redux';
import { MORE_OPTIONS_MODE, ROOMPAXDATA } from '../../constants.js';

function TableLayoutNew({ isViewBooking,lists = [], marginTop = "0px", closePopup = null, isDeleteMode = false, isScrollable=true }) {
  // const dispatch = useDispatch();
    const { actionMode } = useSelector((state) => state.globalreducer)
    function calculateTotalSum(data) {
      let totalSum = 0;
      let currency = null;
    
      // Sum reservationAccom total
      if (data.reservationAccom && data.reservationAccom.length > 0) {
        data.reservationAccom.forEach(accom => {
          totalSum += parseFloat(accom.Total) || 0;
          if (!currency && accom.Currency) {
            currency = accom.Currency;
          }
        });
      }
    
      // Sum ReservationTransfer total if it exists
      if (data.reservationTransfer && data.reservationTransfer.length > 0) {
        data.reservationTransfer.forEach(transfer => {
          totalSum += parseFloat(transfer?.TotalPrice) || 0;
        });
      }
    
      // Sum ReservationExcursion total if it exists
      if (data.ReservationExcursion && data.ReservationExcursion.length > 0) {
        data.ReservationExcursion.forEach(excursion => {
          totalSum += parseFloat(excursion.Total) || 0;
        });
      }
    
      return {
        total: totalSum.toFixed(2),
        currency: currency
      };
    }

    function getCurrency(data) {
      for (let item of data) {
        if (item && item.reservationAccom && item.reservationAccom.length > 0) {
          return item.reservationAccom[0].Currency;
        }
      }
      return null; // Return null if no currency is found
    }

   
    console.log("table layout",lists);
    

    return (
      <>
        <div className='table-layout-parent'>
          <div className='grid-layoutHead head'>
                <div className='item1' style={{padding:"10px"}}>Item</div>
                <div>Details</div>
                <div>Dates</div>
                <div>Number of Pax</div>
                <div>Price</div>
            </div>
          <div className='quotation-details-container'>
            {
              <div className={`body ${isScrollable?"body-scroll":""}`} >
                {lists.hasOwnProperty("reservationAccom") && <AccomTableV2 isViewBooking={isViewBooking} closePopup={closePopup} list={lists} isDelete={isDeleteMode} />}
                {lists.hasOwnProperty("reservationTransfer") && <TransferTable isViewBooking={isViewBooking} closePopup={closePopup} isDelete={isDeleteMode} list={lists} />}
              </div>

            }
          </div>
          <div className='dashed-total'>
             {/* <div>Total:
              &nbsp; {calculateTotalSum(lists).currency}
              &nbsp; {formatPrice(calculateTotalSum(lists).total, ',')}&nbsp;
            </div>  */}
            <DocketPrice data={lists}/>
          </div>
        </div>
        <div className='dashed-totalPdf' style={{display:'none'}}>
            <div>Total: 
            &nbsp; {calculateTotalSum(lists).currency}
            &nbsp; {formatPrice(calculateTotalSum(lists).total,',')}&nbsp;
                  </div>
        </div>
      </>
    );
}

export default TableLayoutNew;