import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  messages:null,
  loading:true,
  delete:{isActive:false,isPopup:false,delData:null},
  activePopup:{carCapacity:false,saveCotpop:false,transferSearch:null}
}



export const errorReducer = createSlice({
  name: 'errorreducer',
  initialState:INITIAL_STATE,
  reducers: {
        getErrorMessages(state,action){
          
          state.messages = action.payload
        },
        getLoadingStatus(state,action){
          state.loading = action.payload
        },
        removeErrorMessages(state,action){
          state.messages = null
        },
        checkDelete(state,action){
        
          let {delbtn,delpop,items} = action.payload
          state.delete.isActive=delbtn
          state.delete.isPopup=delpop
          state.delete.delData =items
        },
        resetDelete(state){
          state.delete=INITIAL_STATE.delete;
        },
        openPopup(state,action){
          let name = action.payload
          state.activePopup.saveCotpop= true
         
        },


        //new
        searchError(state,action){
          let{type,name,message}=action.payload
          state.activePopup[name]= type,
          state.messages =message
        },
        removeSearch(state,action){
          let{name}=action.payload
          state.activePopup[name]= null,
          state.messages =null
        }


  },
})

export const { searchError,removeSearch,openPopup,checkDelete,getErrorMessages,getLoadingStatus,removeErrorMessages, resetDelete } = errorReducer.actions

export default errorReducer