import React from "react";
import { useDispatch } from "react-redux";
import { accomDates, accomPax, clearRoom, removeAccomgroupe, removePendingRooms, removeQuotationItem, resetCreateRoom, resetPendingAccomGroupRooms, resetPendingRooms, setSelRooms } from "../store/accomodationReducer";
import { QUOTELIST_ENDPOINT } from "../services/apiEndpoints";
import { getAccomInputObject } from "../utility/globalFunc";
import { MORE_OPTIONS_MODE, ROOMPAXDATA, ROUTE_PATHS } from "../constants";
import { resetActionMode, setActionMode } from "../store/globalReducer";
import axios from "axios";
import { addRefcode, removeTransfer, resetTransfer, trigerId } from "../store/transferReducer";

const useMoreOptionQuotation = () => {
  const dispatch = useDispatch();
  let token = localStorage.getItem("access-token");
  const removeItem = (item, service) => {
    if (service=="accom") {
      dispatch(removeAccomgroupe({itemHotelId : item.HotelId, itemDate : item.Dates}))
      dispatch(resetPendingAccomGroupRooms({itemHotelId : item.HotelId, itemDate : item.Dates}))
    } else if (item.hasOwnProperty("IdTransfer")) {
      dispatch(removeTransfer({idTransfer:item.IdTransfer}))
    } else {
      //put here excursion logic
    }
  };

  function clearAccomData(routes=''){
    dispatch(clearRoom());
    dispatch(resetCreateRoom());
    if (routes===ROUTE_PATHS.SELECTED_ACCOMMODATION) {
      dispatch(resetPendingRooms());
    }else{
      dispatch(removePendingRooms());
    }
  }

  function clearTransferData() {
    dispatch(trigerId(0)); 
    dispatch(resetTransfer()); 
  }

  const clearDocket=({routes=""}={})=>{ 
    clearAccomData(routes)
    //put here transfer logic
    clearTransferData();
    //put here excursion logic
  }

  const initAddItemsMode=(file_name,fetchHotelList)=>{
    dispatch(clearRoom());
    axios.request({
      headers:{
        Authorization: `Bearer ${token}`
      },
      method:'POST',
      url: process.env.REACT_APP_API_URL + QUOTELIST_ENDPOINT,
      params:{to_ref_code:file_name}
    })
    .then(res=>{
      console.log("res add",res);
      
      let quotation = JSON.parse(res.data?.reducer||"{}");
      let firstAccom=quotation.selectedRooms[0]||{};
      let pax=quotation.selectedRooms[0].roomPax[0];
      let paxInfo=[{
        client_type:pax.clientType[0],
        adult_count: pax.adult,
        children:[],
      }]
      let sendObject=getAccomInputObject({dates:firstAccom.date,clientsInfo:paxInfo});
      let roomPax={
        ...ROOMPAXDATA,
        adult:pax.adult
      };
      dispatch(accomDates(firstAccom.date))
      dispatch(accomPax([roomPax]))
      dispatch(setSelRooms(quotation.selectedRooms||[]));
      dispatch(addRefcode(res.data.TO_ref));
      let redirect=ROUTE_PATHS.ACCOMMODATION_SELECT
      let module = 1
      // HOTEL LIST
      fetchHotelList(sendObject,redirect,module); 
      dispatch(setActionMode(MORE_OPTIONS_MODE.ADD_ITEM))
    })
    .catch((err) => {
      console.error(err);
      dispatch(resetActionMode())
        alert(err);
    });
  }
  const cancelAddItemMode=()=>{
    clearDocket();
    dispatch(addRefcode(null));
    dispatch(resetActionMode());
  }
  return { removeItem,clearDocket,initAddItemsMode,cancelAddItemMode };
};

export default useMoreOptionQuotation;
