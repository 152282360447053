import React,{createContext,useState,useEffect} from 'react';
import Table_booking from "../components/Quotations/Table_booking";
import Dropdownlist from '../components/Quotations/Dropdownlist';
import Booking_header from '../components/Quotations/Booking_header';
import Confirm_booking from '../components/Quotations/Confirm_booking';
import NavbarBlock from '../components/Transfer/NavbarBlock.js';
import useQuoteList from '../components/Quotations/hooks/useQuoteList.js';
import useFilterQuotation from '../hooks/useFilterQuotation.js';
import TableBooked from '../components/Quotations/TableBooked.js';
import { BOOKING_PAGE, SORT_DATES,QUOTATION_TYPE,RESERVATION_TYPE } from '../constants.js';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Back } from '../components/MiniComponents/Atom.js';

export const bookingGlobal = createContext();

const QUOTATION_FILTER = {quotation_type:QUOTATION_TYPE,sort_type:SORT_DATES[0].value,client_type:null,file_status:null,page_num:1,search_file:null};
const BOOKING_FILTER = {reservation_type:RESERVATION_TYPE,sort_type:SORT_DATES[0].value,client_type:null,file_status:null,page_num:1};

function QuotationPage() {
    const {accomLoading} = useSelector((state) => state.accomodationreducer)
    const [bookingG, setBookingG] = useState({selectedrow:{},dropdata:{},dropname:"" });
    const {fetchData,quoteList,loading,fetchBookingData,bookingList,nbPages} = useQuoteList();
    const [initialData,setInitialData]=useState([]);//to remove when filter will be managed by back-end
    const [quoteData, setQuoteData] = useState([]);
    const [filter,setFilter]=useState(QUOTATION_FILTER);
    const [bookFilter,setBookFilter]=useState(BOOKING_FILTER);
    const location=useLocation();
    const [highlight,setHighlight]=useState(location?.state?.showLast || false)
    const [page,setPage]=useState(QUOTATION_TYPE);


    useEffect(()=>{
        
        if (location.state?.page) {
            setPage(location.state.page);
            setTimeout(() => {
                setHighlight(false)
            }, 4000);
        }
    },[location.state])


    useEffect(() => {
        if(page === QUOTATION_TYPE){
            setFilter(QUOTATION_FILTER);
        }
        else{
            setBookFilter(BOOKING_FILTER)
        } 
        
    }, [page]);
    
    useEffect(()=>{
        fetchData(filter,setQuoteData);
    },[filter])
    
    useEffect(()=>{
        fetchBookingData(bookFilter)
    },[bookFilter])
    
    const switchPage=()=>{
        if (page===QUOTATION_TYPE) {
            setPage(BOOKING_PAGE)
            // setFilter(prev=>{
            //     prev.list_type=BOOKING_PAGE;
            //     return prev;
            // })


        }else{
            setPage(QUOTATION_TYPE)
            // setBookFilter(prev=>{
            //     prev.list_type=QUOTATION_TYPE;
            //     return prev;
            // })
        }
    }

    const changeNoPage=(page)=>{
        setFilter(prev=>({
            ...prev,
            page_num:page
        }))
    }
    
    return (
        <bookingGlobal.Provider value={{bookingG, setBookingG}}>
            <>
            {accomLoading ? <div className='accomSkeletonLoad1'></div> : 
            <div className='booking_index'>
                <Booking_header setQuoteData={setQuoteData} quotelist={quoteData||initialData || []} switchPage={switchPage} page={page} filterData={filter} updateFilter={setFilter}/>
                {page===QUOTATION_TYPE && <Dropdownlist setFilter={setFilter} page={page} /> }
                {page===BOOKING_PAGE && <Dropdownlist setFilter={setBookFilter} page={page}/> }
                {/* <Back style={{ transform: "translate(1px, -422px)" }}name={"quotation"} label={"Home"} /> */}
                {page===QUOTATION_TYPE&&<Table_booking data={quoteData || quoteList || []} loading={loading} nbPages={nbPages} changeNoPage={changeNoPage}/>}
                {page===BOOKING_PAGE&&<TableBooked data={bookingList || []} loading={loading} highLightLastBooking={highlight} setBookFilter={setBookFilter} bookfilter={bookFilter} nbPages={nbPages} loadingGrid={loading}/>}
                {/* <Confirm_booking/>  */}
            </div>}
            </>
        </bookingGlobal.Provider>
    );
}


export default QuotationPage;