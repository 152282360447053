import React, { useRef, useState,useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import useMoreOptionQuotation from '../../hooks/useMoreOptionQuotation'
import useOutsideClick from '../../hooks/useOutsideClick';
import useAddItems from '../Quotations/hooks/useAddItems';
import TableLayoutNew from './TableLayoutNew';
import { capitalizeFirstLetter, CLIENTDATA, MORE_OPTIONS_MODE } from '../../constants';
import {setActionMode, setShowMoreTimeLine} from "../../store/globalReducer"
import CircularProgress from '@mui/material/CircularProgress';
import { MyDocument } from '../Quotations/pdf/MyDocument';
import { pdf } from '@react-pdf/renderer';
import useSendMail from '../Quotations/hooks/useSendMail';
import { useLocation } from 'react-router-dom';
import { extractWord, getClientTypeByAcronyme, getGlobalClientType } from '../../utility/globalFunc';



export function CancelUpdatePopup({closePopup,openNew}) {
    const {refCode}=useSelector(state=>state.transfer_Global)
    const {cancelAddItemMode}=useMoreOptionQuotation();
    const [className,setClassName]=useState("");
    const popupRef=useRef();
    const handleCancel=(e)=>{
      e.preventDefault();
      animateClose(cancelAddItemMode);
    }

    const animateClose=(additionalAction)=>{
      setClassName("fade-out-300");
      setTimeout(() => {
        closePopup();
        setClassName("");
        additionalAction&&additionalAction()
      }, 200);
    }

    useOutsideClick(popupRef,()=>{
      animateClose();
    })

  return (
    <div className={`cancel-update-container ${className}`} ref={popupRef}>
        <h4 className='cancel-update-title'> You are about to delete?</h4>
        <p className='refCode-subtitle'><span className='refCode-label'>“{refCode}”</span>&nbsp;quotation</p>
        <button onClick={handleCancel}  className='cancel-update-button'>Cancel</button>
        <div onClick={()=>animateClose} className='continue-update-button'>Continue adding items</div>
    </div>
  )
}


export const QuoteDeletePopup =({name,closePopup})=>{
  const closePopref =useRef(null)
  const {deleteQuotation,loading}=useAddItems()
  const dispatch = useDispatch();


  useOutsideClick(closePopref,()=>{
    dispatch(setActionMode(null))
    closePopup();
  })

  const callDeletequote=()=>{
    let payload ={"to_ref":name,"service_type":"ALL"}
    console.log("loading:",loading);
    
    deleteQuotation(payload,MORE_OPTIONS_MODE.DELETE_QUOTATION)
  }



  return(
      <div className='cancel-update-container' ref={closePopref}>
          <h4 className='cancel-update-title'> You are about to delete?</h4>
          <p className='refCode-subtitle'><span className='refCode-label'>“{name}”</span>&nbsp;quotation</p>
          <div style={{display:"flex",gap:"20px"}}>
            <button className='continue-update-button' onClick={()=>closePopup()}>Cancel</button>
            <button className='cancel-update-button' onClick={()=>callDeletequote()}>
              {loading ? <CircularProgress color="secondary" style={{ width: "20px", height: "20px", color: "#fff", marginLeft: "8px" }}/> : "Delete"}
            </button>

          </div>
          
      </div>
  )
}


export const QuoteDeleteService =({data,closePopup})=>{
  const closePopref =useRef(null)
  const {quoteBookList} =useSelector((state) => state.globalreducer)
  const dispatch = useDispatch();

  // const {deleteQuotation}=useAddItems()
  useOutsideClick(closePopref,()=>{
    dispatch(setActionMode(null))
    closePopup();
  })


  const isViewBooking=()=>{
    return quoteBookList.hasOwnProperty("IdBooking");
}

  return(
      <div className='delete_item_popup' ref={closePopref}>
        <p>Remove item from your quotation</p>
        <TableLayoutNew lists={quoteBookList} isViewBooking={isViewBooking()} total={10} closePopup={closePopup} isDeleteMode= {true}/>
      </div>
  )
}

export const ExportPdfPopup =({isFromDocket, closePopup})=>{

  const closePopref =useRef(null)
  const dispatch = useDispatch();
  dispatch(setShowMoreTimeLine(true))
  useOutsideClick(closePopref,()=>{
    dispatch(setActionMode(null))
    closePopup();
  })
  // traitement data PDF
  const { agency_name, first_name, last_name } = useSelector((state) => state.authpath.userData);
  const { subAgencyAdded } = useSelector((state) => state.authpath);
  let isSubAgencyHaveValue = Object.keys(subAgencyAdded).length > 0;
  const { Adult, Child, Teen, Infant } = useSelector((state) => state.globalreducer.paxData);
  const { refCode } = useSelector((state) => state.transfer_Global);
  const [pdfUrl, setPdfUrl] = useState('');
  const { sortedList, totalPrice, quoteBookList } = useSelector((state) => state.globalreducer);
  const location = useLocation();
  const isSaved = location.pathname.includes("selected-view-details");
  const params = new URLSearchParams(location.search);
 
  let buildQuoteBookList = {
    ClientCategory: "STD",
    QuoteId: null,
    ToRef: null,
    IdBooking: quoteBookList.IdBooking, 
    refCode: !isSaved ? "Quotation n°20825" : extractWord(quoteBookList.refCode || quoteBookList.ToRef) + " Quotation",
    reservationAccom: isSaved ? quoteBookList.reservationAccom : sortedList.services.reservationAccom,
    reservationTransfer: isSaved ? quoteBookList.reservationTransfer : sortedList.services.reservationTransfer,
    ...(isSaved && quoteBookList.Clients && { Clients: quoteBookList.Clients }),
  }

  let dataPdf = {
    notSavedQuotation: isSaved,
    file_name: params.get('file_nb'),
    first_name,
    last_name,
    agency_name,
    subagency_name: isSubAgencyHaveValue ? subAgencyAdded.agency_name : agency_name,
    clientType: getGlobalClientType(isSaved ? quoteBookList:  sortedList.services),
    Adult,
    Child,
    Teen,
    Infant,
    quoteBookList: buildQuoteBookList,
    totalPrice,
    allServices: [
      ...(buildQuoteBookList?.reservationAccom || []),
      ...(buildQuoteBookList?.reservationTransfer || [])
    ]
  };
  console.log({dataPdf});
  
  let pdfName = dataPdf.quoteBookList.hasOwnProperty("Clients") ? "Mautourco_Booking" :"Mautourco_Quotation";
  async function convertToPdf() {
    console.table("chk",dataPdf);
    const blob = await pdf(<MyDocument data={dataPdf} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${pdfName}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    closePopup();
  }
  return(
    <>
      <div className='exportpdf-parent' ref={closePopref}>
      
        <p className='title'>
          Export {isFromDocket ? "quotation" : quoteBookList.hasOwnProperty("Clients") ? "booking" : "quotation"}
        </p>

        <p>Your {isFromDocket ? "quotation" : quoteBookList.hasOwnProperty("Clients") ? "booking" : "quotation"} will be generate in PDF Format</p>
        {/* <div className='containerCheckBox'>
            <input type='checkbox' id='validation-cancellation'  />
            <label className='label-acceptance' htmlFor="validation-cancellation">Include items technical sheet</label>
        </div> */}
        <button onClick={() => convertToPdf()}>Export {isFromDocket ? "quotation" : quoteBookList.hasOwnProperty("Clients") ? "booking" : "quotation"}</button>
      </div>
      {(isFromDocket || !isSaved) &&
        <div className={`toast-error unsaved-quotation-docket fade-in-100`}>
          <span>This quotation is not saved. Save it if you want to keep it for later.</span>
        </div>
      }
    </>
  )
}

export const QuotationSendMail = ({ closePopup,openNew,text='' }) => {
  const closePopref = useRef(null);
  const { SendMail, error, loading ,isSuccess} = useSendMail();
  const {userData}=useSelector(state=>state.authpath)
  const [recipient, setRecipient] = useState("");
  const [subject, setSubject] = useState("");
  const [mailContent, setMailContent] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [errors, setErrors] = useState({ recipient: "", subject: "" });

  useEffect(()=>{
    setMailContent(EmailText(text,(userData?.first_name +" "+ userData?.last_name)))
    
  },[])

  useOutsideClick(closePopref, () => closePopup()); // Outside click handler

  const handleEmailContentChange = (event) => {
    setMailContent(event.target.value);
  };

  const EmailText = (text,name="") =>{
    let emailText = `Dear Sir/Madam, \n\nPlease find your ${text} for ${name} attached here with.\nWe remain at your disposal for any questions or further clarification.\n\nKind regards,\nThe Mautourco Team`
    return emailText
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        setPdfFile(file);
        setErrors((prevErrors) => ({ ...prevErrors, pdfFile: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          pdfFile: "Only PDF files are allowed.",
        }));
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmitMail = async (e) => {
    e.preventDefault();

    let validationErrors = {};

    if (!recipient.trim()) {
      validationErrors.recipient = "Recipient email is required.";
    } else if (!validateEmail(recipient)) {
      validationErrors.recipient = "Invalid email address.";
    }

    if (!subject.trim()) {
      validationErrors.subject = "Subject is required.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});

    const formData = new FormData();
    formData.append("recipient_list", recipient);
    formData.append("subject", subject);
    formData.append("body", mailContent);
    formData.append("copy_carbon", recipient);

    // Attach the PDF file only if it exists
    if (pdfFile) {
      formData.append("files", pdfFile);
    }

    try {
      await SendMail(formData);

      // Clear the form
      setRecipient("");
      setSubject("");
      setMailContent(
        "Dear Sir/Madam, \n\nPlease find your booking for [Name] attached herewith.\nWe remain at your disposal for any questions or further clarification.\n\nKind regards,\nThe Mautourco Team"
      );
      setPdfFile(null);
      closePopup();

      openNew(e,"SEND_MAIL_SUCCESS_POPUP")
      setTimeout(()=>{
        closePopup()
      },4000)

    } catch (error) {
      alert("Failed to send email.");
    }
  };

  return (
      <div className="quotation-mail-popup" ref={closePopref}>
        <p>Send {text} via e-mail</p>
        <form onSubmit={onSubmitMail}>
          <div>
            <input
              type="text"
              placeholder="Recipient*"
              value={recipient}
              onChange={(e) => setRecipient(e.target.value)}
              required
            />
            {errors.recipient && <span className="error">{errors.recipient}</span>}
          </div>
          <div>
            <input
              type="text"
              placeholder="Subject*"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            {errors.subject && <span className="error">{errors.subject}</span>}
          </div>
          <textarea
            name="emailContent"
            placeholder="Write your email here..."
            rows="10"
            cols="30"
            value={mailContent}
            onChange={handleEmailContentChange}
          ></textarea>
          <div>
            <input type="file" accept="application/pdf" onChange={handleFileChange} />
            {errors.pdfFile && <span className="error">{errors.pdfFile}</span>}
          </div>

          <div className="button-block">
            <div>
              <button type="submit" disabled={loading}>
                {loading ? "Sending..." : "Send"}
              </button>
            </div>
          </div>
        </form>
        {text == "unsaved quotation" &&  <div className={`toast-error unsaved-quotation fade-in-100`}>
        < span>This quotation is not saved. Save it if you want to keep it for later.</span>
       </div>}
       
      </div>
  );
};


