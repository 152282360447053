import React,{useState,useEffect} from 'react'
import { capitalizeFirstLetter,getTotalPax,QUOTE_INPUT_LABEL,INPUT_DATA,getOrdinal,MORE_OPTIONS_MODE } from '../../constants'
import BookingPersonV1 from '../Transfer/BookingPersonV1';
import {getBeforeUnderscore,generateIdIndexBase} from "../../utility/globalFunc";
import { InputBox,FlightNumberBox, BookingAutoCompleteSurname } from './Atom';


const temp = [ {
    type:'adult',
    title: '',
    last_name: '',
    age: 0,
    status:'',
    honeymoon:'',
    hotel_id: -1,
    language: '',
    first_name: '',
    sur_name: '',
    remarks: '',
    room_detail_id: -1, 
    id:  1,
  }]

function BookingInputs({ errorInput, seterrorInput, refCode, first_name, last_name, handleChange, formState,setFormState,searchOnPaxDone=false,roomPax,isPaxSelected,serviceRerservation,optionData,setOptionData,actionMode}) {

    const [focusedElements, setFocusedElements] = useState([]);
    const [transferFlight,setTransferFlight]=useState([])

    const handleFocused = (newItem) =>{
        setFocusedElements((prevItems) => {
            if (!prevItems.includes(newItem)) {
                return [...prevItems, newItem];
            }
            return prevItems; 
        });
    }

    function restoreStatusInput(){
        seterrorInput({ indiceError: [false, false], statusStyle: ['success', 'success'] });
    }

    function removeFocus(e){
        restoreStatusInput();
        const inputElement = e.target;
        if (!inputElement.value) {
            inputElement.classList.remove('success');
            inputElement.classList.add('error');
        }
    }

    function detectChange(e) {
       if(errorInput){
           const inputElement = e.target;
           if (inputElement.classList.contains('error')) {
               inputElement.classList.remove('error');
           }
           if(inputElement.value != " "){
               inputElement.classList.add('success');
           }
       }
    }

    const getAge=(pax)=>{
        return pax.type!=="adult"?"("+pax.age+" yo)":"";
    }

    const renderForm = (formState) => {
        let typeNumber = {};

        const incrementTypeNumber = (type) => {
            typeNumber[type] = (typeNumber[type] || 0) + 1;
        };
        const [showPopupIndex, setShowPopupIndex] = useState(null);

        const handleApplySurname = (index, confirm) => {
            let dataToFill = confirm ? formState[0].sur_name : null ;  
            handleChange(index,'sur_name', dataToFill);
            
            let inputElement = document.getElementById('surname');
            if (inputElement.classList.contains('error')) {
                inputElement.classList.remove('error');
            }
            if(inputElement.value != " "){
                inputElement.classList.add('success');
            }
            
            setShowPopupIndex(null);
        };

        const handleFocusSurname = (index) => {
            setShowPopupIndex(index);
        };

        return (
            formState?.map((item, index) => {
                incrementTypeNumber(item.type);
                return (
                    <div key={index} className='quote_inputs'>
                        <h5 className='quote_inputs_h5'>
                            {capitalizeFirstLetter(item.type)} No. {item.numero || typeNumber[item.type]} {getAge(item)}
                        </h5>

                        <div className='quote_inputboxes'>
                            <div className='quote_inputboxes_first_name'>
                                <textarea
                                    onBlur={removeFocus}
                                    className={`input ${errorInput && index === 0 ? errorInput.statusStyle[0] : ""}`}
                                    value={item.first_name || ''}
                                    onFocus={() => handleFocused(`firstname${index}`)}
                                    onChange={(e) => { handleChange(index, 'first_name', e.target.value); detectChange(e); }}
                                    placeholder="Please insert client’s first name"
                                />
                                {!focusedElements.includes(`firstname${index}`) && index === 0 && (
                                    <span className='quote_inputboxes_first_name_star'>*</span>
                                )}
                            </div>

                            <div className='quote_inputboxes_surname' >
                                <textarea
                                    id='surname'
                                    onBlur={(e) => {removeFocus ;detectChange(e) }}
                                    className={`input ${errorInput && index === 0 ? errorInput.statusStyle[1] : ""}`}
                                    value={item.sur_name || ''}
                                    onFocus={() => handleFocusSurname(index)}
                                    onChange={(e) => { handleChange(index, 'sur_name', e.target.value); detectChange(e); }}
                                    placeholder="Please insert client’s surname"
                                />
                                {!focusedElements.includes(`sur_name${index}`) && index === 0 && (
                                    <span className='quote_inputboxes_surname_star'>*</span>
                                )}
                            </div>

                            {errorInput && index === 0 && errorInput.indiceError.some(error => error) && (
                                <div className={`toast-error-form ${errorInput ? 'show' : ''}`}>
                                    <span>Please input client’s name before going further in your reservation</span>
                                </div>
                            )}

                            <textarea
                                value={item.remarks || ''}
                                onChange={(e) => handleChange(index, 'remarks', e.target.value)}
                                placeholder="Do you have any remarks ?"
                            />
                        </div>

                        {showPopupIndex === index && index !== 0 && (
                            <BookingAutoCompleteSurname
                                labelPax={`${capitalizeFirstLetter(item.type)} No. ${item.numero || typeNumber[item.type]}`}
                                onApply={(confirm) => {
                                    handleApplySurname(index,confirm);
                                }}
                            />
                        )}
                    </div>
                );
            })
        );
    };

    const checkTransferFlight=()=>{
        let isAirportAvaliable =  serviceRerservation?.reservationTransfer?.some((data) => data.LocationFromId === 752 || data.LocationToId === 752)
        return isAirportAvaliable
    }

    useEffect(()=>{
        if(actionMode === MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_NEW_PAX){
            const missingNameObjects = serviceRerservation?.reservationTransfer?.filter(obj => !("isPersisted" in obj));
            setTransferFlight(missingNameObjects)
        }
    },[])


    return (
        <div className='quote_input_parent'>
            <div className='quote_input_left'>
                <div className='quote_left_head'>
                    <p className='quote_left_headtxt0'>{getBeforeUnderscore (refCode)}</p>
                    <div className='quote_left_headtxt1'>Booking n° {getBeforeUnderscore(refCode)}</div>
                    <div className='quote_left_headtxt2'>{first_name}  {last_name}</div>
                    <div className='quote_left_mandatory'>Mandatory fields* </div>
                </div>
                <>
                    {(serviceRerservation?.reservationTransfer?.length > 0 && checkTransferFlight()) || transferFlight.length > 0 ? (
                        <div className='quote_section_title marginBottom-lvl1'>1. {QUOTE_INPUT_LABEL[0]}</div>
                    ) : null}
                    
                    <div className='quote_input_flight'>
                        {generateIdIndexBase(
                        actionMode !== MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_NEW_PAX ? serviceRerservation?.reservationTransfer || [] : transferFlight,
                        "IdTransfer"
                        )
                        .filter(({ LocationFromId, LocationToId }) => LocationFromId === 752 || LocationToId === 752)
                        .map(({ IdTransfer, LocationFromName, LocationToName, TransferDate }) => (
                            <FlightNumberBox key={IdTransfer}>
                            <div className='quote_input_flight_title'>
                                {IdTransfer} {getOrdinal(IdTransfer)} Transfer
                            </div>
                            <div className='quote_input_flight_subtitle'>
                                {LocationFromName} &rarr; {LocationToName}
                            </div>
                            <div className='quote_input_flight_date'>{TransferDate}</div>
                            <InputBox data={INPUT_DATA} classname='flight-input' />
                            </FlightNumberBox>
                        ))}
                    </div>
                </>

                <div className='quote_input_paxchoice'>
                    {/* <div>{JSON.stringify(roomPax)}</div> */}
                <h5 className='quote_section_title marginBottom-lvl2'>
                    {serviceRerservation?.reservationTransfer?.length > 0 && checkTransferFlight() || transferFlight.length> 0 ?" 2. ":"1. "}
                    {QUOTE_INPUT_LABEL[1]}
                    <span className = "stars" style={{color:"#FFA75D"}}>*</span>
                </h5>
                    <BookingPersonV1 
                        cname={7} 
                        paxInfo={roomPax} 
                        setLablel={'0 Adult'} 
                        serviceRerservation={serviceRerservation}
                        optionData={optionData}
                        setOptionData={setOptionData} />
                </div>
                <div className={`quote_input_paxform ${isPaxSelected ? '' : 'disabled'}`}>
                    <h5 className='quote_section_title' style={{marginBottom:"20px"}}>
                        {serviceRerservation?.reservationTransfer?.length > 0 && checkTransferFlight() || transferFlight.length> 0 ?" 3. ":"2. "}
                        {QUOTE_INPUT_LABEL[2]}
                    </h5>
                    <form>
                        { 
                            getTotalPax(roomPax[0]) > 0 ? renderForm(formState) : renderForm(temp)
                            // getTotalPax(transferPax[0]) > 0 ? renderForm(formState) : renderForm(temp)
                        }
                    </form>
                </div>
            
            </div>   
        </div>
    )
}

export default BookingInputs