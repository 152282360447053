import React, { useEffect, useState } from 'react'
import NavbarBlock from '../Transfer/NavbarBlock'
import PaxDetails from '../MiniComponents/PaxDetails'
import TableLayout from '../MiniComponents/TableLayout'
import { properCase } from '../../utility/globalFunc';
import { useSelector,useDispatch } from 'react-redux';
import TableLayoutNew from '../MiniComponents/TableLayoutNew';
import {getBeforeUnderscore} from "../../utility/globalFunc.js"


function BookingRecap({data,dataRecap,refCode,first_name,last_name,validatedData}) {
  const [paxNum,setPaxNum]=useState([]);

  const getLabel=(item)=>{
    let label=item.status;
    return properCase(label);
  };

  useEffect(()=>{    
    let num=[];
    let paxNumber={};
    for (let i = 0; i < data.length; i++) {
      let status = data[i].status;
      paxNumber[status]=paxNumber[status]?paxNumber[status] + 1:1;
      num.push(paxNumber[status]);
    }
    setPaxNum(num);
  },[])
  return (
    <>
        <div className='booking-recap-container'>
            <div className='clients-information'>
            <p className='clients-information_headtxt0'>{getBeforeUnderscore(refCode)}</p>
                <div className='clients-information_headtxt1'>Booking n° {getBeforeUnderscore(refCode)}</div>
                <div className='clients-information_headtxt2'>{first_name}  {last_name}</div>
                <h3 className='title-recap-booking'>1. Client information</h3>
                <div className='clients-info-container'>
                {
                  paxNum&&data.map((pax,ind)=>{
                    let label=getLabel(pax);
                    return (<PaxDetails firstName={pax.first_name} lastName={pax.last_name} phoneNumber={pax.phoneNumber} key={pax.first_name+ind} label={label} numero={paxNum[ind]}/>)
                  })
                }
                </div>
            </div>
            <div className='booking-resume'>
                <h3 className='title-recap-booking'>2. Booking resume</h3>
                {/* <TableLayout lists={dataRecap} marginTop='0px'/> */}
                <TableLayoutNew lists={dataRecap} total={100} isScrollable={false}/>
            </div>
        </div>
    </>
  )
}

export default BookingRecap
