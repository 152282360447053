import React, { Fragment } from 'react'
import AccomQuoteDetail from "../Accomodation/minicomponents/AccomQuoteDetail"
import TransferQuoteDetail from '../Accomodation/minicomponents/TransferQuoteDetail';
import QuoteCancellationPolicy from './QuoteCancellationPolicy';

const QuoteDetailsLayout = ({data}) => {
  
  console.log("QuoteDetailsLayout:",data);
  


  return (
    <div>
        {
          data.hasOwnProperty("reservationAccom") &&(
            data.reservationAccom.map((list,id)=>(
              <Fragment key={id}>
                <AccomQuoteDetail data={list}/>
                <div className='dashed-separator'></div>
              </Fragment>
            ))

          )
        }

        {
          data.hasOwnProperty("reservationTransfer") &&(
            data.reservationTransfer.map((list,id)=>(
              <Fragment key={id}>
                 <TransferQuoteDetail data={list} serviceName="Transfer"/> 
                 <div className='dashed-separator'></div>
              </Fragment>
            ))

          )
        }

    </div>
  ) 
}

export default QuoteDetailsLayout