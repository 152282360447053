import { useEffect } from "react";

const useStickyItems = ({containerSelector, itemSelector, stickyItemSelector, trigger, addX=0, addY=0,customStickySelector,handleOutside, closeRef }) => {
    
    const isStickyItemOutsideContainer = (itemFollow, container) => {
      
        if (!itemFollow || !container) return false;
      
        const followRect = itemFollow.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
      
        return (
          followRect.bottom <= containerRect.top || 
          followRect.top + 10 > containerRect.bottom
        //   stickyRect.left < containerRect.left || 
        //   stickyRect.right > containerRect.right 
        );
      };

    useEffect(() => {
        if (!trigger) {
            return
        }
        const container = document.querySelector(`[class*="${containerSelector}"]`);
        
        if (!container) return;
        const outerContainer = container.parentElement;
        const itemsList = Array.from(document.querySelectorAll(`[class*="${itemSelector}"]`));
        const stickyItems = document.querySelectorAll(customStickySelector?customStickySelector:`[class*="${stickyItemSelector}"]`);

        if (!outerContainer || stickyItems.length === 0 || itemsList.length === 0) return;

        const parentMap = new Map();
        stickyItems.forEach(stickyItem => {
            const parent = stickyItem.parentNode;
            
            const parentIndex = itemsList.indexOf(parent);
            if (parentIndex !== -1) {
                parentMap.set(stickyItem, parentIndex);
            }
        });

        const handleScroll = () => {
            parentMap.forEach((parentIndex, stickyItem) => {                
                const scrollingParent = document.querySelectorAll(`[class*="${itemSelector}"]`)[parentIndex];
                if (scrollingParent) {                    
                    let parentBCR = scrollingParent.getBoundingClientRect();
                    stickyItem.style.left = (parentBCR.x + addX) + "px";
                    stickyItem.style.position = "fixed";
                    stickyItem.style.top = (parentBCR.y + addY) + "px";
                    
                    handleOutside && isStickyItemOutsideContainer(closeRef&&closeRef.current?closeRef.current:scrollingParent,container) && handleOutside(stickyItem);
                }
            });
        };

        handleScroll();

        container.addEventListener("scroll", handleScroll);
        window.addEventListener("scroll",handleScroll);
        return () => {
            container.removeEventListener("scroll", handleScroll)
            window.removeEventListener("scroll", handleScroll)
        };
    }, [containerSelector, itemSelector, stickyItemSelector,customStickySelector,trigger]);

}

export default useStickyItems;
