import React, { useState, useEffect } from 'react';
import useQuoteList from '../../components/Quotations/hooks/useQuoteList.js';
import useDebounce from '../../hooks/useDebounce.js';
import { Client_dropdown } from '../../components/Quotations/small_dropdowns';
import { file_dropdown, client_datatype, rows, pdf_format, BOOKING_PAGE, QUOTATION_TYPE, SORT_DATES, ROUTE_PATHS } from "../../constants";
import { useNavigate } from 'react-router-dom';
import useExportCSV from './hooks/useExportCSV.js';
import CsvDownload from 'react-csv-downloader';

function Booking_header({ setQuoteData, quotelist, switchPage, page }) {
    const [expopopup, setExpopopup] = useState(false);
    const [isVisited, setIsVisited] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [searchInput, setSearchInput] = useState({
        quotation_type: QUOTATION_TYPE,
        sort_type: SORT_DATES[0].value,
        client_type: null,
        file_status: null,
        page_num: null,
        search_key: null
    });

    const {headerCSV,dataCSV}=useExportCSV(quotelist);

    const navigate=useNavigate();

    const debouncedSearch = useDebounce(searchInput, 500); 
    const { fetchData } = useQuoteList();

    const exportpopup = (e, name) => {
        e.preventDefault();
        setExpopopup(name === "open");
    };

    useEffect(() => {
        const loadUsers = async () => {
            if (!debouncedSearch?.search_key?.trim())  await fetchData(searchInput,setQuoteData); 
            setFilterLoading(true);
            await fetchData(debouncedSearch,setQuoteData);
            setFilterLoading(false);            
        };

        loadUsers();
    }, [debouncedSearch]);

    const handleInputChange = (e) => {
        setSearchInput((prev) => ({
            ...prev, 
            search_key: e.target.value
        }));
    };

    const onClickNewQuote=()=>{
        navigate(ROUTE_PATHS.ACCOMMODATION_SELECT);
    }


    return (
        <div className="booking_head_parent">
            <h2 className="title">Quotation & Booking</h2>
            <div className="btnblock">
                <div
                    className={`${QUOTATION_TYPE === page ? "page-selected" : "bookbtn"}`}
                    onClick={switchPage}
                >
                    Quotation
                </div>
                <div
                    className={`${BOOKING_PAGE === page ? "page-selected" : "bookbtn"}`}
                    onClick={switchPage}
                >
                    Booking
                </div>
            </div>
           { page === QUOTATION_TYPE && <div className="exportblock">
                <input
                    type="text"
                    placeholder="Enter your file name"
                    className={isVisited ? "visited" : ""}
                    onChange={handleInputChange} // Update local search input
                    onClick={() => {
                        setIsVisited(true);
                    }}
                />
                <div>
                    <CsvDownload
                        datas={dataCSV}
                        columns={headerCSV}
                        filename='quotation'
                        extension='.csv'
                        separator=';'
                        wrapColumnChar="'"
                    >
                        <button onClick={(e) => exportpopup(e, "open")}>Export</button>
                    </CsvDownload>
                    <button onClick={onClickNewQuote}>New Quote</button>
                </div>
            </div>}
        </div>
    );
}

export default Booking_header;
