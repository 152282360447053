import React,{Fragment, useEffect, useRef, useState} from 'react';
import CalDate from './CalDate.js';
import Skeleton from '@mui/material/Skeleton';
import "react-datepicker/dist/react-datepicker.css";
import LocPoints from './LocPoints.js';
import { useSelector,useDispatch } from 'react-redux';
import {deleteRow,addLists,lengthOne, removeTransfer, resetTransfer} from "../../store/transferReducer.js"
import {checkDelete} from "../../store/errorReducer.js"
import usePopupData from '../../hooks/usePopupData.js';
import LocPointsTransfer from './LocPointsTransfer.js';
import {MORE_OPTIONS_MODE, ROUTE_PATHS, TRANSFER_INPUT_OBJECT} from "../../constants.js"
import MulticalanderTransfer from '../Excursion/MulticalanderTransfer.js';
import BookingPerson from './BookingPerson.js';
import useGetHotels from '../../hooks/useGetHotels.js';
import { deepCompare, deepCompareWithIgnoringFields } from '../../utility/globalFunc.js';
import useStickyItems from '../../hooks/useStickyItems.js';

function ListTransfer({styles,lists=[],errchk,updateerr,scroll,noClick, addArrow=false, ispopup=false, isScrollable=false,isInputBox=false}) {
    const {transferUpdates,loading,transferList}=useSelector((state) => state.transfer_Global)
    const {isActive:delStatus,isPopup} = useSelector((state) => state.errorreducer.delete)
    const {isOpen,openPopup,getPopupContent}=usePopupData()
    const dispatch = useDispatch()
    const { hotelData, loading: getload, fetchData } = useGetHotels();
    const {actionMode}=useSelector(state=>state.globalreducer);
    const isMainTransfer = window.location.href.includes(ROUTE_PATHS.SELECT_VEHICLE)

    const newTransferRef=useRef(null);
    
    useEffect(() => {
        !hotelData && fetchData()
    }, [])
    // //useEffect for styling
    useEffect(() => {
        const rmdpContainers = document.querySelectorAll('.listsParent .rmdp-container');

        rmdpContainers.forEach(container => {
            container.style.marginRight = '40px';
            container.style.marginLeft = '5px';
        });
    }, [transferUpdates]); 



    function deletefunc(e, name, data) {
        e.preventDefault()
        if (transferUpdates.length === 1) {
            let sendObj = { delbtn: false, delpop: false }
            dispatch(checkDelete(sendObj))
            dispatch(lengthOne())
            dispatch(resetTransfer());

        } else {
            dispatch(deleteRow(data.IdTransfer))
            let sendObj = { delbtn: true, delpop: false }
            dispatch(checkDelete(sendObj))
        }
    }
    
    const deleteTransfer =(e,name,data)=>{
        e.preventDefault();
        // empty 
        if((delStatus && name === "delpopup") && (data.LocationFromName === "")){

          if (transferUpdates.length === 1) {
            let sendObj = {delbtn:false,delpop:false}
            dispatch(checkDelete(sendObj))
            updateerr(false)
            dispatch(lengthOne())
            dispatch(resetTransfer());
            // openPopup("del-message");
        
          } else {
            if (transferList.some(transfer=>transfer.IdTransfer===data.IdTransfer)) {
                dispatch(removeTransfer({idTransfer:data.IdTransfer}));
            }else{
                dispatch(deleteRow(data.IdTransfer))
            }
            // openPopup("del-message");
          }
    
        }

        // for popup
        if((delStatus && name === "delpopup") && (data.LocationFromName !== "")){ 
            // let sendObj = {delbtn:true,delpop:true,items:data}
            // dispatch(checkDelete(sendObj));
            deletefunc(e, "delete", data);
            // openPopup("del-message");
        }
        
    }

    const addInputBoxes =(e)=>{
        e.preventDefault();
        let objectLength = transferUpdates.length +1;
        let addInputs = { ...TRANSFER_INPUT_OBJECT };
        if (actionMode===MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_EXISTING_PAX) {
            const newTranfer=transferUpdates.find(item => !item.isPersisted && !item.IsSearched)
            const {AdultCount,TeenCount,ChildCount,InfantCount} =newTranfer;
            addInputs={...addInputs,AdultCount,TeenCount,ChildCount,InfantCount}
        }
        addInputs.IdTransfer = objectLength;
        dispatch(addLists({addInputs,index:transferUpdates.length}))
    }

    const isPaxDisable=()=>{
        return actionMode===MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_EXISTING_PAX;
    }

    useEffect(()=>{
        if (!(actionMode===MORE_OPTIONS_MODE.ADD_ITEM)) {
            return;
        }
        const isNewTransfer=transferList.every(item=>item.isPersisted)
        const lastTransfer=transferUpdates[transferUpdates.length-1];
        const isAlreadyClicked=deepCompareWithIgnoringFields(lastTransfer,TRANSFER_INPUT_OBJECT,["IdTransfer"]);
        
        if (newTransferRef.current&&isNewTransfer&&!isAlreadyClicked) {            
            newTransferRef.current.click();
          }  
    },[])


    return (
        <>
        <div className={'inputBlocks '+ (!noClick ? "":"noclick") + ` ${isScrollable?"scrollable":""}`} >
            {
                    lists?.length >= 1 ? (lists.map((data, rowId) => {
                        let transferRow=data||TRANSFER_INPUT_OBJECT;
                        return( 
                            <Fragment key={"trans-"+rowId}>
                                {isInputBox && rowId != 0 && <div className='also-block'></div>}
                                <div className={`transfer-new-design ${delStatus ?" delhover":""}`}  onClick={delStatus ? (e)=>{deleteTransfer(e,"delpopup",transferRow)} :null}>
                                {isInputBox &&
                                      <div className='transfer-new-design-box'>
                                        <div className={`tranfser-icon ${delStatus ?" delhover":""}`}>&nbsp; &nbsp; &nbsp; transfer {rowId +1}</div>
                                        {delStatus && <button className={`tranfser-ask-delete ${delStatus ?"delhover":""}`}>&nbsp; Delete ? &nbsp;</button>}
                                     </div>
                                }
                                    <div key={"transInp"+rowId} style={styles} className={"listsParent "+(transferRow.isPersisted?" disable":"") + (delStatus ?" delhover":"") }   >
                                        <div style={{marginRight:"10px"}}>
                                            <BookingPerson cname={2} scroll={scroll} paxInfo={transferRow} rowId={rowId} isTransfer={true} isDisable={isPaxDisable()} isDeleting={delStatus}/>
                                        </div> 
                                            <MulticalanderTransfer errmsg={errchk} label="Transfer Date" label2={'dd/mm/yyyy'} month={2} page={1} rangeDate={[]} rowid={rowId} calDate={transferRow.TransferDate} isDeleting={delStatus} isMainTransfer={isMainTransfer}/>
                                        <LocPointsTransfer
                                            locationPoints={hotelData}
                                            label="Pick-up-point"
                                            inpText="Search for a pick-up-point"
                                            inpTextDetail="Please select the point A of this transfer"
                                            points="pickup"
                                            passArray1={transferRow.LocationFromName}
                                            passArray2={transferRow.LocationFromDetails}
                                            passId={transferRow.IdTransfer}
                                            errmsg={errchk}
                                            rowid={rowId}
                                            scroll={scroll}
                                        />
                                        {/* <div className={!addArrow ? "inputLine" :"inputLine arrowLine"}></div> */}
                                        <div className={!addArrow ? "inputLine" :"inputLine"}></div>
                                        <LocPointsTransfer
                                            locationPoints={hotelData}
                                            label="Destination"
                                            inpText="Search for a destination Point"
                                            inpTextDetail="Please select the point B of this transfer"
                                            points="destination"
                                            passArray1={transferRow.LocationToName}
                                            passArray2={transferRow.LocationToDetails}
                                            passId={transferRow.IdTransfer}
                                            errmsg={errchk}
                                            rowid={rowId}
                                            scroll={scroll}
                                        />
                                        <div  className={delStatus ? "deliconbox": "hide"} ></div> 

                                    </div>
                            </div>
                        </Fragment>
                        )
                    })):<></>
            }
            {!loading && !noClick }
        </div>
        {isOpen ? getPopupContent():<></>} 
        </>
    );
}


export default ListTransfer;