import { createSlice } from '@reduxjs/toolkit'
import {TRANSFER_INPUT_OBJECT} from "../constants.js"
import { getTransfersVehiclesChoices, sortByDateAsc } from '../utility/globalFunc.js'



export const INITIAL_STATE = {
  tempId:0,
  visitedTransferList: true,
  hotelList:[],
  regionDetails:[],
  transferList:[],
  refCode:null,
  TransferDates:[],
  transferUpdates:[TRANSFER_INPUT_OBJECT],
  transferUpdatesCopy:null,
  transferTotal:null,
  updatePax:null,
  totalpax:null,
  loading:false,
  loadingBoxCar: false,
  others:null,
  isSameVehicle:false,
  isResultSameVehicle:false,
  ageInfo:[[]],
  transferPax:[{"adult":1,"teen":0,"child":0,"infant":0,"room_id":1,"paxAge":[]}],
  isPaxDisabled:false,
  isClickedButtonDone:false,
 }
 
 
 
 
 export const transferData = createSlice({
   name: 'transfer_Global',
   initialState:INITIAL_STATE,
   reducers: {
        trigerId(state, action) {
          state.tempId = action.payload;
        },
        nextTransferId(state){
          const currentId=state.tempId;
          const nextId = state.transferList.findIndex((transfer, index) => index > currentId && !transfer.isPersisted)
          state.tempId=nextId;
        },
        setVisitedTransferList(state, action) {
          state.visitedTransferList = !state.visitedTransferList;
        },
        resetTransferList(state,action){
          state.transferList=INITIAL_STATE.transferList;
        },

        resetTransferUpdates(state,action){
          state.transferUpdates=INITIAL_STATE.transferUpdates;
        },

        // saved quote 
        updateTransferDetails(state,action){
          state.transferUpdates = action.payload;
        },

        updateTransferlistDetails(state,action){
          state.transferList = action.payload;
        },

         getHotels(state,action){
             state.hotelList = action.payload
         },
         
        listTransfer(state, action) {
          const { region_details, transfer_data, transDate } = action.payload;
          state.visitedTransferList = false;
          // Trier `transferUpdates` par date au moment de recherche

          // console.log("chk",{transfer_data});
          
          state.transferUpdates =  sortByDateAsc(state.transferUpdates);
          state.transferList = sortByDateAsc(transfer_data);
          state.regionDetails = region_details;
          state.TransferDates = transDate;
        },
        addListTransfer(state,action){
          const { region_details, transfer_data, transDate,idsSearched } = action.payload;
          state.visitedTransferList = false;
          
          const newTransfers=[];
          const currentTransfers=[...state.transferList];
          
          for (let i = 0; i < idsSearched.length; i++) {
            const id = idsSearched[i];
            const index=currentTransfers.findIndex(transfer=> transfer.IdTransfer===id)
            if (index>-1) {
              currentTransfers[index]=transfer_data[i];
            }else{
              newTransfers.push(transfer_data[i]);
            }
          }

          // Trier `transferUpdates` par date au moment de recherche
          const newTransferUpdates=sortByDateAsc(state.transferUpdates);
          const newTransferList=sortByDateAsc([...currentTransfers,...newTransfers]);
          const firstIndexNotSelected=newTransferList.findIndex((trans=>!trans.isPersisted&&(!trans.IsSearched||!trans.Car_selected)));
          const changeChoiceMode=state.isSameVehicle||state.isSameVehicle!==state.isResultSameVehicle;

          state.transferUpdates =  newTransferUpdates;
          state.transferList = newTransferList;
          state.regionDetails = getTransfersVehiclesChoices(newTransferUpdates,state.regionDetails,region_details,changeChoiceMode);
          state.TransferDates = transDate;
          state.tempId=firstIndexNotSelected;
        },
         updateTransPax(state,action){
           let {paxData,totalpax} = action.payload 
           state.updatePax = paxData
           state.totalpax =totalpax
         },
         
         updateRegion(state,action){
           let {detailId,regionId,name,count,price,finalprice} =action.payload
           console.log(detailId,regionId,name,price);
        
           
           state.regionDetails[detailId][regionId][name] = count
           //state.regionDetails[detailId][regionId].name.price = price
           state.regionDetails[detailId][regionId].final_price = finalprice
         },
 

       
         addRefcode(state,action){
          console.log(action.payload);
           state.refCode = action.payload 
         },

      
 
         clearTransfer(state){
           return INITIAL_STATE; 
         },
 
         calUpdates(state,action){
           let {id,name,value}=action.payload
           state.transferUpdates[id][name] = value
           state.transferUpdates[id].IsSearched=false;
         },
 
         clearDates(state,action){
           let {id,name}=action.payload
           state.transferUpdates[id][name] = ""
         },
 
         locationUpdates(state,action){
           let {id,name,details,location,region,point}=action.payload
           if(point === "pickup"){
             state.transferUpdates[id].LocationFromName = name
             state.transferUpdates[id].LocationFromDetails = details
             //state.transferUpdates[id].Pickup_region = region
             state.transferUpdates[id]["LocationFromId"] = location
           }
           else{
             state.transferUpdates[id].LocationToName = name
             state.transferUpdates[id].LocationToDetails = details
             //state.transferUpdates[id].Destination_region = region
             state.transferUpdates[id]["LocationToId"] = location
           }
           state.transferUpdates[id].IsSearched=false;
         },
 
         addLists(state,action){
           const { addInputs, index } = action.payload;
           state.transferUpdates.splice(index, 0, addInputs);
           
         },
         removeLocation(state,action){
           let {rowid:id,points}=action.payload
           if(points === "pickup"){
             state.transferUpdates[id].LocationFromName = ""
             state.transferUpdates[id].LocationFromDetails = ""
             //state.transferUpdates[id].Pickup_region = ""
             state.transferUpdates[id]["LocationFromId"] = ""
           }
           else{
             state.transferUpdates[id].LocationToName = ""
             state.transferUpdates[id].LocationToDetails = ""
             //state.transferUpdates[id].Destination_region = ""
             state.transferUpdates[id]["LocationToId"] = ""
           }
         },
 
         deleteRow(state,action){
           const newArr = state.transferUpdates.filter(row => row.IdTransfer !== action.payload);
           state.transferUpdates = newArr.map((item, index) => ({
             ...item,
             IdTransfer: index + 1
           }));
           state.rowDelete = true
           const newArrs = state.transferList.filter(row => row.IdTransfer !== action.payload);
           state.transferList = newArrs.map((item, index) => ({
             ...item,
             IdTransfer: index + 1
           }));
           const newTempId=state.tempId>newArr.length-1?state.tempId-1:state.tempId;
           state.tempId=newTempId;
         
         },
         lengthOne(state,action){
           state.transferUpdates =[TRANSFER_INPUT_OBJECT]
         },
         loadStatus(state,action){
           state.loading =action.payload
         },
          loadStatusBoxCar(state, action) {
            state.loadingBoxCar = action.payload
          },
         transPax(state,action){
           state.transferPax  = action.payload;
         },
         removeTransfer(state,action){
          let {idTransfer}=action.payload;
          const indTrans=state.transferList.findIndex(trans=>trans.IdTransfer===idTransfer);
          
          const transferList=[...state.transferList].filter((trans)=>trans.IdTransfer!=idTransfer);
          const regionDetails=[...state.regionDetails].filter((_,ind)=>ind!=indTrans);
          const transferInput=[...state.transferUpdates].filter((trans)=>trans.IdTransfer!=idTransfer);
          const newTempId=state.tempId>transferInput.length-1?state.tempId-1:state.tempId;
          console.log({newTempId});
          
          state.transferList=transferList;
          state.transferUpdates=transferInput.length===0?[TRANSFER_INPUT_OBJECT]:transferInput;
          state.regionDetails=regionDetails;
          state.tempId=newTempId;
         },
         resetTransfer(state,action){
          state.transferList=INITIAL_STATE.transferList;
          state.regionDetails=INITIAL_STATE.regionDetails;
          state.transferUpdates=INITIAL_STATE.transferUpdates;
         },
         transferUpdatePax(state,action){
          const {id=0,AdultCount,TeenCount,ChildCount,InfantCount}=action.payload;
          state.transferUpdates[id].AdultCount = AdultCount,
          state.transferUpdates[id].TeenCount = TeenCount,
          state.transferUpdates[id].ChildCount = ChildCount,
          state.transferUpdates[id].InfantCount = InfantCount
          state.transferUpdates[id].IsSearched=false;
        },
         transferDeletePax(state,action){
          const {id}=action.payload
          state.transferUpdates[id].AdultCount = 1,
          state.transferUpdates[id].TeenCount = 0,
          state.transferUpdates[id].ChildCount = 0,
          state.transferUpdates[id].InfantCount = 0
          state.transferUpdates[id].IsSearched=false;
        },
        setAllSearched(state){
          for (let i = 0; i < state.transferUpdates.length; i++) {
            state.transferUpdates[i].IsSearched=true;
          }
        },
        setIsSameVehicle(state,action){
          state.isSameVehicle=action.payload;
        },
        setIsResultSameVehicle(state,action){
          state.isResultSameVehicle=action.payload;
        },
        startRemoveTransfer(state){
          state.transferUpdatesCopy=state.transferUpdates;
        },
        commitRemoveTransfer(state){
          state.transferUpdatesCopy=null;
          if (state.transferUpdates.length<3) {
            state.isSameVehicle=false;
          }
        },
        rollBackRemoveTransfer(state){
          state.transferUpdates=state.transferUpdatesCopy;
          state.transferUpdatesCopy=null;
        },
        setIsPaxDisabled(state,action){
          state.isPaxDisabled =action.payload
        },
        setIsClickedButtonDone(state,action){
          state.isClickedButtonDone =action.payload
        },
        resetAllTransfer(state){
          state = INITIAL_STATE;
          return state;
        },
   },
 })
 
export const {loadStatusBoxCar,updateTransferlistDetails,updateTransferDetails,setVisitedTransferList,trigerId,transPax,updateRegion,loadStatus,updateTransPax,clearDates,lengthOne,deleteRow,addLists,removeLocation,getHotels,listTransfer,isTransferdone,addRefcode,clearTransfer,calUpdates,locationUpdates,removeTransfer,resetTransfer,transferUpdatePax,transferDeletePax,setAllSearched,addListTransfer,setIsSameVehicle,setIsResultSameVehicle,nextTransferId,startRemoveTransfer,commitRemoveTransfer,rollBackRemoveTransfer,setIsPaxDisabled,setIsClickedButtonDone,resetAllTransfer} = transferData.actions
 
export default transferData