import React, { useState,useRef,useEffect } from "react";
import {hasAgeZero, scrollToDependsBoundary, scrollToTop,checkRoomPaxZero} from "../../utility/globalFunc.js"
import {accomPaxCount,formatPax,getTotalPax,PAX_VALIDATION_PARMS,ROOMPAXDATA} from "../../constants"
import { useDispatch } from "react-redux";
import {excurPax} from "../../store/excursionReducer.js"
import {transPax,transferUpdatePax,transferDeletePax} from "../../store/transferReducer.js"
import {accomPax,addAgeInfo,accomPaxBookingDefault} from "../../store/accomodationReducer.js"
import PaxDropdown from "./PaxDropdown.js";
import useOutsideClick from '../../hooks/useOutsideClick.js';
import { te } from "date-fns/locale";
import useStickyItems from "../../hooks/useStickyItems.js";

function BookingPerson({cname,paxInfo,scroll,additionalActionOnDone=undefined,isTransfer=false,rowId,isDisable=false,isDeleting=false}) {
  const [popupOpen, setPopupOpen] = useState({dropDown: false,submitClk: false,addRoom:false});
  const [optionData, setOptionData] = useState();
  const [transferPax, setTransferPax] = useState([])
  
  const btnref = useRef(null);
  const dispatch = useDispatch();

  useOutsideClick(btnref, () => {
    if (popupOpen.dropDown) {
      scrollToTop();
     
    }
    setPopupOpen({ dropDown: false, submitClk: false }); 
  });


  const handleDropdown = (e) => {
    e.preventDefault()
    setPopupOpen((prevstate) => ({
      ...prevstate,
      dropDown:!popupOpen.dropDown
    }));
    if(!isTransfer){

      setOptionData(paxInfo)
    }else{
      setOptionData(transferPax)
    }

  };

  const confirmPax = (e, name) => {
    e.preventDefault();

    if(!isTransfer){

        if (name === "clear") {
          setOptionData([ROOMPAXDATA])
        }
        else {
          setPopupOpen((prevstate) => ({ ...prevstate,dropDown: false,submitClk: true,}));
          if(cname === 3){
            console.log("excursion:",optionData);
            dispatch(excurPax(optionData))
          }
          else if(cname === 2){
            console.log("transfer",optionData);
            dispatch(transPax(optionData))
          }   
          else if(cname === 7){
            console.log("optionData",optionData);
            dispatch(accomPaxBookingDefault(optionData))
          }   
          else  {
            console.log("optionData",optionData);
             dispatch(accomPax(optionData)) 
            additionalActionOnDone&&additionalActionOnDone();
          } 
        }
    }else{
        if(name === "clear"){
          dispatch(transferDeletePax({id:rowId}))
        }else{
          let payload = {id:rowId, AdultCount:optionData[0]?.adult,TeenCount:optionData[0]?.teen,ChildCount:optionData[0]?.child,InfantCount:optionData[0]?.infant}
          
          dispatch(transferUpdatePax(payload))
          setPopupOpen((prevstate) => ({ ...prevstate,dropDown: false,submitClk: true,}));
          setTransferPax(optionData)
        }
    }
   
  };

  useEffect(() => {
    if (!popupOpen?.dropDown) return; 
    if (scroll) {
      // alert("test"); 
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [popupOpen, scroll]);
  
  useEffect(()=>{
    if(isTransfer){
      let tempPaxInfo = [{
        "adult": paxInfo?.AdultCount,
        "teen": paxInfo?.TeenCount,
        "child": paxInfo?.ChildCount,
        "infant": paxInfo?.InfantCount,
        "room_id": 1,
        "paxAge": []
      }]
      
       setTransferPax(tempPaxInfo) 
    }else{
      setTransferPax(paxInfo)
    }

  },[paxInfo])

  useStickyItems({
    containerSelector: "scrollable",
    itemSelector: "selectDropdown-pax",
    stickyItemSelector: "optionBox-pax",
    trigger:popupOpen.dropDown,
    addX:-200,
    addY:64,
    handleOutside: () => {
      setPopupOpen({ dropDown: false, submitClk: false })
    }
  })
  
  return (
    <div className={`selectDropdown-pax ${isDisable?"disable":"fade-in-300"}`} ref={btnref}  style={ cname ==="accomodation"?{justifyContent: "flex-start",marginLeft:"10px"}:{} } >
      <div className={`numOfPax ${!popupOpen.dropDown ?"numOfPax_sel":"paxpop_open"} ${isTransfer ? "transfer" :""} ${isDeleting ? "delhover":""}`} onClick={(e) => handleDropdown(e)} >
        {!isTransfer ? <div className="pax-text">{popupOpen.dropDown ?  "Select nb of pax": <span> Number of pax</span>}</div> : <div className="pax-text transfer">{popupOpen.dropDown &&  "Nb of pax"}</div>}
        
        {
          !popupOpen.dropDown &&
          <div className={`dropdown-paxmenu ${isDeleting ? "delhover":""} ${isDisable?"":"fade-in-300"}`} >
              <div className="end_hover selLabel2">
                 {!isTransfer ? (paxInfo.length > 1 ? <>{accomPaxCount(paxInfo)[0]}</>:<>{ formatPax(paxInfo[0])}</>) : (isTransfer &&  transferPax.length > 0 && <>{`${getTotalPax(transferPax[0])} Pax` }</>) }
              </div>    
          </div>
        }
      </div>

      {popupOpen.dropDown && <PaxDropdown optionList={optionData} setOptionData={setOptionData} cname={cname} confirmPax={confirmPax} hideAge={isTransfer}/>}
    </div>
  );
}

export default BookingPerson;