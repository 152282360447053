import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ADD_ITEM_QUOTATION_ENDPOINT,QUOTELIST_ENDPOINT } from '../../../services/apiEndpoints';
import { createSelector } from '@reduxjs/toolkit';
import { buildPayloadQuotation } from '../../../utility/globalFunc';
import { addRefcode } from '../../../store/transferReducer';
import { axiosRequestBody,axiosRequestParams } from '../../../services/apiClient';
import { clearRoom } from '../../../store/accomodationReducer';
import { MORE_OPTIONS_MODE,QUOTATION_TYPE2, ROUTE_PATHS } from '../../../constants';
import {setQuoteBookList} from "../../../store/globalReducer"

const useAddItems = () => {

    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {actionMode} =useSelector((state) => state.globalreducer)  


    const getNewItems=(items)=>{
        const filtered=items.filter((item)=>item.isNew)        
        return filtered.map(({isNew,isPersisted,...rest})=>rest);
    }


    const addItems=async(transferlist,selExcursion,selRooms,cotation_name,first_name,last_name,roomPax,savedFileNumber)=>{
        setLoading(true);
        console.log("actmode1",actionMode);
    

        try {
            console.log("newitems",getNewItems(transferlist));
            let payload=buildPayloadQuotation(getNewItems(transferlist),getNewItems(selExcursion),getNewItems(selRooms),cotation_name,first_name,last_name,roomPax,actionMode);
            console.log("payload",payload);
            payload.file_no = savedFileNumber
            const response = await axiosRequestBody("PUT",ADD_ITEM_QUOTATION_ENDPOINT,payload);
            setStatus(response.data.status)
            console.log("update quote res:", response.data)
           // dispatch(addRefcode(response.data.to_ref_code))
            if(response.data.status===201){
                dispatch(clearRoom());
                //dispatch(addRefcode(null));
            }


        } catch (error) {
                console.log("accom error response",error);
                if(error.response && error.response.status === 409){
                    alert(error.response.data.message)
                }
                else if (error.response) {
                    alert(error.response.data.message)
                } else {
                    navigate(ROUTE_PATHS.INTERNAL_ERROR,{state:{message:error.response?.data?.message||"",status:error.response?.status}})
                }
        } finally {
            setLoading(false)
        }
    }

    const deleteQuotation =async(payload,mode,callBack)=>{

        setLoading(true);
        try {
            const {file_no,...deletePayload}=payload;
            const response = await axiosRequestParams("DELETE",ADD_ITEM_QUOTATION_ENDPOINT,deletePayload);
            if (response.status === 200) {
                if (mode === MORE_OPTIONS_MODE.DELETE_QUOTATION) {
                    setLoading(false);
                    navigate(ROUTE_PATHS.QUOTATION)

                }
                else {
                    let updatedData = await axiosRequestParams("GET", QUOTELIST_ENDPOINT, { file_no, to_ref_code: payload.to_ref, quotation_type: QUOTATION_TYPE2 })

                    dispatch(setQuoteBookList(updatedData.data.result))
                }
            }
           else{

           }
        

        } catch (error) {
                console.log("accom error response",error);
                if(error.response && error.response.status === 409){
                    alert(error.response.data.message)
                }
                else if (error.response) {
                    // alert(error.response.data.message)
                    throw error;
                } else {
                    navigate(ROUTE_PATHS.INTERNAL_ERROR,{state:{message:error.response?.data?.message||"",status:error.response?.status}})
                }
        } finally {
            setLoading(false)
        }
    }

    return {status,loading,error,addItems,deleteQuotation};
}

export default useAddItems
