import React, { useState, useEffect, useRef, Fragment } from 'react';
import { buildNonAvailableMealPlan, buildNonAvailableMealPlans, buildSelectedRoom, backenedDates, formatPrice, generateUniqueId, getCurrency, scrollToDependsBoundary, buildReservationAccom, getRoomIndexInsertFromBottom,getOffersID,getOffersDetails } from "../../utility/globalFunc.js"
import { AtomOrdinalButton, ImageWithFallBack, NightStay } from '../MiniComponents/Atom.js';
import Occupancy from './minicomponents/Occupancy.js';
import Amenities from "../MiniComponents/Amenities.js"
import { getOrdinal, addingTotalPax, maxRoomTxt, Errorroomtxt, scrollRemove, SERVICE_TYPE, MORE_OPTIONS_MODE, MEAL_PLANS, MEAL_PLAN_STATUS_NON_AVAILABLE } from "../../constants.js"
import { DisplayPax } from '../Excursion/ExcursionArray.js';
import { useSelector, useDispatch } from 'react-redux';
import RoomPopup from '../MiniComponents/RoomPopup.js';
import AmenitiesPopup from '../MiniComponents/AmenitiesPopup.js';
import { selectedRooms, createRoom, resetIsnewHotel, chooseMealPlan, selectCreateRooms, removeQuotationItem, restoreStatusRoom, accomDates } from "../../store/accomodationReducer.js"
import DefaultRoomImg from "../../Assets/images/default-img/room.jpg";
import B2BSkeleton from './minicomponents/B2BSkeleton.js';
import ROOMS_SKELETON from "../../Assets/images/skeleton/rooms-skeleton.png";
import CancellationPolicyPopup from './minicomponents/CancellationPolicyPopup.js';
import LazyImage from '../MiniComponents/LazyImage.js';
import { AccomStockStatus } from './minicomponents/Atom.js';
import mealplan from '../../Assets/images/meal-check.png'
import { createSelector } from '@reduxjs/toolkit';
import usePopupData, { ROOM_OFFERS_POPUP } from '../../hooks/usePopupData.js';

const POPUP_CANCELLATION_STATE_INIT = { open: false, onValidate: null, cancellationData: null }
export const ROOM_STATUS = { ONREQUEST: "ON REQUEST", STOPSALES: "STOP SALES", FREESALES: "FREE SALES",UNAVAILABLE:"Unavailable" }


const selectAccomRooms=(state)=>state.globalreducer.sortedList.services.reservationAccom;
const selectHotelId=(_,hotelId="")=>hotelId;
const selectDates=(_,__,dates="")=>dates;

const getRoomNumber=createSelector(
    [selectAccomRooms,selectHotelId,selectDates],
    (accoms,hotelId,dates)=>{   
        for (const accom of accoms) {
            if (accom.HotelId===hotelId&&accom.Dates===dates) {
                return accom.Rooms.length;
            }
        }
        return 0;
    }
)

function HotelRooms({ data, hotelName, accomDate,hotelId,otherImage,hotelRoomProps }) {
    
    const [roomNo, setRoomNo] = useState(0);
    const { roomPax, createRooms,roomStatus ,calendarDate,accomLoading,pendingRooms } = useSelector((state) => state.accomodationreducer)
    const { actionMode,sortedList } = useSelector((state) => state.globalreducer)
    const [buttons, setButtons] = useState([])
    const [popupOpen, setPopupOpen] = useState({ popup: false, rateSheet: null, maxPax: null, currency: null, roomAmenitiesData: null, hotelName: null, seemore: false, price: null, images: null, roomName: null,stockStatus:null });
    const dispatch = useDispatch();
    const [textButtonSelected, setTextButtonSelected] = useState("Added");
    const [popupCancellation, setPopupCancellation] = useState(POPUP_CANCELLATION_STATE_INIT);
    const roomContainerRef = useRef(null);
    const [avaiableRooms, setAvaibleRooms] = useState([])
    const [unavaiableRooms, setUnavaiableRooms] = useState([])
    const [currentRoomTotalPax, setCurrentRoomTotalPax] = useState([]);
    const [buttonLabelNo,setButtonLabelNo]=useState(1);
    const [roomOfferPopupData,setRoomOfferPopupData]=useState({roomName:"",roomImage:"",mealPlan:"",clientType:"Standard",pax:{},amenities:undefined,cancellationData:null});
    const { isOpen, openPopup, getPopupContent } = usePopupData();
    
    const roomNumber = useSelector((state) => getRoomNumber(state,hotelId,accomDate))

    let {other_images,desc,amenities}=hotelRoomProps

    
    useEffect(() => {
        scrollRemove(popupOpen.seemore)
        filterRoom();
    }, [popupOpen.seemore, createRooms[roomNo]?.RoomStay])

    useEffect(() => {
        
        let nextRoomNumber = pendingRooms.startRoomIndex ? pendingRooms.startRoomIndex : (roomNumber + 1);
        
        if (!accomLoading&&pendingRooms.requiredNbRooms>=nextRoomNumber) {
            let firstNotSelect=Math.max(pendingRooms.roomsFilled.indexOf(false),0);
            setRoomNo(firstNotSelect)
            setButtons(Array.from({ length: roomPax.length }, (_, i) => (
                { id: i, clicked: i <= firstNotSelect || pendingRooms.roomsFilled[i], no: i + nextRoomNumber, select: pendingRooms.roomsFilled[i] }
            )))
            setButtonLabelNo(nextRoomNumber);
        }
    }, [accomLoading, pendingRooms.requiredNbRooms,pendingRooms.key,pendingRooms.reloadRoomNumber])


    /// ADD ROOM CLICK FUNCTION STARTS.............

    const selectMeal = (e, roomNo, roomIndex, mealPlanId) => {
        e?.preventDefault();
        dispatch(chooseMealPlan({ roomNo: roomNo, roomId: roomIndex, mealPlanId: mealPlanId }));
    };

    const viewPopup = (e, amenities, name, mealPlan, room) => {
        
        let { RoomAmenities: room_amenities, price, OtherImages: other_images, RoomName: roomname, MaxPax: max_pax, HotelCurrency:currency } = amenities
        let pricenew = getRoomPrice(amenities)
        e.preventDefault();

        if (name === "view-details") {
            // let rateSheetObj = mealPlan.filter(item=>item.IsBase === true)
            
            setPopupOpen((prevstate) => ({
                ...prevstate,
                popup: true,
                images: [room.MainImage,...room.OtherImages],
                price: mealPlan.AmountAfterTax,
                roomName: room.RoomName,
                hotelName: hotelName,
                seemore: false,
                maxPax: max_pax,
                currency: mealPlan.Currency,
                rateSheet: mealPlan,
            }))
        }
        else {
            setPopupOpen((prevstate) => ({
                ...prevstate,
                popup: false,
                roomAmenitiesData: room_amenities,
                seemore: true,
                price: pricenew,
                roomName: roomname,
                images: other_images,
                currency: currency,
                stockStatus:amenities.Status,
                images: [amenities.MainImage,...amenities.OtherImages],
            }))
        }

    }

    const getSelectedMealPlan = (room) => {
        for (let i = 0; i < room.RoomRatePlan.length; i++) {
            const element = room.RoomRatePlan[i];
            if (element.IsBase) {
                return element;
            }
        }
        return undefined;
    }

    const getRoomPrice = (room) => {
        let mealplan = getSelectedMealPlan(room);
        return mealplan?.AmountAfterTax;
    }

    /// ADD ROOM CLICK FUNCTION STARTS.............
    const addRoom = (e, room, roomnum) => {
        console.log("room",room);
        
        e.preventDefault();

        let { selectedStatus, HotelCurrency, CancellationPolicy, MainImage, RoomAmenities } = room;
        let selectedMealPlan = getSelectedMealPlan(room);
        if (!selectedStatus) {
            setTimeout(() => {
                
                if (roomContainerRef.current) {
                    if (pendingRooms.roomsFilled.length > 1) {
                        scrollToDependsBoundary(roomContainerRef.current, "start", "smooth", -200);
                    }
                }
            }, 150);
            buttons&&setTimeout(() => {
                btnColorChange(e, "roomclk", roomnum, buttonLabelNo+1);
            }, 300);
        } else {
            let btnArray = [...buttons];
            btnArray[roomnum].select = false;
            btnArray[roomnum].clicked = true;
        }
        let idAccom = generateUniqueId();
        let cancellationId = CancellationPolicy.map((data) => data.CancellationId)
        let offersID = getOffersID(room.RoomRatePlan)
        let offersDetails= getOffersDetails(room.RoomRatePlan)
       
        dispatch(selectCreateRooms({ roomNo: roomnum, roomId: room.RoomID, idAccom }));
        let selectedRoom = buildSelectedRoom(room, selectedMealPlan, hotelId, hotelName, accomDate, idAccom, [roomPax[roomnum]], actionMode, HotelCurrency, cancellationId, CancellationPolicy, RoomAmenities,other_images,desc,amenities,offersID,offersDetails);
        console.log("selectedRoom",selectedRoom)
        dispatch(resetIsnewHotel(false));
        let roomNoFromBottom=getRoomIndexInsertFromBottom(pendingRooms.roomsFilled,roomNo);
        dispatch(selectedRooms({room:selectedRoom,roomNo:roomNoFromBottom}));

    };

    const btnColorChange = (e, name, id,no) => {
        e.preventDefault();
        let btnlength = buttons.length - 1;
        no&&setButtonLabelNo(no)

        if (!buttons||!buttons.length) {
            return
        }

        if (name === "btnClk") {
            setRoomNo(id);
        }
        else if (name === "roomclk" && id !== btnlength && buttons[id + 1].select === false) {
            let btnArray = [...buttons]
            btnArray[id].select = true
            btnArray[id + 1].clicked = true
            setRoomNo(id + 1)
            setButtons(btnArray)
        }
        else if (name === "roomclk" && id !== btnlength && buttons[id + 1].select === true) {
            let btnArray = [...buttons]
            btnArray[id].select = true
            btnArray[id].clicked = true
            setButtons(btnArray)
        }
        else {
            let btnArray = [...buttons]
            btnArray[id].select = !btnArray[id].select
            setButtons(btnArray)
            // setButtonLabelNo(bu);
        }
    }

    const closePopup = (e) => {
        e.preventDefault();
        let animDiv = document.getElementById('amenities-popup');
        if (animDiv) {
            animDiv.classList.add('fadeOut');
            animDiv.addEventListener('animationend', () => {
                animDiv.style.display = 'none';
            });
        }
        setTimeout(() => {
            setPopupOpen({ popup: false, id: null, seemore: false })
        }, 2000);
    }

    useEffect(() => {
        setCurrentRoomTotalPax(roomPax?.filter((item, index) => index === roomNo))
    }, [roomNo, roomPax])


    const old_getButtonClassName = (room) => {
        if (!room.selectedStatus && createRooms.length === 1) {
            return "add-quote-btn ";
        } else {
            return "add-quote-btn-multiple-room ";
        }
    }

    const getButtonClassName = (room) => {
        const isRoomSelected = room.selectedStatus === "selected";
        const isRoomNotSelected = room.selectedStatus === "not-selected";
        const isSingleRoom = createRooms.length === 1;
        const isMultipleRooms = createRooms.length > 1;


        if (isRoomSelected) {
            return "selected_btn";
        }
        if (isRoomNotSelected && isSingleRoom) {
            return "seemore_btn notseldiv";
        }
        if (isRoomNotSelected && isMultipleRooms) {
            return "seemore_btn notseldiv";
            // return "add-quote-btn-multiple-room ";
        }
        return "add-quote-btn";
    };


    const getRoomAmenities = (room) => {
        let data = [];
        room.RoomDimension && data.push({img:"room-size",AmenityName:room.RoomDimension + " m²"});
        return data;
    }

    const getRoomAmenitiesDetails = (room) => {
        let data = [];
        data.push(room.RoomAmenities);
        return data;
    }

    const showCancellationPolicyPopup = (show) => {
        if (!show) {
            setPopupCancellation(POPUP_CANCELLATION_STATE_INIT);
        }
    }

    const handleAddRoom = (e, room, roomNo) => {        
        if (pendingRooms.roomsFilled.length<=1){
            setTimeout(() => {
                dispatch(restoreStatusRoom());
            }, 5000);
        }
        
        
        if (room.selectedStatus !== "selected") {
            setPopupCancellation(prev => {
                let cancellationData = room.CancellationPolicy

                    .filter(data => data.Rooms.includes(room.RoomName))
                    .map(data => { return { ...data, rooms: [room.RoomName] } });
                return {
                    open: true,
                    onValidate: () => addRoom(e, room, roomNo),
                    cancellationData: cancellationData
                }
            });
        } else {
            if (room.idAccom) {
                dispatch(removeQuotationItem(room.idAccom));
                if (buttons&&buttons.length) {
                    let buttonsArray=[...buttons]
                    buttonsArray[roomNo].select = false;
                    buttonsArray[roomNo].clicked = true;
                    setButtons(buttonsArray);
                }
            }
        }
    }

    const isOfferApplied = (mealPlan) => {
        
        return !!mealPlan?.OffersApplied.length;
    }

    const filterRoom = () => {
        let avaiableRooms = []
        let unavaiableRooms = []
        createRooms[roomNo]?.RoomStay?.map((room, roomInd) => {
            if (room.MaxPax >= addingTotalPax(currentRoomTotalPax, false) && room.Status !== ROOM_STATUS.STOPSALES) {
                avaiableRooms.push(room)
                return;
            }
           
            unavaiableRooms.push(room)
        })
        createRooms[roomNo]?.UnavailableRooms?.map((room)=>{
            unavaiableRooms.push(room)
            
        })
        setAvaibleRooms(avaiableRooms)
        setUnavaiableRooms(unavaiableRooms)
    }

    const getMealPlan = (room, allAvailable) => {
        if (allAvailable) {
            return buildNonAvailableMealPlans(MEAL_PLANS);
        }
        let plans = []
        for (let i = 0; i < MEAL_PLANS.length; i++) {
            let plan = room.RoomRatePlan.find((meal) => meal.PlanName === MEAL_PLANS[i]);
            if (plan) {
                plans.push(plan);
            } else {
                plans.push(buildNonAvailableMealPlan(MEAL_PLANS[i]));
            }
        }
        return plans;
    }

    
    function getButtonText(room, createRooms, roomNo, textButtonSelected) {
        const isRoomNotSelected = !room.selectedStatus;
        const isSingleRoom = createRooms.length === 1;
        const isMultipleRooms = createRooms.length > 1;
        const isRoomSelected = room.selectedStatus === "selected";
        
        if (isRoomSelected) {
            return textButtonSelected;
        }
        if (isRoomNotSelected && isSingleRoom) {
            return "Add to quote";
        }
        if (isRoomNotSelected && isMultipleRooms) {
            return `Add for ${roomNo}${getOrdinal(roomNo)} room`;
        }
        // Valeur par défaut
        return "--";
    }

    const onClickOffer=(room,mealPlan)=>{
        
        setRoomOfferPopupData(prev=>({
            ...prev,
            roomImage:room.MainImage,
            roomName:room.RoomName,
            pax:currentRoomTotalPax,
            amenities,
            mealPlan,
            cancellationData:room.CancellationPolicy,
        }));
        openPopup(ROOM_OFFERS_POPUP);
    }

    const roomDetails = (roomDetails,isAvailable) => {
        return roomDetails.map((room, roomInd) => {
            let selectedMealPlan = getSelectedMealPlan(room);
           
            
            return (
                <div key={"room" + room.RoomID} className={'room-info-parent ' + (room.RoomID)}>
                    <div className="room-info-imgBlock" onClick={(e) => { viewPopup(e, room, "see-more", selectedMealPlan, room) }}>
                        {/* <ImageWithFallBack src={room.MainImage} alt={"room image"} fallBackImg={DefaultRoomImg} /> */}
                        <LazyImage className="img-room" src={room.MainImage} alt={"room image"} fallBackImg={DefaultRoomImg} hasFallback={true}/>
                        <AccomStockStatus status={room.Status}/>
                    </div>
                    <div className='room-details-container'>
                        <div onClick={(e) => { viewPopup(e, room, "see-more", selectedMealPlan, room) }} className='room-name'>{room.RoomName}{isOfferApplied(selectedMealPlan ?selectedMealPlan:null) && <i className='discount-icon'></i>}</div>
                        <Occupancy hotelpax={room.MaxPax} selectedPax={addingTotalPax(currentRoomTotalPax, false)} />
                        <div style={{ margin: "20px 0 38px" }}>
                            <b className='room-amenities'>Room amenities</b>
                            <Amenities data={getRoomAmenities(room)} multiple={false} multi={true} viewPopup={viewPopup} list={room} room={room} isRoom={true} isIconSvg={true}/>
                        </div>

                        <div className={room.selectedStatus === "selected" ? "disable" : ""}>
                            <p className='meal-title'>Meal plan</p>
                            <>
                                {
                                    getMealPlan(room, ["STOP SALES", "Unavailable"].includes(room.Status) || room.max_pax < addingTotalPax(currentRoomTotalPax, false)).map((mealPlan, mealIndex) => {
                                        return (
                                            <Fragment key={"meal" + mealIndex}>
                                                {mealPlan.status === MEAL_PLAN_STATUS_NON_AVAILABLE ?

                                                    <div className='meal-box non-available'>
                                                        <div className='tariff-container'>
                                                            <span className='tariff-label'>{mealPlan.PlanName}</span>
                                                            <span className='tariff-price'>
                                                                Not available for this room
                                                            </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={'meal-box ' + (mealPlan.IsBase ? "selected" : "")} onClick={(e) => selectMeal(e, roomNo, room.RoomID, mealPlan.PlanID)} >
                                                        <div className='tariff-container'>
                                                            <span className='tariff-label' style={mealPlan.OfferValue != "0.00" ? { color: "#F66666" } : {}}>{mealPlan.PlanName}     {mealPlan.OfferValue != "0.00" ? <i className='discount-icon2'></i> : <i className='include-icon'></i>}</span>
                                                            <span className='tariff-price' style={{color:"#666666"}} >
                                                                {mealPlan.AmountAfterTax !== null ? mealPlan.AmountAfterTax && formatPrice(mealPlan.AmountAfterTax) +" "+room.HotelCurrency : "Included"}
                                                            </span>
                                                        </div>
                                                        <div className='checkbox'>{mealPlan.IsBase && <img src={mealplan}/>}</div>
                                                    </div>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </>
                        </div>
                        
                      
                            {(room.MaxPax >= addingTotalPax(currentRoomTotalPax, false) && room.Status !== "STOP SALES" && room.Status !== "Unavailable") ?
                                <div className='view-block'>
                                    {calendarDate.length > 1 && <NightStay from={calendarDate[0]} to={calendarDate[1]} />}
                                    <div className='view-details' onClick={(e) => { viewPopup(e, room, "view-details", selectedMealPlan, room) }}>View details</div>
                                </div> :
                                <div className='view-block error'>
                                    {
                                        Errorroomtxt(
                                            room.Status === "STOP SALES" ? "one" :
                                                (room.max_pax < addingTotalPax(currentRoomTotalPax, false) ? "two" : "")
                                        )
                                    }
                                </div>
                            }
                            {room.Status !== "Unavailable" &&<div className='offers-container'>
                                {
                                    isOfferApplied(selectedMealPlan) &&
                                    <>
                                        <span className='offer-applied'>{getCurrency(selectedMealPlan.Currency)} {selectedMealPlan.OfferValue}</span>
                                        {/* {selectedMealPlan.OffersApplied.map((offer, ind) => (
                                            <span key={"offer" + ind}>{offer}</span>
                                        ))} */}
                                        <span className='offer-message' onClick={()=>onClickOffer(room,selectedMealPlan)}>{selectedMealPlan.OffersApplied.length>1?"Multiple offers":"-"+selectedMealPlan.RoomTariff[0].Offers[0].OfferValue+" % Offer"} applicable</span>
                                    </>
                                }
                            </div>}
                        


                       {room.Status !== "Unavailable" ? <div className='hotel_btnblk hotel-card-btn'>
                            {/* <h1>TEST</h1> */}
                            <DisplayPax price={getRoomPrice(room)} roomNo={roomNo} totalpax={currentRoomTotalPax} currency={selectedMealPlan?.Currency} cname={3} />
                            {
                                (room.MaxPax >= addingTotalPax(currentRoomTotalPax, false) && room.Status !== "STOP SALES") ?
                                    (
                                        <button
                                            className={getButtonClassName(room)}
                                            onClick={(e) => {
                                                handleAddRoom(e, room, roomNo);
                                            }}
                                            onMouseEnter={() => setTextButtonSelected("Remove ?")}
                                            onMouseLeave={() => setTextButtonSelected("Added")}
                                        >
                                            {getButtonText(room, createRooms, buttons[roomNo]?.no||1, textButtonSelected)}
                                        </button>
                                    )
                                    :
                                    (<button className='seemore_btn notseldiv'><span>--</span></button>)
                            }
                        </div>
                        :<div className='error'>{room.Remark}</div>
                    }
                    </div>
                </div>
            )
        })
    }

    const getClassNameRoomStatus=(item)=>{
        
        if (item.clicked && item.select && item.id === roomNo) {
            return "selectedOne";
        }
        if (item.clicked && item.select) {
            return "alreadysel";
        }
        if (item.clicked && !item.select && item.id === roomNo) {
            return "needtosel"
        }
        if (item.clicked && !item.select && item.id !== roomNo) {
            return "disable4";
        }
        return "disable3"

    }
    
    
    return (
        <>
            {
                roomStatus.loading ? (
                    <B2BSkeleton imgUrl={ROOMS_SKELETON} />
                ) : (
                    createRooms[roomNo] ? (
                        <div className='rooms-main-parent' ref={roomContainerRef}>
                            <div className='room-btnBlock'>
                                {
                                    buttons.length > 1 && buttons.map((item, ind) => (
                                        <div 
                                            key={ind} 
                                            className={'room-btn ' + getClassNameRoomStatus(item)} 
                                            onClick={(e) => { btnColorChange(e, "btnClk", item.id, item.no) }}
                                        >
                                            {item.no}{getOrdinal(item.no)} Room
                                        </div>
                                    ))
                                }
                            </div>
    
                            <div className={`hotel-rooms-available`}>
                                <p className="hotel-rooms-parent-unavailable_text"><span>Available rooms</span></p>
                            </div>
                            <div className='hotel-rooms-parent'>
                                {roomDetails(avaiableRooms,true)}
                            </div>
                            <div className={unavaiableRooms.length > 0 ? `hotel-rooms-parent-unavailable` : 'hotel-rooms-parent'}>
                                <p className="hotel-rooms-parent-unavailable_text"><span>Unavailable rooms</span></p>
                            </div>
                            <div className='hotel-rooms-parent unavailable-rooms'>
                                {roomDetails(unavaiableRooms,false)}
                            </div>
                            <>
                                {popupOpen.popup && (
                                    <div className='deletepopup_container'>
                                        <RoomPopup list={popupOpen} roomNo={roomNo} setPopupOpen={setPopupOpen} />
                                    </div>
                                )}
                                {popupOpen.seemore && (
                                    <div 
                                        id="amenities-popup" 
                                        className='deletepopup_container' 
                                        onClick={(e) => { closePopup(e) }}
                                    >
                                        <AmenitiesPopup 
                                            list={popupOpen} 
                                            roomNo={roomNo} 
                                            setPopupOpen={setPopupOpen} 
                                            totalpax={currentRoomTotalPax} 
                                        />
                                    </div>
                                )}
                                <CancellationPolicyPopup 
                                    show={popupCancellation.open} 
                                    onValidate={popupCancellation.onValidate} 
                                    cancellationData={popupCancellation.cancellationData} 
                                    setShow={showCancellationPolicyPopup} 
                                />
                            </>
                        </div>
                    ) : (
                        <div>{roomStatus.error}</div>
                    )
                )
            }
            
            {isOpen&&getPopupContent(roomOfferPopupData)}
        </>
    );
    
}

export default HotelRooms;