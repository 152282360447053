import React, { useEffect, useRef } from 'react';
import Multicalander from '../Excursion/Multicalander.js';
import LocationPoint from './LocationPoint.js';
import {MORE_OPTIONS_MODE, ROUTE_PATHS, STARRATING} from "../../constants.js"
import BookingPerson from '../Transfer/BookingPerson.js';
import StarRatingAccom from './StarRatingAccom.js';
import useHotelList from './hooks/useHotelList.js';
import useHotelDetails from './hooks/useHotelDetails.js';
import { accomDates, selHotel,resetIsnewHotel } from '../../store/accomodationReducer.js';
import { useSelector,useDispatch } from 'react-redux';
import { getAccomInputObject, getNextDaysString } from '../../utility/globalFunc.js';

function AccomInputs({alignbtn,styles,scroll,remRating,searchOnPaxDone=false}) {

    const isFirstRender = useRef(true);  

    const {offset,starRating,calendarDate:inputsDate,backendDate,selectedHotel,roomPax,selRooms,budgetRange,isOffer,criteria }= useSelector((state) => state.accomodationreducer)
    const {actionMode}=useSelector(state=>state.globalreducer);
    const {fetchHotelList,loading,fetchHotelTagList} = useHotelList();
    const {error,loading:detailsLoading,fetchHotelData } = useHotelDetails();
    let {style_input1,style_input2,pageNo}=styles

    const dispatch = useDispatch();
   

   

    const handleValues =(label,data)=>{
        const selectedHotel={
            loc_name: data.Hotel || data.loc_name, 
            loc_det: data.Region || data.loc_det, 
            loc_id: data.HotelID || data.loc_id
        }
        dispatch(selHotel(selectedHotel))
    }

    const getMarginButton=()=>{
        const defaultGap=32;
        let gridGap=parseFloat((style_input2.gap || "0"));
        return `${defaultGap-gridGap}px`;
    }

    const getMarginPaxDropdown=()=>{
        const defaultGap=30;
        let gridGap=parseFloat((style_input2.gap || "0"));
        return `${defaultGap-gridGap}px`;
    }
    
    const searchData =(e)=>{
        e?.preventDefault();
        let budgetRange = {from:0,to:0}

        const ratings = starRating.filter(item => item.checked).map(item => item.rating);
        const clientsInfo = roomPax.map((item) => ({
                client_type: item.clientType[0].toUpperCase(),
                adult_count: item.adult,
                children:item.paxAge.flatMap(item =>
                    Object.entries(item)
                    .filter(([key, value]) => key !== 'id' && !isNaN(value))
                    .map(([key, value]) => Number(value))
                )
          }));
        const actionAND = criteria.map(item => item.action === "AND" ? item.id_tag : null).filter(id_tag => id_tag !== null);
        const actionOR = criteria.map(item => item.action === "OR" ? item.id_tag : null).filter(id_tag => id_tag !== null);  
        const defaultDates=getNextDaysString();
        
        if (!inputsDate.length) {
            dispatch(accomDates(defaultDates));
        }
        
        let sendObject =getAccomInputObject({dates:backendDate, clientsInfo, star_rating:ratings, budgetRange,offset, OR:actionOR,AND:actionAND,isOffer});
        
        let type = typeof selectedHotel.loc_id;
        let isValid = Object.values(sendObject).includes("");

        if(!isValid && type === "string" ){
            let redirect=ROUTE_PATHS.ACCOMMODATION_SELECT
            let module = 0
            let page=1
            // HOTEL LIST            
            fetchHotelTagList(sendObject,redirect,module,page,true); 
            //fetchFilterList() 
        }
        else if(!isValid && type === "number"){
            // HOTEL LIST DETAILS
            delete sendObject.filters;
            sendObject.hotel_id = selectedHotel.loc_id;
            selRooms.length && dispatch(resetIsnewHotel(true))
            fetchHotelData(sendObject,ROUTE_PATHS.ACCOMMODATION); 
        }
        else{
            alert("please fill the Input fields")
        }  
    }

    const labelPax = ["All hotels","Find a hotel"]

    useEffect(()=>{    
        if (isFirstRender.current) {  
            isFirstRender.current = false;  
            return;  
        }  
        if (searchOnPaxDone&&inputsDate.length==2) {
            searchData();
        }
    },[roomPax,inputsDate])

    const isPaxSelectorDisable=()=>{
        return actionMode===MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_EXISTING_PAX;
    }

    return (
        <div style={{position:"relative"}}>
            <div className={"accomInput_parent "+(pageNo > 1 ? "remove-flex":"")} >
                <div style={style_input1} className={alignbtn ? "" : "accom-select-date"}>
                    <Multicalander label="Arrival" label2={inputsDate.length === 0 ? "dd/mm/yyyy" : inputsDate[0]} rangeDate={inputsDate} month={2} page={1} />
                    <Multicalander label="Departure" label2={inputsDate.length === 0 || inputsDate.length === 1 ? "dd/mm/yyyy" : inputsDate[1]} rangeDate={inputsDate} month={2} page={1} /> 
                </div>
            
                <div style={style_input2}>
                    <div style={pageNo > 1 ? {margin:"0 0 0 0px"}:{margin:"0 0 0 0"}}>
                        <LocationPoint
                            label={labelPax}
                            inpText="Search for a hotel"
                            points="pickup"
                            passArray1={selectedHotel.loc_name}
                            passArray2={selectedHotel.loc_det}
                            cname="accomodation"
                            allHotel={true}
                            handleValues={handleValues}
                            searchHotelClassName='selLabelLonger'
                        />


                    </div>
                    { !remRating && <div><StarRatingAccom label1="Star Rating filter" label2="Select star rating" data={starRating}/></div>}
                    <div style={alignbtn ? {marginLeft:getMarginPaxDropdown()} : {}}>
                        <BookingPerson scroll={scroll} cname={1} paxInfo={roomPax} isDisable={isPaxSelectorDisable()}/>
                    </div>
                    {!alignbtn && <div style={{marginLeft:getMarginButton(),float:"right",width:"100%",height:"auto"}}> <button className='ex_search' onClick={(e)=>{searchData(e)}}><span>Search</span></button></div>}
                </div>
            </div>
            {alignbtn &&<div className='btnblock'><button className='ex_search' onClick={(e)=>{searchData(e)}}>Search</button></div>}
        </div>
    );
}

export default AccomInputs;