import React, { useEffect, useState } from 'react'
import useHotelDetails from '../hooks/useHotelDetails'
import DEFAULT_REGION_IMG from "../../../Assets/images/default-img/region.jpg"
import DEFAULT_FLIC_EN_FLAC_IMG from "../../../Assets/images/dummy_images/R-FF.jpg"
import DEFAULT_GRAND_GAUBE_IMG from "../../../Assets/images/dummy_images/R-GG.jpg"
import DEFAULT_LE_MORNE_IMG from "../../../Assets/images/dummy_images/R-LM.jpg"
import { ImageWithFallBack, NearBy_Region } from '../../MiniComponents/Atom';
import B2BSkeleton from './B2BSkeleton';
import REGION_SKELETON from "../../../Assets/images/skeleton/region-skeleton.png";

function HotelRegion({hotelRegion,hotelId,regionData,setRegionData}) {
    const { fetchHotelRegionDetails }=useHotelDetails();
    useEffect(()=>{
        fetchHotelRegionDetails(hotelId,setRegionData);
    },[hotelId]);

    let region_amenities= [
        {label:"Average Temperature in :",imgName:"thermometer.png",degree:"24° - 27 °"},
        {label: "Near Sunset Boulevard",imgName:"location.png",degree:""},
        { label:"Near Grand-Baie Bazaar",imgName:"location.png",degree:""},
        {label: "Near Jardin Pamplemousse",imgName:"location.png",degree:""},
    ]

    const region_amenities_styles={color:"#434343",fontSize:"13px",fontWeight:"400",height:"23px"}
    const getImgDefault=()=>{
        if (hotelId===171) {
            return DEFAULT_FLIC_EN_FLAC_IMG;
        }
        if (hotelId===4852) {
            return DEFAULT_GRAND_GAUBE_IMG;
        }
        if (hotelId===429) {
            return DEFAULT_LE_MORNE_IMG;
        }
        return "";
    }
  return (
    <div>
     {regionData?(
        <>
            <div className='hotel_name' style={{marginTop:"20px"}}>{hotelRegion}</div>
            <div>
                <NearBy_Region nearRegion={regionData?.nearby_region}></NearBy_Region> 
            </div>
            <div className='region-parent'>
                <div className='description-region-container'>
                    <div className='title-description'>{regionData?.description?.title_description}</div>
                    <div className='description'>{regionData?.description?.text_description}</div>
                    <div className='title-activities'>{regionData?.activities?.title_description}</div>
                    <div className='description-activities'>{regionData?.activities?.text_description}</div>
                </div>
                <div className='img-region-container'>
                    <ImageWithFallBack src={regionData.image||getImgDefault()} alt="region image" fallBackImg={DEFAULT_REGION_IMG}/>
                </div>
                
           </div>
        </>)
     :<B2BSkeleton imgUrl={REGION_SKELETON}/>}
   </div>
  )
}

export default HotelRegion
