import { useState } from 'react';
import { axiosRequestParams,axiosRequestBody} from '../services/apiClient.js';
import {BOOKING_ENDPOINT,CLIENT_DETAILS_ENDPOINT} from "../services/apiEndpoints.js"

import {userLogout} from "../store/authentication.js"
import {clearTransfer} from "../store/transferReducer.js"
import {clearexcursion} from "../store/excursionReducer.js"
import {clearPax,clearAll} from "../store/globalReducer.js"
import {clearAccom} from "../store/accomodationReducer.js"

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TIMEOUT_DURATION } from '../constants.js';


// Custom hook to fetch data from an API
const useToRefBooking = () => {
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);  
    const dispatch = useDispatch();
    const navigate = useNavigate();
 
    
    const refBookingService = async (payload) => {
        console.log("req payload:",payload);
        let bookingPayload  = {"to_ref_code" :payload.to_ref_code}
        setLoading(true);
        try {
            const axiosConfig = { timeout: TIMEOUT_DURATION }; 
            const saveClient = await axiosRequestBody("POST",CLIENT_DETAILS_ENDPOINT,payload,axiosConfig);
            setIsSuccess(true)
        } catch (error) {

                if(error.response && error.response.status === 401){
                    navigate(ROUTE_PATHS.HOME)
                }
                else if(error.response && error.response.status === 409){
                     alert(error.response.data.message)
                    setIsSuccess(false)
                   
                }
                // else if (error.response && ![409, 401].includes(error.response.data.status)) {
                //     alert(error.response.data.message)
                // } 
               
                else {
                    alert(error.response.data.message)
                    setIsSuccess(false)
                    // navigate("/internal-error",{state:{message:error.response.data.message,status:error.response.status}})
                }
               
                
        } finally {
            setLoading(false);
            
        }
    };
   
    return { error,loading,isSuccess,refBookingService };
};


export default useToRefBooking;