import React,{useState,useEffect,useRef, useContext} from 'react';
import {DROPDOWN_DATA,FEEDBACK,ALSOLIKE,scrollRemove,splitDescription,amenitiesDetails,CANCELLATIONDATA,HOTEL_RATING_ACCOM, HOTEL_DETAILS_0PTIONS, ROUTE_PATHS} from "../../constants"
import Dropdowns from '../Transfer/Dropdowns.js';
import AccomInputs from './AccomInputs.js';
import { Back, BackButtonHandler, GlobalAmenities, useBackButtonHandler } from '../MiniComponents/Atom.js';
import Rating from '@mui/material/Rating';
import HotelRating from "../MiniComponents/HotelRating.js"
import Amenities from '../MiniComponents/Amenities.js'
import HotelsOptions from './HotelsOptions.js'
import Showhotels from './Showhotels.js';
import AccomMaps from "./AccomMaps.js"
import Feedback from '../Excursion/Feedback.js';
import NavbarBlock from '../Transfer/NavbarBlock.js';
import { useLocation, useNavigate } from 'react-router-dom';
import ImageCarosel from '../Excursion/ImageCarosel.js';
import { useSelector,useDispatch } from 'react-redux';
import CancelationPolicy from '../Excursion/CancelationPolicy.js';
import StarIcon from '@mui/icons-material/Star';
import { lowerAndRemoveWhiteSpace, properCase } from '../../utility/globalFunc.js';
import {setShowDropdown} from "../../store/globalReducer.js";
import B2BSkeleton from './minicomponents/B2BSkeleton.js';
import HOTEL_CARD_SKELETON from "../../Assets/images/skeleton/suggestion-skeleton.png";
import { LayoutContext } from '../layout/MainLayout.js';
import usePopupData from '../../hooks/usePopupData.js';
import { removePendingRooms, resetPendingRooms } from '../../store/accomodationReducer.js';


function AccomSelected(props) {
    const [openMap ,setOpenMap] = useState(false);
    const {accomList,paxData,selRooms,calendarDate,accomLoading,backenddate,roomPax,suggestionLoading,hotelSuggestions,pendingRooms} = useSelector((state) => state.accomodationreducer)
    const {dropDownPositionY,shwowDropDown} = useSelector((state) => state.globalreducer)
    const targetRef = useRef(null);
    const optionsRef=useRef(null);
    const dispatch = useDispatch();
    const serviceSelectorRef=useRef(null);
    const {showLeftServiceSelector,setShowLeftServiceSelector}=useContext(LayoutContext);
    const { isOpen, openPopup, getPopupContent,closePopup } = usePopupData();

    
    const list_structure1 = {
        style_input1:{display:"grid",gridTemplateColumns:"170px 170px",gap:"13px"},
        style_input2:{display:"grid",gridTemplateColumns:"362px 241px 156px",gap:"24px",marginLeft:"24px"},
        pageNo:0
    }
    const locationData = useLocation();
    let {HotelDescriptions:desc,HotelImage:main_image,OtherImages:other_images,hotel_rating,feedback,HotelAmenities:amenities,cancellation_policy,hotel_rooms,HotelName:name,HotelLocation:loc_det,HotelRating:rating,HotelID:hotel_id,HotelTags:type_tags,Position:position}=saveInLocalHotel();
    // let {backPage} = locationData.state;
    
    let HOTELROOMS_PROPS ={other_images,desc,amenities}
    const typesOfhotel = type_tags?.map((typhotel,ind)=>{
        
        // let imageUrl;
        let fallbackImageUrl = require(`../../Assets/images/accomodation/icons/honeymoon.png`);

        // try {
        //     imageUrl = require(`../../Assets/images/accomodation/icons/${lowerAndRemoveWhiteSpace(typhotel)}.png`);
        // } catch (error) {
        //     imageUrl = fallbackImageUrl;
        // }

     
        const Imgstyle={
            width:"fit-content",
            backgroundImage:`url(${typhotel.HotelTagIcon ?typhotel.HotelTagIcon:fallbackImageUrl})`,
            color:"#4A4A4A",
            backgroundRepeat: "no-repeat",
            paddingLeft: "31px", 
            backgroundPosition: '0 center',
            backgroundSize:'20px'
        }


        return(
            <div key={ind}  style={Imgstyle} className='hotel_category'>
                {typhotel.HotelTag} {type_tags.length -1 === ind? "" :<span className='separator_bar'>|</span>}
            </div>
    )})

    const checkAlignment = () => {
        if(targetRef.current ){
            const targetRefPoY = targetRef.current.getBoundingClientRect() 
            if(targetRefPoY.top <= dropDownPositionY){
                dispatch(setShowDropdown(true))
            }
            if(targetRefPoY.top >= dropDownPositionY ){             
                dispatch(setShowDropdown(false))

            }
        }
    }
    
    useEffect(() => {
        if (calendarDate.length < 1) {navigate(ROUTE_PATHS.ACCOMMODATION)}
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        return () =>{
            dispatch(removePendingRooms())
        }
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        scrollRemove(openMap);
       
    }, [openMap])
    
    useEffect(() => {

        window.addEventListener('scroll', checkAlignment);
        // Cleanup
        return () => {
          window.removeEventListener('scroll', checkAlignment);
        };
      }, []);

    const onClickSeeMoreAmenities=()=>{
        if (optionsRef.current) {
            const navRef=optionsRef.current.navRef?.current;
            let scroll=navRef?.scrollIntoView;
            let clickOption=navRef?.clickOption;
            scroll&&scroll();
            clickOption&&setTimeout(() => {
                clickOption(HOTEL_DETAILS_0PTIONS.RANK);
            }, 300);
        }
    }

    useEffect(() => {
        let observer = null;
        if (serviceSelectorRef.current&&!accomLoading) {

            const options = {
                root: null,
                threshold: 1,
            }
            observer = new IntersectionObserver((entries) => {
                let entry=entries[0];
                setShowLeftServiceSelector(!entry.isIntersecting&&!accomLoading)
            }, options)
            observer.observe(serviceSelectorRef.current);
        }
        return () => {
            if (observer!=null) {
                observer.disconnect();
            }
            setShowLeftServiceSelector(false);
        }
    }, [serviceSelectorRef,accomLoading])

    function saveInLocalHotel() {
        
        if(locationData?.state){    
            localStorage.setItem("HotelSelected",JSON.stringify(locationData?.state.result))
            return locationData?.state.result
        }else{
            
            return JSON.parse(localStorage.getItem("HotelSelected"))
        }
    }


    return (
        <>
            {
                accomLoading ? 
                <div className='accomSkeletonLoad2'></div> : 
                <>
                    {/* <NavbarBlock/> */}
                        <div className='selectAccom_parent'>
                        <div className='SelectedAccom_left'>
                        <Back label={name} name={ROUTE_PATHS.ACCOMMODATION_SELECT} marginBottom="40px"/>
                        <Dropdowns dropData={DROPDOWN_DATA} imgcount={1} name="Accommodation"/>
                        <div style={{marginTop:"82px",float:"left"}} ref={serviceSelectorRef}>
                            <AccomInputs alignbtn={false} styles={list_structure1} remRating={true} searchOnPaxDone={true}/>
                        </div>
    
                        <div className='mainHotel_block'>
                            <div className='hotel_nameblock' ref={targetRef}>
                                <div className='hotel_name' >{name}</div> 
                                <div className='hotel_types'>{typesOfhotel}</div> 
                            </div>
                            <div className='rating_location' style={{marginBottom:"71.2px"}}>{properCase(loc_det)} <span className='separator_bar'>|</span> <Rating emptyIcon={<StarIcon fontSize="inherit" />} name="read-only" value={rating} readOnly size="small" /></div>
                            <ImageCarosel data ={[...other_images]}/>  
                            <div className='title_txt'>{desc[0].HotelDescTitle}</div>  
                            <div className='description-container'>{desc[0].HotelDesc}</div>  
                            <div className='splitBox1'>
                                <GlobalAmenities amenities={amenities} isMultiple={true} onSeeMoreClick={onClickSeeMoreAmenities}/>
                                <div className='container-map-img-filter'>
                                    <div className='map-img-filter hotel-details'><span onClick={(e) => { setOpenMap(true) }}>View on map</span></div>
                                </div>
                            </div> 
                            {/* <div className='splitBox2'>
                                <div className='review_block'>
                                    <HotelRating data={HOTEL_RATING_ACCOM}/> 
                                </div>
                                    <Feedback data={FEEDBACK} label=""/> 
                            </div> */}
                            {/* <div style={{marginTop:"75px",width:"100%"}}> */}
                                {/* <CancelationPolicy cancelData={hotel_rooms['1'][0]?.cancellation_policy||CANCELLATIONDATA}/>  */}
                            {/* </div>  */}
    
                            <div style={{margin:"90px 0px",float:"left",width:"100%"}}>
                                <HotelsOptions hotelRoomProps ={HOTELROOMS_PROPS} hotelName={name} date={calendarDate} roomData={hotel_rooms} hotelId={hotel_id} hotelDescriptionImg={other_images[0]} hotelDesc={desc} hotelRegion={loc_det} ref={optionsRef}/>
                                <div className='also-block'>
                                    <p className='suggestions-title'>
                                        <span>You may also like</span>
                                    </p>
                                    {suggestionLoading?
                                    <B2BSkeleton imgUrl={HOTEL_CARD_SKELETON}/>
                                    :    
                                    <Showhotels 
                                        hotel={hotelSuggestions} 
                                        foldername="accomodation" 
                                        // styles={list_structure1} 
                                        url="/accom/room/details" 
                                        redirect={ROUTE_PATHS.SELECTED_ACCOMMODATION}
                                        paxInfo={roomPax}
                                        btnBlock={true}
                                        amenitiesMarginTop={17}
                                        amenitiesMarginBottom={25}
                                        listType={true}
                                    />
                                    }
                                </div>
                            </div> 

                            
                        </div>
                            {/* {selRooms.length ?<div className='fixedQuotation'><Quotation cname="Accomodation" details={selRooms}/></div>:null}  */}
                            <BackButtonHandler service={pendingRooms}  />
                    </div> 
                    </div> 
                    {/* need to change points={ALSOLIKE} it is static data */}
                        {openMap &&  <AccomMaps setOpenMap={setOpenMap}  points={[locationData.state.result]} paxData={paxData} hotelId={hotel_id} btn={false}/>} 
                    {/* <Footer/> */}
                </>
            }
        </>
    );
}

export default AccomSelected;