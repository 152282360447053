import React, { useEffect, useRef } from 'react';
import usePopupData from '../../hooks/usePopupData';
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import useOutsideClick from '../../hooks/useOutsideClick.js';
import { useState } from 'react';
import {  MORE_OPTIONS_MODE, ROOMPAXDATA, ROUTE_PATHS } from '../../constants.js';
import BookingNewService from '../Booking/BookingNewService.js';
import { accomodationReduxData, getPaxCategoryByAge, transferReduxData } from '../../utility/globalFunc.js';
import { setActionMode } from '../../store/globalReducer.js';
import { addRefcode, transferUpdatePax, updateTransferDetails, updateTransferlistDetails } from '../../store/transferReducer.js';
import { accomPax, setSelRooms } from '../../store/accomodationReducer.js';

const buildPaxFromSelectedAccom=(pax,selected)=>{
    const roomPax={...ROOMPAXDATA};
    roomPax.adult=0;
    selected.forEach(ind => {
        const label=getPaxCategoryByAge(pax[ind].age).toLowerCase();
        const nbPax=roomPax[label]+1;
        roomPax[label]=nbPax;
    });
    return roomPax;
}

const buildPaxFromSelectedTransfer=(pax,selected)=>{
    const transferPax={AdultCount:0,InfantCount:0,ChildCount:0,TeenCount:0};
    selected.forEach(ind => {
        const label=getPaxCategoryByAge(pax[ind].age)+"Count";
        const nbPax=transferPax[label]+1;
        transferPax[label]=nbPax;
    });
    return transferPax;
}

function AddServiceBox({ closePopup, openNew, toPath, booking = {} }) {

    const { isOpen, getPopupContent, openPopup } = usePopupData();
    const [newPax, setNewPax] = useState(false);
    const dispatch = useDispatch();
    const btnref = useRef(null);
    const [step, setStep] = useState(1)
    const finalStep = useRef(() => { });
    const { Clients,dateFrom,dateTo } = booking;
    const {selectedPaxUpdateBooking} = useSelector(state => state.globalreducer)
    const {transferUpdates} = useSelector(state => state.transfer_Global)
    const [isNotSelectedPax,SetIsNotSelectedPax] = useState(false)
    const navigate=useNavigate();

    let { reservationAccom = [], reservationTransfer = [], ToRef = "" } = booking

    const actions={
        "Accommodation":{
            label:"Accomodation",
            handleExistingPax:(selected)=>{
                const finalpax=buildPaxFromSelectedAccom(Clients,selected);
                dispatch(accomPax([finalpax]));
            },
            handleNewPax:(pax)=>{},
            onNext:()=>{
                navigate(ROUTE_PATHS.ACCOMMODATION_SELECT);
            },
            paxInfo:[{ "adult": 1, "teen": 0, "child": 0, "infant": 0, "room_id": 1, "paxAge": [], "clientType": ["Standard", "STD"] }],
            cname:1
        },
        "Transfer":{
            label:"Transfer",
            handleExistingPax:(selected)=>{
                const finalpax=buildPaxFromSelectedTransfer(Clients,selected);
                dispatch(transferUpdatePax(finalpax))
            },
            handleNewPax:(pax)=>{
            },
            onNext:()=>{
                navigate(ROUTE_PATHS.SELECT_VEHICLE)
            },
            paxInfo:{"IdTransfer": 1,"TransferDate": "","LocationFromName": "","LocationFromDetails": "","LocationToName": "","LocationToDetails": "","TotalPrice": 0,"Car_selected": false,"TransferTime": "00:00:00","Remarks": "Transfer remarks from B2B UI","Flight": "Air Mauritius","Currency": null,"Pic_url": null,"TransferType": null,"AdultCount": 1,"TeenCount": 0,"ChildCount": 0,"InfantCount": 0,"IsSearched": false},
            cname:2
        }
    }

    const [service,setService]=useState(actions.Accommodation);

    const notSaveData = (e) => {
        e.preventDefault();
        closePopup();
    }

    useOutsideClick(btnref, () => {
        closePopup();
    });

    useEffect(()=>{
       if(selectedPaxUpdateBooking.length > 0) SetIsNotSelectedPax(false)
        
    },[selectedPaxUpdateBooking,isNotSelectedPax])

    const setStepFinal = (callback) => {
        finalStep.current = callback;
    }

    const onClickFinal = () => {
        const mode = newPax ? MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_NEW_PAX : MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_EXISTING_PAX;
        if(selectedPaxUpdateBooking.length == 0 && mode===MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_EXISTING_PAX){
            SetIsNotSelectedPax(true)
            return;
        }
        dispatch(setActionMode(mode))
        dispatch(addRefcode(ToRef))

        
        let accomRedux = accomodationReduxData(reservationAccom)
        let transferRedux = transferReduxData(reservationTransfer)
        const transfers=[...transferRedux,transferUpdates[0]];

        console.log({transfers});
        

        dispatch(setSelRooms(accomRedux))
        dispatch(updateTransferDetails(service.label==="Transfer"?transfers:transferRedux))
        dispatch(updateTransferlistDetails(transferRedux))
        finalStep.current?.();
    }

    const askingForService = () => {
        return (
            <div className='dialogue-asking-service' ref={btnref}>
                <div className='dialogue-asking-service-text'>
                    <p>Are you sure you want to add this service to your booking?</p>
                    <p>This action is irreversible, and you won't be able to undo it. If you are certain you <br /> want to add this service, please confirm below. New prices and cancellation policies will apply to the added service.</p>
                </div>
                <div className='dialogue-asking-service-container-button' >
                    <button onClick={(e) => notSaveData(e)} >Cancel</button>
                    <button className={""} onClick={(e) => (setStep(2))}>Next</button>
                </div>
            </div>
        )
    }
    const ChoosingService = () => {
        const nexStep = (isNewPax) => {
            setNewPax(isNewPax);
            setStep(4);
        }
        return (
            <div className='dialogue-choose-service' ref={btnref}>
                <div className='dialogue-choose-service-text'>
                    <p >Please select how you want to add items to your booking.</p>
                </div>
                <div className='dialogue-choose-service-container' >
                    <div className='dialogue-choose-service-container-child' onClick={() => { nexStep(true) }}>
                        <p>Add new pax to your <br /> reservation while adding <br /> a new service</p>
                    </div>
                    <div className='dialogue-choose-service-container-child service' onClick={() => { nexStep(false) }}>
                        <p>Add new service  to your <br /> reservation for your <br /> existing pax</p>
                    </div>
                </div>
            </div>
        )
    }

    const PaxSelectionNewService = () => {
        return (
            <div className='dialogue-pax-selections' ref={btnref}>
                <div className='dialogue-pax-selections-text'>
                    <p>Pax selections</p>
                    <p>Please select the pax who will be concerned by the new service</p>
                </div>
                <BookingNewService newPax={newPax} pax={Clients} setFinalStep={setStepFinal} notSelected={isNotSelectedPax} service={service} setService={setService} actions={actions}/>
                <div className='dialogue-pax-selections-container-button' >
                    <button onClick={(e) => notSaveData(e)} >Cancel</button>
                    <button className={""} onClick={(e) => {
                        onClickFinal()
                    }}>Next</button>
                </div>
            </div>
        )
    }

    return (
        <>
            {step == 1 && askingForService()}
            {step == 2 && ChoosingService()}
            {step == 4 && PaxSelectionNewService()}
            {isOpen ? getPopupContent() : <></>}
        </>

    );
}

export default AddServiceBox;

