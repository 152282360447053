import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import arrow from "../../Assets/images/quotation_arrow.png";
import { B2BPagination, PaxDetailsTags } from "../MiniComponents/Atom";
import { MoreOptionsThreeDotted } from "./MoreOptions";
import useQuoteList from "./hooks/useQuoteList";
import { dateToConventionalFormat, scrollToTop,getBeforeUnderscore,properCase } from "../../utility/globalFunc";
import TABLE_SKELETON from '../../Assets/images/skeleton/table-booking-skeleton.png';
import B2BSkeleton from "../Accomodation/minicomponents/B2BSkeleton";
import { RESERVATION_TYPE, RESERVATION_TYPE2, ROUTE_PATHS, SORT_DATES, STATUS_BOOKED,STATUS_PENDING,STATUS_REQUEST } from '../../constants';
import {setQuoteBookList} from "../../store/globalReducer"
import usePopupData, { BOOKING_CANCELLATION_SUCCESS } from "../../hooks/usePopupData";

function TableBooked({ data,highLightLastBooking=false,setBookFilter,bookfilter, nbPages,loadingGrid }) {
  
  const [currentPage, setCurrentPage] = useState(1);

  const {fetchBookingDetails,loading} = useQuoteList();
  const {getPopupContent,openPopup,isOpen, closePopup}=usePopupData()
  const [dataPopup,setDataPopup]=useState({});
  const location=useLocation();
 
  const changeNoPage=(page)=>{
    setBookFilter(prev=>({
        ...prev,
        page_num:page
    }))
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    changeNoPage(page)
    scrollToTop(0);
  };

  const openViewDetails = (e, data) => {
    e.preventDefault();
    fetchBookingDetails(data.file_name)
  };

  useEffect(()=>{
    setCurrentPage(bookfilter.page_num || 1)
  },[bookfilter])

  const isLastBookingHighlighted=(index)=>{
    return highLightLastBooking && currentPage === 1 && index === 0;
  }


  useEffect(()=>{
    const {idBooking,bookingName}=location.state || {};
    
    if (!!idBooking && !!bookingName) {
      setDataPopup({idBooking,bookingName})
      openPopup(BOOKING_CANCELLATION_SUCCESS);
      setTimeout(()=>{
        closePopup();
      },3000)
    }
  },[])


  return (
    <>
      <table className="table-booking-parent">
        <thead className="table-booking-header">
            <tr className="table-booking-row">
                <th>File nb</th>
                <th>Client name</th>
                <th>File Status</th>
                <th className="date-cell">From</th>
                <th> <img src={arrow} alt="arrow" /></th>
                <th className="date-cell">To</th>
                <th>Nb of pax</th>
                <th>Created by</th>
                <th></th>
            </tr>
        </thead>
        {!loading &&  data && data?.length > 0 && (
            <tbody className="table-booking-body">
                {data.map((list, index) => (
                    <React.Fragment key={`${list.booking_id}_${index}`}>
                        <tr
                            key={list.booking_id}
                            className={`table-booking-row row-body ${list.status === "ON REQUEST" ? "yellow" : ""} ${ isLastBookingHighlighted(index) ?'last': ""}`}
                            onClick={(e) => openViewDetails(e, list)}
                        >
                            <td>{list.booking_id}</td>
                            <td className="ref-cell">{getBeforeUnderscore(list.file_name)}</td>
                            <td className="ref-cell">
                              <div className={`statuscell ${
                                  list.status === STATUS_BOOKED ? "status_booked" :
                                  list.status === STATUS_PENDING ? "status_pending" : 
                                  "status_payment"}`}>
                                  {properCase(list.status === STATUS_PENDING ? STATUS_REQUEST:list.status)}
                                </div>
                            </td>
                            
                            <td className="date-cell">{dateToConventionalFormat(list.date[0])}</td>
                            <td className="arrow-cell">
                                <img src={arrow} alt="arrow" />
                            </td>
                            <td className="date-cell">{dateToConventionalFormat(list.date[1])}</td>
                            <td className="pax-details">
                                <PaxDetailsTags paxDetails={list.pax_details} customContainer={true} />
                            </td>
                            <td>
                                {list.created_by},<br />
                                <b>{list.agency_name}</b><br />
                                <label style={{ fontWeight: "300" }}>{list.created_date}</label>
                            </td>
                            <td className="booking-status">
                              <div className="quote-btn-container">
                                  <div className="quote-btn status_req2">Proforma</div>
                                  <div className="quote-btn status_req3">Voucher</div>
                              <div onClick={(e) => e.stopPropagation()}>
                                        <MoreOptionsThreeDotted quotation={list}/>
                                {isLastBookingHighlighted(index) && <div className="booking-new-indicator">--</div>}
                              </div>
                              </div>
                            </td>
                        </tr>
                        {index!==data.length-1&&<tr key={"hr-"+index}>
                          <td colSpan={100}><hr/></td>
                        </tr>}
                    </React.Fragment>
                ))}
            </tbody>
        )}
      </table>

      {loading || loadingGrid ? (
          <B2BSkeleton imgUrl={TABLE_SKELETON} style={{ marginTop: "25px" }} />
      ) : (
          <div style={{ margin: "30px 0px", fontSize: "22px", width: "100%" }}>
              {!data?.length && "No data found....."}
          </div>
      )}

      {nbPages&&<div className="pagination">
          <B2BPagination
              currentPage={currentPage}
              onPageChange={handlePageChange}
              nbPages={nbPages}
          />
      </div>}

      {isOpen&&getPopupContent(dataPopup)}

    </>
  );
}

export default TableBooked;
