import React,{forwardRef, useEffect, useRef, useState} from 'react';
import { useLocation } from 'react-router-dom';
import usePopupData, { CANCEL_UPDATE_POPUP, WARNING_EXPORT_PDF_POPUP, SEND_MAIL } from '../../hooks/usePopupData.js'; 
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import {getTotalPrice, setReservationService} from "../../store/globalReducer.js"
import MoreOptions from '../MiniComponents/MoreOptions.js';
import useMoreOptionQuotation from '../../hooks/useMoreOptionQuotation.js';
import { MORE_OPTIONS_MODE, ROOMPAXDATA, ROUTE_PATHS, SERVICE_TYPE } from '../../constants.js';
import useAddItems from '../Quotations/hooks/useAddItems.js';
import Dropdowns from './Dropdowns.js';
import {DocketPrice} from "../MiniComponents/Atom.js"
import {DROPDOWN_DATA} from "../../constants"
import {buildReservationAccom, calculateMinMaxAndSumAccomAndTransfer, getCurrency, isServiceAccomodation, isServiceTransfer, separateurMillier } from '../../utility/globalFunc.js';
import useMoreOptionBooking from '../../hooks/useMoreOptionBooking.js';
import TimelineDocket from '../MiniComponents/Timeline.js';
import { accomPaxBookingDefault } from '../../store/accomodationReducer.js';
import { setIsClickedButtonDone, setIsPaxDisabled } from '../../store/transferReducer.js';


function getServiceDate(service) {
  if (isServiceAccomodation(service)) {
    return service.date ? service.date[0] : null;
  }
  if (isServiceTransfer(service)) {
    return service.TransferDate || service.date || null;
  }
  return null;
}

const Quotation=forwardRef(({isIntersectingFooter=false,additionalBottomDistance=0,showServiceSelector},ref)=>{
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const {totalPrice,actionMode,sortedList,shwowDropDown,quoteBookList,savedFileNumber} =useSelector((state) => state.globalreducer)  
  const {agency_id,first_name,last_name} =useSelector((state) => state.authpath.userData)  
  const {transferList=[],refCode,updatePax:paxres,totalpax} =useSelector((state) => state.transfer_Global)  
  const {selExcursion=[],excursionTotal} =useSelector((state) => state.excursionreducer)
  const { selRooms, roomPax, isNewHotel, numberOfRoom,pendingRooms,resetroomPaxBooking } = useSelector((state) => state.accomodationreducer)
  const { isOpen, openPopup, getPopupContent,closePopup } = usePopupData();
  const { clearDocket } = useMoreOptionQuotation()
  const [showRemove,setShowRemove]=useState(false);
  const {addItems,status,loading}=useAddItems();
  const [disableUpdate,setDisableUpdate]=useState(true);
  const [updatedData,setIsUpdatedData]=useState(null);
  const [currency, setcurrency] = useState();
  
  const [error,setError] = useState(null);
  const {handleUpdateBooking,loadingBooking}=useMoreOptionBooking(actionMode,sortedList.services,agency_id);


  let transfer_sum = 0;
  let excursion_sum = 0;
  let accomodation_sum = 0;
  
  let gngtoselect = 1;

  let quotationList = transferList.concat(selExcursion,selRooms)

  quotationList = quotationList.map((obj,key)=>{
    let { TransferDate:date , ...rest } = obj;
    return { date, docketid: key+1, ...rest }; 
  })

  const compareDates = (a, b) => {
    const aDate = getServiceDate(a)
    const bDate = getServiceDate(b);
    return aDate - bDate;
  }

  const sortedArray = quotationList.sort(compareDates); 

  useEffect(() => {
//     console.log('sortedList', sortedList);
// console.log("accomodation_sum",accomodation_sum);

    
    setcurrency(sortedArray.length ? sortedArray[0].currency||sortedArray[0].Currency : 0);
    accomodation_sum = parseFloat(accomodation_sum.toFixed(3));
    let totalprice = excursion_sum + transfer_sum +accomodation_sum
    

    setDisableUpdate(!existNewItems());
     dispatch(getTotalPrice(totalprice))

     if(status == 201 && updatedData!= null){
      openPopup('updated-quotation',updatedData)
    }
  }, [sortedArray,status,updatedData]);

  useEffect(()=>{

    if(isOpen && location.pathname == ROUTE_PATHS.ACCOMMODATION_SELECT ){
      closePopup();
    }
  },[location.pathname])
 
// Adding total transfer price
  transferList.forEach((element) => {
    transfer_sum += element.TotalPrice;
    if(element.Car_selected){
      gngtoselect = gngtoselect + 1;
    }
  });

  selExcursion.length && selExcursion.forEach(element => excursion_sum += element.price)
  selRooms.length && selRooms.forEach(element => accomodation_sum += element.price)
  
  const viewCotation=(e)=>{
    e.preventDefault();
    let response = showError();
    if(!response){
      setTimeout(()=>{setError(null)},2000)
      return;
    } 
    let {hostname,protocol,port }=window.location;
    port ?  port = ":"+port : port = ""
    let serverUrl = protocol+`//`+hostname+port+ROUTE_PATHS.VIEW_DETAILS;
    window.open(serverUrl, '_blank');
  }

  const openInputbox =(e)=>{
    e.preventDefault();   
    let response = showError();
    console.log({response});
    
    if(!response){
      setTimeout(()=>{setError(null)},2000)
      return;
    } 
    openPopup("info")
  }

 
  // open clients page
  const bookNow =(e)=>{
    e.preventDefault();
    let response = showError();
    if(!response){
      setTimeout(()=>{setError(null)},2000)
      return;
    } 

    let {services}=sortedList
    let {reservationTransfer,reservationAccom,reservationExcursion}=services

    dispatch(accomPaxBookingDefault(resetroomPaxBooking))
    dispatch(setIsPaxDisabled(false)) 
    dispatch(setIsClickedButtonDone(false)) 
    navigate(ROUTE_PATHS.USER_DETAILS,{state:{service:{reservationAccom,reservationTransfer,reservationExcursion},refCode:refCode}});
    
  }

  // New function---
      const setDefaultPax=(accomodations,transfers)=>{
  
        function buildRoomPaxData(inputData) {
          const outputData = { ...inputData }; // Clone the input object
          const paxAge = [];
          // Iterate over each pax type (teen, child, infant)
          ['teen', 'child', 'infant'].forEach((paxType) => {
            for (let i = 0; i < inputData[paxType]; i++) {
              paxAge.push({ [paxType]: 0, id: i+1 });
            }
          });
        
          outputData.paxAge = paxAge; // Add the paxAge array to the object
          return outputData;
        }
      
        const minMax = calculateMinMaxAndSumAccomAndTransfer(accomodations, transfers) 
        let paxData={
          ...ROOMPAXDATA,
          adult:minMax.minAdults||0,
          teen:minMax.minTeen||0,
          infant:minMax.minInfant||0,
          child:minMax.minChild||0,
        }
        paxData = buildRoomPaxData(paxData)
        let pax=[paxData];
        dispatch(accomPaxBookingDefault(pax));
      }




  // make a global update function with payload [1]
  const updateQuotation= async (e)=>{
    e?.preventDefault();
    await addItems(transferList,selExcursion,selRooms,refCode,first_name,last_name,roomPax,savedFileNumber);
    const data = {"selRooms":selRooms,"pax":roomPax,"refCode":refCode}
    setIsUpdatedData(data)
  }

  const DOCKET_OPTIONS = [
    {
      label:"Remove items",
      onClick:()=>{setShowRemove(prev=>!prev)}
    },
    {
      label:"Remove all items",
      onClick:()=>{clearDocket({
        routes:location.pathname
      })}
    },
    {
      label:"Send via mail",
      onClick:()=>{openPopup(SEND_MAIL,"",'unsaved quotation')}
    },
    {
      label:"Export in PDF",
      onClick:()=>{openPopup(WARNING_EXPORT_PDF_POPUP)}
    },
  ];

  useEffect(()=>{
    if (!sortedArray.length) {
      setShowRemove(false);
    }    
  },[sortedArray,shwowDropDown])



const existNewItems=()=>{return sortedArray.some(item=>item.isNew==true); }

const containerRefToScroll = useRef(null);

const isDocketVisible=()=>{
    let isCarSelected = transferList.some(data => data.Car_selected);
    // console.log({ls:sortedArray.length,lt:transferList.length,isCarSelected});
    
    if(sortedArray.length >=1 && transferList.length && isCarSelected){return true;}
    if(sortedArray.length >=1 && selRooms.length >=1){return true;}
    if(pendingRooms.requiredNbRooms>0){return true;}
    return false;
}

const checkValidServices = (serviceName) =>{    
  let returnvalue = false;
  if(serviceName === "accom"){
    
      if(pendingRooms.roomsFilled &&  pendingRooms.roomsFilled.length > 0){
        let counteurRoomValid = 0;
        for(let i = 0 ; i < pendingRooms.roomsFilled.length;i++){
            if(pendingRooms.roomsFilled[i] == false){
              return returnvalue;
            }else{
              counteurRoomValid++;
            }
        }
        if (counteurRoomValid == pendingRooms.roomsFilled.length){
          returnvalue = true;
        }
      }
      else{
        returnvalue = true;
      }
  }
  if(serviceName === "transfer"){
    if(transferList && transferList.length > 0){
      let counteurCarValid = 0;
        for(let i = 0 ; i < transferList.length;i++){
          if(transferList[i].Car_selected == false){
            return returnvalue;
          }else{
            counteurCarValid++;
          }
      }
      if(counteurCarValid == transferList.length){
        returnvalue = true;
      }
    }else{
      returnvalue = true;
    }
  }
  return returnvalue;
}

const checkServiceName = () =>{
  let serviceName = "";
  if(location.pathname == ROUTE_PATHS.SELECT_VEHICLE) serviceName = "transfer";
  else if(location.pathname == ROUTE_PATHS.SELECTED_ACCOMMODATION) serviceName = "accom";
  else if(location.pathname == ROUTE_PATHS.ACCOMMODATION_SELECT) serviceName = "accom";
  return serviceName;
}

const showError = () =>{
  let serviceName = checkServiceName()
  let isValidServices =  checkValidServices(serviceName);
  
  if(!isValidServices){
      setError(serviceName)
    return false; 
  }
  return true;
}


useEffect(()=>{
  dispatch(setReservationService({"serviceType":SERVICE_TYPE.TRANSFER,"data":transferList}));
},[transferList])

  
useEffect(() => {
  let reservationAccom = buildReservationAccom(selRooms, isNewHotel)
  console.log("reservationAccom",reservationAccom);
  
  dispatch(setReservationService({ "serviceType": SERVICE_TYPE.ACCOMODATION, "data": reservationAccom }))
}, [selRooms,isNewHotel,pendingRooms])


const getBackButton=()=>{
  if(actionMode===MORE_OPTIONS_MODE.ADD_ITEM){
    return (<>
      {/* <button onClick={openCancelUpdatePopup} className='cancel-update-btn'>Cancel</button> */}
      <div className='update-warning-red'>
        To update your quotation, you need to first add a new item.
      </div>
      <button onClick={loading?undefined:(e) => updateQuotation(e)} className={`update-btn  ${loading ? 'disable' : ''}`} disabled={disableUpdate }>
       {loading ? "Updating quotation" : "Update quotation" } 
      </button>
    </>)
  }
  if (actionMode.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING)) {
    return (<>
      <div className='update-warning-red'>
        To update your booking, you need to first add a new item.
      </div>
      <button onClick={loadingBooking?undefined:(e) => handleUpdateBooking(e)} className={`update-btn  ${loadingBooking ? 'disable' : ''}`} disabled={disableUpdate}>
        {loadingBooking?"Updating booking":"Update booking"}
      </button>
    </>)
  }
}
  
const quotationOrBooking=()=>{
  return actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING)?"booking":"quotation";
}


let {reservationTransfer,reservationAccom}=sortedList.services
//console.log("sortedList",sortedList.services); 


  return (
    <>
      <div className={`fixedQuotation ${isIntersectingFooter?"follow-scroll":""}`} style={isIntersectingFooter?{bottom:`${additionalBottomDistance}px`}:{}} ref={ref}>
        {!actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING) && <div className={`service-selector-container ${showServiceSelector ? "" : "hide-service"}`}>
          <Dropdowns dropData={DROPDOWN_DATA} imgcount={1} name="Accommodation" />
        </div>}
        <div className={`quotationBlock ${isDocketVisible()?"":"hide"}`}>
          <div className="headerQuotation">
            <div className="headerQuotation-txt">{actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING) ? "Your booking" : "Your quotation"}</div>
            {actionMode==null&&<MoreOptions options={DOCKET_OPTIONS}/>}
          </div>
          <div ref= {containerRefToScroll} className={`quotation_body ${reservationTransfer.length || reservationAccom.length ? 'priceadded' : ''}`} >
            {/* [Docket] */}
            <TimelineDocket containerRef={containerRefToScroll} transferData={sortedArray} paxdata={paxres} nxtselect={gngtoselect} totalpax={totalpax} showRemove={showRemove}/>          
          </div>   
          <div className="quotation-total" ><DocketPrice data={sortedList}/></div>
          {<div className="view_quotation">
            {actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING)?null:<div onClick={(e)=>{viewCotation(e)}}>
              <span>View {quotationOrBooking()}</span>
            </div>}
          </div>}
          <div className={(!showRemove && actionMode ) ? 'bookButtons-update' : "bookButtons" }>
            {showRemove ? (
              <>
                <button style={{ marginLeft: "174px", background: "rgba(20, 155, 158, 1)", color: 'white', border:'rgba(22, 163, 145, 1)'}} onClick={(e)=>setShowRemove(false)} className='save-quote-btn'>Done</button>
              </>
            ) : (
              actionMode == null ? (
                <>
                  <button onClick={(e) => openInputbox(e)} className=''>Save quotation</button>
                  <button onClick={(e) => bookNow(e)} className='pay-book-btn'>Book now</button>
                </>
              ) : (
                getBackButton()
              )
            )}
          </div>
        </div>
        {actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM) && <div className={(!showRemove && actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM) )?'toast-warning-update':'toast-warning'}>
          Caution : Please save your {quotationOrBooking()} to apply latest changes
        </div>}
        {error && error == "transfer" && <div className='vehicle-fill-pop'><p>Please select all vehicles of your transfer list ! 🙅🏽‍♂️</p></div>}
        {error && error == "accom" && <div className='hotel-fill-pop'><p>Please select all the rooms in your hotel ! 🙅🏽‍♂️</p></div>}
      </div>
      {isOpen ? getPopupContent():<></>}
    </>
    
  );
})



export default Quotation;