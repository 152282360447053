import React,{useState,useEffect, useRef} from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import usePopupData, { ADD_SERVICE_BOOKING, CANCEL_BOOKING, SUCCESS_POPUP } from '../hooks/usePopupData.js'; 
import PaxDetails from '../components/MiniComponents/PaxDetails'
import QuoteCancellationLayout from '../components/Quotations/QuoteCancellationLayout.js';
import TableLayoutNew from "../components/MiniComponents/TableLayoutNew.js"
import TimelineView from '../components/MiniComponents/TimelineView.js';
import logo from "../Assets/images/mautourco-logo.png"
import { properCase,getBeforeUnderscore, getClientTypeByAcronyme, getGlobalClientType, getPaxCategoryByAge } from '../utility/globalFunc.js';
import {  setQuoteBookList, totalList, triggerNotifDelete} from '../store/globalReducer.js';
import { BookingActionDropdown, QuotationActionDropdown } from '../components/Quotations/small_dropdowns.js';
import {capitalizeFirstLetter, CLIENTDATA, MORE_OPTIONS_MODE, ROUTE_PATHS, SELECTED_ACCOM} from "../constants.js";
import { Back, NotifDeleteQuotation } from '../components/MiniComponents/Atom.js';
import CancelBooking from '../components/MiniComponents/CancelBooking.js';
import useCategoryCounter from '../hooks/useCategoryCounter.js';
// It was fully based on our globalreducer -> sortedList and totalPrice


function SelectedViewDetails(props) {
    const {subAgencyAdded,userData} =useSelector((state) => state.authpath);
    const {agency_name,first_name,last_name,agency_id} =userData; 
    const { totalPrice, quoteBookList, showNotifDelete, actionMode } =useSelector((state) => state.globalreducer)
    const { isOpen, openPopup, closePopup, getPopupContent } = usePopupData();
    const navigate = useNavigate();
    const dispatch =useDispatch()
    const [popupOpen, setPopupOpen] = useState(false);
    const location=useLocation();
    const params=new URLSearchParams(location.search);
    const file_name=params.get('file_nb');
    const {successUpdate}=location.state || {};
    const actionModeRef = useRef(actionMode);  
    const {getCategoryCount} = useCategoryCounter("client_status");

   

    let isSubAgencyHaveValue = Object.keys(subAgencyAdded).length > 0
    let totalClients = quoteBookList?.Clients?.length
    
    const Booking=(e)=>{
        e.preventDefault();
        let {reservationTransfer,reservationAccom,reservationExcursion,refCode}=quoteBookList
        dispatch(totalList(quoteBookList));
        navigate(ROUTE_PATHS.USER_DETAILS,{state:{service:{reservationAccom,reservationTransfer,reservationExcursion},refCode:refCode}});
    }

    const confirmSave=(e)=>{
        openPopup("info")
    }

    const showFooter=()=>{
        return quoteBookList.refCode !== null && !quoteBookList.IdBooking
    }

    const isViewBooking=()=>{
        return quoteBookList.hasOwnProperty("IdBooking");
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!quoteBookList) window.history.back();
        
        return ()=>{
            if (!actionModeRef.current?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING)) {                                
                dispatch(setQuoteBookList([]))
            }
        }
    }, []);

    useEffect(()=>{
        actionModeRef.current = actionMode;  
    },[actionMode])

    useEffect(() => {
        if(showNotifDelete){
            setTimeout(() => {
                dispatch(triggerNotifDelete(false));
            }, 4000);
        }
    }, [showNotifDelete])
    

    useEffect(()=>{
        if (!!successUpdate) {   
            openPopup(SUCCESS_POPUP,"The booking has been updated successfuly !");
            setTimeout(()=>{
                closePopup();
            },2000)
        }
    },[])
    return (
        <>
            {
                showNotifDelete &&
                <div className={`container-notif-delete ${!showNotifDelete ? 'hide' : ''}`}>
                    <NotifDeleteQuotation isBooking={isViewBooking()}></NotifDeleteQuotation>
                </div>
            } 
        <div className='view-quotationPage'>
                <div id="toPdf" className="save_quotaion_popup">
                <>
                    <div className="save_quotaion_head">
                        <div className="save_quotaion_headlft">
                        {isViewBooking()&&
                            <Back name={"quotation"}  label={ getBeforeUnderscore(file_name)+" Booking"}/>
                        } 
                        <p className='save_quotaion_headlft_orange'>
                            {!isViewBooking() ? (
                                <>
                                   <span className='save_quotaion_headlft_num'>File number: {file_name }</span>
                                    {getBeforeUnderscore(quoteBookList.refCode||quoteBookList.ToRef)}
                               
                                </>
                            ) : (
                                "Booking N° " + quoteBookList.IdBooking
                            )}
                        </p>
                            <p className='save_quotaion_headlft_name'>{first_name}  {last_name}, {agency_name}</p>
                           { !isViewBooking() && <div className='timeline-container'><TimelineView list={quoteBookList}/></div>}

                        </div>
                        <div className="save_quotaion_headryt">
                            <img src={logo}/>
                            
                            <div className='cotDocdetails'>
                                <p>Document details</p>
                                {quoteBookList.refCode !== null ? <div><span className="label" style={{ marginTop: "0px" }}>File number:</span> <span style={{ marginTop: "0px" }} className="value">{file_name.substring(0,6)}</span></div> : <></>}
                                <div><span className="label">Agency:</span> <span className="value">{isSubAgencyHaveValue ? subAgencyAdded.agency_name: agency_name}</span></div>
                                <div><span className="label">Client-Type:</span> <span className="value">{getGlobalClientType(quoteBookList)}</span></div>
                               {quoteBookList?.Clients && <div><span className="label">Number of pax:</span> <span className="value">{totalClients}</span></div>}
                            </div> 
                            <div className='dropdownContainer'>
                                <div className='buttonDrop' onClick={() => setPopupOpen(prev => !prev)}><label>More options</label></div>
                                {popupOpen && !isViewBooking() && <QuotationActionDropdown file_name={quoteBookList.refCode} setPopupOpen={setPopupOpen} isView={false} data={quoteBookList} isaddindService={isViewBooking() ? true : false} CancelBooking={CancelBooking}/>}
                                {popupOpen && isViewBooking() && <BookingActionDropdown booking={quoteBookList}/>}
                            </div>
                        </div>
                    </div>
                    
                    {isViewBooking()&&<div className={!isViewBooking() ?'absolute-added': 'absolute-added-booking'}>
                        <h3 className='title-recap-booking'>1. Client information</h3>
                        <div className='absolute-added-booking-client-info'>
                        {isViewBooking() && quoteBookList.Clients.map((pax,ind)=>{
                            let label=getPaxCategoryByAge(pax.Age||pax.age||0);
                            const nb = getCategoryCount(pax);
                            return (
                                <div className='absolute-added-booking-client-info-container' key={ind+"paxdetails"}>
                                    <PaxDetails firstName={pax.name} lastName={pax.surName} phoneNumber={pax.phoneNumber ? pax.phoneNumber:""} age={pax.Age || pax.age || 0} key={pax.surName+ind} label={label} numero={nb}/>
                              </div>
                            )
                        })}
                        </div>
                    </div>}
                                   
                    {<div className='booking-resume'>
                       { quoteBookList.hasOwnProperty("Clients") && <h3 className='title-recap-booking'>2. Booking resume</h3> } 
                            <TableLayoutNew isViewBooking={isViewBooking()} lists={quoteBookList} total={totalPrice} isScrollable={false}/>
                        <QuoteCancellationLayout data={{"reservationTransfer":quoteBookList.reservationTransfer,"reservationAccom":quoteBookList.reservationAccom}}/>
                    </div>}
                    
                    {showFooter() && <div className='save_quotaion_button'>
                    {
                        quoteBookList.refCode !== null ? 
                        null :  
                        <button  onClick={(e)=>{confirmSave(e)}} className={'finsavecotation '+(quoteBookList.refCode !== null ?"disabledCss2":"")}>Save Quotation</button>  
                    }
                    <button onClick={(e)=>{Booking(e)}}   className='finbooknow' >Book Now</button>   

                    </div> }    
                </>
                {isOpen ? getPopupContent():<></>}
            </div> 
        </div>
        </>
    );
}

export default SelectedViewDetails;