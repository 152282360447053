import React,{useEffect} from 'react';
import SameInputs from "../Excursion/SameInputs";
import { useSelector,useDispatch } from 'react-redux';
import {addLists,clearTransfer, loadStatus, setIsSameVehicle} from "../../store/transferReducer.js"
import useCarList from '../../hooks/useCarlist.js';
import Skeleton from '@mui/material/Skeleton';
import { useLocation, useNavigate } from 'react-router-dom';
import{ROUTE_PATHS, TRANSFER_INPUT_OBJECT} from "../../constants.js"
import { scrollToSectionv1,formatDateString } from '../../utility/globalFunc.js';
import { DisplayMoreTransfer } from './Atom.js';



function ButtonBlock({addTransfer,delbtn,deleteItemClick,setSearchClick,errchk, onDisplayClick, isListScrollable=false}) {
  
    const {transferUpdates,loading,isSameVehicle,isResultSameVehicle}=useSelector((state) => state.transfer_Global)
    const {currency:mtcoCurrency}=useSelector((state) => state.authpath.userData)
    const {subAgencyAdded}=useSelector((state) => state.authpath)
    const location=useLocation();


   
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {fetchDataTransfer,error } = useCarList();
   
   
    const addInputBoxes =(e)=>{
        e.preventDefault(); 
        let objectLength = transferUpdates.length +1;
        let addInputs = { ...TRANSFER_INPUT_OBJECT };
        addInputs.IdTransfer = objectLength;
        dispatch(addLists({addInputs,index:transferUpdates.length}));
        setTimeout(() => {
            const scrollableContainer = document.querySelector(".transfer_box .inputBlocks.scrollable");

            if (scrollableContainer) {
                scrollableContainer.scrollTo({
                    top: scrollableContainer.scrollHeight,
                    behavior: "smooth"
                })
            }
            scrollToSectionv1('buttonBlocks',650)
        }, 300);
    }

    const getTransferPayload=()=>{
        if (isSameVehicle) {
            return transferUpdates;
        }
        if (isSameVehicle!=isResultSameVehicle) {
            return transferUpdates;
        }
        const {pathname}=location;
        if (pathname === ROUTE_PATHS.TRANSFER) {
            return transferUpdates;
        }
        return transferUpdates.filter(item=>!item.IsSearched);
    }

    const searchData = (e)=>{
        // alert('test')
        e.preventDefault();
        const newTransfers=getTransferPayload();
        const result =  newTransfers.map(item => ({
            idLocationFrom: item.LocationFromId ? item.LocationFromId:"",
            idLocationTo: item.LocationToId ? item.LocationToId:"",
            transferDate: item?.TransferDate ? formatDateString(item?.TransferDate,'dd/MM/yyyy', 'yyyy-MM-dd'):"",
        }));;

        const transfersIdNotSearched=newTransfers.map(transfer=>transfer.IdTransfer);
        let {currency:agencyCurrency} =subAgencyAdded;
        
        const sendObject = { 
            payload:{
                currency:!Object.entries(subAgencyAdded).length ? mtcoCurrency :agencyCurrency,
                use_same_vehicle:isSameVehicle,
                transfer_data: result,
            },
            list:newTransfers,
            transfersIdNotSearched
        };
        
        
        const hasEmptyString = result.some(item => Object.values(item).some(value => value === ""));
        

        if (hasEmptyString || result.length === 0 ) {
            setSearchClick(true) 
        } 
          
        else {
            
            setSearchClick(false);

            // dispatch(loadStatus(true))
            
            setTimeout(() => {
                navigate(ROUTE_PATHS.SELECT_VEHICLE);
            }, 500);
            fetchDataTransfer(sendObject).then(rep=>{
                scrollToSectionv1('pickCarsText', 100);
            })
        }
    }

    const refreshPage=()=>{
        dispatch(loadStatus(true))
        setTimeout(() => {
            dispatch(loadStatus(false))
        }, 300);
    }

    const isSearchDisabled=()=>{
        const notSearchedTransfer=transferUpdates.filter(transfer=>!transfer.IsSearched);
        const isSameVehicleResult=isSameVehicle===isResultSameVehicle;
        const {pathname}=location;        
        return !notSearchedTransfer.length && isSameVehicleResult && pathname === ROUTE_PATHS.SELECT_VEHICLE;
    }

    const getButtonLabel=()=>{
        const {pathname}=location;
        if (pathname !== ROUTE_PATHS.SELECT_VEHICLE) {
            return "Search";
        }
        const notSearchedTransfer=transferUpdates.filter(transfer=>!transfer.IsSearched);
        const isSameVehicleResult=isSameVehicle===isResultSameVehicle;
        if (notSearchedTransfer.length>0 || !isSameVehicleResult) {
            return "Update search results";
        }
        return "Search";
    }

    const getButtonClassName=()=>{
        const {pathname}=location;
        if (pathname !== ROUTE_PATHS.SELECT_VEHICLE) {
            return "";
        }
        const notSearchedTransfer=transferUpdates.filter(transfer=>!transfer.IsSearched);
        const isSameVehicleResult=isSameVehicle===isResultSameVehicle;
        if (notSearchedTransfer.length>0 || !isSameVehicleResult) {
            return "update-search-results";
        }
        return "";
    }

    const displayDisable=()=>{
        return transferUpdates.length <= 3;
    }
    return (
        <>
            <div id= 'buttonBlocks' className="buttonBlock" style={{marginTop:transferUpdates.length >3 ? "61px":""}}>
            <DisplayMoreTransfer disable={displayDisable()} onClick={onDisplayClick} isScrollable={isListScrollable}/>
            {errchk ? <p className='buttonBlock_select_p'>Please fill the Transfer</p>:<></>}
            <div className="errormsg ">{errchk ? errchk.message:""}</div>
            { !delbtn ?
                
            <div className={`buttonBlock_select ${getButtonClassName()}`}>
                <div className="buttonBlockRight">
                  <>{addTransfer ?<button  className="addTransferBtn"  onClick={(e) => { addInputBoxes(e)}}  id="addTransfer_btn" >Add a new transfer line</button>:<></>}</>
                   <button className={`searchBtn ${getButtonClassName()}`}  onClick={isSearchDisabled()?refreshPage:(e) => {searchData(e) }} id="searchBtn">{getButtonLabel()}</button>
                </div>
            </div> 
            : <div className="buttonBlock_delete">
                    <button className="deleteAll"  onClick={(e) => {deleteItemClick(e,"close") }} id="delallBtn">Done</button>
                </div> 
              }
        </div>
        
        </>
    );
}

export default ButtonBlock;