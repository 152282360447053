import React,{useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import useQuotationService from '../../hooks/useQuotationService.js';
import { ROUTE_PATHS } from '../../constants.js';
import useResetSearch from '../../hooks/useResetSearch.js';

const QuotationUpdatedPopUp = ({data}) =>{
    const [popupopen,setpopupOpen]=useState({succespop:false,popstatus:null,quotation_screen:false})
    const {fetchQuotationDataAndGoToBooking,fetchQuotationData}=useQuotationService();
    const {savedFileNumber} =useSelector((state) => state.globalreducer)
    const {resetAllServices} = useResetSearch()

    
    const [fadeClass,setFadeClass]=useState("");
    const navigate = useNavigate();

    const openGridQuote =(e)=>{
        e.preventDefault();
        navigate(ROUTE_PATHS.QUOTATION)
    }

    const openClientPage=(e)=>{
        e.preventDefault();
        const payload={"file_nb": savedFileNumber,"file_name":data.refCode}
        alert()
          fetchQuotationData(payload,(res) => {
            let accomTotal = 0;
            let result =res.data.result;
            console.log("result:",result);
            navigate(ROUTE_PATHS.USER_DETAILS,{state:{service:result,refCode:refCode}});
            //dispatch(totalList({folder:"",services:result}))


        },(err) => {
            alert(err);
            console.log("error",err);
            
        });
    }

    const handleOpenPopup=(e,isClosed)=>{
        e?.preventDefault();
        if (!isClosed) {   
            setFadeClass("fade-out-200");
            setTimeout(() => {
                setFadeClass("");
                closepopup(isClosed);
            }, 200);
        }else{
            closepopup(isClosed);
        }
    }


    useEffect(() => {
        return () => {
            resetAllServices();
        }
    }, [])

    return(
        <div className={`${popupopen.succespop ?"save_input_popup":"save_input_popup"} ${fadeClass}`}>
                <div onClick={(e)=>{ handleOpenPopup(e,false)}} className='close-button'></div>
         <div className={'success_cotpopup fade-in-200'}>
        <div>  
            <div className='success_popchild'>
                <div >Your quotation has been successfully updated !<br/><span>"{data.refCode}"</span><span> quotation</span></div>
                <div className='success_popchild_btns'>
                    <button onClick={(e)=>{openGridQuote(e)}}>View on quotation grid</button>
                    <button onClick={(e)=>{openClientPage(e)}}>Book now</button>
                </div>
            </div>
        </div>      
    </div>  
    </div>
    )

}
export default QuotationUpdatedPopUp;