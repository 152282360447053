import React,{useEffect, useRef,useState} from 'react';
import Calendar from "react-multi-date-picker";
import { ShowCalendar,CalendarFooter, CalendarButton, CalanderHeaderTransfer } from './ExcursionArray.js';
import {useDispatch } from 'react-redux';
import {calUpdates,clearDates} from "../../store/transferReducer.js"
import { scrollToDependsBoundary } from '../../utility/globalFunc.js';
import { WEEK_DAYS } from '../../constants.js';
import {format,parse} from "date-fns";
import useStickyItems from '../../hooks/useStickyItems.js';


function MulticalanderTransfer({errmsg,label,label2,month,page,rangeDate,rowid,calDate,isDeleting=false,isMainTransfer=false}) {
  const datePickerRef = useRef(null)
  const [value, setValue]=useState(new Date());
  const rightButtonRef=useRef(null);
  const dispatch = useDispatch()
  const [hasScroll,setHasScroll]=useState(false);
  const [isOpen,setIsOpen]=useState(false);
  const calendarParentRef=useRef(null);
  // ---------------
  const [selectedDate,setSelectedDate]=useState(null)
  
  const handleChange = (date ) => {
    if(date && date.length == 0){
      return
    }
    
    const lastindex = date[date.length -1 ]
    const day = lastindex?.day
    const month = lastindex?.month?.number
    const year = lastindex?.year
     const tempdateString = year+"-"+month+"-"+day;
     const tempdate =new Date(tempdateString)
    setSelectedDate(tempdate);

  };

  


  const handlePositionChange=(data,isMainTransfer)=>{
    
    if (!hasScroll) { 
     if(isMainTransfer){
      const rect=data.popper;
      rect.top=data.element.top-100;
      rect.bottom=rect.top+rect.width;
      scrollToDependsBoundary(rect)
      setHasScroll(true);
     }else{
      const rect=data.popper;
      rect.top=data.element.top-2;
      rect.bottom=rect.top+rect.width;
      scrollToDependsBoundary(rect)
      setHasScroll(true);
     }
    }
  }

  const handlePostionOnCalendarClose = () =>{   
    if(datePickerRef.current.isOpen){

      scrollToDependsBoundary(datePickerRef)
    } 
  }
  
  // Call removeSelectedDate function to remove a selected date
  const handleRemoveDate = (type) => {
        /// It is to validate the dates
        if(type==="validate" && selectedDate !== null){
            let payload = {id:rowid,name:"TransferDate",value:format(selectedDate,"dd/MM/yyyy")}
            dispatch(calUpdates(payload))
            datePickerRef.current.closeCalendar()
        }
        if(type==="validate"){datePickerRef.current.closeCalendar()}

        if(type === "clear"){
          setSelectedDate(null)
          let payload = {id:rowid,name:"TransferDate"}
          dispatch(clearDates(payload))
        }
  };


  const handleDateValue=()=>{
    
    if (!selectedDate) {
      setValue(new Date());
    } else {
      setValue(selectedDate);
    }
  };

  const handleDateValueOnClosed = () =>{
    if( selectedDate !== null){
      let payload = {id:rowid,name:"TransferDate",value:format(selectedDate,"dd/MM/yyyy")}
      dispatch(calUpdates(payload))
  }
  }
  
  const setRightRef=(ref)=>{
    rightButtonRef.current=ref.current;
  }
  const transformDate = (dateString) => {
    const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());
    return new Date(parsedDate);
  };

  const resetCalendarPosition = () => {
    const secondChild = datePickerRef.current.children[datePickerRef.current.children.length - 1]; // Get the second child
    if (secondChild) {
      secondChild.style.top = 0;
      secondChild.style.left = "154px";
      secondChild.style.position = "absolute";
    }
  }

  useEffect(()=>{
    if(calDate){
      const date = transformDate(calDate)
      setSelectedDate(date)
    }
  },[])

  useEffect(()=>{
    if (datePickerRef.current) {
      resetCalendarPosition()
    }
  },[])

  useStickyItems({
    containerSelector: "scrollable",
    itemSelector: "rmdp-container",
    customStickySelector:'[style*="will-change"]',
    trigger:isOpen,
    closeRef:calendarParentRef,
    handleOutside: (sticky) => {
      if (datePickerRef.current) {
        datePickerRef.current.closeCalendar()
        
        sticky.style.left = 0;
        sticky.style.top = 0;
        sticky.style.position = "absolute";
      }
    },
  })

    return (
      <Calendar 
        render={(data, openCalendar) => {

            return (
                // calendar inputs initial display
                <div onClick={(e)=>{openCalendar();}}> 
                <ShowCalendar errchk={errmsg} label={label} label2={calDate  ? calDate :label2} ref={calendarParentRef}/>
                </div>
            )
        }}
        format="DD/MM/YYYY"  
        ref={datePickerRef}
        value={selectedDate}
        onMonthChange={(date)=>{setValue(new Date(date))}}
        onChange={handleChange}
        weekDays={WEEK_DAYS}
        range={false}
        closeOnSelect={false}
        minDate={month === 2 && page !== 5 ? new Date() : month === 1 ? rangeDate[0] : false }
        numberOfMonths={month}
        maxDate={null}
        disableMonthPicker={true}
        disableYearPicker={true}
        weekStartDayIndex={1}
        renderButton={<CalendarButton value={value} setRightRef={setRightRef}/>}
        headerOrder={["LEFT_BUTTON", "RIGHT_BUTTON"]}
        multiple={true}
        onClose={()=>{
            handleDateValue();
            setHasScroll(false);
            resetCalendarPosition();
            handleDateValueOnClosed();
            handlePostionOnCalendarClose()
            setIsOpen(false);
          }
        }
        onOpen={()=>{
            handleDateValue();
            setIsOpen(true);
          }
        }
        onPositionChange={data => handlePositionChange(data,isMainTransfer)}
        plugins={[
            <CalendarFooter position="bottom" handleDates={handleRemoveDate} />,
            <CalanderHeaderTransfer position="top" label="Transfer date" dummytxt="dd/mm/yyyy" dates={selectedDate}/>
           
          ]}
      /> 
    
    );
}

export default MulticalanderTransfer;