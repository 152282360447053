import React,{useState,useEffect,useRef, useContext} from 'react';
import Dropdowns from '../Transfer/Dropdowns';
import {DROPDOWN_DATA,scrollRemove,GRID_INPUTS, SHOWFILTER, BUDGET_RANGE,ROUTE_PATHS, MORE_OPTIONS_MODE} from "../../constants"
import {removePendingRooms, selHotel, setDisplayUnavailableHotels } from '../../store/accomodationReducer.js';
import FiltersData from '../Excursion/FiltersData.js';
import ExcursionFilter from '../Excursion/ExcursionFilter.js';
import { Back } from '../MiniComponents/Atom.js';
import AccomMaps from './AccomMaps.js';
import AccomInputs from "./AccomInputs.js"
import Showhotels from '../Accomodation/Showhotels';
import useGetscrollInfo from './hooks/useGetscrollInfo.js';
import useHotelList from './hooks/useHotelList.js';
import { useSelector,useDispatch } from 'react-redux';
import FilterResume from '../MiniComponents/FilterResume.js';
import {useElementsIntersection} from '../../hooks/useElementsIntersection.js';
import { LayoutContext } from '../layout/MainLayout.js';
import {resetActionMode, setShowDropdown} from "../../store/globalReducer.js";
import { deepCompareArrays } from '../../utility/globalFunc.js';
import { useNavigate } from 'react-router-dom';



function MainAccomodation({}) {
    const [openMap ,setOpenMap] = useState(false)
    const {displayUnavailableHotels,accomList,accomfilter={},budgetRange,roomPax:paxData,accomLoading,criteria:filterCriteria,starRating,pageLoading,paginationPage,noHotelData,isOffer} = useSelector((state) => state.accomodationreducer)
    const {actionMode} = useSelector((state) => state.globalreducer)
    const [fixedFilter,setFixedFilter]=useState(false);

    const scrollRef = useRef(null);
    const filtersRef = useRef(null);
    const filterResumeRef = useRef(null);
    const accomInputRef = useRef(null);
    const listRef=useRef(null);
    const {footerRef,setShowLeftServiceSelector} = useContext(LayoutContext);
  
    const dispatch = useDispatch()
    const navigate=useNavigate();
    const {fetchHotelList,buildFetchListPayload,fetchHotelAndTagList} = useHotelList();
    const checkedratings = starRating.filter(item => item.checked).map(item => item.label);
    const isFiltered=()=>{return filterCriteria.length > 0 || checkedratings.length > 0 || isOffer || !deepCompareArrays(budgetRange,[BUDGET_RANGE.MIN_PRICE,BUDGET_RANGE.MAX_PRICE]);}
    const isFilterIntesecting=useElementsIntersection(filterResumeRef,footerRef);

    useEffect(() => {
        scrollRemove(openMap)
        dispatch(setShowDropdown(false))
    }, [openMap])

    useEffect(() => {
        //to remove Docket on this page
        dispatch(removePendingRooms());
        dispatch(selHotel({"loc_name":"","loc_det":"","loc_id":"all"}))
    }, [])

    const handleScrollEnd = () => {
        let sendObject = buildFetchListPayload()
        
        let redirect=ROUTE_PATHS.ACCOMMODATION_SELECT
        let module = 1
        // [pagination]
        
        !noHotelData ? fetchHotelList(sendObject,redirect,module,paginationPage+1,false,false):null
    };

    const handleFixedFilter=(filtersElement)=>{
        if (filtersElement && filtersElement.isIntersecting) {
            setFixedFilter(false)
        } else if (filtersElement && !filtersElement.isIntersecting) {
            setFixedFilter(true)
        }
    }

    const handleUnavalaibleHotelsCheckbox=(e)=>{
        let showUnavailableHotels=e.target.checked;
        dispatch(setDisplayUnavailableHotels(showUnavailableHotels));
        
        let payload=buildFetchListPayload();
        
        let redirect=ROUTE_PATHS.ACCOMMODATION_SELECT
        let module = 1
        fetchHotelList(payload,redirect,module,1,false,false,showUnavailableHotels);
    }

    useEffect(() => {
        let sendObject = buildFetchListPayload()

        let redirect = ROUTE_PATHS.ACCOMMODATION_SELECT
        let module = 1
       
        if (!noHotelData){            
            fetchHotelAndTagList(sendObject,redirect,module,1,false,false);
        }
    }, [])
    
    useEffect(() => {
        let observer = null;
        if (accomInputRef.current) {

            const options = {
                root: null,
                threshold: 1,
            }
            observer = new IntersectionObserver((entries) => {
                let entry=entries[0];
                setShowLeftServiceSelector(!entry.isIntersecting)
            }, options)
            observer.observe(accomInputRef.current);
        }
        return () => {
            if (observer!=null) {
                observer.disconnect();
            }
            setShowLeftServiceSelector(false);
        }
    }, [accomInputRef.current])

    useEffect(() => {  
        const handleBackButton = (event) => {
            if (actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING)) {
                dispatch(resetActionMode());
            } 
            navigate(-1);
            /* else {
                window.removeEventListener("popstate", handleBackButton);
                window.history.back();
            }   */
        };
    
        window.history.pushState(null, "", window.location.href);
        window.addEventListener("popstate", handleBackButton);  
        
        return () => {  
          window.removeEventListener("popstate", handleBackButton);  
        };  
      }, [navigate]);  

    useGetscrollInfo(listRef, handleScrollEnd,filtersRef,handleFixedFilter,accomLoading,noHotelData);

    return (
        <>
           { 
             !pageLoading.status ?
                <>
                   
                    <div className='mainexcursion_parent' ref={scrollRef}>
                        <div className='sub_mainexcursion_parent'>
                            <div className={`filter_parent ${fixedFilter&&"hide-blank"}`}>
                                <div>
                                    {!actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING)&&<Dropdowns dropData={DROPDOWN_DATA} imgcount={1} name="Accommodation" center={true}/>}
                                    <div className='filter_list' ref={filtersRef}>
                                        <div className='filter_list_name'>Filters</div>
                                        
                                        <div style={{margin:"29px 0 56px 0"}}>
                                            {openMap &&  <AccomMaps setOpenMap={setOpenMap}  points={accomList} paxData={paxData} btn={true}/>}
                                            <div className='map-img-filter'><span onClick={(e)=>{setOpenMap(true)}}>Find a hotel with map</span></div>
                                        </div>
                            
                                        <div> 
                                            <ExcursionFilter filterOptions={accomfilter}  module={1} scrollRef ={scrollRef}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {fixedFilter&&<FilterResume filters={accomfilter} scrollRef={scrollRef} ref={filterResumeRef} intersectFooter={isFilterIntesecting}/>}
                            {/* {fixedFilter&&<FilterResume filters={accomfilter} scrollRef={scrollRef} ref={filterResumeRef} intersectFooter={false}/>} */}

                            <div className='filter_hotels' >
                                    <Back label="Hotel List" name={ROUTE_PATHS.ACCOMMODATION}/>
                                    <div style={{width:"957px",float:"left"}} ref={accomInputRef}>
                                        <AccomInputs alignbtn={false} styles={GRID_INPUTS} scroll={false} remRating={true} searchOnPaxDone={true} />
                                    </div> 
                                
                                    <div className='excursion_places'>
                                        <div className={`hotel-list-header${isFiltered()?"":"-no-filter"}`}> 
                                            <div className='places_hdtxt'>Hotel list</div>
                                            {/* <div className='excursion_subfilter'><ShowFilters hotelList={accomList} /></div>  */}
                                        </div>
                                        <div className={`filterbox_loop show-unavailable-hotel${isFiltered()?"":"-no-filter"}`}>
                                            <label htmlFor='unavailable-hotel'>
                                            <input type='checkbox' id='unavailable-hotel' checked={displayUnavailableHotels} onChange={handleUnavalaibleHotelsCheckbox}/>
                                                {SHOWFILTER[SHOWFILTER.length-1].name}
                                            </label>
                                        </div>
                                        { isFiltered() && <FiltersData label="Filters applied :" tagsFilter={filterCriteria} ratingFilter={starRating} scrollRef ={scrollRef} isOffer={isOffer} budgetRange={budgetRange}/>   }
                                        {accomList&&
                                        <Showhotels 
                                            btnBlock={true} 
                                            hotel={accomList} 
                                            foldername="accomodation" 
                                            paxInfo={paxData}
                                            listType={true}
                                        />
                                        }
                                        {
                                            accomLoading&&<div className='hotel-skeleton'></div>
                                        }
                                        {/* {accomLoading?null:<div className='infinite-breakpoint' ref={listRef}/>} */}
                                        {!accomLoading&&<div className='infinite-breakpoint' ref={listRef}/>}
                                    </div> 
                            </div>
                        </div>
                    </div>
                </>
                : <div className={pageLoading.firstFlow ? 'accomSkeletonLoad1' : 'accomSkeletonLoad2'}></div>
            }

        </>
    );
}

export default MainAccomodation;