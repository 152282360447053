import React, { useState,useEffect } from 'react';
import axios from "axios"
import {axiosRequestParams} from "../../../services/apiClient.js"
import {QUOTELIST_ENDPOINT,BOOKING_ENDPOINT} from "../../../services/apiEndpoints.js"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUrlParams } from '../../../utility/globalFunc.js';
import { setQuoteBookList } from '../../../store/globalReducer.js';
import {RESERVATION_TYPE2, ROUTE_PATHS} from "../../../constants.js"

// Custom hook to fetch data from an API
const useQuoteList = () => {
    const [quoteList, setQuoteList] = useState(null);
    const [bookingList, setBookingList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);  
    const [nbPages,setNbPages]=useState(1);
    let token = localStorage.getItem("access-token");
    const navigate = useNavigate();
    const dispatch=useDispatch();

    const fetchData = async (filter={},setStateCallback) => {
        setLoading(true)
       
        try {
            const response = await axios.request({
                headers: {Authorization: `Bearer ${token}`},
                method: 'GET',
                url: process.env.REACT_APP_API_URL+`${QUOTELIST_ENDPOINT}?${getUrlParams(filter)}`,
            });
            setQuoteList(response.data.result)
            setNbPages(response.data.nb_page||1)
            if (setStateCallback) {setStateCallback(response.data.result);}
        
        } catch (error) {
            if(error.response && error.response.status === 401){
                navigate(ROUTE_PATHS.HOME)
            }else if (error.response) {
                setQuoteList([])
                setNbPages(0)
                if (setStateCallback) { setStateCallback([]); }
                setError(error.response.data.message);
            } else {
                navigate(ROUTE_PATHS.INTERNAL_ERROR)
            }
        } finally {
            setLoading(false)
        }
    };

    const fetchBookingData = async (Bookfilter={}) => {
        setLoading(true)
        try {
            setBookingList([])
            let payload = getUrlParams(Bookfilter)
            const params = new URLSearchParams(payload);
            const sendobj = Object.fromEntries(params.entries());
            
            const response = await axiosRequestParams("GET",BOOKING_ENDPOINT,sendobj)

            setBookingList(response.data.result)
            setNbPages(response.data.nb_page||1)
            
        
        } catch (error) {
            console.log("error",error);
            
            if(error.response && error.response.status === 401){
                navigate(ROUTE_PATHS.HOME)
            }else if (error.response) {
                setError(error.response.data.message);
            } else {
                navigate(ROUTE_PATHS.INTERNAL_ERROR)
            }
        } finally {
            setLoading(false)
        }
    };

    // const filterToRefDatas =async (sendobj)=>{
    //     console.log("sendobj:",sendobj);
        
    //     try {
    //         const response = await axiosRequestParams("GET",QUOTELIST_ENDPOINT,sendobj)
    //         console.log("response:",response);
            
        
    //     } catch (error) {
    //         if(error.response && error.response.status === 401){
    //             navigate("/")
    //         }else if (error.response) {
    //             setError(error.response.data.message);
    //         } else {
    //             navigate("/internal-error")
    //         }
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    
    const fetchBookingDetails=async (to_ref, otherparams={}, redirect=true)=>{
        setLoading(true)
        try {
            let payload = getUrlParams({to_ref, reservation_type:RESERVATION_TYPE2});
            const params = new URLSearchParams(payload);
            const sendobj = Object.fromEntries(params.entries());
            
            const response = await axiosRequestParams("GET",BOOKING_ENDPOINT,sendobj)
            const booking=response.data.result;
            const {reservationAccom,reservationTransfer,IdBooking,Clients,ToRef,AdultCount,ChildCount,TeenCount,InfantCount,DateFrom,DateTo} =booking
            const result = {"reservationAccom":reservationAccom,"reservationTransfer":reservationTransfer,"refCode":ToRef,"IdBooking":IdBooking,"Clients":Clients,dateFrom:DateFrom,dateTo:DateTo}
            
            dispatch(setQuoteBookList(result))
            redirect&&navigate(`${ROUTE_PATHS.SELECTED_VIEW_DETAILS}?file_nb=${ToRef}`,{
                state:{
                    ...otherparams
                }
            })
        
        } catch (error) {
            console.log("error",error);
            
            if(error.response && error.response.status === 401){
                navigate(ROUTE_PATHS.HOME)
            }else if (error.response) {
                setError(error.response.data.message);
            } else {
                navigate(ROUTE_PATHS.INTERNAL_ERROR)
            }
        } finally {
            setLoading(false)
        }
    }
   
  return { quoteList,loading,fetchData,fetchBookingData,bookingList,nbPages,fetchBookingDetails };
};


export default useQuoteList;