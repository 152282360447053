import React, { useEffect, useState } from 'react'
import { separateurMillier } from '../../utility/globalFunc'
import { CarDetails, Supplement } from '../MiniComponents/Atom'
import { DisplayPax } from '../Excursion/ExcursionArray'
import { useSelector } from 'react-redux'

export default function TransferCarList({ visitMode, data, passId, ind, noClick,selectedCar }) {
    const {  updatePax,tempId: currentTransferId } = useSelector((state) => state.transfer_Global);
    const { AdultCount: Adult=0, ChildCount: Child=0, TeenCount: Teen=0, InfantCount: Infant=0 } = useSelector((state) => state.transfer_Global.transferUpdates[currentTransferId] || {});

    const totalPax = Adult + Child + Teen + Infant;
    let errtxt1 = "Supplement is not available for this vehicle"
    let errtxt2 = "There’s no Infant or Child in your pax selection"

    const getUnits=()=>{
        // if (data.TransferType===TRANSFER_VEHICLE_TYPE.PAX) {
            return totalPax;
        // }
        // return Math.ceil(totalPax/data.MaxPax);
    }
    // let errtxt3 = "Supplement is not available for this vehicle"
    const [buttonClass, setButtonClass] = useState('');

    useEffect(() => {
        const getColorButton = (status, noClick) => {
            if (status === "selected") {
                return "seldiv";
            } else if (status === null && noClick) {
                return "";
            } else {
                return "notseldiv";
            }
        };
        setButtonClass(getColorButton(data.status, noClick));
    }, [data, passId, noClick]);

  return (
        <div style={{
              animation: "shimmer 1.5s infinite"
          }}>
            <div style={{ position: "relative" }}>
                <img src={data.Pic_url} alt="car img" className="carInfo-img" />
            </div>

            <div className="display-car-data">
                
              <div className="details-block" style={{ borderBottom: visitMode ? "none" : "", marginBottom: !visitMode ? "42px": "" }}>
                <CarDetails carInfo={data} visitMode={visitMode}/>
                </div>
                {!visitMode &&(
                    <div style={{marginTop:"20px"}}>
                        <div className='sup-hdtxt'>Supplement <span>{data.MaxAllowedBabySeat === 0 ? errtxt1 : (Infant === 0 && Child === 0) ? errtxt2 : ""}</span></div>

                        <div className={"supplement-block " + (data.MaxAllowedBabySeat === 0 || Infant === 0 && Child === 0 || data.status !== null ? "disable" : "")}>
                            <Supplement data={data} name="Baby Seats" supmod={1} notes={false} lugtype="" detailId={passId} regionId={ind} />
                        </div>
                    </div>
                )}
                {/* <div className={"supplement-block " + (!data.LuggageCar || data.LuggageCar?.capacity === 0 || data.status !== null ? "disable" : "")}>
                    <Supplement data={data} name="Luggage Car" supmod={2} notes={true} lugtype="Cars" detailId={passId} regionId={ind} />
                </div>
                <div className={"supplement-block " + (!data.LuggageTruck || data.LuggageTruck?.capacity === 0 || data.status !== null ? "disable" : "")}>
                    <Supplement data={data} name="Luggage Truck" supmod={2} notes={true} lugtype="Trucks" detailId={passId} regionId={ind} />
                </div> */}     

              <div className={'sup-notes ' + (data.TransferType !== "Trip" ? "hidden" : "")} style={visitMode ? { display: 'none' } : {}}>
                    *Note : The number of vehicles will depend on the number of pax
                    {/* <p>Base Price: {data.tariff_type === "Trip" ? data.base_price +".00":"" }</p> */}
                    <p>Base Price: {data.TransferType === "Trip" ? separateurMillier(data.AdultPrice) + ".00" : ""}</p>
                </div>
                {visitMode &&
                <div className='info-message-visit'>
                      {/* Please enter the transfer details to receive more information about the supplements and prices for this vehicle. */}
                      <p className='notes'>Please enter the transfer details to receive more information about <br />he supplements and prices for this vehicle.</p>
                </div>
                }
                

              {!visitMode && 
                  <div className="btnblock_carList">
                      <DisplayPax
                          visitMode={visitMode}
                          totalpax={[updatePax]}
                          unit={getUnits()}
                          list={data}
                          price={data.final_price}
                          currency={data.Currency}
                          cname={2}
                      />
                      <button
                          id="selectedBtn"
                          className={`carselBtn ${buttonClass}`}
                          onClick={(e) => selectedCar(e, data, ind, passId)}
                      >
                          <span>
                              { data.status === "selected" ? "Selected" : data.status === null ? "Add to quote" : "--"}
                          </span>
                      </button>
                  </div>
              }

            </div>
        </div> 

  )
}
