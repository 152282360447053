import dropDownOPtImg1 from "./Assets/images/chambre1.png"
import dropDownOPtImg2 from "./Assets/images/chambre2.png"
import dropDownOPtImg3 from "./Assets/images/chambre3.png"
import dropDownOPtImg4 from "./Assets/images/chambre4.png"

import dropDownOPtImg11 from "./Assets/images/global-icon/white-accom.png"
import dropDownOPtImg22 from "./Assets/images/global-icon/white-transfer.png"
import dropDownOPtImg33 from "./Assets/images/global-icon/white-excursion.png"
import dropDownOPtImg44 from "./Assets/images/whitechambre4.png"

import transferSuperior from "./Assets/images/static_transfer/superior.png"
import transferClubExecutive from "./Assets/images/static_transfer/club.png"
import transferstandar from "./Assets/images/static_transfer/standar.png"
import transferSuperior2 from "./Assets/images/static_transfer/superior2.png"




//import Booking_summary from "./Quotation_bookings/Booking_summary";
export const DEFAULT_DATE_FORMAT="dd/MM/yyyy";
export const ISO_8601_DATE_FORMAT="yyyy-MM-dd";
export const WEEK_DAYS=["Su","Mo", "Tu", "We", "Th", "Fr", "Sa"];
export const TIMEOUT_DURATION=600000;
export const MAUTOURCO_ID = 407 // test need to change 407

export const BUDGET_RANGE={
    MIN_PRICE:0,
    MAX_PRICE:50000,
}
export const OFFSET =0
export const QUOTATION_PAGE="quotation";
export const BOOKING_PAGE="booking";
export const QUOTATION_TYPE="list"
export const QUOTATION_TYPE2="details"
export const RESERVATION_TYPE="folder"
export const RESERVATION_TYPE2="service"


export const FREE_SALES_STATUS="FREE SALES";
export const ON_REQUEST_STATUS="ON REQUEST";
export const STATUS_BOOKED ="Confirmed";
export const STATUS_PENDING="PENDING";
export const STATUS_REQUEST="Requested";

export const MORE_OPTIONS_MODE={
    ADD_ITEM:"ADD_ITEM",
    REMOVE_ITEM:"REMOVE_ITEM",
    DELETE_QUOTATION:"DELETE_ALL",
    ADD_ITEM_BOOKING:"ADD_ITEM_BOOKING",
    ADD_ITEM_BOOKING_NEW_PAX:"ADD_ITEM_BOOKING_NEW_PAX",
    ADD_ITEM_BOOKING_EXISTING_PAX:"ADD_ITEM_BOOKING_EXISTING_PAX",
}

export const BASE_ROUTE_PATH = 'b2b'; // Dynamic base path

export const ROUTE_PATHS = {
    HOME: `/${BASE_ROUTE_PATH}`,
    // Accomodation
    ACCOMMODATION: `/${BASE_ROUTE_PATH}/accommodation`,
    ACCOMMODATION_SELECT: `/${BASE_ROUTE_PATH}/accommodation-select`,
    SELECTED_ACCOMMODATION: `/${BASE_ROUTE_PATH}/selected-accomodation`,
    // Transfer
    TRANSFER: `/${BASE_ROUTE_PATH}/transfer`,
    SELECT_VEHICLE: `/${BASE_ROUTE_PATH}/select-vehicle`,
    // Excursion
    EXCURSION: `/${BASE_ROUTE_PATH}/excursion`,
    EXCURSION_SELECT: `/${BASE_ROUTE_PATH}/excursion-select`,
    SELECTED_EXCURSION: `/${BASE_ROUTE_PATH}/selected-excursion`,
   // Quotation
    QUOTATION: `/${BASE_ROUTE_PATH}/quotation`,
    VIEW_DETAILS: `/${BASE_ROUTE_PATH}/view-details`,
    SELECTED_VIEW_DETAILS: `/${BASE_ROUTE_PATH}/selected-view-details`,
   // Quotation Inputs
    USER_DETAILS: `/${BASE_ROUTE_PATH}/userdetails`,
    QUOTATION_INPUTS: `/${BASE_ROUTE_PATH}/quotation-inputs`,
    //Error Page
    INTERNAL_ERROR: `/${BASE_ROUTE_PATH}/internal-error`,
  };


export const REFRESH_TOKEN_EVENT="REFRESH_TOKEN_EVENT";
export const REGION_FILTER_NAME = "Region";
export const STAR_RATING_FILTER_NAME = "Starrating";
export const POPULAR_FILTER_NAME = "popular_filters";
export const OFFER_FILTER_NAME = "offers";
export const BUDGET_RANGE_FILTER_NAME = "Price between ";
export const HOTEL_TYPE_FILTER_NAME = "Hotel Type";

export const AIRPORT_LOCATION_NAME="Airport";
export const AIRPORT_SSR_LOCATION_NAME="SSR International Airport";

export const NAVBAR_HEIGHT=78;

export const TRANSFER_VEHICLE_TYPE={
    UNIT:"Trip",
    PAX:"Pax"
}

export const TRANSFER_SUPPLEMENTS=[
    {
        'type':'LuggageTruck',
        'label':'Luggage Truck',
        'icon':"luggage-icon"
    },
    {
        'type':'LuggageCar',
        'label':'Luggage Car',
        'icon':"luggage-icon"
    },
    {
        'type':'baby_seat_count',
        'label':'Baby seat',
        'icon':"baby-seat-icon"
    },
]

export const PAX_VALIDATION_PARMS ={ADDROOMPAX:"addroom",CONFIRMPAX:"confirmpax"}


export const DEFAULT_SERVICES={
    services:{
        reservationAccom:undefined,
        reservationTransfer:undefined,
        reservationExcursion:undefined,
    }
}

export const SELECTED_ACCOM = {
    "IdBooking": 1126540,
    "ToRef": "testcase_01042024_14",
    "Status": "Confirmed",
    "DateFrom": "2024-11-06",
    "DateTo": "2024-11-15",
    "AdultCount": 2,
    "TeenCount": 0,
    "ChildCount": 1,
    "InfantCount": 0,
    "Clients": [
        {
            "client_sname": "SIMONE",
            "client_oname": "FRONGIA",
            "client_status": "Adult"
        },
        {
            "client_sname": "SIMONE",
            "client_oname": "FRONGIA",
            "client_status": "Adult"
        },
        {
            "client_sname": "SIMONE",
            "client_oname": "Loic",
            "client_status": "Child"
        }
    ],
    "ReservationAccom": [
        {
            "HotelName": "SUGAR BEACH RESORT",
            "HotelAmenities": [],
            "Dates":["06/11/2024","12/11/2024"],
            "Images": ["https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s","https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s","https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s"],

            "Total": "4986.00",
            "Currency": "GBP",
            "Description": "Situated on the west coast of Mauritius, Sugar Beach offers unparalleled access to the turquoise waters of the Indian Ocean and a perfect vantage point for breathtaking sunsets over pristine lagoons. This prime location ensures convenience without compromising on exclusivity.  Sugar Beach Mauritius boasts a range of elegantly appointed rooms and suites, each meticulously designed to offer comfort and sophistication. The resort features an array of world-class facilities, including 19 restaurants and bars serving gourmet cuisine inspired by local flavors and international delicacies. Indulge in rejuvenating treatments at the luxurious Aura Spa, or partake in a variety of water sports and recreational activities. Ideal for both leisure and business travelers, Sugar Beach Mauritius offers state-of-the-art conference facilities and event venues. Experience the essence of Mauritius, where every detail is crafted to exceed expectations and every moment is designed to inspire.",
            "TitleDescription": "A Blend of Colonial Elegance and Contemporary Comfort",
            "Rooms": [
                {
                    "RoomName": "DELUXE SEA VIEW ROOM",
                    "RoomAmenities": {
                        "Bathroom": [
                            "Lotion",
                            "Shower gel",
                            "Shampoo",
                            "Hair dryer"
                          ],
                          "Bedroom": [
                            "Bedsheets",
                            "Black-out curtain",
                            "Mini-bar",
                            "Air-conditioning",
                            "Premium mattress"
                          ],
                          "Entertainment": [
                            "TV",
                            "Cable channels",
                            "Free newspaper"
                          ],
                          "Others": [
                            "Daily house keeping",
                            "Electrical adapters/chargers",
                            "Phone"
                          ]
                    },
                    "RoomImage": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s",
                    "AdultCount": 2,
                    "TeenCount": 0,
                    "ChildCount": 2,
                    "InfantCount": 0,
                    "MealPlan": "Half Board",
                    "CancellationPolicies": [
                        {
                            "Description": "Cancellations Policy: United Kingdom\nHigh/ Shoulder High Seasons - Where written notice of cancellation 7 days or less of Guest(s) check in date as per the Reservation (or a no show by the Guest(s) or an early departure or an amendment to the Reservation), a cancellation fee calculated at 100% (hundred percent) of the Reservation Amount shall be payable by the Tour Operator to Sun (no refund will be applicable if payment of the Reservation Amount has been effected;)\n",
                            "Value": 100,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        },
                        {
                            "Description": "Cancellations Policy: United Kingdom\nLow/ Shoulder Low/ Shoulder Seasons - Where written notice of cancellation is 7 (seven) days or less of Guest(s) check in date (or a no show by the Guest(s) or an early departure or\nan amendment to the Reservation), a cancellation fee calculated at 100% (hundred percent) of the Reservation Amount shall be payable by the Tour Operator to Sun (no refund will be applicable if payment of the Reservation Amount has been effected).\n\n",
                            "Value": 100,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        },
                        {
                            "Description": "Cancellations Policy: United Kingdom\nUnexpected departure - 100%",
                            "Value": 100,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        },
                        {
                            "Description": "Cancellations Policy: All Markets except UK\nHigh/ Shoulder High Seasons - no cancelation fees are payable by the Tour Operator (or a full refund of the Reservation Amount will be applicable if payment has been effected by the Tour Operator) provided written notice of cancellation is received by Sun not less than 30 (thirty) days prior to Guest(s) check in date as per the Reservation. Where written notice of Cancellation is less than 30(thirty) days but more than 7 days of Guest check in date as per the Reservation, a cancellation fee calculated at 50% (fifty percent) of the Reservation Amount shall be payable by the Tour Operator to Sun (or a refund equivalent to 50% of the Reservation Amount will be given by Sun if payment has been effected). ",
                            "Value": 50,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        }
                    ]
                }
            ],
           
        }
    ],
    "ReservationTransfer":[],
    "ReservationExcursion":[]
}

export const QUOTE_INPUT_LABEL = ["Enter your flight number for Mauritius", "Please specify the total of pax in the reservation", "General Information"]

export const INPUT_DATA ={type:"text",placeholder:"Input flight number",required:true}

export const SERVICE_TYPE ={
    ACCOMODATION:"reservationAccom",
    TRANSFER:"reservationTransfer",
    EXCURSION:"reservationExcursion",
    OTHERS: "reservationOthers"
}


export const SELECTEDDETAILS =[
                {
                    "HotelName": "SUGAR BEACH RESORT",
                    "HotelAmenities": [
                        {
                        "AmenityIcon": null,
                        "AmenityName": "North"
                      },
                      {
                        "AmenityIcon": null,
                        "AmenityName": "South"
                      },
                      {
                        "AmenityIcon": null,
                        "AmenityName": "North"
                      },
                      {
                        "AmenityIcon": null,
                        "AmenityName": "South"
                      },
                      {
                        "AmenityIcon": null,
                        "AmenityName": "North"
                      },
                      {
                        "AmenityIcon": null,
                        "AmenityName": "South"
                      },
                      {
                        "AmenityIcon": null,
                        "AmenityName": "North"
                      },
                      {
                        "AmenityIcon": null,
                        "AmenityName": "South"
                      },
                    ],
                    "Dates":["06/11/2024","12/11/2024"],
                    "Images": ["https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s","https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s","https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s"],
                    "Total": "4986.00",
                    "Currency": "GBP",
                    "Description": "Situated on the west coast of Mauritius, Sugar Beach offers unparalleled access to the turquoise waters of the Indian Ocean and a perfect vantage point for breathtaking sunsets over pristine lagoons. This prime location ensures convenience without compromising on exclusivity.  Sugar Beach Mauritius boasts a range of elegantly appointed rooms and suites, each meticulously designed to offer comfort and sophistication. The resort features an array of world-class facilities, including 19 restaurants and bars serving gourmet cuisine inspired by local flavors and international delicacies. Indulge in rejuvenating treatments at the luxurious Aura Spa, or partake in a variety of water sports and recreational activities. Ideal for both leisure and business travelers, Sugar Beach Mauritius offers state-of-the-art conference facilities and event venues. Experience the essence of Mauritius, where every detail is crafted to exceed expectations and every moment is designed to inspire.",
                    "TitleDescription": "A Blend of Colonial Elegance and Contemporary Comfort",
                    "Rooms": [
                        {
                            "RoomName": "DELUXE SEA VIEW ROOM",
                            "RoomAmenities": { "Bathroom": [
                                "Lotion",
                                "Shower gel",
                                "Shampoo",
                                "Hair dryer"
                              ],
                              "Bedroom": [
                                "Bedsheets",
                                "Black-out curtain",
                                "Mini-bar",
                                "Air-conditioning",
                                "Premium mattress"
                              ],
                              "Entertainment": [
                                "TV",
                                "Cable channels",
                                "Free newspaper"
                              ],
                              "Others": [
                                "Daily house keeping",
                                "Electrical adapters/chargers",
                                "Phone"
                              ]},
                            "RoomImage": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s",
                            "AdultCount": 2,
                            "TeenCount": 0,
                            "ChildCount": 2,
                            "InfantCount": 0,
                            "MealPlan": "Half Board"
                        },
                        {
                            "RoomName": "SEA VIEW ROOM",
                            "RoomAmenities": { "Bathroom": [
                                "Lotion",
                                "Shower gel",
                                "Shampoo",
                                "Hair dryer"
                              ],
                              "Bedroom": [
                                "Bedsheets",
                                "Black-out curtain",
                                "Mini-bar",
                                "Air-conditioning",
                                "Premium mattress"
                              ],
                              "Entertainment": [
                                "TV",
                                "Cable channels",
                                "Free newspaper"
                              ],
                              "Others": [
                                "Daily house keeping",
                                "Electrical adapters/chargers",
                                "Phone"
                              ]},
                            "RoomImage": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s",
                            "AdultCount": 2,
                            "TeenCount": 0,
                            "ChildCount": 2,
                            "InfantCount": 0,
                            "MealPlan": "Half Board"
                        }
                    ],
                    "CancellationPolicies": [
                        {
                            "Description": "Cancellations Policy: United Kingdom\nHigh/ Shoulder High Seasons - Where written notice of cancellation 7 days or less of Guest(s) check in date as per the Reservation (or a no show by the Guest(s) or an early departure or an amendment to the Reservation), a cancellation fee calculated at 100% (hundred percent) of the Reservation Amount shall be payable by the Tour Operator to Sun (no refund will be applicable if payment of the Reservation Amount has been effected;)\n",
                            "Value": 100,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        },
                        {
                            "Description": "Cancellations Policy: United Kingdom\nLow/ Shoulder Low/ Shoulder Seasons - Where written notice of cancellation is 7 (seven) days or less of Guest(s) check in date (or a no show by the Guest(s) or an early departure or\nan amendment to the Reservation), a cancellation fee calculated at 100% (hundred percent) of the Reservation Amount shall be payable by the Tour Operator to Sun (no refund will be applicable if payment of the Reservation Amount has been effected).\n\n",
                            "Value": 100,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        },
                        {
                            "Description": "Cancellations Policy: United Kingdom\nUnexpected departure - 100%",
                            "Value": 100,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        },
                        {
                            "Description": "Cancellations Policy: All Markets except UK\nHigh/ Shoulder High Seasons - no cancelation fees are payable by the Tour Operator (or a full refund of the Reservation Amount will be applicable if payment has been effected by the Tour Operator) provided written notice of cancellation is received by Sun not less than 30 (thirty) days prior to Guest(s) check in date as per the Reservation. Where written notice of Cancellation is less than 30(thirty) days but more than 7 days of Guest check in date as per the Reservation, a cancellation fee calculated at 50% (fifty percent) of the Reservation Amount shall be payable by the Tour Operator to Sun (or a refund equivalent to 50% of the Reservation Amount will be given by Sun if payment has been effected). ",
                            "Value": 50,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        }
                    ]
                },
                {
                    "HotelName": "LA PIROGUE RESORT",
                    "HotelAmenities": [],
                    "Dates":["06/11/2024","12/11/2024"],
                    "Images": ["https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s","https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s","https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s"],

                    "Total": "4986.00",
                    "Currency": "GBP",
                    "Description": "Situated on the west coast of Mauritius, Sugar Beach offers unparalleled access to the turquoise waters of the Indian Ocean and a perfect vantage point for breathtaking sunsets over pristine lagoons. This prime location ensures convenience without compromising on exclusivity.  Sugar Beach Mauritius boasts a range of elegantly appointed rooms and suites, each meticulously designed to offer comfort and sophistication. The resort features an array of world-class facilities, including 19 restaurants and bars serving gourmet cuisine inspired by local flavors and international delicacies. Indulge in rejuvenating treatments at the luxurious Aura Spa, or partake in a variety of water sports and recreational activities. Ideal for both leisure and business travelers, Sugar Beach Mauritius offers state-of-the-art conference facilities and event venues. Experience the essence of Mauritius, where every detail is crafted to exceed expectations and every moment is designed to inspire.",
                    "TitleDescription": "A Blend of Colonial Elegance and Contemporary Comfort",
                    "Rooms": [
                        {
                            "RoomName": "DELUXE SEA VIEW ROOM",
                            "RoomAmenities": { "Bathroom": [
                                "Lotion",
                                "Shower gel",
                                "Shampoo",
                                "Hair dryer"
                              ],
                              "Bedroom": [
                                "Bedsheets",
                                "Black-out curtain",
                                "Mini-bar",
                                "Air-conditioning",
                                "Premium mattress"
                              ],
                              "Entertainment": [
                                "TV",
                                "Cable channels",
                                "Free newspaper"
                              ],
                              "Others": [
                                "Daily house keeping",
                                "Electrical adapters/chargers",
                                "Phone"
                              ]},
                            "RoomImage": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vHT5sMQfjTGManz-iYbFFhkA3Uv2LdUc7w&s",
                            "AdultCount": 2,
                            "TeenCount": 0,
                            "ChildCount": 2,
                            "InfantCount": 0,
                            "MealPlan": "Half Board"
                        }
                    ],
                    "CancellationPolicies": [
                        {
                            "Description": "Cancellations Policy: United Kingdom\nHigh/ Shoulder High Seasons - Where written notice of cancellation 7 days or less of Guest(s) check in date as per the Reservation (or a no show by the Guest(s) or an early departure or an amendment to the Reservation), a cancellation fee calculated at 100% (hundred percent) of the Reservation Amount shall be payable by the Tour Operator to Sun (no refund will be applicable if payment of the Reservation Amount has been effected;)\n",
                            "Value": 100,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        },
                        {
                            "Description": "Cancellations Policy: United Kingdom\nLow/ Shoulder Low/ Shoulder Seasons - Where written notice of cancellation is 7 (seven) days or less of Guest(s) check in date (or a no show by the Guest(s) or an early departure or\nan amendment to the Reservation), a cancellation fee calculated at 100% (hundred percent) of the Reservation Amount shall be payable by the Tour Operator to Sun (no refund will be applicable if payment of the Reservation Amount has been effected).\n\n",
                            "Value": 100,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        },
                        {
                            "Description": "Cancellations Policy: United Kingdom\nUnexpected departure - 100%",
                            "Value": 100,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        },
                        {
                            "Description": "Cancellations Policy: All Markets except UK\nHigh/ Shoulder High Seasons - no cancelation fees are payable by the Tour Operator (or a full refund of the Reservation Amount will be applicable if payment has been effected by the Tour Operator) provided written notice of cancellation is received by Sun not less than 30 (thirty) days prior to Guest(s) check in date as per the Reservation. Where written notice of Cancellation is less than 30(thirty) days but more than 7 days of Guest check in date as per the Reservation, a cancellation fee calculated at 50% (fifty percent) of the Reservation Amount shall be payable by the Tour Operator to Sun (or a refund equivalent to 50% of the Reservation Amount will be given by Sun if payment has been effected). ",
                            "Value": 50,
                            "ValidFrom": "2024-11-01",
                            "ValidTo": "2025-10-31"
                        }
                    ]
                }
                
]
                
export const REGION_DETAIL =[ 
    [
        {
            "TransferTariffId": 907670,
            "TransferCategoryID": 1,
            "VehicleTypeID": 3,
            "CategoryID": 1,
            "Image": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/prestige_car.jpg",
            "Category": "PRESTIGE",
            "VehicleType": "CAR",
            "Region": "Other-Coast",
            "Currency": "MUR",
            "TariffValidFrom": "2024-11-01",
            "TariffValidTo": "2026-10-31",
            "MinPax": 1,
            "MaxPax": 2,
            "AdultPrice": 13100.0,
            "ChildPrice": 0.0,
            "TeenPrice": 0.0,
            "InfantPrice": 0,
            "TransferType": "Trip",
            "MaxLuggage": 0.0,
            "MaxAllowedBabySeat": 0,
            "HandlingFee": "No",
            "HandlingType": "Not Applicable",
            "HandlingCurrency": "",
            "HandlingFeeAdult": 0.0,
            "HandlingFeeChild": 0.0,
            "HandlingFeeDetails": null,
            "Pic_url": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/prestige_car.jpg",
            "BabySeatPrice": 0,
            "BabySeatTax": "Taxable",
            "BabySeatTaxFlag": "Y",
            "BabySeatTariffType": "Per Unit",
            "BabySeatService": "BABY / CHILD / BOOSTER SEAT",
            "BabySeatServiceID": 246,
            "BabySeatServiceDetails": "BABY/CHILD/BOOSTER - TOURS",
            "BabySeatServiceDetailsID": 5654,
            "BabySeatIdClaim": 26599,
            "AirConditionning": true
        },
        {
            "TransferTariffId": 907629,
            "TransferCategoryID": 2,
            "VehicleTypeID": 4,
            "CategoryID": 2,
            "Image": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/minibus.jpg",
            "Category": "STANDARD",
            "VehicleType": "MINIBUS",
            "Region": "Other-Coast",
            "Currency": "MUR",
            "TariffValidFrom": "2024-11-01",
            "TariffValidTo": "2026-10-31",
            "MinPax": 1,
            "MaxPax": 100,
            "AdultPrice": 1650.0,
            "ChildPrice": 825.0,
            "TeenPrice": 0.0,
            "InfantPrice": 0,
            "TransferType": "Pax",
            "MaxLuggage": 0.0,
            "MaxAllowedBabySeat": 0,
            "HandlingFee": "No",
            "HandlingType": "Not Applicable",
            "HandlingCurrency": "",
            "HandlingFeeAdult": 0.0,
            "HandlingFeeChild": 0.0,
            "HandlingFeeDetails": null,
            "Pic_url": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/minibus.jpg",
            "BabySeatPrice": 0,
            "BabySeatTax": "Taxable",
            "BabySeatTaxFlag": "Y",
            "BabySeatTariffType": "Per Unit",
            "BabySeatService": "BABY / CHILD / BOOSTER SEAT",
            "BabySeatServiceID": 246,
            "BabySeatServiceDetails": "BABY/CHILD/BOOSTER - TOURS",
            "BabySeatServiceDetailsID": 5654,
            "BabySeatIdClaim": 26599,
            "AirConditionning": true
        },
        {
            "TransferTariffId": 932125,
            "TransferCategoryID": 4,
            "VehicleTypeID": 5,
            "CategoryID": 4,
            "Image": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/helico.jpeg",
            "Category": "HELICO",
            "VehicleType": "HELICO",
            "Region": "Other-Coast",
            "Currency": "MUR",
            "TariffValidFrom": "2024-11-01",
            "TariffValidTo": "2025-10-31",
            "MinPax": 1,
            "MaxPax": 2,
            "AdultPrice": 65000.0,
            "ChildPrice": 0.0,
            "TeenPrice": 0.0,
            "InfantPrice": 0,
            "TransferType": "Trip",
            "MaxLuggage": 0.0,
            "MaxAllowedBabySeat": 0,
            "HandlingFee": "No",
            "HandlingType": "Not Applicable",
            "HandlingCurrency": "",
            "HandlingFeeAdult": 0.0,
            "HandlingFeeChild": 0.0,
            "HandlingFeeDetails": null,
            "Pic_url": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/helico.jpeg",
            "BabySeatPrice": 0,
            "BabySeatTax": "Taxable",
            "BabySeatTaxFlag": "Y",
            "BabySeatTariffType": "Per Unit",
            "BabySeatService": "BABY / CHILD / BOOSTER SEAT",
            "BabySeatServiceID": 246,
            "BabySeatServiceDetails": "BABY/CHILD/BOOSTER - TOURS",
            "BabySeatServiceDetailsID": 5654,
            "BabySeatIdClaim": 26599,
            "AirConditionning": true
        },
       
        
        {
            "TransferTariffId": 920686,
            "TransferCategoryID": 10,
            "VehicleTypeID": 4,
            "CategoryID": 10,
            "Image": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/minibus.jpg",
            "Category": "PRIVATE",
            "VehicleType": "MINIBUS",
            "Region": "Other-Coast",
            "Currency": "MUR",
            "TariffValidFrom": "2024-11-01",
            "TariffValidTo": "2026-10-31",
            "MinPax": 1,
            "MaxPax": 8,
            "AdultPrice": 4600.0,
            "ChildPrice": 0.0,
            "TeenPrice": 0.0,
            "InfantPrice": 0,
            "TransferType": "Trip",
            "MaxLuggage": 0.0,
            "MaxAllowedBabySeat": 0,
            "HandlingFee": "No",
            "HandlingType": "Not Applicable",
            "HandlingCurrency": "",
            "HandlingFeeAdult": 0.0,
            "HandlingFeeChild": 0.0,
            "HandlingFeeDetails": null,
            "Pic_url": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/minibus.jpg",
            "BabySeatPrice": 0,
            "BabySeatTax": "Taxable",
            "BabySeatTaxFlag": "Y",
            "BabySeatTariffType": "Per Unit",
            "BabySeatService": "BABY / CHILD / BOOSTER SEAT",
            "BabySeatServiceID": 246,
            "BabySeatServiceDetails": "BABY/CHILD/BOOSTER - TOURS",
            "BabySeatServiceDetailsID": 5654,
            "BabySeatIdClaim": 26599,
            "AirConditionning": true
        },
        {
            "TransferTariffId": 933184,
            "TransferCategoryID": 10,
            "VehicleTypeID": 8,
            "CategoryID": 10,
            "Image": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/coach.jpg",
            "Category": "PRIVATE",
            "VehicleType": "COACH",
            "Region": "Other-Coast",
            "Currency": "MUR",
            "TariffValidFrom": "2024-11-01",
            "TariffValidTo": "2026-10-31",
            "MinPax": 1,
            "MaxPax": 12,
            "AdultPrice": 8844.0,
            "ChildPrice": 0.0,
            "TeenPrice": 0.0,
            "InfantPrice": 0,
            "TransferType": "Trip",
            "MaxLuggage": 0.0,
            "MaxAllowedBabySeat": 0,
            "HandlingFee": "No",
            "HandlingType": "Not Applicable",
            "HandlingCurrency": "",
            "HandlingFeeAdult": 0.0,
            "HandlingFeeChild": 0.0,
            "HandlingFeeDetails": null,
            "Pic_url": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/coach.jpg",
            "BabySeatPrice": 0,
            "BabySeatTax": "Taxable",
            "BabySeatTaxFlag": "Y",
            "BabySeatTariffType": "Per Unit",
            "BabySeatService": "BABY / CHILD / BOOSTER SEAT",
            "BabySeatServiceID": 246,
            "BabySeatServiceDetails": "BABY/CHILD/BOOSTER - TOURS",
            "BabySeatServiceDetailsID": 5654,
            "BabySeatIdClaim": 26599,
            "AirConditionning": true
        },
        {
            "TransferTariffId": 907638,
            "TransferCategoryID": 12,
            "VehicleTypeID": 3,
            "CategoryID": 12,
            "Image": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/superior_car.jpg",
            "Category": "SUPERIOR",
            "VehicleType": "CAR",
            "Region": "Other-Coast",
            "Currency": "MUR",
            "TariffValidFrom": "2024-11-01",
            "TariffValidTo": "2026-10-31",
            "MinPax": 1,
            "MaxPax": 3,
            "AdultPrice": 3270.0,
            "ChildPrice": 0.0,
            "TeenPrice": 0.0,
            "InfantPrice": 0,
            "TransferType": "Trip",
            "MaxLuggage": 0.0,
            "MaxAllowedBabySeat": 2,
            "HandlingFee": "No",
            "HandlingType": "Not Applicable",
            "HandlingCurrency": "",
            "HandlingFeeAdult": 0.0,
            "HandlingFeeChild": 0.0,
            "HandlingFeeDetails": null,
            "Pic_url": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/superior_car.jpg",
            "BabySeatPrice": 250,
            "BabySeatTax": "Taxable",
            "BabySeatTaxFlag": "Y",
            "BabySeatTariffType": "Per Unit",
            "BabySeatService": "BABY / CHILD / BOOSTER SEAT",
            "BabySeatServiceID": 246,
            "BabySeatServiceDetails": "BABY/CHILD/BOOSTER - TOURS",
            "BabySeatServiceDetailsID": 5654,
            "BabySeatIdClaim": 26599,
            "AirConditionning": true
        },
        {
            "TransferTariffId": 907649,
            "TransferCategoryID": 14,
            "VehicleTypeID": 3,
            "CategoryID": 14,
            "Image": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/club_executive.jpg",
            "Category": "CLUB EXECUTIVE",
            "VehicleType": "CAR",
            "Region": "Other-Coast",
            "Currency": "MUR",
            "TariffValidFrom": "2024-11-01",
            "TariffValidTo": "2026-10-31",
            "MinPax": 1,
            "MaxPax": 4,
            "AdultPrice": 5400.0,
            "ChildPrice": 0.0,
            "TeenPrice": 0.0,
            "InfantPrice": 0,
            "TransferType": "Trip",
            "MaxLuggage": 0.0,
            "MaxAllowedBabySeat": 2,
            "HandlingFee": "No",
            "HandlingType": "Not Applicable",
            "HandlingCurrency": "",
            "HandlingFeeAdult": 0.0,
            "HandlingFeeChild": 0.0,
            "HandlingFeeDetails": null,
            "Pic_url": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/club_executive.jpg",
            "BabySeatPrice": 250,
            "BabySeatTax": "Taxable",
            "BabySeatTaxFlag": "Y",
            "BabySeatTariffType": "Per Unit",
            "BabySeatService": "BABY / CHILD / BOOSTER SEAT",
            "BabySeatServiceID": 246,
            "BabySeatServiceDetails": "BABY/CHILD/BOOSTER - TOURS",
            "BabySeatServiceDetailsID": 5654,
            "BabySeatIdClaim": 26599,
            "AirConditionning": true
        },
        {
            "TransferTariffId": 907698,
            "TransferCategoryID": 15,
            "VehicleTypeID": 4,
            "CategoryID": 15,
            "Image": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/minibus.jpg",
            "Category": "CLUB FAMILY",
            "VehicleType": "MINIBUS",
            "Region": "Other-Coast",
            "Currency": "MUR",
            "TariffValidFrom": "2024-11-01",
            "TariffValidTo": "2026-10-31",
            "MinPax": 1,
            "MaxPax": 6,
            "AdultPrice": 4800.0,
            "ChildPrice": 0.0,
            "TeenPrice": 0.0,
            "InfantPrice": 0,
            "TransferType": "Trip",
            "MaxLuggage": 0.0,
            "MaxAllowedBabySeat": 3,
            "HandlingFee": "No",
            "HandlingType": "Not Applicable",
            "HandlingCurrency": "",
            "HandlingFeeAdult": 0.0,
            "HandlingFeeChild": 0.0,
            "HandlingFeeDetails": null,
            "Pic_url": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/minibus.jpg",
            "BabySeatPrice": 250,
            "BabySeatTax": "Taxable",
            "BabySeatTaxFlag": "Y",
            "BabySeatTariffType": "Per Unit",
            "BabySeatService": "BABY / CHILD / BOOSTER SEAT",
            "BabySeatServiceID": 246,
            "BabySeatServiceDetails": "BABY/CHILD/BOOSTER - TOURS",
            "BabySeatServiceDetailsID": 5654,
            "BabySeatIdClaim": 26599,
            "AirConditionning": true
        },
        {
            "TransferTariffId": 907680,
            "TransferCategoryID": 18,
            "VehicleTypeID": 3,
            "CategoryID": 18,
            "Image": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/premium_car.jpg",
            "Category": "PREMIUM",
            "VehicleType": "CAR",
            "Region": "Other-Coast",
            "Currency": "MUR",
            "TariffValidFrom": "2024-11-01",
            "TariffValidTo": "2026-10-31",
            "MinPax": 1,
            "MaxPax": 2,
            "AdultPrice": 18100.0,
            "ChildPrice": 0.0,
            "TeenPrice": 0.0,
            "InfantPrice": 0,
            "TransferType": "Trip",
            "MaxLuggage": 0.0,
            "MaxAllowedBabySeat": 0,
            "HandlingFee": "No",
            "HandlingType": "Not Applicable",
            "HandlingCurrency": "",
            "HandlingFeeAdult": 0.0,
            "HandlingFeeChild": 0.0,
            "HandlingFeeDetails": null,
            "Pic_url": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/premium_car.jpg",
            "BabySeatPrice": 0,
            "BabySeatTax": "Taxable",
            "BabySeatTaxFlag": "Y",
            "BabySeatTariffType": "Per Unit",
            "BabySeatService": "BABY / CHILD / BOOSTER SEAT",
            "BabySeatServiceID": 246,
            "BabySeatServiceDetails": "BABY/CHILD/BOOSTER - TOURS",
            "BabySeatServiceDetailsID": 5654,
            "BabySeatIdClaim": 26599,
            "AirConditionning": true
        },
        {
            "TransferTariffId": 907708,
            "TransferCategoryID": 19,
            "VehicleTypeID": 4,
            "CategoryID": 19,
            "Image": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/superior_family_minibus.JPG",
            "Category": "SUPERIOR FAMILY",
            "VehicleType": "MINIBUS",
            "Region": "Other-Coast",
            "Currency": "MUR",
            "TariffValidFrom": "2024-11-01",
            "TariffValidTo": "2026-10-31",
            "MinPax": 1,
            "MaxPax": 5,
            "AdultPrice": 7280.0,
            "ChildPrice": 0.0,
            "TeenPrice": 0.0,
            "InfantPrice": 0,
            "TransferType": "Trip",
            "MaxLuggage": 0.0,
            "MaxAllowedBabySeat": 3,
            "HandlingFee": "No",
            "HandlingType": "Not Applicable",
            "HandlingCurrency": "",
            "HandlingFeeAdult": 0.0,
            "HandlingFeeChild": 0.0,
            "HandlingFeeDetails": null,
            "Pic_url": "https://mautourcoassets.s3.eu-west-2.amazonaws.com/transfer_images/superior_family_minibus.JPG",
            "BabySeatPrice": 250,
            "BabySeatTax": "Taxable",
            "BabySeatTaxFlag": "Y",
            "BabySeatTariffType": "Per Unit",
            "BabySeatService": "BABY / CHILD / BOOSTER SEAT",
            "BabySeatServiceID": 246,
            "BabySeatServiceDetails": "BABY/CHILD/BOOSTER - TOURS",
            "BabySeatServiceDetailsID": 5654,
            "BabySeatIdClaim": 26599,
            "AirConditionning": true
        }
    ]
    ]

export const FILTER_ORDER = [
    REGION_FILTER_NAME,
    OFFER_FILTER_NAME,
    "Hotel Amenities",
    "Meal Plan",
    HOTEL_TYPE_FILTER_NAME,
    "Accessibilities",
    "Room Amenities",
    "Activities",
]

export const EXCURSION_FILTER_TYPES =["category","TYPE"]

export const FILTER_RESUME_ORDER = [REGION_FILTER_NAME, STAR_RATING_FILTER_NAME, HOTEL_TYPE_FILTER_NAME, OFFER_FILTER_NAME]

 export const GRID_INPUTS = {
    style_input1:{display:"grid",gridTemplateColumns:"170px 170px",gap:"13px"},
    style_input2:{display:"grid",gridTemplateColumns:"362px 241px 131px",gap:"21px"},
    pageNo:2
}

export const MEAL_PLANS=[
    "Bed Only",
    "Bed & Breakfast",
    "Half Board",
    "Full Board",
    "All Inclusive",
]

export const PAX_TYPE_ARRAY=["adult","teen","child","infant"];

export const MEAL_PLAN_STATUS_NON_AVAILABLE="non available";

export const TRANSFER_INPUT_OBJECT = {
    IdTransfer: 1,
    TransferDate: "",
    LocationFromName: "",
    LocationFromDetails: "",
    LocationToName: "",
    LocationToDetails: "",
    TotalPrice: 0,
    // Destination_region: "",
    // Pickup_region: "",
    Car_selected: false, 
    TransferTime:"00:00:00",
    Remarks: "",
    Flight: "",
    Currency:null,
    Pic_url:null,
    TransferType:null,
    AdultCount:1,
    TeenCount:0,
    ChildCount:0,
    InfantCount:0,
    IsSearched:false,
}

export const MAURITIUS_BOUNDS=[
    {
        lat:-20.533970,
        lng:57.221225,
    },
    {
        lat:-19.800481,
        lng: 57.862552
    }
]

export const NAVBAR_ITEMS = [
    {id:1,name:"Home",navPage:ROUTE_PATHS.ACCOMMODATION },
    {id:2,name:"Quotation & Booking",navPage:ROUTE_PATHS.QUOTATION },
    {id:3,name:"Payment",navPage:"" },
    {id:4,name:"News",navPage:"" },
    {id:5,name:"Messages",navPage:"" }
]

export const AccomInitialdata = [{
    IdTransfer: 1,
    TransferDate: "",
    LocationFromName: "",
    LocationFromDetails: "",
}]

export const DROPDOWN_DATA = [
    { id:1,label: "Accommodation", imagesrc: dropDownOPtImg1, imagesrc2: dropDownOPtImg11, imagesrc3: "wchambre1",path:ROUTE_PATHS.ACCOMMODATION,subpage:[ROUTE_PATHS.ACCOMMODATION_SELECT,ROUTE_PATHS.SELECTED_ACCOMMODATION]  },
    { id:2,label: "Transfer", imagesrc: dropDownOPtImg2, imagesrc2: dropDownOPtImg22,imagesrc3: "wchambre2",path:ROUTE_PATHS.TRANSFER,subpage:[ROUTE_PATHS.SELECT_VEHICLE] },
    // { id:3,label: "Excursion", imagesrc: dropDownOPtImg3, imagesrc2: dropDownOPtImg33,imagesrc3: "wchambre3",path:ROUTE_PATHS.EXCURSION,subpage:[ROUTE_PATHS.EXCURSION_SELECT,ROUTE_PATHS.SELECTED_EXCURSION] },
    // { id:4,label: "Other Services", imagesrc: dropDownOPtImg4, imagesrc2:dropDownOPtImg44,imagesrc3: "wchambre4",path:ROUTE_PATHS.ACCOMMODATION,subpage:[ROUTE_PATHS.ACCOMMODATION_SELECT] },
];
export const ROOMPAXDATA = {"adult":1,"teen":0,"child":0,"infant":0,"room_id":1,"paxAge":[],"clientType":["Standard","STD"]}

export const bookings ={
    "quotationHeader":[
        {label:"File nb"},
        {label:"Client name"},
        {label:"File Status"},
        {label:"Type"},
        {label:"From",className:"date-cell"},
        {label:""},
        {label:"To",className:"date-cell"},
        {label:"Nb of Services"},
        {label:"Created by"},
        {label:""}],
    "quotationData" :[
        {file_nb:"192de605-d050-4fbf-8eec-582d3a156c63",file_name:"Jean-Luc Emile",status:"On Request",type:"HMN",date:["26/01/2024","29/01/2024"],pax_details: {"adult": 1,"child": 0,"infant": 0,"teen": 0},created_by:"Taziana Pedron",agency_name: "Evolution Travel",created_date:"10/02/23"} ,                                                    
        {file_nb:"22b7eddd-765f-4920-91b4-7e19d12d7377",file_name:"William Thomas",status:"Free sales",type:"STD",date:["27/01/2024","28/01/2024"],pax_details: {"adult": 1,"child": 0,"infant": 0,"teen": 0},created_by:"Taziana Pedron",agency_name: "Evolution Travel",created_date:"10/02/23"} ,                                                    
        {file_nb:"2ac09a32-5703-45db-b6e6-6d2d14b6ff8d",file_name:"Jean-Luc Emile",status:"Pay to Book",type:"HMN",date:["28/01/2024","29/01/2024"],pax_details: {"adult": 1,"child": 0,"infant": 0,"teen": 0},created_by:"Taziana Pedron",agency_name: "Evolution Travel",created_date:"10/02/23"} ,                                                    
    ],
}

export const CURRENCIES={
    "GBP":{
        unicode:"&#163;",
    },
    "MUR":{
        unicode:"&#8360;"
    },
    "EUR":{
        unicode:"&#8364;",
    },
    "USD":{
        unicode:"&#36;",
    }
}

export const FILE_STATUS = ["Free sales","On request","Pay to book"]

export const CLIENTDATA = [
    {id:1,name:"Standard",select:true,value:"STD"},
    {id:2,name:"Honeymoon",select:false,value:"HMN"},
    {id:3,name:"Wedding",select:false,value:"VIP"}
]
export const SORT_DATES=[
    {id:1,name:"Newest to oldest",value:"NTO"},
    {id:2,name:"Last week",value:"LW"},
    {id:3,name:"Oldest to newest",value:"OTN"}
]
   
export const rows = {"10":[{id:1,name:"15"},{id:2,name:"20"},{id:3,name:"25"}]}

export const pdf_format = {"Export options":[{id:1,name:"PDF"},{id:2,name:"EXCEL"},{id:3,name:"CSV"},{id:3,name:"XML"}]}

export const bookings_summary = [
    {id:1,name:"Transfer",LocationFromName:"Airport",LocationToName:"harbour",pax:{name:"Adult",count:3},carType:"Standard",date:"11/12/2023",imgurl:""},
    {id:2,name:"Transfer",LocationFromName:"Airport",LocationToName:"harbour",pax:{name:"Adult",count:3},carType:"Standard",date:"11/12/2023",imgurl:""},

]

export const footerData = {
    "Site_structure":[{id:1,name:"Home"},{id:2,name:"Quotation & Booking"},{id:3,name:"Payment"},{id:4,name:"News"},{id:5,name:"Message"},{id:6,name:"Profile settings"}],
    "Policies":[{id:1,name:"Terms of use"},{id:2,name:"Privacy policies"}],
    "Contact_us":[{id:1,name:"84, Gustave Colin Street,Forest Side 74514, Mauritius"},{id:2,name:"Tel: (+230) 604 3000"},{id:3,name:"Fax:  (+230) 674 3720"},{id:4,name:"Email: ",value:"info@mautourco.com"}],
    "Socials":[{id:1,name:"facebookLogo"},{id:2,name:"indeedLogo"},{id:3,name:"youtubeLogo"},{id:4,name:"twitterLogo"}]
}

export function getOrdinal(n) {
    let ord = 'th';

    if (n % 10 == 1 && n % 100 != 11)
    {
      ord = 'st';
    }
    else if (n % 10 == 2 && n % 100 != 12)
    {
      ord = 'nd';
    }
    else if (n % 10 == 3 && n % 100 != 13)
    {
      ord = 'rd';
    }
  
    return ord;
}

export const INTERSECTION_OBSERVER_STEPS_FOOTER=Array.from({ length: 101 }, (_, i) => i / 100);

//static
export let filterarray = {
    excursion_type:[
        {name:"sea",id:1,label:"Sea",type:"type_excursion"},
        {name:"land",id:2,label:"Land",type:"type_excursion"},
        {name:"good_deal",id:3,label:"Good deal",type:"type_excursion"},
        {name:"tailor_made",id:4,label:"Tailor made",type:"type_excursion"},
    ],
    excursion_category:[
        {id:1,name:"Boat",type:"category"},
        {id:2,name:"Treck",type:"category"},
        {id:3,name:"Wild-life",type:"category"},
        {id:4,name:"Cultural",type:"category"},
        {id:5,name:"Water sport",type:"category"},
        {id:6,name:"Motor sport",type:"category"},
        {id:7,name:"Golf",type:"category"},
    ],
    meal:[
        {name:"included",id:1,label:"Included",type:"meals"},
        {name:"not-included",id:2,label:"Not-included",type:"meals"}
    ],
    accessibility:[
        {id:1,label:"Easy",name:"easy",type:"accessibility"},
        {id:2,label:"Medium",name:"medium",type:"accessibility"},
        {id:3,label:"Hard",name:"hard",type:"accessibility"}]
}

// static
export let additionalFilter = {
    "meal":["Included","Not-included"],
    "accessibility":["Easy","Medium","Hard"]
}

//static
export let additionalhotel = 
    {
        "accessibility": "Medium",
        "category": [
            "Urban",
            "Nature",
            "Wild-life"
        ],
        "currency": "MUR",
        "desc": "Explore Mauritius's lush inland beauty. Visit Grand Bassin, a sacred lake, and marvel at the landscapes of Black River Gorges National Park.\r\nHead to Chamarel for a 300-foot waterfall and the Seven-Coloured Earth geological phenomenon.\r\nConclude at Rhumerie de Chamarel for a guided distillery tour and rum tasting an immersive journey through nature, spirituality, and local craftsmanship in the heart of Mauritius.",
        "duration": "08 hrs",
        "img": "https://mautourcoassets.s3.amazonaws.com/excursion_images/Wild%20South/Black_River_Gorges.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3FLDZHFNR2QTDS73%2F20240408%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240408T100928Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=d307dc109c85719c1ba4f6f4a89299bb21a26a221832946591f92406197fbed5",
        "lang": [
            "English"
        ],
        "meal": "Not-included",
        "name": "Wild South",
        "pois": [],
        "price": 9900,
        "routes": [
            {
                "id": 1,
                "latitude": "-20.418200",
                "location_name": "Grand Bassin Temple",
                "longitude": "57.491710",
                "name": "Grand Bassin"
            },
            {
                "id": 2,
                "latitude": "-20.424500",
                "location_name": "Black River Gorges",
                "longitude": "57.450470",
                "name": "Black River"
            },
            {
                "id": 3,
                "latitude": "-20.442950",
                "location_name": "Colored Earth",
                "longitude": "57.385730",
                "name": "Chamarel"
            },
            {
                "id": 4,
                "latitude": "-20.439860",
                "location_name": "Water False",
                "longitude": "57.373160",
                "name": "Chamarel"
            }
        ],
        "service_details_id": 9532,
        "service_id": 1618,
        "tour_id": 2,
        "type": "Sea"
}


export const  HOTEL_RATING_ACCOM = {
    overall_rating:4.5,
    reviews:467,
    title:"#1 of 3 hotels in Blue Bay",
    name:"Excellent",
    subratings:[
        {id:1,name:"Location",rating:4.5},
        {id:2,name:"Cleanliness",rating:4},
        {id:3,name:"Personnel",rating:4.5},
        {id:4,name:"Food",rating:4.5},
    ]
}

export const FEEDBACK =[
    {
        id:1,
        name:"Brandon Seville",
        pic_url:"",
        place:"Calais, France",
        comments:"Our stay was nothing short of amazing. The beautiful colonial-style architecture, immaculate beach, and lush gardens created an ideal setting for relaxation. The spacious rooms with stunning ocean views were perfectly appointed, and the diverse dining options were consistently delightful with top-notch service. With activities ranging from water sports to tranquil spa treatments, there was always something to enjoy. The staff's warm hospitality and commitment to guest satisfaction made our vacation truly special. We're already looking forward to our next visit to this paradise retreat!"
    },
    {
        id:2,
        name:"Emily Lewis",
        pic_url:"",
        place:"New York, USA",
        comments:"We had an incredible experience during our stay. The resort’s colonial charm, flawless beach, and vibrant gardens provided a picturesque environment for a perfect getaway. Our room was spacious and offered breathtaking ocean views, while the dining experience was a gourmet adventure with exceptional service. From thrilling water sports to peaceful spa sessions, the resort offered a variety of activities that kept us engaged. The staff’s friendliness and dedication to making our stay memorable truly stood out. We can’t wait to come back to this slice of paradise!"
    },
    {
        id:3,
        name:"Sarah Martinez",
        pic_url:"",
        place:"Sydney, Australia",
        comments:"Our vacation was unforgettable. The elegant colonial-style buildings, pristine beach, and lush, well-kept gardens offered a serene atmosphere for relaxation. Our room was spacious and provided stunning ocean views, and every meal we had was a delightful experience, thanks to the excellent dining options and attentive service. The resort offered a wide range of activities, from exciting water sports to relaxing spa treatments, ensuring there was never a dull moment. The staff’s exceptional hospitality and commitment to our comfort made our stay truly wonderful. We’re eager to return to this idyllic getaway!"
    }

]

// static accomodation
export const HOTEL_DETAILS_0PTIONS={
    LABEL:"Hotel details",
    RANK:1
}
export let accomHeading =["Rooms",HOTEL_DETAILS_0PTIONS.LABEL,"Region"]
export let amenitiesDetails=[

	{
		id:1,
		name:"Bars & Restaurants",
        pattern:"lrlrr",
        icon:"../../Assets/images/accomodation/icons/restaurant.png",
		elements:[
			{
                title:"Beach Rouge",
                img:"../../Assets/images/dummy-accom/amen1.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"BISOU / Rooftop Bar & Restaurant",
                img:"../../Assets/images/dummy-accom/amen2.png",      
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"MAISON LUX*",
                img:"../../Assets/images/dummy-accom/amen3.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"Ai KISU",
                img:"../../Assets/images/dummy-accom/amen4.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"Culinary Lab",
                img:"../../Assets/images/dummy-accom/amen5.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"ICI",
                img:"../../Assets/images/dummy-accom/amen6.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			
            
		]
	},
	{
		id:2,
		name:"Wellness and SPA",
        pattern:"llrrll",
        icon:"../../Assets/images/accomodation/icons/lotus.png",
		elements:[
			{
                title:"Beach Rouge",
                img:"../../Assets/images/dummy-accom/amen1.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"BISOU / Rooftop Bar & Restaurant",
                img:"../../Assets/images/dummy-accom/amen2.png",      
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"MAISON LUX*",
                img:"../../Assets/images/dummy-accom/amen3.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"Ai KISU",
                img:"../../Assets/images/dummy-accom/amen4.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"Culinary Lab",
                img:"../../Assets/images/dummy-accom/amen5.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"ICI",
                img:"../../Assets/images/dummy-accom/amen6.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			
            
		]
	},
	{
		id:3,
		name:"Golf",
        pattern:"rlrll",
        icon:"../../Assets/images/accomodation/icons/golf.png",
		elements:[
			{
                title:"Beach Rouge",
                img:"../../Assets/images/dummy-accom/amen1.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"BISOU / Rooftop Bar & Restaurant",
                img:"../../Assets/images/dummy-accom/amen2.png",      
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"MAISON LUX*",
                img:"../../Assets/images/dummy-accom/amen3.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"Ai KISU",
                img:"../../Assets/images/dummy-accom/amen4.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"Culinary Lab",
                img:"../../Assets/images/dummy-accom/amen5.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"ICI",
                img:"../../Assets/images/dummy-accom/amen6.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			
            
		]
	},
	{
		id:4,
		name:"Kids & Family",
        pattern:"lrlrr",
        icon:"../../Assets/images/accomodation/icons/kid-family.png",
		elements:[
			{
                title:"Beach Rouge",
                img:"../../Assets/images/dummy-accom/amen1.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"BISOU / Rooftop Bar & Restaurant",
                img:"../../Assets/images/dummy-accom/amen2.png",      
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"MAISON LUX*",
                img:"../../Assets/images/dummy-accom/amen3.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"Ai KISU",
                img:"../../Assets/images/dummy-accom/amen4.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"Culinary Lab",
                img:"../../Assets/images/dummy-accom/amen5.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"ICI",
                img:"../../Assets/images/dummy-accom/amen6.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			
            
		]
	},
	{
		id:5,
		name:"Activities",
        icon:"../../Assets/images/accomodation/icons/kite-surf.png",
		elements:[
			{
                title:"Beach Rouge",
                img:"../../Assets/images/dummy-accom/amen1.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"BISOU / Rooftop Bar & Restaurant",
                img:"../../Assets/images/dummy-accom/amen2.png",      
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"MAISON LUX*",
                img:"../../Assets/images/dummy-accom/amen3.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"Ai KISU",
                img:"../../Assets/images/dummy-accom/amen4.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"Culinary Lab",
                img:"../../Assets/images/dummy-accom/amen5.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },
			{
                title:"ICI",
                img:"../../Assets/images/dummy-accom/amen6.png",
                desc:"Serving as both a beach club and restaurant, Beach Rouge evolves and transforms throughout the day. For the first time, Beach Rouge is the main restaurant, open from morning till evening. As the sun sets, the restaurant lights up in an electric red color, which is the origin of its name."
            },            
		]
	},
    {
		id:6,
		name:"Others",
        icon:"../../Assets/images/accomodation/icons/others.png",
        elements:[
            {
                img:null,
                desc:null,
                content:["Pool 1", "Pool 2", "Pool 3", "24/7 Reception", "Babysitting Services", "Laundry Facility","Handicap Ramp","Laundry/Dry Cleaning Service","Karaoke"  ]
            }
          ]
	},
	

]

export let accomRegion = [
    {
        id:1,
        img:"../../Assets/images/dummyImg1.png",
        name:"Wild south",
        title:"A popular seaside resort", 
        text1:"You can do everything in Grand Baie, relax in the sun on a deckchair or practice water sports. During the day, there is something for everyone! You can let yourself be tempted by the many boutiques for shopping on Sunset Boulevard where you will find all the big fashionable brands. Grand Baie is also a small, very famous marina because its anchorage is exceptional and well sheltered thanks to the bay and you will find many options for practicing all kinds of water sports.", 
        text2:"Grand Baie is full of activities for all tastes. Discovering the region’s beaches is a must. If the public beach of Grand Baie is not the largest, it is the most lively and splendid. The more peaceful Pereybère beach will appeal to lovers of relaxation with its fine sand and clear waters, while La Cuvette beach, made of sand and rocks, is ideal for practicing water sports.",
        ques:"What activities to do in Grand Baie?",
        
    }
]



export const getBackgroundColor = (status) => {
    
    switch (status) {
      case FREE_SALES_STATUS:
        return '#5E7C5C'; // Set background color to yellow for pending status
      case 'ON REQUEST':
        return '#DBAB00'; // Set background color to green for approved status
      case 'STOP SALES':
        return '#F66666'; // Set background color to red for rejected status
      case 'Unavailable':
        return '#F66666';  
      default:
        return 'white'; // Default background color
    }
}

export const Errorroomtxt = (status) => {
    switch (status) {
      case 'one':
        return 'This room is on stop sale'; 
      case 'two':
        return 'Please adjust the number of pax to match the room’s occupancy'; 
    //   case 'three':
    //     return '#F66666'; 
      default:
        return ''; // Default background color
    }
}

export const generateDateArray = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
};

export const splitDescription =(data) =>{
        let lines =  data.split(/\r?\n/)
        const description = lines.map((data,id)=>(
            <div key={id}>{data}</div>
          ))
        return description  
}

export const MAP_OPTIONS = {
    mapTypeControl:false,
    fullscreenControl: false,
    streetViewControl:false,
    scrollwheel: true,
    styles: [
      {
        featureType: 'all',
        elementType: 'labels',
        stylers: [
          { visibility: 'on' } // Hide all labels
        ]
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          { visibility: 'off' } // Hide all roads 
        ]
      },
      {
        featureType: 'POI',
        elementType: 'labels',
        stylers: [
          { visibility: 'off' } // Hide all roads 
        ]
      },
      {
        featureType: 'Landscape',
        elementType: 'labels',
        stylers: [
          { visibility: 'off' } // Hide all roads 
        ]
      },
      {
        featureType: 'Transit',
        elementType: 'labels',
        stylers: [
          { visibility: 'off' } // Hide all roads 
        ]
      },
      {
        featureType: 'Water',
        elementType: 'labels',
        stylers: [
          { visibility: 'off' } // Hide all roads 
        ]
      },
      
      {
        mapTypeControl: false // Hide the map type controls
      }
      
    ],
    mapTypeId: "roadmap",
};

export const TransferType =(from,to,num)=>{
    if(from === 766 || from === 752){
        return num !== 1 ?"Arrival" :"ARV"
    }
    if(to === 766 || to === 752){
        return num !== 1 ?"Depature":"DEP"
    }
    else{
        return num !== 1 ?"Inter-Hotel":"INH"
    }
}  

export const transformArray =(obj)=>{
    let transformedObj = {};

// Function to transform array into array of objects
function transformArray(arr, type) {
    return arr.map((item, index) => ({
        name: item,
        id: index + 1,
        type: type
    }));
}

// Transform each property in the original object
for (let key in obj) {
    transformedObj[key] = transformArray(obj[key], key);
}

return transformedObj
}


// export const selectedCarDetails =(data)=>{

//     // Destructuring the objects
//     let {ind,regionDetails,selectDetails,selid,userData,max_pax,totalPax}=data;
//     let {status,Category,car_details,VehicleTypeId,TransferCategoryID
//         ,Currency,final_price,pic_url,baby_seat_count,lug_car_count,lug_truck_count,VehicleType,MaxPax
//     }=selectDetails;
    

//     let selectCar = null;
//     status === null ? selectCar = true : selectCar = false
//     let amount = status === null ? final_price:0;

//     // Update user details
//     let transfer_data = userData.map((data1,index) => {
//     if (index === selid) { 
//         return {
//         ...data1,
//         Vehicle_type: status === null ? Category: "",
//         TotalPrice: amount,
//         Car_selected:  selectCar, 
//         VehicleTypeId:VehicleTypeId, 
//         Currency: Currency, // backened need
//         TransferCategoryId:TransferCategoryID, 
//         pic_url:pic_url,
//         capacity:MaxPax,
//         LuggageTruck: lug_truck_count,
//         LuggageCar: lug_car_count,
//         baby_seat_count: baby_seat_count,
//         VehicleTypeName: VehicleType,  
//         CarCount:Math.ceil(totalPax/MaxPax)
//         };
//     } 
//     else{
//         return {
//         ...data1,
//         currency: Currency,
//         };
//     }
//     });

//     // /// Update Coast details
//     let oldArrayData = [...regionDetails];
    
//     let region_details = oldArrayData.map((data, arrInd) => {

//         if (arrInd === selid) {
            
//             let array_Update = data.map((item, regInd,arr) => {
//                 if (regInd === ind && status === null) {
//                     return { ...item, status: "selected" };
//                 } 
//                 else if(status !== null){ return { ...item, status: null }; }
//                 else{return { ...item, status: "not-selected" };}
//             });

//             return array_Update ;
//         } 
//         else { return  data  }
//     });
    

//     return {transfer_data,region_details}

// }







export const createId = (data)=>{
  return  data.map((item,ind)=>{
       return {"id":ind+1,"description":item}
    })
    
}

export const generateIds =(data)=>{
    return  data.map((obj, index) => ({
        ...obj,
        id: index + 1 
      }));
}

export function capitalizeFirstLetter(str, upperCaseOthers = true) {
    if (!str) {
        return "";
    }
    return str.charAt(0).toUpperCase() + (upperCaseOthers ? str.slice(1).toLowerCase() : str.slice(1));
}

export  const backcarousel = [
    {id:1,name:"loginimg1.jpeg",alt:"login-background-img"},
    {id:2,name:"loginimg2.jpeg",alt:"login-background-img2"},
    {id:3,name:"loginimg3.jpeg",alt:"login-background-img3"},
    {id:4,name:"loginimg4.jpeg",alt:"login-background-img4"},
    {id:5,name:"loginimg5.jpeg",alt:"login-background-img5"},
    {id:6,name:"loginimg6.jpeg",alt:"login-background-img5"},
]  

export const accomPaxCount =(paxdata)=>{
    let totalPax = 0;
    const roomCount = paxdata.length;
   
    for (const room of paxdata) {
        totalPax += room.adult + room.child + room.teen + room.infant;
    }
    return [`${totalPax} pax, ${roomCount} rooms`, `${totalPax}`];
}  

export const addingPax =(paxdata)=>{
    return paxdata.map((item)=>{
        return item.adult + item.child + item.teen + item.infant
    })
}

export const addingTotalPax = (paxdata,single) => {

    if(single){
        const { adult, teen, child,infant } = paxdata[0];
        if (teen === 0 &&  child === 0 && infant === 0) {
            return `${adult} ${adult >1 ? "Adults":"Adult"}`;  // Only adult has value
          } else {
            return `${adult + teen + child} pax`;  // Sum of adult, teen, and child
          }
    }
    else{
        return paxdata.reduce((total, item) => {
            return total + item.adult + item.child + item.teen + item.infant;
        }, 0);
    }
    
};

export const formatPax =(booking) => {
    const ageGroups = ['adult', 'child', 'teen', 'infant'];
    const parts = ageGroups
        .filter(key => booking[key] > 0)
        .map(key => {
            let label = key;
            if (key === 'child') {
                label = booking[key] > 1 ? 'children' : 'child';
            } else {
                label = booking[key] > 1 ? `${key}s` : key;
            }
            return `${booking[key]} ${label.charAt(0).toUpperCase() + label.slice(1)}`;
        });
    return parts.join(', ');
}

export const aggregatePaxData =(paxData)=> {
    return paxData.reduce((acc, currentArray) => {
        currentArray.forEach(pax => {
            if (!acc[pax.name]) {
                acc[pax.name] = 0;
            }
            acc[pax.name] += pax.value;
        });
        return acc;
    }, {});
}

export const maxRoomTxt = ["Your number of pax match with the occupancy of the room","The number of pax selected does not match with the room occupancy"]

	

export let STARRATING = [
    {id:1,rating:5,checked:false,name_tag:"5 Stars"},
    {id:2,rating:4,checked:false,name_tag:"4 Stars"},
    {id:3,rating:3,checked:false,name_tag:"3 Stars"},
    {id:4,rating:2,checked:false,name_tag:"2 Stars"},
    {id:5,rating:1,checked:false,name_tag:"1 Star"}
]

export let REGION = [
    {id:1,name:"North"},
    {id:2,name:"East"},
    {id:3,name:"South"},
    {id:4,name:"West"},
]

export const SHOWFILTER = [
    {name:"Most popular",id:6,name:"Most popular",type:"other_filters", disable:true},
    {name:"lowest",id:7,name:"Lowest to Highest",type:"other_filters",disable:false},
    {name:"highest",id:8,name:"Highest to Lowest",type:"other_filters",disable:false},
    {name:"Show also unavailable hotels",id:9,name:"Show also unavailable hotels",type:"other_filters",disable:true},
]

export const ALSOLIKE = [

    {
        "accessibility": [
            "Wheelchair accessible",
            "Elevator to access higher floors",
            "Toilets equipped with grab bars and raised",
            "Low sink to facilitate access",
            "Alarm and shower chair in the bathroom",
            "Braille information"
        ],
        "activities": [
            "Water activities"
        ],
        "amenities": [
            "Golf",
            "Spa",
            "Beach club"
        ],
        "currency": "EUR",
        "desc": "Explore Mauritius's lush inland beauty. Visit Grand Bassin, a sacred lake, and marvel at the landscapes of Black River Gorges National Park.\r\nHead to Chamarel for a 300-foot waterfall and the Seven-Coloured Earth geological phenomenon.\r\nConclude at Rhumerie de Chamarel for a guided distillery tour and rum tasting an immersive journey through nature, spirituality, and local craftsmanship in the heart of Mauritius.",
        "hotel_type": [
            "adult only",
            "Business hotel"
        ],
        "id": 3,
        "img": "../../Assets/images/dummy-accom/hotel1.png",
        "loc_det": "Balaclava, Pamplemousse",
        "meal_plan": [
            "full board",
            "all inclusive",
            "break fast"
        ],
        "name": "Westin Turtle Bay",
        "offers": "(15% Early Bird Offer)*",
        "offer_price": 300,
        "position": {
            "lat":-20.4245,
            "lng":57.45047
        },
        "price": 230,
        "rating": 5,
        "region": "North",
        "room_amenities": [
            "Sea view",
            "Garden view",
            "Bathtub",
            "Electric kettle",
            "Private pool",
            "Villa",
            "Balcony",
            "Flat screen TV"
        ],
        "types_of_rooms": [
            "Deluxe double room",
            "Single Standard room"
        ],
        "room_status" : "Rooms Available"
    
    },
	{
            "accessibility": [
                "Wheelchair accessible",
                "Elevator to access higher floors",
                "Toilets equipped with grab bars and raised",
                "Low sink to facilitate access",
                "Alarm and shower chair in the bathroom",
                "Braille information"
            ],
            "activities": [
                "Water activities"
            ],
            "amenities": [
                "Golf",
                "Spa",
                "Beach club"
            ],
            "currency": "EUR",
            "desc": "Explore Mauritius's lush inland beauty. Visit Grand Bassin, a sacred lake, and marvel at the landscapes of Black River Gorges National Park.\r\nHead to Chamarel for a 300-foot waterfall and the Seven-Coloured Earth geological phenomenon.\r\nConclude at Rhumerie de Chamarel for a guided distillery tour and rum tasting an immersive journey through nature, spirituality, and local craftsmanship in the heart of Mauritius.",
            "hotel_type": [
                "adult only",
                "Business Hotel"
            ],
            "id": 1,
            "img": "../../Assets/images/dummy-accom/hotel1.png",
            "loc_det": "Anse La Raie, Rivière du Rempart",
            "meal_plan": [
                "full board",
                "all inclusive",
                "break fast"
            ],
            "name": "Paradise Cove, Boutique Hotel",
            "offer": "(15% Early Bird Offer)*",
            "offer_price": 300,
            "position": {
                "lat": -20.4182,
                "lng": 57.49171
            },
            "price": 230,
            "rating": 4,
            "region": "North",
            "room_amenities": [
                "Sea view",
                "Garden view",
                "Bathtub",
                "Electric kettle",
                "Private pool",
                "Villa",
                "Balcony",
                "Flat screen TV"
            ],
            "types_of_rooms": [
                "Deluxe double room",
                "Single Standard room"
            ],
        "room_status" : "Rooms Available"
    },

    {
        "accessibility": [
            "Wheelchair accessible",
            "Elevator to access higher floors",
            "Toilets equipped with grab bars and raised",
            "Low sink to facilitate access",
            "Alarm and shower chair in the bathroom",
            "Braille information"
        ],
        "activities": [
            "Water activities"
        ],
        "amenities": [
            "Golf",
            "Spa",
            "Beach club"
        ],
        "currency": "EUR",
        "desc": "Explore Mauritius's lush inland beauty. Visit Grand Bassin, a sacred lake, and marvel at the landscapes of Black River Gorges National Park.\r\nHead to Chamarel for a 300-foot waterfall and the Seven-Coloured Earth geological phenomenon.\r\nConclude at Rhumerie de Chamarel for a guided distillery tour and rum tasting an immersive journey through nature, spirituality, and local craftsmanship in the heart of Mauritius.",
        "hotel_type": [
            "adult only",
            "Business hotel"
        ],
        "id": 2,
        "img": "../../Assets/images/dummy-accom/hotel1.png",
        "loc_det": "Mahébourg, Grand Port",
        "meal_plan": [
            "full board",
            "all inclusive",
            "break fast"
        ],
        "name": "Shandrani Beachcomber Resort & SPA",
        "offer": "(15% Early Bird Offer)*",
        "offer_price": 300,
        "position": {
            "lat":-20.4245,
            "lng":57.45047
        },
        "price": 230,
        "rating": 4,
        "region": "North",
        "room_amenities": [
            "Sea view",
            "Garden view",
            "Bathtub",
            "Electric kettle",
            "Private pool",
            "Villa",
            "Balcony",
            "Flat screen TV"
        ],
        "types_of_rooms": [
            "Deluxe double room",
            "Single Standard room"
        ],
        "room_status" : "Rooms Available"
    },

		
]

export const ALSOMAP = [

	{
            "accessibility": [
                "Wheelchair accessible",
                "Elevator to access higher floors",
                "Toilets equipped with grab bars and raised",
                "Low sink to facilitate access",
                "Alarm and shower chair in the bathroom",
                "Braille information"
            ],
            "activities": [
                "Water activities"
            ],
            "amenities": [
                "Golf",
                "Spa",
                "Beach-club"
            ],
            "currency": "EUR",
            "desc": "Explore Mauritius's lush inland beauty. Visit Grand Bassin, a sacred lake, and marvel at the landscapes of Black River Gorges National Park.\r\nHead to Chamarel for a 300-foot waterfall and the Seven-Coloured Earth geological phenomenon.\r\nConclude at Rhumerie de Chamarel for a guided distillery tour and rum tasting an immersive journey through nature, spirituality, and local craftsmanship in the heart of Mauritius.",
            "hotel_type": [
                "adult only",
                "Business hotel"
            ],
            "id": 1,
            "img": "../../Assets/images/dummy-accom/hotel1.png",
            "loc_det": "Mahébourg, Grand Port",
            "meal_plan": [
                "full board",
                "all inclusive",
                "break fast"
            ],
            "name": "LUX* Grand-Baie",
            "offer": "(15% Early Bird Offer)*",
            "offer_price": 300,
            "position": {
                "lat": -20.4182,
                "lng": 57.49171
            },
            "price": 230,
            "rating": 4,
            "region": "North",
            "room_amenities": [
                "Sea view",
                "Garden view",
                "Bathtub",
                "Electric kettle",
                "Private pool",
                "Villa",
                "Balcony",
                "Flat screen TV"
            ],
            "types_of_rooms": [
                "Deluxe double room",
                "Single Standard room"
            ]
    },

    {
        "accessibility": [
            "Wheelchair accessible",
            "Elevator to access higher floors",
            "Toilets equipped with grab bars and raised",
            "Low sink to facilitate access",
            "Alarm and shower chair in the bathroom",
            "Braille information"
        ],
        "activities": [
            "Water activities"
        ],
        "amenities": [
            "Golf",
            "Spa",
            "Beach-club"
        ],
        "currency": "EUR",
        "desc": "Explore Mauritius's lush inland beauty. Visit Grand Bassin, a sacred lake, and marvel at the landscapes of Black River Gorges National Park.\r\nHead to Chamarel for a 300-foot waterfall and the Seven-Coloured Earth geological phenomenon.\r\nConclude at Rhumerie de Chamarel for a guided distillery tour and rum tasting an immersive journey through nature, spirituality, and local craftsmanship in the heart of Mauritius.",
        "hotel_type": [
            "adult only",
            "Business hotel"
        ],
        "id": 2,
        "img": "../../Assets/images/dummy-accom/hotel1.png",
        "loc_det": "Mahébourg, Grand Port",
        "meal_plan": [
            "full board",
            "all inclusive",
            "break fast"
        ],
        "name": "Shandrani Beachcomber Resort & SPA",
        "offer": "(15% Early Bird Offer)*",
        "offer_price": 300,
        "position": {
            "lat":-20.4245,
            "lng":57.45047
        },
        "price": 230,
        "rating": 4,
        "region": "North",
        "room_amenities": [
            "Sea view",
            "Garden view",
            "Bathtub",
            "Electric kettle",
            "Private pool",
            "Villa",
            "Balcony",
            "Flat screen TV"
        ],
        "types_of_rooms": [
            "Deluxe double room",
            "Single Standard room"
        ]
    },

    {
        "accessibility": [
            "Wheelchair accessible",
            "Elevator to access higher floors",
            "Toilets equipped with grab bars and raised",
            "Low sink to facilitate access",
            "Alarm and shower chair in the bathroom",
            "Braille information"
        ],
        "activities": [
            "Water activities"
        ],
        "amenities": [
            "Golf",
            "Spa",
            "Beach-club"
        ],
        "currency": "EUR",
        "desc": "Explore Mauritius's lush inland beauty. Visit Grand Bassin, a sacred lake, and marvel at the landscapes of Black River Gorges National Park.\r\nHead to Chamarel for a 300-foot waterfall and the Seven-Coloured Earth geological phenomenon.\r\nConclude at Rhumerie de Chamarel for a guided distillery tour and rum tasting an immersive journey through nature, spirituality, and local craftsmanship in the heart of Mauritius.",
        "hotel_type": [
            "adult only",
            "Business hotel"
        ],
        "id": 3,
        "img": "../../Assets/images/dummy-accom/hotel1.png",
        "loc_det": "Mahébourg, Grand Port",
        "meal_plan": [
            "full board",
            "all inclusive",
            "break fast"
        ],
        "name": "Shandrani Beachcomber Resort & SPA",
        "offer": "(15% Early Bird Offer)*",
        "offer_price": 300,
        "position": {
            "lat":-20.4245,
            "lng":57.45047
        },
        "price": 230,
        "rating": 5,
        "region": "North",
        "room_amenities": [
            "Sea view",
            "Garden view",
            "Bathtub",
            "Electric kettle",
            "Private pool",
            "Villa",
            "Balcony",
            "Flat screen TV"
        ],
        "types_of_rooms": [
            "Deluxe double room",
            "Single Standard room"
        ]
    },
		
]

export const VIEWHEADLIST = ["","Base pax","Sharing pax","Base rate","Sharing rate","Base amount","Sharing amount","Amount"]

export const ROOM_OFFERS_TYPE={
    "PERCENTAGE":{sign:"%"}
}

// static cancellation
export const CANCELLATIONDATA=[
    {details: 'confirmed clients do not show up for departure', id: 1, name: '100% cancellation fee '},
    {details: ' Cancellation 24 hrs before departure', id: 2, name: '100% cancellation fee'},
    {details: ' Cancellation 48 hrs before departure', id: 3, name: '50% cancellation fee'},
    {details: ' Weather conditions not suitable to clients', id: 4, name: '100% cancellation fee'},
    {details: ' Bad weather -cancellation by Mautourco before departure', id: 5, name: 'Full refund'},
    {details: ' Bad weather -cancellation by Mautourco during excursion', id: 6, name: 'Partial refund'},
]   

export function pluralize(number, singular, plural = null) {
    // If no plural form is provided, we assume the plural form is just the singular form with an 's' added    
    const pluralForm = plural || `${singular}s`;
    return number === 1 ? singular : pluralForm;
}

export function countTrueValues(obj) {
    let count = 0;

    function traverse(obj) {
        for (let key in obj) {
            if (typeof obj[key] === 'object') {
                traverse(obj[key]);
            } else if (obj[key] === true) {
                count++;
            }
        }
    }

    traverse(obj);
    return count;
}

export function scrollRemove(isOverflowHidden) {
    if (isOverflowHidden) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
}

export function removeUnderscore(str) {
    return str.replace(/_/g, ' ');
}



export function clientInputs(rooms) {
    const result = [];
  
    rooms.forEach(room => {
        const { hotelId, room_detail_id, roomPax } = room;

        roomPax.forEach(data => {
            const { adult, child, teen, infant, clientType, paxAge } = data;
            const honeymoon = clientType[1] !== "HMN" ? 0 : 1;

            const getAge = (type) => {
                const pax = paxAge.find(p => p[type] !== undefined);
                return pax ? pax[type] : 0;
            };

            const addPax = (count, type, age = 0) => {
                for (let i = 0; i < count; i++) {
                    result.push({
                        type,
                        title: "mr",
                        last_name: '',
                        age,
                        status: type,
                        honeymoon,
                        hotel_id: hotelId,
                        language: 1,
                        first_name: '',
                        sur_name: '',
                        remarks: '',
                        room_detail_id: room_detail_id
                    });
                }
            };

            addPax(adult, 'adult');
            addPax(teen, 'teen', getAge('teen'));
            addPax(child, 'child', getAge('child'));
            addPax(infant, 'infant', getAge('infant'));
        });
    });
  
    return result;
}

  
export function removeKeys(array, keysToRemove){
    return array.map(obj => {
      const newObj = { ...obj };
      keysToRemove.forEach(key => {
        delete newObj[key];
      });
      return newObj;
    });
};

export function filterTeens(ageData,name){
    return ageData.flatMap(room => 
        room.paxAge.filter(pax => pax[name] !== undefined)
    );
};

export function incrementIds(data){
    const typeCounts = {};
  
    return data.map(item => {
      if (!typeCounts[item.type]) {
        typeCounts[item.type] = 1;
      } else {
        typeCounts[item.type] += 1;
      }
      
      return {
        ...item,
        id: typeCounts[item.type]
      };
    });
  };

export function reorderObject(obj, order) {
    let reorderedObj = {};
    order.forEach(key => { 
        if (obj.map((data)=>data.hasOwnProperty(key))) {
            reorderedObj[key] = obj.map((data)=>data[key]);
        }
    });

    return reorderedObj;

}

export function convertToFilename(inputString) {
    // Convert the string to lowercase
    let filename = inputString.toLowerCase();
    
    // Replace spaces with underscores
    filename = filename.replace(/ /g, '_');
    
    // Append the file extension
    filename += '.png';
    
    return filename;
  }

export const getTotalPax=(paxObject)=> {
    const { adult, teen, child, infant, room_id } = paxObject;
    const totalPax = adult + teen + child + infant;
    return totalPax;
}

export const checkAllRoomValid = (pendingRooms) =>{
    
    let returnvalue = false;
    
    if(pendingRooms.roomsFilled &&  pendingRooms.roomsFilled.length > 0){
      let counteurRoomValid = 0;
      let countInvalidRoom = 0;
      for(let i = 0 ; i < pendingRooms.roomsFilled.length;i++){
        
           if(pendingRooms.roomsFilled[i] == false){
            countInvalidRoom++;
           }else{
            counteurRoomValid++;
           }

      }
      if (counteurRoomValid == pendingRooms.roomsFilled.length){
        returnvalue = true;
      }
      if(counteurRoomValid == 0){
        returnvalue =true;
      }
    }else{
        returnvalue =true;
    }
    return returnvalue;
  }
export const checkAllTransferValid = (transferList) =>{
    
    let returnvalue = true;
    
    if(transferList && transferList.length > 0){
        let counteurCarValid = 0;
        let counteurCarInValid = 0;
          for(let i = 0 ; i < transferList.length;i++){
            if(transferList[i].Car_selected == true){
                returnvalue = false;
            }
        }
      }
    return returnvalue;
  }
