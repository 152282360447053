import React, { forwardRef, useEffect, useState } from 'react';
import {footerData} from "../../constants"
import { useSelector } from 'react-redux';

const Footer=forwardRef((props,ref) =>{
    const [isPageLoading,setIsPageLoading]=useState(true)
    const {  loading } = useSelector((state) => state.transfer_Global);
    const { loading: loading2 } = useSelector((state) => state.errorreducer);

    const {  pageLoading } = useSelector((state) => state.accomodationreducer);

    useEffect(()=>{
        setIsPageLoading(loading || pageLoading.status || loading2);
    }, [loading, pageLoading, loading2])
    return (
        
        <>
            
            {!isPageLoading ? 
                <div className='footer_parent' ref={ref}>
                        <div>
                            <div className='footer_texthd'>Site structure</div>
                            <div>
                                {
                                    footerData.Site_structure.map((data, id) => {
                                        return (
                                            <div key={data.id}>
                                                <div className='footer_text'>{data.name}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <div className='footer_texthd'>Policies</div>
                            <div>
                                {
                                    footerData.Policies.map((data, id) => {
                                        return (
                                            <div key={data.id}>
                                                <div className='footer_text'>{data.name}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <div className='footer_texthd'>Contact-us</div>
                            <div>
                                {
                                    footerData.Contact_us.map((data, id) => {
                                        return (
                                            <div key={data.id}>
                                                <div className='footer_text2'>{data.name}<span>{data.value
                                                }</span></div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <div className='footer_texthd'>Socials</div>
                            <div className='footer_imgblk'>
                                {
                                    footerData.Socials.map((data, id) => {
                                        return (
                                            <div key={data.id} >
                                                <img src={require("../../Assets/images/" + data.name + ".png")} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='footer_name_rights'>
                            <div className='footer_logo'></div>
                            <div className='footer_rightstxt'>© 2023 Mautourco, All Rights Reserved</div>
                        </div>
                </div>
                :
                    null 
                }
           
        </>
    );
})

export default Footer;