import React, { useState} from 'react';
import axios from "axios"
import {SEND_MAIL} from "../../../services/apiEndpoints.js"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


// Custom hook to fetch data from an API
const useSendMail = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);  
    const [isSuccess, setIsSuccess] = useState(null);  
    let token = localStorage.getItem("access-token");
    const navigate = useNavigate();
    let MAILPATH ="https://staging.mautourco.com/api/v2/" // as per noel i was stored in the varibale not in evn
    const SendMail = async (sendObject) => {
        setLoading(true)
       
        try {
            const response = await axios.request({
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
                method: 'POST',
                url: MAILPATH+`${SEND_MAIL}`,
                data:sendObject
            });
            setIsSuccess(true)
        
        } catch (error) {
            if(error.response && error.response.status === 401){
                navigate("/")
            }else if (error.response) {
                setError(error.response.data.message);
            } else {
                navigate("/internal-error")
            }
            setIsSuccess(false)
        } finally {
            setLoading(false)
        }
    };

  return {loading,error,isSuccess,SendMail };
};


export default useSendMail;