import { useEffect, useRef } from "react";  
const useCategoryCounter = (categoryField) => {  
  const categoryCounts = useRef({});  
  useEffect(() => {  
    categoryCounts.current = {}; // Reset counts on re-render  
  });  
  const getCategoryCount = (item) => {  
    const category = categoryField ? item[categoryField] : item;  

    if (!categoryCounts.current[category]) {  
      categoryCounts.current[category] = 1;  
    } else {  
      categoryCounts.current[category]++;  
    }  

    return categoryCounts.current[category];  
  };  

  return {getCategoryCount};  
};  

export default useCategoryCounter;