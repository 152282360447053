import { useNavigate } from "react-router-dom";
import { axiosRequestParams } from "../services/apiClient";
import { BOOKING_ENDPOINT } from "../services/apiEndpoints";
import { useSelector } from "react-redux";
import { MORE_OPTIONS_MODE, ROUTE_PATHS } from "../constants";
import useBookingService from "./useBookingService";
import { getOnlyNewServices } from "../utility/globalFunc";
import { useState } from "react";

const useMoreOptionBooking = (actionMode, services, agency_id) => {
    const navigate = useNavigate();
    const [loadingBooking,setLoadingBooking]=useState(false)

    const { quoteBookList, selectedPaxUpdateBooking } = useSelector(state => state.globalreducer)
    const { getBookingData,addItems } = useBookingService();

    const cancelBooking = ({ refcode, onSuccess, onError }) => {
        const url = BOOKING_ENDPOINT + "?ToRef=" + refcode
        axiosRequestParams("DELETE", url)
            .then((res) => {
                onSuccess && onSuccess(res);
            }).catch(error => {
                console.log(error);
                onError && onError(error);
            })
    }

    const buildServices=()=>{
        const newServices = getOnlyNewServices(services);
        const {reservationAccom:accomodations,reservationTransfer:transfers,reservationExcursion:excursions=[]}=newServices
        return [...accomodations,...transfers,...excursions];
    }

    const buildClientPayload = () => {
        return selectedPaxUpdateBooking.map((person, ind) => ({
            ...person,
            "language":"5",
            "client_toref": ind + 1
        }));
    }

    const addClientsToServices = (services, nbOfPax = 1) => {
        const updatedServices = {};
        Object.keys(services).forEach(field => {
            updatedServices[field] = services[field].map(item => ({
                ...item,
                clientRefs: Array.from({ length: nbOfPax }, (_, i) => i + 1)
            }));
        });
        return updatedServices; 
    }


    const handleUpdateBooking = () => {
        const { refCode,dateTo, dateFrom } = quoteBookList;
        if (actionMode === MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_NEW_PAX) {
            navigate(ROUTE_PATHS.USER_DETAILS, {
                state: {
                    service: null,
                    refCode: refCode ?? ""
                }
            });
        }
        if (actionMode === MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_EXISTING_PAX) {
            setLoadingBooking(true)
            const clientsPayload = buildClientPayload();
            const newServices=buildServices();
            const payload = getBookingData(newServices, [dateFrom, dateTo], clientsPayload, refCode, agency_id);
            payload.folder.clients=clientsPayload;
            payload.services=addClientsToServices(payload.services,clientsPayload.length);
            addItems(payload).finally(()=>{
                setLoadingBooking(false)
            });
        }
    }
    return { cancelBooking, addItems, handleUpdateBooking,loadingBooking };
}

export default useMoreOptionBooking;