import React, { useEffect, useState } from 'react'
import useCategoryCounter from '../../hooks/useCategoryCounter'
import { capitalizeFirstLetter } from '../../constants';
import { useDispatch } from 'react-redux';
import { setSelectedPaxUpdateBooking } from '../../store/globalReducer';
import { getPaxCategoryByAge } from '../../utility/globalFunc';

function BookingPaxUpdate({ pax = [],onSelectionChange,notSelected=false }) {
    const {getCategoryCount}=useCategoryCounter("client_status");
    const [selected,setSelected]=useState([]);
    const dispatch=useDispatch();

    const onPaxClick=(ind)=>{
        setSelected((prevSelectedItems) => {  
            const isAlreadySelected = prevSelectedItems.some((selected) => selected === ind);  
            if (isAlreadySelected) {  
              return prevSelectedItems.filter((selected) => selected !== ind);  
            } else {  
              return [...prevSelectedItems, ind];  
            }  
          });  
    }

    const isSelected=(ind)=>{
        return selected.includes(ind);
    }

    const isSelectedOrError = (ind)=>{
        if(notSelected){
            return 'not-filled'
        }else{
            if(selected.includes(ind)){
                return 'checked'
            }else{
                return ''
            }
        }
    }

    useEffect(()=>{
        onSelectionChange?.(selected);
        dispatch(setSelectedPaxUpdateBooking(selected));
    },[selected,notSelected,onSelectionChange])
    
    return (
        <div className="service-pax-info-container">
            <div className="service-pax-container">
                {
                    pax.map((client, ind) => (
                        <div className={`service-box-client ${isSelectedOrError(ind)}`} key={"pax-booking-"+ind} onClick={()=>onPaxClick(ind)}>
                            <div className="service-pax-info">
                                <div>{capitalizeFirstLetter(getPaxCategoryByAge(client.age)??"")} no.{getCategoryCount(getPaxCategoryByAge(client.age))}</div>
                                <div className="service-box-client-name">{client.name ? client.name : "N/A"}</div>
                            </div>
                            <div className={`service-pax-rank ${notSelected ? 'not-filled' : ''} `}>
                                {isSelected(ind)&&<i className='check-mark-simple'></i>}
                            </div>
                        </div>
                    ))
                }
                <div className='service-pax-error-container'>
                    <div className={`toast-error-form ${notSelected ? 'show' : ''}`}>
                        <span>Please select the concerned pax for this service</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingPaxUpdate
