import { getBackgroundColor } from "../../../constants"
import { dateToConventionalFormat, transformDate } from "../../../utility/globalFunc"
import { useState } from "react"

export const ImageLayout =({imageList=[]})=>{        
    return(
        <div className="image-grid-container">
             { imageList.length ? imageList.slice(0,3).map((imgList,id)=>(<img  src={imgList} alt="accomodation image layout" key={id}/>)):<></> }
        </div>
    )
}

export const DateAtom =({date})=>{     
    return(
        <div className="datesAtom">
            <span className='quotation_tour_date_first-date'>{date[0] ?transformDate(date[0]):null  }</span>
           { date[1] && <span className='quotation_tour_date_arrow'>&rarr;</span>}
           { date[1] && <span className='quotation_tour_date_second-date'>{transformDate(date[1])}</span>}
        </div>
    )
}

export const AccomStockStatus=({status,className="room-stock-status"})=>{
    const toCapitalize = (txt) => {
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    }
    return (
        <div className={className} style={{ backgroundColor: getBackgroundColor(status) }}>
            <label className='status_txt'>{toCapitalize(status)}</label>
        </div>
    )
}

export const OfferDetails = ({ offer, getOfferType }) => {
    const [showFull, setShowFull] = useState(false);
    const maxLength = 100; // Set the threshold for "Read More"
  
    const handleToggle = () => setShowFull(!showFull);
  
    return (
      <div className="offers-container">
        <div className="offer-details">
          <h6 className="offer-title">
            Offers applicable: {offer.OfferName} -{" "}
            <span className="offer-title-light">{ getOfferType(offer)}</span>
          </h6>
  
          <p className="offer-description offer-desc">
            {showFull ? (
              <>
                {offer.OfferDescription}{" "}
                {offer.OfferDescription.length > maxLength && (
                  <span className="toggle-text-readmore" onClick={handleToggle}>
                    Read Less
                  </span>
                )}
              </>
            ) : (
              <>
                {offer.OfferDescription.slice(0, maxLength)}
                {offer.OfferDescription.length > maxLength && (
                  <>
                    ...{" "}
                    <span className="toggle-text-readmore" onClick={handleToggle}>
                      Read More
                    </span>
                  </>
                )}
              </>
            )}
          </p>
        </div>
        <i className="dashed-line"></i>
      </div>
    );
  };