import React,{useState,useEffect,useRef} from 'react';
import { useSelector ,useDispatch} from 'react-redux';
import {getTotalPax, MORE_OPTIONS_MODE, ROOMPAXDATA} from '../../constants.js';
import { useLocation } from 'react-router-dom';
import usePopupData from '../../hooks/usePopupData.js'; 
import useToRefBooking from '../../hooks/useToRefBooking.js';
import useBookingService from '../../hooks/useBookingService.js';
import NavbarBlock from '../Transfer/NavbarBlock.js';
import BookingStepper from '../MiniComponents/BookingStepper.js';
import BookingInputs from '../MiniComponents/BookingInputs.js';
import BookingServiceDetails from '../MiniComponents/BookingServiceDetails.js';
import BookingRecap from './BookingRecap.js';
import {finabook} from "../../store/globalReducer.js"
import { calculateMinMaxAndSumAccomAndTransfer, getOnlyNewServices } from '../../utility/globalFunc.js';
import { accomPaxBookingDefault,resetaccomPaxBookingDefault } from '../../store/accomodationReducer.js';
import useDocket from '../../hooks/useDocket.js';
import Booking_summary from './Booking_summary.js';
import useBookingValidity from '../../hooks/useBookingPaxValidity.js';

const INPUT_ACCOM_STEP=1;
const SERVICE_DETAILS=2
const BOOKING_RECAP=3;
const INPUT_REFERENCE=4;
const roomPaxBookingDefaultValue =[{"adult":0,"teen":0,"child":0,"infant":0,"room_id":1,"paxAge":[],clientType:["Standard","STD"]}]

function QuoteInputs() {
    const [formState, setFormState] = useState(null)
    const [activeStep,setActiveStep]=useState(1);
    const [optionData, setOptionData] = useState();
    const [errorInput, seterrorInput] = useState(null);
    const [servicePaxFilled,setServicePaxFilled]=useState({filled:false,serviceInd:[]});
    const [openCancelPopup,setOpenCancelPopup] =useState(false);
    const [isPaxSelected, setIsPaxSelected] = useState(false);

    const {transferPax} =useSelector((state) => state.transfer_Global)
    const {first_name,last_name,agency_id} =useSelector((state) => state.authpath.userData)
    const {roomPaxBookingDefault,selRooms}= useSelector((state) => state.accomodationreducer)
    const { userDateClients, sortedList, userData,actionMode,quoteBookList } = useSelector((state) => state.globalreducer)

    const { openPopup, getPopupContent } = usePopupData();
    const {loading:loading1,isSuccess} = useToRefBooking();
    const {getTimeline}=useDocket();
    const { loading:loading2,isSuccess:isSuccess2,validateData,getBookingData,bookingData,setBookingData,getJourneyDateRange,addItems} = useBookingService();
  
    const dispatch = useDispatch()
    const locationData = useLocation();
    const prevRoomPaxRef = useRef();
    let {service,refCode}=locationData.state;
    
    const getServices = () =>{
      const servicess= service || sortedList.services;
      
      if (actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING)) {
        const newServices=getOnlyNewServices(servicess);
        return newServices;
      }
      
      return servicess;
    }

    const {reservationAccom:accomodations,reservationTransfer:transfers,reservationExcursion:excursions=[]}=getServices()
    const services=getTimeline({accomodations,transfers,excursions});
    const journeyDates=getJourneyDateRange(services);
    const {checkIfAllPaxSelectorFilled}=useBookingValidity({bookingData,services})

    

    useEffect(() => {
      let startToRef=1;
      if (actionMode===MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_NEW_PAX) {
        startToRef=quoteBookList?.Clients?.length??1;
      }
      setFormState(prev=>{
        return convertToTargetFormat(prev,roomPaxBookingDefault[0],startToRef)
      });
    },[roomPaxBookingDefault]);
    
    useEffect(() => {
        if (isSuccess || isSuccess2) {
            openPopup("cancellation-Policy");
        }
    }, [isSuccess, isSuccess2,userDateClients]);

    useEffect(() => {

          if(getTotalPax(roomPaxBookingDefault[0]) == 0){ setIsPaxSelected(false) }else{setIsPaxSelected(true) }
          
    },[roomPaxBookingDefault]);




    const handleChange = (index, field, value) => {
        const updatedData = [...formState];
        // Mbola mila ampiana raha manampay
        updatedData[index][field] = value;
        if (field === 'sur_name') {
            updatedData[index].last_name = value;
        }else if (field === 'last_name') {
            updatedData[index].sur_name = value;
        }

        setFormState(updatedData);
    };

    const createPerson = (id, type, age,numero) => ({
      id,
      type,
      title: "mr",
      last_name: "",
      age,
      status: type,
      language: 1,
      first_name: "",
      sur_name: "",
      remarks: "",
      numero,
      ...(type === "infant" || "child" ? { babySeatEnabled: false } : {}) 
    });
    
    const getPaxAge=(index,age,type)=>{
      return age[index]? age[index][type]:0;
    }

    const applyPaxAge=(formPax,age,type)=>{
      return formPax.map((form,ind)=>({
        ...form,
        age:getPaxAge(ind,age,type)
      }))
    }

    const reapplyId=(formPax,startId)=>{
      formPax.forEach((pax,ind)=>{
        pax.id=startId+ind
      })
    }

    const getPeopleByType = (count, type, age = [0], startId = 1,formState) => {
   
      
      let formType= (formState||[]).filter(obj => obj.type === type);  
      
      const nbNewOccurence=count-formType.length;
      if (nbNewOccurence>=0) {
        const formPax=[...formType, ...Array.from({ length: nbNewOccurence }, (_, index) => createPerson( index, type, 0,formType.length+1 + index))];
        reapplyId(formPax,startId);
        
        return applyPaxAge(formPax,age,type);
      }else{
        return formType.slice(0,nbNewOccurence);
      }
    };
    
    const convertToTargetFormat = (prevFormat,{ adult, teen, child, infant, paxAge },startToRef=1) => {
      // const teenAge = paxAge.find((pax) => pax.teen)?.teen || 0;
      const teenAge = paxAge.filter(rep=>!!rep.teen) 
      const childAge = paxAge.filter(rep=>!!rep.child) 
      const infantAge = paxAge.filter(rep=>!!rep.infant)       
      
      const idAdultStart = startToRef;
      const idTeenStart = idAdultStart+adult;
      const idChildStart = idTeenStart+teen;
      const idInfantStart = idChildStart+child;

      const result = [
        // ...(prevFormat||[]),
        ...getPeopleByType(adult, "adult", [], idAdultStart,prevFormat),
        ...getPeopleByType(teen, "teen", teenAge, idTeenStart,prevFormat),
        ...getPeopleByType(child, "child", childAge, idChildStart,prevFormat),
        ...getPeopleByType(infant, "infant", infantAge, idInfantStart,prevFormat)
      ];
      return result;
    };
    

    const handleSubmit = (e) => {
        e?.preventDefault();
        
        dispatch(finabook(bookingData));
        setOpenCancelPopup(true)
        openPopup("cancellation-Policy")
    };

    const addItemsBooking=(e)=>{
      e?.preventDefault();
      addItems(bookingData);
    }

    const setDefaultPax=()=>{

      function buildRoomPaxData(inputData) {
        const outputData = { ...inputData }; // Clone the input object
        const paxAge = [];
        // Iterate over each pax type (teen, child, infant)
        ['teen', 'child', 'infant'].forEach((paxType) => {
          for (let i = 0; i < inputData[paxType]; i++) {
            paxAge.push({ [paxType]: 0, id: i+1 });
          }
        });
      
        outputData.paxAge = paxAge; // Add the paxAge array to the object
        return outputData;
      }
    
      const minMax = calculateMinMaxAndSumAccomAndTransfer(accomodations, transfers) 
      let paxData={
        ...ROOMPAXDATA,
        adult:minMax.minAdults||0,
        teen:minMax.minTeen||0,
        infant:minMax.minInfant||0,
        child:minMax.minChild||0,
      }
      paxData = buildRoomPaxData(paxData)
      let pax=[paxData];
      dispatch(accomPaxBookingDefault(pax));
      // dispatch(resetaccomPaxBookingDefault(pax));
    }

    
    // useEffect(()=>{
    //     setDefaultPax();
    // },[])

    const steps=[

        { 
          step:INPUT_ACCOM_STEP,
          label:"Client's info",
          onNext:()=>{
            let infoStatusInput=validateData(formState);
            if (infoStatusInput.indiceError.every(error => error === false)) {
              let dataBooking = getBookingData(services, journeyDates, formState, refCode, agency_id,actionMode)              
              setBookingData(dataBooking);
              setActiveStep(SERVICE_DETAILS);
              seterrorInput(infoStatusInput);
            } else {
              seterrorInput(infoStatusInput);
            }
          }
        },
        {
          step:SERVICE_DETAILS,
          label:"Service details",
          buttonLabel:"Next",
          onNext:()=>{
            const {isAllServiceFilled,serviceNotFilled}=checkIfAllPaxSelectorFilled()
            
            setServicePaxFilled({
              filled:isAllServiceFilled,
              serviceInd:serviceNotFilled
            })
            if (isAllServiceFilled) {
              setActiveStep(BOOKING_RECAP);
            }
          }
        },
        {
          step:BOOKING_RECAP,
          label:"Booking recap",
          buttonLabel:actionMode===MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_NEW_PAX?"Save & Updates changes":"Done",
          onNext:()=>{
            if (actionMode===MORE_OPTIONS_MODE.ADD_ITEM_BOOKING_NEW_PAX) {
              addItemsBooking()
            }else{
              handleSubmit();
            }
          }
        },
    ];

    const getLastDateInReducer=()=>{
      if (userDateClients.length > 0){
          return userDateClients[userDateClients.length -1];
      }
      return [];
    }
      
    function updateNames(data) {
      return data.map(item => {
          return {
              ...item,
              last_name: item.last_name === "" ? "N/A" : item.last_name,
              first_name: item.first_name === "" ? "N/A" : item.first_name
          };
      });
  }



    return (
        <>
            <NavbarBlock/>
            <div className='booking-inputs-container'>
              
            {activeStep===INPUT_ACCOM_STEP&&<BookingInputs 
                refCode={refCode}
                first_name={first_name}
                last_name={last_name}
                clientDates={getLastDateInReducer()}
                getDates={getLastDateInReducer()}
                errorInput={errorInput}
                seterrorInput={seterrorInput}
                handleChange={handleChange}
                formState={formState}
                setFormState={setFormState}
                loading1={loading1}
                loading2={loading2}
                roomPax={roomPaxBookingDefault}
                isPaxSelected={isPaxSelected}
                serviceRerservation={getServices()}
                optionData={optionData}
                setOptionData={setOptionData}
                actionMode={actionMode}
            />}
            {activeStep===SERVICE_DETAILS&&<BookingServiceDetails
                refCode={refCode}
                first_name={first_name}
                last_name={last_name}
                //selRooms={selRooms}
                services={services}
                servicesData={service}
                clientinfo ={formState}
                bookingData={bookingData}
                setValidatedData={setBookingData}
                servicePaxFilled={servicePaxFilled}
            />}
            {
                activeStep===BOOKING_RECAP&&<BookingRecap 
                data={updateNames(formState)} 
                //dataRecap={selRooms} 
                dataRecap={getServices()} 
                refCode={refCode}
                first_name={first_name}
                last_name={last_name}
                validatedData={bookingData}
                
                />
            }
            </div>    
            {activeStep < steps.length &&
              <div className='quote_input_right'>
                <Booking_summary data={services} dateRange={journeyDates}/>
              </div>
            }
            <BookingStepper  steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} loading={loading2} disableButton={!isPaxSelected}/>
    
            {openCancelPopup? getPopupContent({refCode:refCode === null ? formState[0]?.first_name +formState[0]?.last_name:refCode,selRooms}) :<></>} 
               
        </>
    );
}

export default QuoteInputs;