import { DEFAULT_SERVICES } from "../constants";

export const useBookingAccomPax=({bookingData=DEFAULT_SERVICES,setValidatedData,handleForceUpdate})=>{
    const {reservationAccom}=bookingData.services;

    const isChosenAccom=(client,ind)=>{
        
        let clientRefs=reservationAccom[ind]?.clientRefs;
        let chosen=false;
        if (clientRefs) {
            chosen = clientRefs.includes(client.id);
        }
        return chosen;
    }

    const getRankAccom=(client,ind)=>{
        let clientRefs=bookingData.services.reservationAccom[ind]?.clientRefs;
        if (clientRefs.length) {
            let rank= bookingData.services.reservationAccom[ind]?.clientRefs.indexOf(client.id) + 1;
            return rank===0?null:rank;
        }
        return null;
    }

    const appendDataAccom=(e,info,roomid,maxPax)=>{
        e?.preventDefault();
        let getAccom = reservationAccom.map((item,id)=>{
            let clientRefs=item.clientRefs;
            if(id === roomid){
                if (clientRefs.includes(info.id)) {
                    item.clientRefs = clientRefs.filter(item=>item!=info.id)
                }else{
                    let oldAdditionalRefs=clientRefs.length+1>maxPax?[]:clientRefs;
                    item.clientRefs = [...oldAdditionalRefs, info.id]
                }
            }
            
            return item
        })
        bookingData.services.reservationAccom = getAccom
        setValidatedData(bookingData)
        handleForceUpdate()
    }

    const isValidAccom=(ind,maxPax)=>{
        return reservationAccom[ind].clientRefs?.length >= maxPax;
    }

    const getMaxPaxAccom=(room)=>{
        return room.AdultCount + room.TeenCount + room.ChildCount + room.InfantCount;
    }

    return {
        appendDataAccom,
        getRankAccom,
        isChosenAccom,
        isValidAccom,
        getMaxPaxAccom
    };
}

export const useBookingTransferPax=({bookingData=DEFAULT_SERVICES,setValidatedData,handleForceUpdate})=>{
    const {reservationTransfer}=bookingData.services;

    const isChosenTransfer=(client,ind)=>{
        let clientRefs=reservationTransfer[ind]?.clientRefs;
        let chosen=false;
        if (clientRefs) {
            chosen = clientRefs.includes(client.id);
        }
        return chosen;
    }

    const getRankTransfer=(client,ind)=>{
        const clientsRefs=reservationTransfer[ind]?.clientRefs;
        if (clientsRefs.length) {
            let rank= clientsRefs.indexOf(client.id) + 1;
            return rank===0?null:rank;
        }
        return null;
    }

    const appendDataTransfer=(e,info,ind,maxPax)=>{
        e?.preventDefault();
       // console.log("append transfer",reservationTransfer)
       // console.log("append transfer info",info,ind)
        // let {babySeatEnabled}=info

        const transfers = reservationTransfer.map((item,id)=>{
            let clientRefs=item.clientRefs;
            if(id === ind){
                if (clientRefs.includes(info.id)) {
                    item.clientRefs = clientRefs.filter(item=>item!=info.id)
                }else{
                    let oldAdditionalRefs=clientRefs.length+1>maxPax?[]:clientRefs;
                    item.clientRefs = [...oldAdditionalRefs, info.id]
                }
            }
            
            return item
        })
       // console.log("appended transfer data",transfers)
        bookingData.services.reservationTransfer = transfers
        console.log("bookingData",bookingData);
        
        setValidatedData(bookingData)
        handleForceUpdate()
    }
// here we are appending baby seats to the transfery
    const appendBabySeats=(e,info,serviceIndice)=>{
        e?.preventDefault();
        let {id}=info
        let clientRefs = reservationTransfer[serviceIndice].otherServices[0].clientRefs;
        if (clientRefs.includes(id)) {
            reservationTransfer[serviceIndice].otherServices[0].clientRefs =  clientRefs.filter(item => item !== id);   
        } else {
            clientRefs.push(id);
        }        
        // bookingData.services.reservationTransfer = transfers
        // setValidatedData(bookingData)
        // handleForceUpdate()
    }

    const isValidTransfer=(ind,maxPax)=>{ 
        return reservationTransfer[ind].clientRefs?.length >= maxPax;
    }

    const getMaxPaxTransfer=(transfer)=>{
        return transfer.AdultCount + transfer.TeenCount + transfer.ChildCount + transfer.InfantCount;
    }


    return {
        isChosenTransfer,
        getRankTransfer,
        appendDataTransfer,
        isValidTransfer,
        getMaxPaxTransfer,
        appendBabySeats
    };
}

export const useBookingExcursionPax=({bookingData=DEFAULT_SERVICES,setValidatedData,handleForceUpdate})=>{
    const {reservationAccom}=bookingData;

    const isChosenExcursion=(client,ind)=>{
        let clientRefs=reservationAccom[ind]?.clientRefs;
        let chosen=false;
        if (clientRefs) {
            chosen = clientRefs.includes(client.id);
        }
        return chosen;
    }

    const getRankExcursion=(client,ind)=>{
        let clientRefs=bookingData.services.reservationAccom[ind]?.clientRefs;
        if (clientRefs.length) {
            let rank= bookingData.services.reservationAccom[ind]?.clientRefs.indexOf(client.id) + 1;
            return rank===0?null:rank;
        }
        return null;
    }

    const appendDataExcursion=(e,info,roomid,maxPax)=>{
        e?.preventDefault();
        let getAccom = reservationAccom.map((item,id)=>{
            let clientRefs=item.clientRefs;
            if(id === roomid){
                if (clientRefs.includes(info.id)) {
                    item.clientRefs = clientRefs.filter(item=>item!=info.id)
                }else{
                    let oldAdditionalRefs=clientRefs.length+1>maxPax?[]:clientRefs;
                    item.clientRefs = [...oldAdditionalRefs, info.id]
                }
            }
            
            return item
        })
        bookingData.services.reservationAccom = getAccom
        setValidatedData(bookingData)
        handleForceUpdate()
    }

    const isValidExcursion=(ind,maxPax)=>{ 
        return bookingData.services.reservationAccom[ind].clientRefs?.length >= maxPax;
    }

    return {
        isChosenExcursion,
        getRankExcursion,
        appendDataExcursion,
        isValidExcursion
    };
}