import React,{useState,useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {AIRPORT_LOCATION_NAME, AIRPORT_SSR_LOCATION_NAME, getOrdinal} from "../../constants";
import DisplayCar from "../Transfer/DisplayCar.js"
import ListTransfer from './ListTransfer.js';
import { Skeleton } from '@mui/material';
import { trigerId } from '../../store/transferReducer.js';
// import { info } from "../../../Assets/images/global-icon/info-red.svg";
import  info  from "../../Assets/images/global-icon/info.svg";
import { BackButtonHandler } from '../MiniComponents/Atom.js';
import usePopupData, { TRANSFER_POPUP } from '../../hooks/usePopupData.js';
import { calculateMinMaxAndSumAccomAndTransfer } from '../../utility/globalFunc.js';

const isLocationPointAirport = (locationPoint) => {
  const locationPointLower = locationPoint.toLowerCase();
  return locationPointLower.toLowerCase() === AIRPORT_LOCATION_NAME.toLowerCase() || locationPointLower.toLowerCase() === AIRPORT_SSR_LOCATION_NAME.toLowerCase()
}

const getTransferButtonLabel = (transfer) => {
  const {LocationFromName,LocationToName} = transfer;
  const isFromAirport = isLocationPointAirport(LocationFromName);
  const isToAirport = isLocationPointAirport(LocationToName);
  if (isFromAirport) {
    return "Arrival";
  }
  if (isToAirport) {
    return "Departure";
  }
  return "Inter-Hotel"
}

function SelectCars() {
  const { isOpen, openPopup, getPopupContent } = usePopupData();
    const dispatch = useDispatch();
  const { tempId: getId, isResultSameVehicle, isSameVehicle } = useSelector((state) => state.transfer_Global);
    const { visitedTransferList: isVisited } = useSelector((state) => state.transfer_Global);
    const compBRef = useRef(null);
    const {transferList,transferUpdates,regionDetails,updatePax,loading} =useSelector((state) => state.transfer_Global)    

  let gridStructure = { "gridTemplateColumns":"145px 265px 339px 31px 357px","display":"grid","position":"relative"}
    let result1 = transferList.map(({price,Car_selected,currency,pic_url,vehicle_type,id_transfer_cat,id_vehicle_type,noOfCars,capacity,baby_seat_count,LuggageTruck,LuggageCar,...rest}) => ({...rest}));
    let result2 = transferUpdates.map(({price,Car_selected,currency,pic_url,vehicle_type,capacity,...rest}) => ({...rest}));
    
    let equal =  JSON.stringify(result2) === JSON.stringify(result1)



  const getColorButton = (item, ind) => {
    if (item.Car_selected=== false) {
      if (getId === ind){
        return "transfer_to_fill_focus";
      }
      else{
        return "transfer_enable"
      }
    }
    else{
      if (getId === ind) {
        return "transfer_fill_focus";
      }
      else if (getId !== ind) {
        return "transfer_fill";
      }
    }

  }

    const tClick = (e, selectedId, region) => {
      // if (getId <= selectedId && !transferList[selectedId-1].Car_selected){
      //   return;
      // }
      const current = getId;
      if (transferList[selectedId].Car_selected||selectedId<=getId) {
        dispatch(trigerId(selectedId));
        return;
      }

      if (transferList[current].Car_selected){
        dispatch(trigerId(selectedId));
        return;
      } 
   };
  
   function showPopup(){
     openPopup(TRANSFER_POPUP);
  }
  useEffect(() => {
    for (let ind = 0; ind < transferList.length; ind++) {
      let transfer = transferList[ind];
      if (!transfer.Car_selected) {
        dispatch(trigerId(ind));
        break;
      }
    }
  }, [])

  const getButtons = () => (
    <div className='pickCar_parentloop'>
      {transferList.length > 1 && !isResultSameVehicle && (
        !isVisited ?
          (!loading ? (
            transferList.map((data, ind) => {
              if(data.isPersisted){
                return null;
              }
              return (
                <div key={ind} id={"chk" + ind}
                  className={"carMapParent " + getColorButton(data, ind)}
                  style={{ opacity: getId === ind ? "1" : "0.5" }}
                >
                  <div onClick={(e) => tClick(e, ind, data)}>
                    {getTransferButtonLabel(data)}
                  </div>
                </div>
              );
            })
          ) : (
            <Skeleton width={120} style={{ marginBottom: "20px" }} />
          ))
          : null
      )}
      {transferList.length ==1 && (
        !loading ?
          <div  className={"carMapParent transfer_enable"} >
            <div style={{width:'196px'}}>{getTransferButtonLabel(transferList[0])}</div>
          </div> 
            :  <Skeleton width={120} style={{ marginBottom: "20px" }} />)
      }
      {transferList.length > 1 && isResultSameVehicle && (
        !loading ?
          <div  className={"carMapParent transfer_enable"}>
            <div>All your transfer</div>
          </div> 
            :  <Skeleton width={120} style={{ marginBottom: "20px" }} />)
      }

    </div>
  )

  const useSameVehiculeBox = () =>{
   return (<div class="select-container same-vehicule" onClick={()=>showPopup()}>
        <div bis_skin_checked="1">
          <div class="selectbox2 selectbox2cnt CarIconImgSameVehicule"  >
            <div id="select_deladd" class="selectbox2cnt_sub selectedLocation" >
              <div class="selLabel" bis_skin_checked="1">{`${transferList?.length} transfers`} </div>
              </div>
            </div>
            </div>
        </div>)
  }

  return (
    <div className="carDisplayContainer" ref={compBRef}>
      {!loading ? <div className="pickCarsText" id='pickCarsText'>
      {isVisited? 
          <div className='visitedTransfer'>
            <p>Our fleet
              <span className='infoVehiculeList' >Caution: Please enter transfer information to obtain more details about the vehicles</span>
            </p>
          </div>
        :<p>Result of your search
          <span>Please select your vehicle</span>
        </p>
      }
    </div>: <Skeleton width={120} style={{marginBottom:"20px"}}/>}
      {getButtons()}
      <div style={{width:"100%",float:"left", marginBottom:"108px",marginTop: "21px", display:isVisited?"none":""}}>
          {/* //if !visited */}
        {!isVisited && !isResultSameVehicle && !isSameVehicle?
          <ListTransfer
            lists={[transferList[getId]]}
            styles={gridStructure}
            noClick={true}
            addArrow={true}
            ispopup={true}
          />: null
        }
        {
          isSameVehicle && useSameVehiculeBox()
        }
        
      </div> 

      <div style={{ width: "100%", float: "left" }} 
      >
       
          <DisplayCar
            passId={isVisited? 0 : getId}
            userData={transferList}
            noClick={isVisited ? equal : true}
            position={compBRef.current !== null ? compBRef.current.offsetTop : 0}
          />
      
      <BackButtonHandler service={transferList} serviceName={'Transfer'}  />
      </div>
      {isOpen && getPopupContent()}
    </div>
  );

}

export default SelectCars;