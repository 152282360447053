import React,{useRef,useState,useEffect} from 'react';
import { NotifDeleteQuotation, PaxAge, PaxAgeBookingTransfer } from '../MiniComponents/Atom.js';
import { Client_dropdown } from '../Quotations/small_dropdowns.js';
import {CLIENTDATA,capitalizeFirstLetter,ROOMPAXDATA} from "../../constants.js"
import {hasAgeZero, scrollToDependsBoundary,calculateMinMaxAndSumAccom,calculateMinMaxAndSumAccomAndTransfer} from "../../utility/globalFunc.js"
import { useDispatch, useSelector } from 'react-redux';
import { setIsPaxDisabled,setIsClickedButtonDone } from '../../store/transferReducer.js';

function PaxDropdownBooking({optionList,setOptionData,cname,confirmPax,serviceRerservation}) {
   const mainContainerRef = useRef(null);
   const scrollRef = useRef(null);
   const dispatch = useDispatch()
   const [isDisabled, setIsDisabled] = useState(false);
   const PAXkEYS = ["adult", "teen", "child", "infant"];
   const [animateClass,setAnimateClass]=useState("");
   const [disabledPaxKey,setDisabledPaxKey] = useState([])
   const [disabledPaxKeyMinus,setDisabledPaxKeyMinus] = useState([])
   const [paxKeyRange,sePasKeyRange] = useState({})
   const [isdisableOnAgeZero,setIsdisableOnAgeZero] = useState(false)
   const {roomPax}= useSelector((state) => state.accomodationreducer)
   const {isPaxDisabled,isClickedButtonDone} = useSelector((state)=> state.transfer_Global)
//    const {sortedList}= useSelector((state) => state.globalreducer)
   const sortedList= serviceRerservation;

   const animateFading=(fadingClassName="fade-in")=>{
    setAnimateClass(`show ${fadingClassName}`);
    setTimeout(()=>{
        setAnimateClass("");
    },2000);
   }
   const clearData=(e)=>{
        confirmPax(e,"clear");
        dispatch(setIsPaxDisabled(false));
        dispatch(setIsClickedButtonDone(false))
        animateFading();
        const calculateMinMaxAndSum = calculateMinMaxAndSumAccomAndTransfer(sortedList?.reservationAccom,sortedList?.reservationTransfer)
        setDisabledPaxKey(checkDisabledPaxDetails(calculateMinMaxAndSum,optionList[0],false))
        setDisabledPaxKeyMinus(checkDisabledPaxDetails(calculateMinMaxAndSum,optionList[0],true))
        // scrollToDependsBoundary(mainContainerRef.current,"end")
   }

   const checkDisabledPaxDetails = (roompaxLimit,roompax,isMinus=false) =>{

    let disabled = []
    if(isMinus){
        if(roompaxLimit?.minAdults >= roompax?.adult  ) disabled.push('adult'); else disabled.push('-1')
        if(roompaxLimit?.minTeen >= roompax?.teen)disabled.push('teen'); else disabled.push('0')
        if(roompaxLimit?.minChild >= roompax?.child)disabled.push('child'); else disabled.push('0')
        if(roompaxLimit?.minInfant >= roompax?.infant)disabled.push('infant'); else disabled.push('0')
    }else{
        if(roompaxLimit?.totalAdults <= roompax?.adult  ) disabled.push('adult'); else disabled.push('-1')
        if(roompaxLimit?.totalTeens <= roompax?.teen)disabled.push('teen'); else disabled.push('0')
        if(roompaxLimit?.totalChildren <= roompax?.child)disabled.push('child'); else disabled.push('0')
        if(roompaxLimit?.totalInfants <= roompax?.infant)disabled.push('infant'); else disabled.push('0')
    }
            
        return disabled;

   }



   const handleIncrement = (e, list, name, paxMax) => {
    e.preventDefault();
  
    const { room_id } = list;
  
    setOptionData((prevData) =>
      prevData.map((room) => {
        if (room.room_id === room_id) {
          const namesTotal = checkNames(name,true)
          disablePlusButton(room, name, paxKeyRange)
          if (room[name]  >= paxKeyRange[namesTotal]) {
              return room; 
            }
            checkMinPax(room, name, paxKeyRange)
            enableOrDisableMinusButton(name,false)
          const updatedRoom = incrementRoom(room, name, paxKeyRange);
          return updatedRoom;
        } else {
          return room;
        }
      })
    );
  
    scrollToFocusElement(e, undefined, mainContainerRef.current);
  };

  const checkNames = (names,isTotal)=>{
    if(isTotal){

        switch(names){
            case "adult":
                return "totalAdults"
            case "teen":
                return "totalTeens"
            case "child":
                return "totalChildren"
            case "infant":
                return "totalInfants"
            default:
                return "totalAdults"
        }
    }else{
        switch(names){
            case "adult":
                return "minAdults"
            case "teen":
                return "minTeen"
            case "child":
                return "minChild"
            case "infant":
                return "minInfant"
            default:
                return "minAdults"
        }
    }
  }
  const checkMinPax = (room,name,paxKeyRange) =>{
    const key = checkNames(name,false)
        if(room[name] + 1 < paxKeyRange[key]){
            dispatch(setIsPaxDisabled(true))
        }else{
            dispatch(setIsPaxDisabled(false))
        }
  }
  
  const incrementRoom = (room, name,paxMax) => {
    const namesTotal = checkNames(paxMax,true)
    return {
      ...room,
      [name]: room[name] <= paxMax[namesTotal] ? room[name] + 1 : room[name] ,
      paxAge: updatePaxAge(room, name)
    };
  };
  
  const updatePaxAge = (room, name) => {
    if (name !== "adult") {
      return [...room.paxAge, { [name]: 0, id: room[name] + 1 }];
    }
    return [...room.paxAge];
  };
  

    const handleDecrement = (e, list, name, paxKeyRange) => {
        e.preventDefault();
        const { room_id } = list;
       
        const key = checkNames(name,false)
        if (!canDecrement(list, name)) {
            return;
        }
        
        if((list[name] -1 ) <= paxKeyRange[key]){
            enableOrDisableMinusButton(name,true)
        } if((list[name] ) <= paxKeyRange[key]){
            return;
        }
        setOptionData((prevData) =>
            prevData.map((room) => {
                if (room.room_id === room_id) {
                    enablePlusButton(room, name, paxKeyRange)
                    disableButtonDoneWithDecrement(room, name, paxKeyRange)
                    return decrementRoom(room, name, list)
                } else {
                    return room;
                }
            }
            )
        );

        scrollToFocusElement(e, undefined, mainContainerRef.current);
    };

    const canDecrement = (list, name) => {
        return !(list[name] === 0 || (name === 'adult' && list[name] === 1));
      };
      
    const decrementRoom = (room, name, list) => {
        console.log({room,name,list});
        
        return {
            ...room,
            [name]: room[name] - 1,
            paxAge: room.paxAge.filter(
                (pax) => {
                    return !(pax.hasOwnProperty(name) && pax.id === list[name])
                }
            )
        };
    };

    const disableButtonDoneWithDecrement = (room, name, paxKeyRange) => {
        const key = checkNames(paxKeyRange, false)
        if (room[name] - 1 < paxKeyRange[key]) {
            dispatch(setIsPaxDisabled(true))
        } else {
            dispatch(setIsPaxDisabled(false))
        }
    }

    const scrollToFocusElement=(e,scrollChildren,scrollMainParent,animate=false)=>{
        e?.preventDefault();        
        e?.stopPropagation();
        // animate&&animateFading("fading-pax-height");
        scrollChildren&&setTimeout(() => {
            scrollChildren?.scrollTo({
                top: scrollChildren.scrollHeight,
                behavior: animate?"smooth":"smooth",
            });        
        }, 10);
        
        // scrollMainParent&&setTimeout(() => {
        //     scrollToDependsBoundary(scrollMainParent,"end");
        // }, scrollChildren?150:10);
    }

    const confirmButton = (e,name)=>{
        e.preventDefault();
        let disabled=hasAgeZero(optionList);
        
        if(name == "done"){
              dispatch(setIsClickedButtonDone(true))
            }        
            dispatch(setIsPaxDisabled(disabled))
            if(!disabled){
                confirmPax(e,name);
                dispatch(setIsClickedButtonDone(false))
        }
        scrollToFocusElement(e,undefined,mainContainerRef.current);
    }
    const checkedDisabledButton = (optionList,reservationAccom) =>{
        
        if(optionList.adult < reservationAccom.minAdults ){
            dispatch(setIsPaxDisabled(true))
        }else if (optionList.teen < reservationAccom.minTeen){
            dispatch(setIsPaxDisabled(true))
        }
        else if (optionList.child < reservationAccom.minChild){
            dispatch(setIsPaxDisabled(true))
        }
        else if (optionList.infant < reservationAccom.minInfant){
            dispatch(setIsPaxDisabled(true))
        }
    }

    const disablePlusButton = (room,name,paxKeyRange) =>{
        const namesTotal = checkNames(name,true)
        
        if (room[name] +1  >= paxKeyRange[namesTotal]) {
            let tempPaxKey = disabledPaxKey
            let index = 0 
            if(name == "adult") index = 0;
            if(name == "teen")  index = 1;
            if(name =="child") index = 2;
            if(name == "infant") index = 3;
            if(name != disabledPaxKey[index]){
                tempPaxKey[index] = name;
            }
            setDisabledPaxKey(tempPaxKey)
           
        }
    }
    const enableOrDisableMinusButton = (name,disabled=false) =>{
            let tempPaxKey = disabledPaxKeyMinus
            let index = 0 
            if(name == "adult") index = 0;
            if(name == "teen")  index = 1;
            if(name =="child") index = 2;
            if(name == "infant") index = 3;  
            if(disabled){
                if(name != disabledPaxKeyMinus[index]){
                    tempPaxKey[index] = name;
                }
            }else{
                if(name == disabledPaxKeyMinus[index]){
                    tempPaxKey[index] = '0';
                }
            }         
            setDisabledPaxKeyMinus(tempPaxKey)   
    }
    const enablePlusButton = (room,name,paxKeyRange) =>{
        const namesTotal = checkNames(name,true)
          
        if (room[name]   <= paxKeyRange[namesTotal]) {
            let tempPaxKey = disabledPaxKey
            let index = 0 
            if(name == "adult") index = 0;
            if(name == "teen")  index = 1;
            if(name =="child") index = 2;
            if(name == "infant") index = 3;
            if(name == disabledPaxKey[index]){
                tempPaxKey[index] = '0';
            }
            setDisabledPaxKey(tempPaxKey)
        }
    }

    const handleAgeChange=(ageValue,paxAge)=>{
        setOptionData(prevData=>prevData.map(paxInfo=>{
            const newPaxAge=paxInfo.paxAge.map(pax=>{
                if(pax[Object.keys(paxAge)[0]] !== undefined && pax.id === paxAge.id) {
                    return { 
                        ...pax,
                        ...{ [Object.keys(paxAge)[0]]: ageValue } 
                    };
                }
                return pax;
            })
            return {...paxInfo,paxAge:newPaxAge};
        }))
    }

    useEffect(()=>{
        const scrollMainParent = mainContainerRef.current;
        scrollToFocusElement(undefined,undefined,scrollMainParent);
        const calculateMinMaxAndSum = calculateMinMaxAndSumAccomAndTransfer(sortedList?.reservationAccom,sortedList?.reservationTransfer)
        
        setDisabledPaxKey(checkDisabledPaxDetails(calculateMinMaxAndSum,optionList[0],false))
        
        setDisabledPaxKeyMinus(checkDisabledPaxDetails(calculateMinMaxAndSum,optionList[0],true))
        checkedDisabledButton(optionList[0],calculateMinMaxAndSum)
        sePasKeyRange(calculateMinMaxAndSum)        
    },[setOptionData,optionList,isClickedButtonDone])

    return (
        <div id='box' className={"optionBox-pax" + (optionList.length > 1 ? " roomAdded":"")+(` ${animateClass}`)}  ref={mainContainerRef}>
            <div className={optionList.length > 1 ? "optionBox-scroll":"" }  ref={scrollRef}>
                {
                    optionList.map((paxData,paxInd)=>{
                        return (
                            <div  key={"room_pax_"+paxData.room_id} className={optionList.length > 1 ?"room-list ":""}>
                            {
                                PAXkEYS.map((data,ind)=>(
                                    <div  key={ind} className='paxbox-row'>
                                        <div className="label">{capitalizeFirstLetter(data)}</div>
                                        <div className='OptionValueBox-pax'>
                                            <div className={  disabledPaxKeyMinus[ind] == data ? 'pax_decrement disable':'pax_decrement'} onClick={(e) => { handleDecrement(e,paxData,data,paxKeyRange)}}></div>
                                            <div className="paxValueBox">{paxData[data]}</div>  
                                            <div className={  disabledPaxKey[ind] == data ? 'pax_increment disable':'pax_increment'} onClick={(e) => {handleIncrement(e,paxData,data,roomPax)}}></div>
                                            {/* <div className={'pax_increment'} onClick={(e) => {handleIncrement(e,paxData,data,paxKeyRange)}}></div> */}
                                        </div>
                                    </div> 
                                ))
                            }
                              <div className='age-section-container'>
                                {
                                    paxData?.paxAge.length > 0 && <p>Please specify the ages of pax on arrival:</p>
                                }
                                {    
                                    paxData?.paxAge.length > 0 && 
                                    <div className='age-container'>
                                                {paxData?.paxAge.map((ageList, ind) => (<PaxAgeBookingTransfer key={ind} ageInd={ind} data={ageList} onAgeChange={(age)=>handleAgeChange(age,ageList)}  />))}
                                    </div>
                                }
                                {
                                     paxData?.paxAge.length > 0 &&  <div className='errMsg'>{isClickedButtonDone && "You need to specify age to continue your search"}</div>
                                }
                            </div>
                            { paxKeyRange &&
                            
                            <div className='age-section-container-booking'>
                                     <p>Please note that your dossier <br/></p>
                                    <div className='age-section-container-booking-inner'>
                                        <div><p>needs to have :</p> </div>
                                        <div>
                                            <p>
                                            <span className='min-pax'> Min pax {paxKeyRange?.minPax}&nbsp;| Max pax {paxKeyRange?.maxPax} </span> <br/>
                                                {/* <span className='min-pax'> Min adults {paxKeyRange?.minPax}&nbsp;| Max adults {paxKeyRange?.maxPax} </span> <br/>
                                                <span className='min-pax'> Min teen   {paxKeyRange?.minTeen}   &nbsp; | Max teens {paxKeyRange?.maxTeen} </span> <br/>
                                                <span className='min-pax'> Min child  {paxKeyRange?.minChild} &nbsp;| Max child {paxKeyRange?.maxChild} </span> <br/>
                                                <span className='min-pax'> Min infant {paxKeyRange?.minInfant} | Max infants {paxKeyRange?.maxInfant} </span>  */}
                                            </p>
                                        </div> 
                                         
                                     </div>
                                     
                            </div>
                            }
                            <div>
                               {cname === 1 && <Client_dropdown data={CLIENTDATA} label={paxData.clientType} phase={1} setOptionData={setOptionData} optionList={optionList} roomId={paxData.room_id}/>}
                            </div>
                        </div>    
                    )})
                }
            </div>
            
            <div className={"pax-button" + (optionList.length&&optionList.length>0?" pax-age-added":"")}> 
                <div className="pax-done-btn">
                    <div className="paxClear" onClick={(e) => { clearData(e) }} >Clear Pax</div>
                    <button disabled={isPaxDisabled} className={isPaxDisabled ? 'paxDone disable' : 'paxDone' } onClick={(e) => { confirmButton(e, "done");}}>Done</button>
                </div>
            </div>
        </div>
    );
}

export default PaxDropdownBooking;