import React,{useState,useEffect} from 'react';
import ListTransfer from './ListTransfer.js';
import ButtonBlock from '../MiniComponents/ButtonBlock.js';
import { useSelector,useDispatch } from 'react-redux';
import {checkDelete, resetDelete} from "../../store/errorReducer.js"
import {DeletePopup} from "../Transfer/SaveCotation.js"
import MessagesPopup from '../MiniComponents/MessagesPopup.js';
import { Skeleton } from '@mui/material';
import usePopupData from '../../hooks/usePopupData.js';
import SameInputs from '../Excursion/SameInputs.js';
import { commitRemoveTransfer, rollBackRemoveTransfer, setIsSameVehicle, startRemoveTransfer } from '../../store/transferReducer.js';
import { scrollToSectionv1 } from '../../utility/globalFunc.js';

function TransferInputs({scroll, gridStyle}) {
    const [searchClick,setSearchClick] =useState(false)
    const [gridStructure, setgridStructure] = useState({ "gridTemplateColumns": "226px 265px 372px 61px 362px", "display": "grid", "position": "relative", })
    const {transferUpdates, loading, isSameVehicle, transferUpdatesCopy}=useSelector((state) => state.transfer_Global);
    const {isActive:delActive,isPopup} = useSelector((state) => state.errorreducer.delete)
    const {messages} = useSelector((state) => state.errorreducer) 
    const {isOpen,openPopup,getPopupContent}=usePopupData() 
    const [popupclose,setpopupclose] =useState(false)
    const [isContainerScrollable,setIsContainerScrollable]=useState(false);


    const dispatch = useDispatch();

    useEffect(() => {
        if (window.location.href.includes('/select-vehicle')) {
            setgridStructure ({ "gridTemplateColumns": "226px 265px 328px 35px 328px", "display": "grid", "position": "relative", })
        }

        return ()=>{
            dispatch(resetDelete());
        }
    }, [])
    

    useEffect(() => {
        if(popupclose){
            openPopup("del-message")
            const timeoutId = setTimeout(() => {
                setpopupclose(false)
              }, 2000); 
        
              return () => {
                clearTimeout(timeoutId);
            };
        }
        
    }, [popupclose]);
    
    
    // to open the delicom and done button
    const deleteItemClick = (e,name)=>{
        e.preventDefault();    
            if(name === "open_delpopup"){
                let sendObj = {delbtn:true,delpop:false}
                dispatch(checkDelete(sendObj));
                dispatch(startRemoveTransfer());
                setIsContainerScrollable(false);
            }
            else{
                let sendObj = {delbtn:false,delpop:false}
                setSearchClick(false)
                if (transferUpdates.length!==transferUpdatesCopy.length) {
                    openPopup("del-message");
                }
                dispatch(checkDelete(sendObj))
                dispatch(commitRemoveTransfer())
            }
        
    }

    const onSameVehicleChange=(isChecked)=>{
        dispatch(setIsSameVehicle(isChecked));
    }

    const cancelDeleteMode = () =>{
        dispatch(resetDelete());
        dispatch(rollBackRemoveTransfer());
    }

    const toggleScroll = () => {
        setIsContainerScrollable(prev => !prev);
        if(isContainerScrollable){
            scrollToSectionv1('buttonBlocks', 0,600)
        }
        else{
            scrollToSectionv1('selectDropdown',0,0)
        }
    }

    const isSameInputDisable = () =>{
        if (delActive) {
            return true;
        }

        if (transferUpdates.length<2) {
            return true;
        }

        return false;
    }

    useEffect(() => {
        if (transferUpdates.length === 4 && !delActive) {
            setIsContainerScrollable(true);
        }
    }, [transferUpdates])
    
    return (
        <>
            <div className={`transfer_box ${delActive?"danger":""}`}>
                <div className="headTransfer">
                    <h2 className={`transfer-input-title ${delActive && "danger"}`}>{delActive?"Remove Transfer":"Select your transfer details"}</h2>
                    <SameInputs label={"Use the same vehicle for all your transfers"} type="checkbox" data={isSameVehicle} onchange={onSameVehicleChange} disable={isSameInputDisable()}/>
                    {
                        delActive?
                        <div className="cancel-remove" onClick={cancelDeleteMode}>
                            Cancel
                        </div>
                        :
                        <div className={`delTransferBtn ${delActive || transferUpdates.length<2 ? "disable":""}`} onClick={(e) => { deleteItemClick(e, "open_delpopup") }} id="deleteButton" >
                            Delete transfer
                        </div>
                    }
                </div>
                <ListTransfer styles={gridStyle} delStatus={delActive} lists={transferUpdates} errchk={searchClick} updateerr={setSearchClick}  scroll={scroll} noClick={false} isScrollable={isContainerScrollable} isInputBox={true}/>
                <ButtonBlock addTransfer={true} delbtn={delActive} deleteItemClick={deleteItemClick} setSearchClick={setSearchClick} errchk={searchClick} onDisplayClick={toggleScroll} isListScrollable={isContainerScrollable}/> 
            </div>
            { isPopup ?<div className='deletepopup_container'><DeletePopup  setSearchClick={setSearchClick} setpopupclose={setpopupclose}/></div>:<></> }
            {messages !== null ?<div className='deletepopup_container'><MessagesPopup data={messages}/></div>:<></>}
            {isOpen ? getPopupContent():<></>} 
            
        </>
    )}

export default TransferInputs;