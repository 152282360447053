import React,{useRef} from 'react';
import useOutsideClick from '../../hooks/useOutsideClick.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { CategoryItem, PaxDetailsTagsV1 } from './Atom.js';

import useMoreOptionBooking from '../../hooks/useMoreOptionBooking.js';
import { useSelector } from 'react-redux';
import { getPaxCategoryByAge } from '../../utility/globalFunc.js';
import BookingCancellationList from './BookingCancellationList.js';
import { CircularProgress } from '@mui/material';
import { BOOKING_PAGE, ROUTE_PATHS } from '../../constants.js';
import { useNavigate } from 'react-router-dom';

function CancelBooking({closePopup,openNew,toPath,booking}) {
    const {subAgencyAdded,userData} =useSelector((state) => state.authpath);
    const {agency_name,first_name,last_name,agency_id} =userData; 
    const [loading, setLoading] = useState(false);
    const navigate=useNavigate();

    const btnref = useRef(null);
    const {cancelBooking}=useMoreOptionBooking()
    let isSubAgencyHaveValue = Object.keys(subAgencyAdded).length > 0
    useOutsideClick(btnref, () => {
        closePopup();
    });

    const onNext=(e)=>{
        e.preventDefault();
        setLoading(true);
        cancelBooking({
            refcode:booking.refCode,
            onSuccess:(res)=>{
                setLoading(false);
                navigate(ROUTE_PATHS.QUOTATION),{state:{
                    idBooking:booking.IdBooking,
                    bookingName:booking.refCode,
                    page:BOOKING_PAGE
                }};
            },
            onError:(error)=>{
                const message=error.response?.data?.message??"Error when cancelling booking"
                alert(message)
                setLoading(false);
            }
        })
    }

    const getPax=()=>{
        const {Clients}=booking;
        const pax={
            AdultCount: 0,
            ChildCount: 0,
            InfantCount: 0,
            TeenCount: 0
        }
        for (const client of Clients) {
            const category=getPaxCategoryByAge(client.age)+"Count";
            pax[category]=pax[category]+1;
        }
        return pax
    }

    return (
        <div className='dialogue-cancel-booking' ref={btnref}>
            <div className='dialogue-cancel-booking-text'>
                <p >Cancel booking</p> 
                <p>This action is irreversible, and you won't be able to undo it. If you are certain 
                 <br/> you want to cancel your booking, please confirm below. </p>
            </div>
            <div className='dialogue-cancel-booking-details'>
                <div className='dialogue-cancel-booking-details-box1'>
                    <p className='client-name'>{booking.refCode} #{booking.IdBooking}</p>
                    <p className='booking-cancellation'>Booking cancellation</p>
                    <PaxDetailsTagsV1 paxDetails={getPax()}/>
                </div>
                <div className='dialogue-cancel-booking-details-box2'>
                        <div className='cotDocdetails'>
                                <p>Document details</p>
                                { <div><span className="label" style={{ marginTop: "0px" }}>Booking nb:</span> <span style={{ marginTop: "0px" }} className="value">{booking.IdBooking}</span></div>}
                                <div><span className="label">Agency:</span> <span className="value">{isSubAgencyHaveValue ? subAgencyAdded.agency_name: agency_name}</span></div>
                                <div><span className="label">Client-Type:</span> <span className="value">Standard</span></div>
                        </div>
                </div>
            </div>
            <BookingCancellationList booking={booking}/>
            <div className='dialogue-cancel-booking-container-button' >
                <button className={`${loading?"loading":""}`} onClick={onNext} disabled={loading}>Confirm cancellation
                    {loading && (
                        <CircularProgress
                            color="secondary"
                            style={{ width: "20px", height: "20px", color: "#fff", marginLeft: "8px" }}
                        />
                    )}
                </button> 
            </div>
    </div>
            
    );
}

export default CancelBooking;

