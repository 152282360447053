import React, { forwardRef,Fragment, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {DateAtom,OfferDetails} from "../Accomodation/minicomponents/Atom.js";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { updateRegion,clearTransfer ,addRefcode } from "../../store/transferReducer.js";
import useFinalBooking from '../../hooks/useFinalBooking.js';
import useBookingService from "../../hooks/useBookingService.js"
import {splitDescription,capitalizeFirstLetter, pluralize, BOOKING_PAGE,DROPDOWN_DATA, NAVBAR_HEIGHT, checkAllRoomValid,MORE_OPTIONS_MODE, checkAllTransferValid, ROUTE_PATHS, BASE_ROUTE_PATH} from "../../constants"
import {calculateNights, centerElement, formatDate,dateToConventionalFormat, getElementNbOfLines, getOfferType, getOrdinals, scrollToDependsBoundary, scrollToElement,getBeforeUnderscore, formatPrice, separateurMillier, docketTotalPrice, getClientTypeByAcronyme, properCase} from "../../utility/globalFunc.js"
import useOutsideClick from '../../hooks/useOutsideClick.js';
import useClosestOverflowParent from '../../hooks/useClosestOverflowParent.js';
import usePopupData, { OFFERS_POPUP } from '../../hooks/usePopupData.js';
import {clearexcursion} from "../../store/excursionReducer.js"
import {clearPax,clearAll,addRefToBooking, triggerNotifDelete, setActionMode} from "../../store/globalReducer.js"
import {clearAccom, setPageLoading} from "../../store/accomodationReducer.js"
import cotationclose from "../../Assets/images/close_cotation1.png"
import DefaultMainHotelImage from "../../Assets/images/default-img/hotel-main.jpg"
import arrowPagination from "../../Assets/images/arrow-pagination.png";
import arrowNoPagination from "../../Assets/images/arrow-no-pagination.png";
import AmenitiesStatic from '../../Assets/images/accomodation/staticImages/amenitiesStatic.png';
import Amenities from './Amenities.js';
import LazyImage from './LazyImage.js';
import { getOrdinal } from '../../constants';
import useAddItems from '../Quotations/hooks/useAddItems.js';
import CircularProgress from '@mui/material/CircularProgress';
import successImageNotif from "../../Assets/images/popup/successBookingNotif.svg";
import declinedImageNotif from "../../Assets/images/popup/declinedBookingNotif.svg";
import trash from "../../Assets/images/delete.svg";
import trashInvalid from "../../Assets/images/trash-grey.svg";
import trashHover from "../../Assets/images/trash-hover.svg";
import deleteTransfer from "../../Assets/images/popup/deleteTransfer.svg";
import arrow from "../../Assets/images/arrow.svg";
import useQuotationService from "../../hooks/useQuotationService.js"
import { setIsPaxDisabled } from '../../store/transferReducer.js';
import useResetSearch from '../../hooks/useResetSearch.js';


export const ImageWithFallBack = forwardRef(({ src, alt, width, height, onLoad = () => { }, onClick = () => { }, style = {}, className = "", fallBackImg = "", onError = undefined, onMouseEnter}, ref) => {
    const handleError = (e) => {
        onError && onError(e);
        e.target.onerror = null;
        if (e.target.src !== fallBackImg) {
            e.target.src = fallBackImg;
            e.target.onerror = null;
        }else{
            e.target.onerror = null;
        }
    }
    if (width) {
        style.width = width;
    }
    if (height) {
        style.height = height;
    }
    useEffect(() => {
        if (!src) {
            onError && onError();
        }
    }, [src])
    return (
        <img src={src || fallBackImg} ref={ref} alt={alt} style={style} className={className} onLoad={onLoad} onClick={onClick} onError={handleError} onMouseEnter={onMouseEnter}/>
    )
})

export function Description ({label,details,listType}){
    const [nbLines,setNbLines]=useState(false);
    const descriRef=useRef(null);
    useEffect(()=>{
        let element=descriRef.current
        if (element) {
            let style=getComputedStyle(element)
            const maxHeight = parseFloat(style.maxHeight);
            const isMultiline = element.scrollHeight > maxHeight;
            setNbLines(isMultiline);
        }
    },[descriRef,details,label]);
    // styles are visually good confirmed ny malikah
    return(
        <div style={{color:"#505050"}}>
            <label className='description-label'><b className='description-title'>{label ? label:""}</b></label>
            <div style={{fontWeight:"400"}} ref={descriRef} className={`hotel-card-description ${nbLines?"limited-nbr-lines":""}`}>{details && listType ? splitDescription(details): !listType ?splitDescription(details): ""}</div>
        </div>
    )
}
export function NearBy_Region({ nearRegion }) {

    // styles are visually good confirmed by Malikah
    return (
        <div className='parent_container_near_region'>
            {/* //info temperature statique */}
            {/* <div className='container_near_region'> */}
                {/* <span className='infoTemp'>Temperature moyenne en juillet : 24°- 27°</span><br /> */}
            {/* </div> */}
            {nearRegion.map((region, key) => (
                <div key={key}  className='container_near_region'>
                    <span className='textRegion'>Near {region}</span><br />
                </div>
            ))}
        </div>
    );
}

export function RoomOffers(){
    
}

export const Button =({styles,label})=>{
    return(
        <button style={styles}>{label}</button>
    )
}


export const PaxAge = ({ data, onAgeChange, isDisabled, setIsDisabled=undefined})=>{
    const ages = Array.from({ length: 18 }, (_, index) => index + 1);
    const [showDropdown,setShowDropdown]=useState(false);
    const [age,setAge]=useState(data[Object.keys(data)[0]]?data[Object.keys(data)[0]]:null);
    const elementRef=useRef(null);
    const overflowParent=useClosestOverflowParent(elementRef);
    
    const handleSelectChange = (value) => {
        if (value && setIsDisabled){
            setIsDisabled(false)
        }
        setAge(value);
        onAgeChange&&onAgeChange(value);
        setShowDropdown(false);
    };

    const onClickShow=()=>{
        setShowDropdown(!showDropdown)
    }

    useOutsideClick(elementRef,()=>{
        setShowDropdown(false);
    })

    useEffect(()=>{
        setTimeout(() => {
           centerElement(elementRef.current,overflowParent.current);
        }, 10);
    },[]);

    return(
        // <div style={{marginBottom:"10px"}}>
            <div className='sub-age-container'>
                <div className='category-title'>Age, {capitalizeFirstLetter(Object.keys(data)[0])}  {Object.values(data)[1]}:</div>
                <div className='select-age-wrapper' ref={elementRef}>
                <div className={"select-age "+(showDropdown?"clicked":"")+(isDisabled&&!age?" disabled":"")} value={data[Object.keys(data)[0]]} onClick={onClickShow}>
                    {age||'--'}
                    
                </div>
                {
                    showDropdown &&
                    <div className='age-choice-container'>
                        {
                            ages.map((item) =>(<div key={"age_"+item} className={age===item?"selected":""} onClick={()=>handleSelectChange(item)}>{item}</div>))
                        }
                    </div>
                }   
                </div>
            </div> 
        // </div>
    )
}
export const PaxAgeBookingTransfer = ({ data, onAgeChange})=>{
    const dispatch = useDispatch();
    const ages = Array.from({ length: 18 }, (_, index) => index + 1);
    const [showDropdown,setShowDropdown]=useState(false);
    const [age,setAge]=useState(data[Object.keys(data)[0]]?data[Object.keys(data)[0]]:null);
    const elementRef=useRef(null);
    const overflowParent=useClosestOverflowParent(elementRef);
    const {isPaxDisabled,isClickedButtonDone} = useSelector((state)=> state.transfer_Global)
    
    const handleSelectChange = (value) => {
        if (value && isPaxDisabled && isClickedButtonDone){
            dispatch(setIsPaxDisabled(false))
        }
        setAge(value);
        onAgeChange&&onAgeChange(value);
        setShowDropdown(false);
    };

    const onClickShow=()=>{
        setShowDropdown(!showDropdown)
    }

    useOutsideClick(elementRef,()=>{
        setShowDropdown(false);
    })

    useEffect(()=>{
        setTimeout(() => {
           centerElement(elementRef.current,overflowParent.current);
        }, 10);
    },[]);

    return(
        // <div style={{marginBottom:"10px"}}>
            <div className='sub-age-container'>
                <div className='category-title'>Age, {capitalizeFirstLetter(Object.keys(data)[0])}  {Object.values(data)[1]}:</div>
                <div className='select-age-wrapper' ref={elementRef}>
                <div className={"select-age "+(showDropdown?"clicked":"")+(isClickedButtonDone &&!age?" disabled":"")} value={data[Object.keys(data)[0]]} onClick={onClickShow}>
                    {age||'--'}
                    
                </div>
                {
                    showDropdown &&
                    <div className='age-choice-container'>
                        {
                            ages.map((item) =>(<div key={"age_"+item} className={age===item?"selected":""} onClick={()=>handleSelectChange(item)}>{item}</div>))
                        }
                    </div>
                }   
                </div>
            </div> 
        // </div>
    )
}


export const Back =({label,name, marginBottom=""})=> {
    const navigate = useNavigate();
    const { isOpen, openPopup, getPopupContent } = usePopupData();
    const {sortedList} = useSelector((state) => state.globalreducer)
    const {roomPax,pendingRooms,selRooms} = useSelector((state) => state.accomodationreducer)
    const pathname = window.location.pathname;
    const DEFAULT_PATH= ROUTE_PATHS.ACCOMMODATION;
     
    const style={marginBottom}
    const firstSubpages = DROPDOWN_DATA.map(item => item.subpage[0]);
    const isRestricted =  firstSubpages.includes(pathname)    
    const isSortedListLength = Object.values(sortedList.services).some(array => array.length > 0);

    let {requiredNbRooms} = pendingRooms


    
    const goBack=(e)=>{
        e.preventDefault();
        
        if (pathname === ROUTE_PATHS.SELECTED_ACCOMMODATION){
            if(requiredNbRooms > 0){
                let isvalidRoom = checkAllRoomValid(pendingRooms)
                if(!isvalidRoom){
                    openPopup("dialogue-box","",ROUTE_PATHS.ACCOMMODATION_SELECT) 
                    return;
                }
            }
            navigate(ROUTE_PATHS.ACCOMMODATION_SELECT);
        }
        else if(pathname === ROUTE_PATHS.ACCOMMODATION_SELECT && selRooms.length ){
        //     console.log("pathname /accomodation-select test:",pathname);
        //     alert()
        //    if(requiredNbRooms !== selRooms.length) {
            // openPopup("dialogue-box","",DEFAULT_PATH) 
            navigate(ROUTE_PATHS.ACCOMMODATION)
        //    }
        //    else{

        //    }
        }
        else{
            const regex=/^\/?b2b\//;
            const route=regex.test(name)?name:`/${BASE_ROUTE_PATH}/${name}`;
            (isRestricted && isSortedListLength) ? openPopup("dialogue-box", "", DEFAULT_PATH) : navigate(route);
        }
    } 


    return (
        <>
            <div className='back_items' style={style}>
                <div className='back-txt' onClick={(e)=>{goBack(e)}}>Back</div> 
                <div className='back-label'>{ label}</div>
            </div>
            {isOpen ? getPopupContent():<></>}
        </>
    );
}

// export const Supplement =({data,name,supmod,notes,lugtype,detailId,regionId,infant=0})=>{
    //const{ infant:Infant} =useSelector((state) => state.transfer_Global.transferPax[0])    
export const Supplement =({data,name,supmod,notes,lugtype,detailId,regionId,infant=0})=>{
    const { tempId: currentTransferId } = useSelector((state) => state.transfer_Global);
    const {ChildCount: Child=0,InfantCount: Infants=0,AdultCount:Adults=0,TeenCount:Teens=0 } = useSelector((state) => state.transfer_Global.transferUpdates[currentTransferId] || {});
    const Infant = Child+Infants;
    const TotalPax = Adults+Teens+Infant;
      



    let {Currency:cur,BabySeatPrice,MaxAllowedBabySeat:max_baby_seats,LuggageCar,
        LuggageTruck,baby_seat_count:babyseat,lug_car_count:carlug,
        lug_truck_count:trucklug,final_price,MaxPax }=data


    let split = name.split(" ")
    const dispatch = useDispatch()
    
    const handleCounter=(e,name)=>{
        e.preventDefault();
        console.log("Infant:",Infant);
        console.log("TotalPax:",TotalPax, MaxPax);
        console.log("No of car:",);
        const totalCars = Math.ceil(TotalPax/MaxPax);

        
        if(lugtype === ""){
            if (Infant>0){
                if (name === "inc" &&  Infant  > babyseat) {
                    let count = babyseat + 1
                    let price = BabySeatPrice * count
                    let finalprice = final_price + BabySeatPrice
                    dispatch(updateRegion({ detailId, regionId, name: "baby_seat_count", count, price, pname: "new_babyseat_price", finalprice }))
                }
                else if (name === "dec" && babyseat > 0) {
                    let count = Math.max(0, babyseat - 1)
                    let price = BabySeatPrice * count
                    let finalprice = final_price - BabySeatPrice
                    dispatch(updateRegion({ detailId, regionId, name: "baby_seat_count", count, price, finalprice }))
                }
            }
            else{
                alert("There’s no Infant or Child in your pax selection" )
            }
        }
        else if (lugtype === "Cars"){
            // && carlug < luggage_car.capacity
            if (name === "inc" && LuggageCar?.capacity > carlug ){
                let count = carlug +1
                let price = LuggageCar?.price * count
                let finalprice = final_price + LuggageCar?.price
                dispatch(updateRegion({detailId,regionId,name:"lug_car_count",count,price,finalprice}))

            }
            else if (name === "dec" && carlug > 0){
                let count = Math.max(0, carlug - 1)
                let price = LuggageCar?.price * count
                let finalprice = final_price - LuggageCar?.price
                dispatch(updateRegion({detailId,regionId,name:"lug_car_count",count,price,pname:"LuggageCar",finalprice}))

            }
        }
        //truck
        else{
            // && trucklug < luggage_car.capacity
            if (name === "inc" && LuggageTruck?.capacity > trucklug){
                 let count = trucklug +1
                 let price = LuggageTruck?.price * count
                 let finalprice = final_price + LuggageTruck?.price
                 dispatch(updateRegion({detailId,regionId,name:"lug_truck_count",count,price,pname:"LuggageTruck",finalprice}))
 
            }
            else if (name === "dec" && trucklug > 0){
                let count = Math.max(0, trucklug - 1)
                let price = LuggageTruck?.price * count
                let finalprice = final_price - LuggageTruck?.price
                dispatch(updateRegion({detailId,regionId,name:"lug_truck_count",count,price,pname:"LuggageTruck",finalprice}))
            }
        }
        
    }


    return(
        <>
            {/* <div className={'supplement-row '+(notes === true ? "":Infant === 0 && notes === false ? "order":"")}> */}
            <div className={'supplement-row '}>
                <div className={'sup-text '+(supmod ===2 ? "sup-text-lugg":"")}>
                    <div>{split[0]}<br/>{split[1]}</div>
                    <div className='sup-txtryt'>
                        {supmod === 1 ? "Add your baby seat":"Additional luggage capacity" }<br/>
                        <span>{lugtype === "" ? `This car can only take ${max_baby_seats} baby seats`: lugtype === "Cars"?LuggageCar?.capacity:LuggageTruck?.capacity }</span>
                    </div>
                </div>
                {notes ? <div className='sup-notes-capacity'>{}Please note this item is for extra luggage in addition of your luggage already included </div>:<></>}
                <div className='sup-button'>
                    <div><span>{cur ==="MUR" ?<span>&#8360;</span>:cur ==="EUR" ? <span>&euro;</span>:<span>&#x0024;</span>} {lugtype === "" ? BabySeatPrice : lugtype === "Cars" ?LuggageCar?.price:LuggageTruck?.price }.00 </span></div>
                    <div className='supplement-count'>
                        <span onClick={(e)=>{handleCounter(e,"dec")}}></span>
                        <div className='paxValueBox'>{lugtype === "" ? babyseat :lugtype === "Cars" ? carlug+` Car${carlug >1 ? "s":""}` : trucklug+` Truck${trucklug >1 ? "s":""}`}</div> 
                        <span onClick={(e)=>{handleCounter(e,"inc")}}></span>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export const CarDetails =({carInfo, visitMode})=>{
    //let {min_pax,max_pax,air_condition,luggage_capacity,max_baby_seats,cat_name}=carInfo
    let {Category,MaxPax,MinPax,MaxLuggage,MaxAllowedBabySeat,VehicleType}=carInfo
    
    return(
        <>
            <div className="carCategory">{capitalizeFirstLetter(Category)} {capitalizeFirstLetter(VehicleType)}</div>
            {/* <div className="carCategory">{properCase(Category)}</div> */}
           {/* {!visitMode && 
           } */}
            <div style={{display : visitMode ? "none ":"" }} className="car_details_block">
                <div className="car_details"><span>Maximum passenger capacity</span>{MinPax} - {MaxPax} Pax </div>
                <div className="car_details"><span>Air conditioned vehicle</span>{""} </div>
                <div className="car_details"><span>Maximum luggage capacity</span>{MaxLuggage} Suitcase and hand bag per pax </div>
                <div className="car_details"><span>Baby/booster/child seats</span><p style={{ color: MaxAllowedBabySeat > 0 ? "#6DA767":"#F67D7D"}}>{MaxAllowedBabySeat > 0 ? "Applicable" :"Not applicable"}</p> </div>
                <div className="car_details" style={{ marginTop: "10px"}}><span>Other supplements</span><p style={{ color: "#6DA767" }}>Applicable</p> </div>

            </div>
        </>
        
    )
}

export const HeadingNav =forwardRef(({data,updatefn,selId},ref)=>{
    const parentRef=useRef(null);
    const childsRef=useRef([]);
    const [barWidth,setBarWidth]=useState(200);
    const [barMarginLeft,setBarMarginLeft]=useState(0);

    useImperativeHandle(ref,()=>({
        scrollIntoView:()=>{
            scrollToElement(parentRef.current,"start","smooth",-NAVBAR_HEIGHT-1);
        },
        clickOption:(index)=>{
            let optionRef=childsRef.current[index];
            if (optionRef) {
                optionRef.click()
            }
        }
    }))

    useEffect(() => {
        if (parentRef.current) {
          const childElement = parentRef.current.children[selId];
          if (childElement) {
            setBarWidth(childElement.offsetWidth)
            let marginLeft=selId===1?0:childElement.offsetLeft;
            setBarMarginLeft(marginLeft);
          }
        }
      }, [selId]);
    return(
        <div className='accom-Heading' ref={parentRef}>
            <div className='bar-selected' style={{ width:`${barWidth}px`,marginLeft:`${barMarginLeft}px` }}></div>
           {data.map((item, index) => (
            <div ref={ref=>childsRef.current[index]=ref} key={index} className={selId === index+1 ? 'labels selected':'labels'} onClick={(e)=>{updatefn(index+1)}}>
                <span>{item}</span>
            </div>
            ))}
        </div>
    )
})

export const AmenitiesPattern =({data=[],pattern="lr"})=>{
    console.log("AmenitiesPattern:",data);
    
    return(
        <Fragment key={"amenities-pattern"}>
            {
                data && data.map((item,ind)=>{
                    const patternId=pattern[ind%pattern.length];
                    return(
                        
                        <Fragment key={"amenities-item"+ind}>
                            {
                                typeof item === 'object' ?
                                    <div key={ind} className={ `accordion-section ${patternId}`}>
                                    {
                                        typeof item === 'object' ?
                                        < >
                                            <div className='accordion-image-container'>
                                                <LazyImage 
                                                    className='accordion-image' 
                                                    src={item === null || item?.img === null ?AmenitiesStatic:item.img} 
                                                    alt="amenities img" 
                                                    effect='blur'
                                                    />
                                            </div>
                                            <div>
                                                <b>{item?.title}</b><span>{item?.desc}</span>
                                            </div>
                                        </>
                                        :<>{item}</>
                                        
                                    }
                                    </div>
                                :<div className='other-element'>
                                    {item}
                                </div>    
                            }
                       
                        </Fragment>
                    )
                })
            }
        </Fragment>
        
    )
}
export const AccomTable =({list})=>{

    let nbNights=calculateNights(list.date[0],list.date[1]);
    return(
        <>
            <div className='first-item-category'>
                {/* <div className='accomIcon-table'>Accomodation</div> */}
                <CategoryItem icon={"../../Assets/images/global-icon/accomodation-icon.svg"} title={"Accomodation"}>
                    <div className='category-hotel-name'>{list.hotelName}</div>
                </CategoryItem>
            </div>
            <div>
                <div className='nb-nights'>{nbNights} {pluralize(nbNights,"night","nights")}</div>
                <div className='hotel-name wrap-text'>{list.name}</div>
                <div className='client-type'>Client type: {capitalizeFirstLetter(list.ClientCategory || list.ClientType || "STD", false)}</div>
                <div>
                    <b>Meal Plan:</b>
                    <span className='meal-plan'>{list.plan}</span>
                </div>
            </div>
            <div className='date-container'>
                <span className='date'>{list.date[0] } <span className='date-arrow'>&rarr;</span> </span>
                <span className='date'>{list.date[1]}</span> 
            </div>
            <div><PaxDetailsTags paxDetails={list.roomPax[0]}/></div>
            <div className='total-line-price'>EUR {list.price.toLocaleString()}</div>   
        </>
    )
}

export const AccomTableV1 =({list})=>{
    return(
        <>
        
            <div className='first-item-category'>
                {/* <div className='accomIcon-table'>Accomodation</div> */}
                <CategoryItem icon={"../../Assets/images/global-icon/accomodation-icon.svg"} title={"Accomodation"}>
                {list.reservationAccom.map((reservationAccomData,indexOne)=>{
                    return(
                        <div className='category-hotel-name' key={'reservationAccomData'+indexOne}>{reservationAccomData.HotelName}</div>
                    )
                })}
                </CategoryItem>
            </div>
            <div>
                {list.reservationAccom.map((reservationAccomDataDetails,indexOne)=>{
                    let nbNights=calculateNights(reservationAccomDataDetails.Rooms[0].Dates[0],reservationAccomDataDetails.Rooms[0].Dates[1]);
                    return(
                        <Fragment key={indexOne}>
                            <div className='nb-nights'>{nbNights} {pluralize(nbNights,"night","nights")}</div>
                            {reservationAccomDataDetails.Rooms.map((reservationAccomDataRoom,indexTwo)=>{
                                return(
                                   < Fragment key={indexTwo}>
                                    <div className='hotel-name'>{reservationAccomDataRoom.RoomName}</div>
                                    <div className='client-type'>Client type STD</div>
                                    <div>
                                        <b>Meal Plan :</b>
                                        <span className='meal-plan'>{reservationAccomDataRoom.MealPlan}</span>
                                    </div>
                                   </Fragment> 
                                )
                            })}
                        </Fragment>
                    )
                })}
            </div>
            {
                list.reservationAccom.map((reservationAccomDataDetails,indexOne)=>{
                    return(
                        <div className='date-container'>
                            <span className='date'>{reservationAccomDataDetails.Rooms[0].Dates[0] } <span className='date-arrow'>&rarr;</span> </span>
                            <span className='date'>{reservationAccomDataDetails.Rooms[0].Dates[1]}</span> 
                        </div>
                    )

                })
            }
            {
                list.reservationAccom.map((reservationAccomDataDetails,indexOne)=>{
                    return (
                        reservationAccomDataDetails.Rooms.map((reservationAccomDataRoom,indexTwo)=>{
                            return(
                                <>
                                <div><PaxDetailsTagsV1 paxDetails={ reservationAccomDataRoom}/></div>
                                <div className='total-line-price'>{reservationAccomDataDetails.Currency} { reservationAccomDataDetails.Total.toLocaleString()}</div>   
                            </>
                            )
                        })
                    )

                })
            }
            
        </>
    )
}

export const AccomTableV2 = ({ isViewBooking, closePopup,list,isDelete }) => {
    
    const dispatch = useDispatch();
    const { isOpen, openPopup, getPopupContent } = usePopupData();
    let {reservationAccom,reservationTransfer}=list
    let allService = [...reservationAccom, ...reservationTransfer];
    let allServiceLength = allService.length;
    const [showError,setShowError]=useState(false);
    const [errorClass,setErrorClass]=useState(false);
    const [hover, setHover] = useState(false);
    const [currentOffers, setCurrentOffers]=useState([])
    const handleMouseEnter = () => {
        setErrorClass(true);
        setShowError(true);
    };
    const [showRemovePopup, setshowRemovePopup] = useState(false);
    const handleMouseLeave = () => {
        setErrorClass(false);
        setTimeout(() => {
            setShowError(false);
        }, 100);
    };
    const {deleteQuotation}=useAddItems()
    const {deleteBookingItem}=useBookingService()

    const [payloadToDetele, setpayloadToDetele] = useState(null);
    const [selectedItem, setselectedItem] = useState(null);

    
    const buildPayload = (data) => {
        const refCode = list.ToRef ? list.ToRef : list.refCode;
        if (!isViewBooking) {
            return { "to_ref": refCode, "service_type": "ACCOM", 'file_no': list.QuoteId, "hotel_id": data.HotelId }
        }
        return {ToRef: refCode, data: data};
    }

    const removeItems=(data)=>{
        setselectedItem(data);
        setshowRemovePopup(true);
        setpayloadToDetele(buildPayload(data));
    }
    
    const removeItemQuotation = () =>{
        deleteQuotation(payloadToDetele, MORE_OPTIONS_MODE.REMOVE_ITEM).then(rep => {
            dispatch(setActionMode(null));
            setTimeout(() => {
                dispatch(triggerNotifDelete(true));
            }, 300);
        }).catch(err => {
            dispatch(setActionMode(null))
            alert('An Error occurred.')
            console.log('Error delete Quotation', err);
        }).finally(()=>{
            closePopup();
        })
    }

    const removeItemBoking = () =>{
        
        deleteBookingItem(payloadToDetele).then(rep => {
            dispatch(setActionMode(null));
            setTimeout(() => {
                dispatch(triggerNotifDelete(true));
            }, 300);
        }).catch(err => {
            dispatch(setActionMode(null))
            console.log('Error delete Quotation', err);
            if (err.status===400) {
                alert(err.response.data.message)
            }
            else{
                alert('An Error occurred.')
            }
        }).finally(()=>{
            closePopup();
        })
    }

    const confirmDelete = () => {
        
        if (isViewBooking) {
            removeItemBoking()
        }
        else{
            removeItemQuotation()
        }
    }

    const offersPopupPayload =(e,data)=>{
        e.preventDefault();
        openPopup(OFFERS_POPUP)
        setCurrentOffers(data)
    }

    return (
        <>
            <PopupDeleteItem isViewBooking={isViewBooking} service={"Accomodation"} showRemovePopup={showRemovePopup} setshowRemovePopup={setshowRemovePopup} data={selectedItem} confirmDelete={confirmDelete}></PopupDeleteItem>

            {list.reservationAccom.map((reservationAccomData, indexOne) => {
                

                let paxTotal = reservationAccomData.Rooms.reduce(
                    (acc, room) => {
                        return {
                            AdultCount: acc.AdultCount + room.AdultCount,
                            ChildCount: acc.ChildCount + room.ChildCount,
                            InfantCount: acc.InfantCount + room.InfantCount,
                            TeenCount: acc.TeenCount + room.TeenCount,
                        };
                    },
                    {
                        AdultCount: 0,
                        ChildCount: 0,
                        InfantCount: 0,
                        TeenCount: 0,
                    }
                );

                return (
                    <Fragment key={"item"+indexOne}>
                        <div
                            className=' grid-layout'
                            style={allServiceLength !== 1 ? { width: '99%' }:{}}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            onClick={allServiceLength !== 1 && isDelete ? () => removeItems(reservationAccomData) : undefined}
                        >

                            <div 
                                className='first-item-category'
                            >
                                <CategoryItem
                                    icon={"../../Assets/images/global-icon/accomodation-icon.svg"}
                                    title={"Accommodation"}
                                >
                                    <div
                                        className='category-hotel-name'
                                        key={'reservationAccomData' + indexOne}
                                    >
                                        {reservationAccomData.HotelName}
                                    </div>
                                </CategoryItem>
                            </div>

                            <div>
                            <Fragment key={indexOne}>
                                
                                {reservationAccomData.Rooms.map((room, indexTwo) => (
                                    <Fragment key={"room"+indexTwo}>
                                        <div className='nb-nights'>
                                        {
                                        calculateNights(
                                            room.Dates[0],
                                            room.Dates[1]
                                        )
                                        } {pluralize(calculateNights(room.Dates[0],room.Dates[1]), "night", "nights")}
                                    </div>

                                    <div className='hotel-name wrap-text' key={indexTwo}>
                                        <span className='hotel-name-room-text'>Room <span>{indexTwo+1} :</span></span>{room.RoomName}
                                    </div>
                                    {/* offers part */}
                                    <>
                                        {room?.Offers?.map((offer)=>(<div key={offer.OfferId} className='offer-text' onClick={(e)=>offersPopupPayload(e,offer)}>{offer.OfferValue}% offers Applied</div>))}
                                    </>
                                    <div className='client-type'>Client type: {capitalizeFirstLetter(room.ClientCategory || room.ClientType || "STD", false) }</div>
                                    </Fragment>
                                ))}
                                <div>
                                    <b>Meal Plan :</b>
                                    {reservationAccomData.Rooms.map((room, indexTwo) => (
                                        <span className='meal-plan' key={indexTwo}>
                                            <span className='hotel-name-room-text'>Room <span>{indexTwo+1} :</span></span>{room.MealPlan}
                                        </span>
                                    ))}
                                </div>
                            </Fragment>
                            </div>

                            <div className='date-container'>
                                {
                                    reservationAccomData.Rooms.map((room,indThree)=>(
                                    <div style={{marginBottom:"24px"}} key={"room-date-"+indThree}>
                                        <span className='date'>
                                            {dateToConventionalFormat(room.Dates[0])}{" "}
                                            <span className='date-arrow'>&rarr;</span>{" "}
                                        </span>
                                        <span className='date'>
                                            {dateToConventionalFormat(room.Dates[1])}
                                        </span>
                                    </div >
                                ))
                            }
                            </div>
                            <div>
                                <PaxDetailsTagsV1 paxDetails={paxTotal} />
                            </div>
                            <div className='total-line-price' style={isDelete ? { display: "flex", justifyContent: "space-between" } : {}}>
                                {reservationAccomData.Currency}{" "}
                                {formatPrice(reservationAccomData.Total,',')}
                                {/* {isDelete &&  <div><button  disabled ={allServiceLength === 1} onClick={()=>removeItems(reservationAccomData)}>delete</button></div>} */}
                            </div>
                            <div className='container-trash'>
                                {isDelete &&
                                    <>
                                        <div className='separator'></div>
                                        {allServiceLength !== 1 ?
                                            <div className='image-trash'

                                            >
                                            </div>
                                            :
                                            <div className='image-trash'
                                                onMouseLeave={handleMouseLeave}
                                                onMouseEnter={handleMouseEnter}
                                            >
                                                <img style={{ width: "25px", height: "25px" }} src={trashInvalid} />
                                                {showError &&
                                                    <div className='inside-image-trash'>

                                                        <div className={`toast-error fade-${errorClass ? "in" : "out"}-100 disabled`}>
                                                            <span>To remove item, your quotation needs to have a minimum of 2 items</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {/* <button disabled={allServiceLength === 1} onClick={() => removeItems(reservationTransferData)}>test</button> */}

                                    </>
                                }
                            </div>
                        </div>
                        <div className='dashed-separator'></div>
                    </Fragment>
                );
            })}
             {isOpen&&getPopupContent(currentOffers)} 
        </>
    );
};

export const TransferTable = ({ isViewBooking , list, isDelete, closePopup }) => {
    const dispatch = useDispatch();
    let {reservationAccom,reservationTransfer}=list
    let allService = [...reservationAccom, ...reservationTransfer];
    let allServiceLength = allService.length
    const [hover, setHover] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorClass, setErrorClass] = useState(false);
    const {deleteQuotation}=useAddItems()
    const {deleteBookingItem}=useBookingService()
    const handleMouseEnter = () => {
        setErrorClass(true);
        setShowError(true);
    };

    const handleMouseLeave = () => {
        setErrorClass(false);
        setTimeout(() => {
            setShowError(false);
        }, 100);
    };
    const [showRemovePopup, setshowRemovePopup] = useState(false);
    const [payloadToDetele, setpayloadToDetele] = useState(null);
    
   
   const [selectedItem, setselectedItem] = useState(null)

   const buildPayload = (data) =>{
       const refCode = list.ToRef ? list.ToRef : list.refCode;        
        if (!isViewBooking){
            return {"to_ref":refCode,"service_type":"TRANSFER",'file_no':list.QuoteId, "service_id":data.ServiceId}
        }
        return {ToRef: refCode, data: data};

   }
    const removeItems=(data)=>{
        setselectedItem(data);
        setshowRemovePopup(true);
        setpayloadToDetele(buildPayload(data));
    }

    const removeItemQuotation = () =>{
        deleteQuotation(payloadToDetele, MORE_OPTIONS_MODE.REMOVE_ITEM).then(rep => {
            dispatch(setActionMode(null));
            setTimeout(() => {
                dispatch(triggerNotifDelete(true));
            }, 300);
        }).catch(err => {
            dispatch(setActionMode(null))
            alert('An Error occurred.')
            console.log('Error delete Quotation', err);
        }).finally(()=>{
            closePopup();
        })
    }

    const removeItemBoking = () =>{
        
        deleteBookingItem(payloadToDetele).then(rep => {
            dispatch(setActionMode(null));
            setTimeout(() => {
                dispatch(triggerNotifDelete(true));
            }, 300);
        }).catch(err => {
            dispatch(setActionMode(null))
            console.log('Error delete Quotation', err);
            if (err.status===400) {
                alert(err.response.data.message)
            }
            else{
                alert('An Error occurred.')
            }
        }).finally(()=>{
            closePopup();
        })
    }

    const confirmDelete = () => {
        if (isViewBooking) {
            removeItemBoking()
        }
        else{
            removeItemQuotation()
        }
    }

    return (
        <>
            <PopupDeleteItem isViewBooking={isViewBooking} service={"Transfer"} showRemovePopup={showRemovePopup} setshowRemovePopup={setshowRemovePopup} data={selectedItem} confirmDelete={confirmDelete}></PopupDeleteItem>
            {list.reservationTransfer.map((reservationTransferData, indexOne) => {

                let paxTotal = 
                    {
                        AdultCount: reservationTransferData?.AdultCount ? reservationTransferData?.AdultCount : 0,
                        ChildCount: reservationTransferData?.ChildCount ? reservationTransferData?.ChildCount : 0,
                        InfantCount: reservationTransferData?.InfantCount ? reservationTransferData?.InfantCount : 0,
                        TeenCount: reservationTransferData?.TeenCount ? reservationTransferData?.TeenCount : 0,
                    };

                return (
                    <Fragment key={"item"+indexOne}>       

                        <div 
                            className=' grid-layout'
                            style={allServiceLength !== 1 ? { width: '99%' } : {}}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            onClick={allServiceLength !== 1 && isDelete ? () => removeItems(reservationTransferData) : undefined}
                        >
                            <div className='first-item-category' >
                                <CategoryItem
                                    icon={"../../Assets/images/chambre2.png"}
                                    title={"Transfer"}
                                >
                                    <div
                                        className='category-hotel-name'
                                        key={'reservationTransferData' + indexOne}
                                    >
                                    <div className='transfer-location'> {reservationTransferData.LocationFromName}{" "}<span className='date-arrow'>&rarr;</span>{" "}</div>
                                        
                                        <div className='transfer-location' style={{marginTop:'6px'}}>{reservationTransferData.LocationToDetails ? reservationTransferData.LocationToDetails : reservationTransferData?.LocationToName  }</div>
                                    </div>
                                </CategoryItem>
                            </div>

                            <div className='second-item-category-transfer'>
                                {reservationTransferData?.Vehicle_type ? reservationTransferData?.Vehicle_type : reservationTransferData?.VehicleTypeName}
                            </div>

                            <div className='date-container'>
                                <span className='date'>
                                    {dateToConventionalFormat(reservationTransferData?.TransferDate)}{" "}
                                </span>
                            </div>
                            <div>
                                <PaxDetailsTagsV1 paxDetails={paxTotal} />
                            </div>
                            <div className='total-line-price' style={isDelete ? { display: "flex", justifyContent: "space-between" } : {}}>
                                {reservationTransferData.Currency}{" "}
                                {formatPrice(reservationTransferData?.TotalPrice,',')}
                            </div>
                            <div className='container-trash'>
                                {isDelete &&
                                    <>
                                        <div className='separator'></div>
                                        {allServiceLength !== 1 ?
                                            <div className='image-trash' >
                                            </div>
                                            :
                                            <div className='image-trash'
                                                onMouseLeave={handleMouseLeave}
                                                onMouseEnter={handleMouseEnter}
                                            >
                                                <img style={{ width: "25px", height: "25px" }} src={trashInvalid} />
                                                {showError &&
                                                    <div className='inside-image-trash'>

                                                        <div className={`toast-error fade-${errorClass ? "in" : "out"}-100 disabled`}>
                                                            <span>To remove item, your quotation needs to have a minimum of 2 items</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }

                                    </>
                                }
                            </div>
                        </div>
                        <div className='dashed-separator'></div>

                    </Fragment>
                );
            })}
        </>
    );
};

export const NightStay =({from, to, flexDirection="row", otherClassName=""})=>{
    return(
        <div className={`nights-parent ${flexDirection==="column"?"flex-column":""} ${otherClassName}`} ><span>{calculateNights(from,to) }</span> <span>nights</span></div>
    )
}

{/* <div className={'patternBlock '+(patId === 1 || patId === 4 || patId === 5 ?"pat1":patId === 2 ?"pat2":patId === 3 ?"pat3":"")} key={ind}> */}

export const FilterTag = ({onClick,text}) =>{
    // console.log("allData",text);

  return (
    <div className='filter_lists' onClick={onClick}>{capitalizeFirstLetter(text)}</div>
  )
}

export const HotelImgPreview = ({nbrLeftImages,imgSrc,imgClassName,imgStyle={},onClick=()=>{}},onLoad) => {

  return (
    <div className='img-hotel-preview' onClick={onClick}>
        {nbrLeftImages>1&&<div className='images-left'>+{nbrLeftImages}</div>}
        <LazyImage src={imgSrc} style={imgStyle} key={"img2"} alt="excursion-image" className={imgClassName} fallBackImg={DefaultMainHotelImage} hasFallback={true}/>
    </div>
  )
}


export const CancellationPolicyPopup =({closepopup,data})=> {
    const [isChecked,setIsChecked]=useState(false);
    const [inputRefPopup, setInputRefPopup] = useState({popup:true,value:null});
    const [confirmedStep,setConfirmedStep]=useState(0);
    const { finalBooking,loading,status,bookingId} = useFinalBooking()
    const {userDateClients,finalBookingData} = useSelector((state) => state.globalreducer)
    const { bookingService,loading:loading2,isSuccess,bookingData} = useBookingService();
    const closeRef = useRef()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {resetAllServices} = useResetSearch()
    
    let {refCode,selRooms}=data
    let LABEL ="I have read and agreed to the terms and conditions"

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {document.body.style.overflow = 'auto'};
    }, [])

    useEffect(() => {    
        if(bookingData !== null){
            alert(bookingData.data.message)
            setTimeout(() => {
                navigate(ROUTE_PATHS.ACCOMMODATION)
              }, 2000); // 2000 milliseconds = 2 seconds
        }
      },[bookingData]);


    const bookingFinal = (e) => {
        e.preventDefault();

        if (loading) return 
        finalBooking(finalBookingData)
    }

    useEffect(() => {
        resetAllServices();
    }, [])


    const confirmationSteps=[
        {
            text:"Your booking has been successfully confirmed !",
            className:"confirmed",
            buttonLabel:"Next",
            onNext:()=>{
                setConfirmedStep((prev)=>{return prev+1})
            }
        },
        {
            text:"You will receive a copy of your booking in your mail inbox",
            className:"confirmed-mail",
            buttonLabel:"Open booking grid",
            onNext:()=>{
                navigate(ROUTE_PATHS.QUOTATION,{state:{page:BOOKING_PAGE,showLast:true}})
                dispatch(clearAll())
                dispatch(clearTransfer())
                dispatch(clearexcursion())
                dispatch(clearAccom())
            }
        },
    ]

    useOutsideClick(closeRef, () => {
        closepopup()
    });

    const handleChange = (event) => {
        setInputRefPopup((prev) => ({
          ...prev, // Keep the existing properties
          value: event.target.value, // Update the `value` property
        }));
    };

    const handleNext=(e)=>{
        e.preventDefault();
        if(inputRefPopup.value === null || inputRefPopup.value === ""){
           alert("Please enter the reference code")
        }
        else{
            dispatch((addRefcode(inputRefPopup.value)))
            dispatch((addRefToBooking(inputRefPopup.value)))
          

            setInputRefPopup((prev) => ({
                ...prev, 
                popup:false
            }));
        }
    }
    
    return(
        <>
            {
                status === null && !inputRefPopup.popup ? 
                    <div className="booking-done-popup" ref={closeRef}> 
                        <div className='popup-content-container'>
                            <div className="popup-heading">Confirmation of Terms & Cancelation policy</div>
                            <div className={"popup-content "+(selRooms.length >1 ? "scrollpop":"")}>
                                {
                            
                                    selRooms.map((data)=>{
                                        return(
                                            <div className="popup-sub-heading" key={data.idAccom}>
                                                {selRooms.length > 1 && <div className='roomnum'>Room {data.idAccom}</div>}
                                                
                                                <div className="accom-label">Accommodation </div>
                                                <div  className="hotel-name"><b>{data.hotelName}</b></div>
                                                <div>
                                                    <span className='date'>{data.date[0]}</span>  &rarr; <span className='date'>{data.date[1]}</span>
                                                    <span className='nights'>{calculateNights(data.date[0], data.date[1])} nights</span>
                                                </div>
                                                
                                            
                                                {/* need an id */}
                                                <div className='container-policy-room'>
                                                    {data.cancellation_policy.map((policy,id)=>(
                                                        <div key={policy.CancellationId} style={{color:"#434343",marginTop:"10px"}}>
                                                            <div className='policy-room'>{policy.Rooms}</div>
                                                        <div className='policy-period'>Policy period applies: <span>{policy.ValidFrom}</span>   &rarr; <span>{policy.ValidTo}</span> </div>
                                                        <div className='policy-price' style={{marginLeft:"10px",marginTop:"10px"}}><b>{policy.Value}% of total price</b></div>
                                                            <div style={{
                                                                marginLeft: "10px", color: "#797979"}}> {policy.Description} </div>
                                                        </div>
                                                    ))} 
                                                </div>
                                            </div>
                                        )
                                    })

                                }
                            </div>
                        </div>    
                        
                        <div className="popup-btn-block">
                            <div className='sameInputs_parent'>
                                <label><input type="checkbox" onClick={()=>{setIsChecked(!isChecked)}}/> {LABEL}</label>
                            </div>
                            <button className={"confirm-book-btn "+(isChecked ? "":"disable")} onClick={(e)=>{bookingFinal(e)}} >
                                
                                {loading ? (
                                <CircularProgress
                                color="secondary"
                                style={{ width: "20px", height: "20px", color: "#fff", marginLeft: "8px" }}
                                />
                            ) : "Confirm booking"}
                            </button>
                        </div>   
                    </div>:
                status === null && inputRefPopup.popup ?
                 <div className="inputref-parent-conteiner" ref={closeRef}>
                    <div className='input-ref-parent'>
                        <h4>Enter Your Reference Code</h4>
                        <input type="text" className='toref_textbox' placeholder='Input code to continue' autoFocus onChange={handleChange}/>
                        <div><button onClick={(e)=>handleNext(e)} className='ref_button'>Continue</button></div>
                    </div>
                    
                 </div>:
                    <div className={`booking-done-popup popup-confirmed-booking ${confirmationSteps[confirmedStep].className}`}>
                        {/* <div onClick={(e)=>{ closePopup(e)}} style={{textAlign: "right",padding: "10px 10px 0",cursor:"pointer"}}><img src={cotationclose} alt="close img"/></div> */}
                        <p>{confirmationSteps[confirmedStep].text}</p> 
                        <p><span>"{getBeforeUnderscore(refCode)}"</span> <span className='bookind-id-text'>with id <span>{bookingId}</span></span></p>
                        <button onClick={confirmationSteps[confirmedStep].onNext} className='next-button'>{confirmationSteps[confirmedStep].buttonLabel}</button>
                    </div>   
            }
           
                     
          
        </>
    )
}

export const ServiceNbTags=(({serviceCount})=>{
    return (
        <div className='pax-details-container'>
            <div className={serviceCount.accom_count > 0 ? "paxselected" : "paxunselected"}>
                {`Accommodation${serviceCount.accom_count > 1 ? "s" : ""} ${serviceCount.accom_count}`}
            </div>
            <div className={serviceCount.transfer_count > 0 ? "paxselected" : "paxunselected"}>
                {`Transfer${serviceCount.transfer_count > 1 ? "s" : ""} ${serviceCount.transfer_count}`}
            </div>
            <div className={serviceCount.tours_count > 0 ? "paxselected" : "paxunselected"}>
                {`Excursion${serviceCount.tours_count > 1 ? "s" : ""} ${serviceCount.tours_count}`}
            </div>
        </div>
    );
});


export const PaxDetailsTags=({paxDetails,customContainer=false})=>{
    const children=(
        <>    
            <div className={paxDetails.adult > 0 ? "paxselected" : "paxunselected"}>
                {`Adult${paxDetails.adult > 1 ? "s" : ""} ${paxDetails.adult}`}
            </div>
            <div className={paxDetails.teen > 0 ? "paxselected" : "paxunselected"}>
                {`Teen${paxDetails.teen > 1 ? "s" : ""} ${paxDetails.teen}`}
            </div>
            <div className={paxDetails.child > 0 ? "paxselected" : "paxunselected"}>
                {`Child${paxDetails.child > 1 ? "ren" : ""} ${paxDetails.child}`}
            </div>
            <div className={paxDetails.infant > 0 ? "paxselected" : "paxunselected"}>
                {`Infant${paxDetails.infant > 1 ? "s" : ""} ${paxDetails.infant}`}
            </div>

        </>);
  return (
    <>
    {
        customContainer?children:
        <div className='pax-details-container'>
            {children}
        </div>
    }
    </>
  )
}
export const PaxDetailsTagsV1=({paxDetails,customContainer=false})=>{
    const children=(
        <>    
            <div className={paxDetails.AdultCount > 0 ? "paxselected" : "paxunselected"}>
                {`Adult${paxDetails.AdultCount > 1 ? "s" : ""} ${paxDetails.AdultCount}`}
            </div>
            <div className={paxDetails.TeenCount > 0 ? "paxselected" : "paxunselected"}>
                {`Teen${paxDetails.TeenCount > 1 ? "s" : ""} ${paxDetails.TeenCount}`}
            </div>
            <div className={paxDetails.ChildCount > 0 ? "paxselected" : "paxunselected"}>
                {`Child${paxDetails.ChildCount > 1 ? "ren" : ""} ${paxDetails.ChildCount}`}
            </div>
            <div className={paxDetails.InfantCount > 0 ? "paxselected" : "paxunselected"}>
                {`Infant${paxDetails.InfantCount > 1 ? "s" : ""} ${paxDetails.InfantCount}`}
            </div>

        </>);
  return (
    <>
    {
        customContainer?children:
        <div className='pax-details-container'>
            {children}
        </div>
    }
    </>
  )
}

export const CategoryItem=({icon,title,children})=>{
    return (
        <div className='category-container'>
            <div className={`img-icon ${title}-icon ${icon}`}></div>
            <div className='category-details'>
                <div className='category-title'>{title}</div>
                {children}
            </div>
        </div>
    );
}

export const B2BPagination=({currentPage=1,total,nbPerPage,onPageChange,nbPages})=>{
    const [items,setItems]=useState([]);

    const getTotalMaxPages=()=>{
        if (nbPages) {
            return nbPages
        }
        return Math.ceil(total/nbPerPage);
    }

    useEffect(()=>{
        let tempo = [];
        let beginIndex=1;
        let maxPages=nbPages||getTotalMaxPages();

        if(maxPages < 8){
            for(let i=beginIndex;i<=maxPages;i++){
                tempo.push({libelle:i.toString(),page:i});
            }
        }
        else{
            tempo.push({libelle:'1',page:1});
            if (currentPage>3) {
                tempo.push({libelle:'...',page:beginIndex});
            }
            for (let page= Math.max(2,currentPage-2); page< Math.min(currentPage+3,maxPages); page++) {
                tempo.push({libelle:page.toString(),page:page})
            }
            if (currentPage<maxPages-3) {
                tempo.push({libelle:'...',page:beginIndex});
            }
            if (maxPages>1) {
                tempo.push({libelle:maxPages.toString(),page:maxPages})
            }
            for (let i = beginIndex; i < tempo.length; i++) {
                if (tempo[i].libelle==='...') {
                    tempo[i].page=Math.floor((tempo[i-1].page+tempo[i+1].page)/2)              
                }
                
            }
        }
        setItems(tempo);
    },[currentPage,total,nbPages]);

    const renderButton=(item)=>{
        let buttons=[];
        items.map((item) => {
          if (item.libelle === "...") {
            buttons.push(
              <span
                key={`paginate-three-points-${item.page}`}
                onClick={() => onPageChange(item.page)}
              >
                {item.libelle}
              </span>
            );
          } else {
            buttons.push(
              <button
                key={`paginate-number-${item.page}`}
                onClick={() => onPageChange(item.page)}
                className={currentPage === item.page ? "active" : ""}
              >
                {item.libelle}
              </button>
            );
          }
        });
        return buttons;
    }
    return (
        <>
            <img onClick={()=>currentPage>1?onPageChange(currentPage-1):null}  src={currentPage > 1 ? arrowPagination : arrowNoPagination} className='arrow-prev'/>
            {
                renderButton()
            }
            <img key="arrow-right-pagination" onClick={() => currentPage < getTotalMaxPages() ?onPageChange(currentPage + 1):null} src={currentPage < getTotalMaxPages() ? arrowPagination : arrowNoPagination} className='arrow-next'/>
        </>
    );
}

export const GlobalAmenities=({amenities,isPopup=false,closePopup,isMultiple=false,onSeeMoreClick})=>{
    const containerRef=useRef(null);
    
    isPopup&&useOutsideClick(containerRef,()=>{
        closePopup&&closePopup();
    })

    return (
        <div className='amenities_block' ref={containerRef}>
            <p>Global amenities</p>
            <Amenities multiple={isMultiple} data={amenities} multi={true} onSeeMoreClick={onSeeMoreClick}/>
        </div>
    )
}

export const CancellationInfo =({serviceName,date=[],location,})=>{
    return(
        <div className='cancellationInfo-parent'>
             <div className={`cancellationInfo-service ${serviceName +"icon"}`}>{serviceName}</div>
             <div className='cancellationInfo-location'>{location}</div> 
             <div style={{display:"flex",gap:"17px"}}>
                <DateAtom date={date}/> 
                {date.length > 1 && <span><NightStay from={date[0]} to={date[1]?date[1]:date[0]} flexDirection="row" otherClassName="removeNightscss"/></span>}
            </div>
        </div>
    )
}

export const QuoteDetailsPolicy =({data})=>{    
    return(
        <div className='quote-det-policy-parent'>
             <div className='quote-det-period'>Policy period applies <b> {formatDate(data.ValidFrom)} - {formatDate(data.ValidTo)}</b></div>
             <div className='quote-det-price'><b>{data.Value} % of total price</b></div> 
            <div>{data.Description}</div> 
        </div>
    )
}
//
export const AtomOrdinalButton = ({typeService}) => {

    const { numberOfRoom: numberOfRoomStatus } = useSelector((state) => state.accomodationreducer);


    // let tempStatusBtn = numberOfRoom; 

    const [statusButton, setstatusButton] = useState(numberOfRoomStatus);
    
    function getClassButton(index){


        // let style = 'alreadysel' refa avy mselect
        // let style = 'selectedOne' //tsaiko
        // let style = 'needtosel' //initial
        // let style = 'disable4' //tsaiko
        // let style = 'disable3' //tsaiko

        // setstatusButton();
        
        return 'selectedOne';
    }
    useEffect(() => {
        setstatusButton(numberOfRoomStatus);
    }, [numberOfRoomStatus])
    
    return (
        <>
            {statusButton.length > 1 && statusButton.map((orderStatus,index) => (
                <div key={orderStatus.number} className={`room-btn ${getClassButton(index)}`}>
                    {index+1}{ getOrdinal(orderStatus.number)} {typeService}
                    {/* //ceci return 1st Room... */}
                </div>
            ))}
        </>
    );
}

export const EmptyRoomTimeline=({roomNo})=>{
    return (
        <div>
            <div className='excursion-map roomno disable'>
                Room {roomNo}
            </div>
            <p className='infoRoom disable'>
                Please select your {getOrdinals(roomNo)} room
            </p>
        </div>
    );
}

export const FilledRoomTimeline=({isMonoRoom,room,roomNo})=>{
    return (
        <>
            <div className='quotation_tour_date excursion-map dates'><span className='quotation_tour_date_first-date'>{room.Dates[0]}</span><span className='quotation_tour_date_arrow'>&rarr;</span><span className='quotation_tour_date_first-date'>{room.Dates[1]}</span></div>
            <div className='excursion-map nights-docket'>{calculateNights(room.Dates[0], room.Dates[1])} nights</div>
            {isMonoRoom && <div className='excursion-map roomno'>Room {roomNo}</div>}
            {/* pax details */}
            <div className='excursion-map'><b>Client type </b> | {room.ClientType}</div>
            {room.AdultCount > 0 && (
                <div className='excursion-map paxIcon'>
                    {room.AdultCount} Adult{room.AdultCount > 1 && 's'}
                </div>
            )}
            {room.TeenCount > 0 && (
                <div className='excursion-map paxIcon'>
                    {room.TeenCount} Teen{room.TeenCount > 1 && 's'}
                </div>
            )}
            {room.ChildCount > 0 && (
                <div className='excursion-map paxIcon'>
                    {room.ChildCount} Child{room.ChildCount > 1 && 'ren'}
                </div>
            )}
            <div className='excursion-map suite'>{room.RoomName}</div>
            <div className='excursion-map meal_plan'><b>Meal Plan</b> | {room.MealPlan}</div>
        </>
    );
}

export const useBackButtonHandler = (condition, onBackButtonPressed) => {
    useEffect(() => {
      // Push initial state to prevent immediate back
      window.history.pushState(null, "", window.location.pathname);
  
      const handlePopState = (event) => {
        // Prevent the default back behavior
        event.preventDefault();
        
        if (!condition) {
          // If condition is false, prevent navigation
          window.history.pushState(null, "", window.location.pathname);
          if (onBackButtonPressed) {
            onBackButtonPressed();
          }
        } else {
          // If condition is true, allow navigation by going back
          window.history.back();
        }
      };
  
      window.addEventListener("popstate", handlePopState);
  
      // Cleanup function
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }, [condition, onBackButtonPressed]); // Dependencies array
  };

export const BackButtonHandler = ({ service,serviceName = "Accomodation" }) => {
    const {  isOpen,openPopup, getPopupContent } = usePopupData();

    useEffect(() => {
      let isValidServices = false 
      if(serviceName == "Accomodation"){
        isValidServices = checkAllRoomValid(service);
        
      }else if (serviceName == "Transfer") {
        isValidServices = checkAllTransferValid(service)
      }
      const handlePopState = (event) => {
        event.preventDefault();

        if (isValidServices) {
            window.history.back();
        } else {
            if(serviceName == "Accomodation"){
                openPopup("dialogue-box", "", ROUTE_PATHS.ACCOMMODATION_SELECT);
            }else if(serviceName == "Transfer"){
                openPopup("dialogue-box", "", "/transfer");
            }
            window.history.pushState(null, "", window.location.pathname);
        }
    };
  
      // Initial push state
      window.history.pushState(null, "", window.location.pathname);
      
      // Add event listener
      window.addEventListener("popstate", handlePopState);
  
      // Cleanup function
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }, [service?.roomsFilled]);
  
    return (
        <div>
           {isOpen ? getPopupContent():<></>}
        </div>
    ); 
  };

export const DocketPatternPrice = ({ currency, price, display }) => {
    const priceRef = useRef(null);
    const [hasOverflow, setHasOverflow] = useState(false);
    const { totalPrice } = useSelector((state) => state.globalreducer);

    useEffect(() => {
        const checkOverflow = () => {
            if (priceRef.current) {
                const quotationBody = priceRef.current.closest('.quotation_body');
                if (quotationBody) {
                    setHasOverflow(quotationBody.scrollHeight > quotationBody.clientHeight);
                }
            }
        };

        // Exécuter après le premier rendu
        setTimeout(checkOverflow, 0);

        window.addEventListener("resize", checkOverflow);
        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, [totalPrice]); // Déclencher l'effet à chaque changement de `totalPrice`

    return (
        <div
            ref={priceRef}
            style={{
                display: display ? 'none' : 'block',
                marginRight: hasOverflow ? '-12px' : '-27px' // Appliquer le style en fonction de l'overflow
            }}
            id='price'
            className='docket-price'
        >
            <p>{currency} {separateurMillier(price, ',')}</p>
        </div>
    );
};

export const NoTransferResult=()=>{
    return (
        <div>
            <h1>No available vehicles ...</h1>
        </div>
    )
}
export const NotifDeleteQuotation = ({isBooking=false}) => {
    return (
        <div className="container-notif-delete-quotation">
            <h1 className='font-notif'>Your {isBooking?"booking":"quotation"} has been saved and updated succesfully ! 🚀</h1>
        </div>
    )
}
export const NotifSuccessBooking = () => {
    const usersBooking = { id: "1", bookingName: "Adrien Bosquet", status: "rejected" };
    return (
        <div className="notif-wrapper">
           
                <div
                    key={usersBooking.id}
                    className={`container-notif ${usersBooking.status === "accepted" ? "success" : "rejected"}`}
                    // style={{
                    //     animationDelay: `${index * 3}s`, // Délai dynamique en fonction de l'index
                    // }}
                >
                    <div className="notif-icon">
                        <img
                            src={usersBooking.status === "accepted" ? successImageNotif : declinedImageNotif}
                            alt={usersBooking.status}
                        style={{ width: "150px", height: usersBooking.status ==="accepted"? "100px":"112px", transform: "scale(0.8)" }}
                        />
                    </div>

                    {/* Partie droite avec le texte */}
                    <div className="notif-content">
                        <p className="statusMessage">
                            {usersBooking.status === "accepted"
                                ? `Your booking request has been successfully accepted!`
                            : `Unfortunately your booking request has been declined !`}
                        </p>

                    <p className='user-booking'><span style={{ color: usersBooking.status === "accepted" ? "#0F7173" : "#F66666"  }}>{`"${usersBooking.bookingName}"`}</span> Booking</p>
                    </div>
                </div>
        
        </div>
    );
};

export const PopupDeleteItem = ({ isViewBooking , service, showRemovePopup, setshowRemovePopup, data, confirmDelete }) => {
    const btnRef = useRef(null);
    useOutsideClick(btnRef, () => {
        setshowRemovePopup(false);
    });
    useEffect(() => {
     
    }, [showRemovePopup]);
    function closePopup(){
        setshowRemovePopup(false);
    }
    function deleteItem(){
        confirmDelete();
        setshowRemovePopup(false);
    }
    return (
        <>
            {showRemovePopup &&
                // <div className='deletepopup_containerBooking' >
                    <div className='deletepopup_containerBooking' onClick={(e) => { e.stopPropagation()}}>

                    <div ref={btnRef}>
                        <div className='popup-delete-item'>
                            <p className='title-popup'>Are you sure you want to delete this</p>
                            <p className='title-popup'><span style={{ color: "#C16161" }}>{service}</span> item from your {isViewBooking ?"booking":"quotation"}?</p>
                            
                            <div className='item-deleted'>
                                <p><img src={deleteTransfer} className="img-delete-service" /> <span className="name-service-delete">{service}</span></p>
                                <div style={{paddingLeft:"31px"}}>
                                    {
                                        service === "Accomodation"? 
                                        (<>
                                                <p className='title-popup ex'>{data.HotelName}</p>
                                        </>)
                                        :
                                        service === "Transfer" ?
                                        (<>
                                            <p className='title-popup ex'>{data.LocationFromDetails || data.LocationFromName}<img style={{marginLeft:"6px", width:"17px", height:"17px"}} src={arrow}/></p>
                                            <p className='title-popup ex'>{data.LocationToDetails || data.LocationToName}</p>
                                        </>)
                                        : null //for excursion
                                        
                                    }
                                    {/* <p className='title-popup ex' style={{marginTop:"9px"}}>LUX* Grand-Baie</p> */}
                                </div>
                            </div>
                            <div className='button-action'>
                                <button className='cancel' onClick={(e) => closePopup(e)}> Cancel</button>
                                <button className='delete' onClick={(e) => deleteItem(e)}> Delete</button>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export const FlightNumberBox=({children})=>{    
    return(
        <div className='flight-Number-box'>   
            {children}                  
        </div>
    )
}

export const InputBox=({data,classname})=>{
    return(<input {...data} className={classname}/>)
}

export const QuoteSuccess=({closepopup})=>{
    const {refCode,transferList=[]} =useSelector((state) => state.transfer_Global) 
    const {savedFileNumber} =useSelector((state) => state.globalreducer)
    const {fetchQuotationData}=useQuotationService();

    const navigate = useNavigate()
    const openGridQuote =(e)=>{
            e.preventDefault();
            closepopup();
            navigate(ROUTE_PATHS.QUOTATION);
            
    }

const openClientPage=(e)=>{
    e.preventDefault();
    // { file_no:refCode.file_nb, to_ref_code: refCode.file_name, quotation_type:QUOTATION_TYPE2 
    const payload={"file_nb": savedFileNumber,"file_name":refCode}
    console.log("payload",payload)
   //navigate(ROUTE_PATHS.USER_DETAILS,{state:{service:result,refCode:refCode}});

    fetchQuotationData(payload,(res) => {
        let accomTotal = 0;
        let result =res.data.result;
        console.log("result:",result);
        navigate(ROUTE_PATHS.USER_DETAILS,{state:{service:result,refCode:refCode}});
        //dispatch(totalList({folder:"",services:result}))


    },(err) => {
        alert(err);
        console.log("error",err);
        
    });
}


    return(
        <div 
        // className={status !== null ?'success_cotpopup fade-in-200':"hide"}
        className='success_cotpopup fade-in-200'

        >
        <div className='success_popchild'>
            <div >Your quotation has been successfully saved !<br/>
                <span>"{getBeforeUnderscore(refCode)}"</span>
                <span> quotation</span>
            </div>
            <div className='success_popchild_btns'>
                <button 
                onClick={(e)=>{openGridQuote(e)}}
                >View on quotation grid</button>
                <button 
                onClick={(e)=>{openClientPage(e)}}
                >Book now</button>
            </div>
        </div>
        </div>
    )
}



export const DisplayMoreTransfer=({disable=false, onClick=undefined, isScrollable=false})=>{

    const getButtonLabel = () => {
        if (disable || isScrollable) {
            return "more";
        }
        return "less";
    }

    return (
        <div className={`transfer-display ${getButtonLabel()} ${disable&&"disable"}`} onClick={e=>{onClick&&!disable&&onClick(e)}}>
            <div className='display-icon-container'><i className={`display-icon`}></i></div>
            <div>Display {getButtonLabel()} Transfer lines</div>
        </div>
    )
}
 export const BookingAutoCompleteSurname = ({ labelPax, onApply }) => {
    return (
        <div className='autocomplete_box' onMouseDown={(e) => e.stopPropagation()}>
            <p>Do you want to use “Adult nb.1” surname for “{labelPax}” ?</p>
            <button onClick={() => onApply(false)}>No</button>
            <button onClick={() => onApply(true)}>Yes</button>
        </div>
    );
};


export const OffersPopup = ({closePopup,offers}) => {
    const popup_Ref = useRef(null);

    useOutsideClick(popup_Ref, () => {
        closePopup();
      });
    return (
        <div className='offers-parent-popup' ref={popup_Ref}>
            <OfferDetails key={offers.OfferId} offer={offers} getOfferType={getOfferType} />
        </div>
    )
}


export const DocketPrice = ({data}) => {
    const [docketPrice, setDisplayDocketPrice]=useState([])
    useEffect(()=>{
        setDisplayDocketPrice(docketTotalPrice(data.services ?  data.services:data ))   // docket price structure
    },[data])

    return (
        <div className='docket-price-parent' >
            <div>Total:</div>
            <div className='price-list'>{docketPrice.map((prices,ind)=>( <DocketPriceList key={ind} list={prices}/>))}</div>
           
        </div>
    )
}

export const DocketPriceList =({list})=>{
   
    return(
        <div>
            {list.currency}. {separateurMillier(list.price,",")}
        </div>
    )
}