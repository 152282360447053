import React, { Fragment } from 'react'
import deleteTransfer from "../../Assets/images/global-icon/transfer-icon-grey.svg";
import deleteAccom from "../../Assets/images/global-icon/double-bed-grey.svg";
import arrow from "../../Assets/images/arrow.svg";
import { dateToConventionalFormat, isServiceAccomodation, isServiceTransfer } from '../../utility/globalFunc';
import { NightStay } from './Atom';
const TransferPattern = ({ transfer }) => {
    return (
        <div className='item-deleted'>
            <p><img src={deleteTransfer} className="img-delete-service" /> <span className="name-service-delete">Transfer</span></p>
            <div style={{ paddingLeft: "10px", marginTop: '13px' }}>
                <p className='title-popup ex'>Sir Seewoosagur Ramgoolam<img style={{ marginLeft: "6px", width: "17px", height: "17px" }} src={arrow} /></p>
                <p className='title-popup ex'>International Airport</p>
            </div>
            <div className="quotation-map-child" style={{ paddingLeft: "37px", color: "#0F7173", marginTop: "4px" }}>
                <span>{'18/07/2023'}</span>
            </div>
            <div className="quationcar_img">Superior Car</div>
        </div>
    );
}

const AccommodationPattern = ({ accommodationRoom, hotelName }) => {
    return (
        <div className='item-deleted'>
            <div className='accom-header'>
                <p><img src={deleteAccom} className="img-delete-service" /> <span className="name-service-delete">Accomodation</span></p>
                <h5 className='hotel-name'>{hotelName}</h5>
                <p className='dates-container'>
                    <span className='accom-date'>{dateToConventionalFormat(accommodationRoom.Dates[0])}</span>
                    <span className='date-arrow'>&rarr;</span>
                    <span className='accom-date'>{dateToConventionalFormat(accommodationRoom.Dates[1])}</span>
                    <NightStay from={accommodationRoom.Dates[0]} to={accommodationRoom.Dates[1]} otherClassName='night-stay' />
                </p>
            </div>
            <div className='room-policies'>
                <h5 className='room-name'>{accommodationRoom.RoomName}</h5>
                {
                    accommodationRoom.cancellation_policy?.map((policy, ind) => {
                        return (
                            <div key={"accom-policy" + ind}>
                                <p className='period-application'>Policy period applies:
                                    <span className='accom-date'>{dateToConventionalFormat(policy.ValidFrom)}</span>
                                    <span className='date-arrow'>&rarr;</span>
                                    <span className='accom-date'>{dateToConventionalFormat(policy.ValidTo)}</span>
                                </p>
                                <div className='policy-details'>
                                    <h5 className='policy-header'>{policy.Value}% of total price</h5>
                                    <p className='policy-details'>{policy.Description}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>)
}

function BookingCancellationList({ booking }) {
    const getAccomodationPattern = (accomodation) => {
        return accomodation.Rooms.map((room, ind) => {
            return (
                <Fragment key={"accom" + ind}>
                    <AccommodationPattern hotelName={accomodation.HotelName} accommodationRoom={room} />
                    <div className='dashed-total'></div>
                </Fragment>
            )
        })
    }
    const getCancellationPattern = () => {
        const { reservationAccom, reservationTransfer } = booking;
        const services = [...reservationAccom, ...reservationTransfer];
        return services.map((service, ind) => {
            return (
                <Fragment key={'service' + ind}>
                    {
                        isServiceAccomodation(service) && getAccomodationPattern(service)
                    }
                    {
                        isServiceTransfer(service) && <TransferPattern transfer={service}/>
                    }
                    <div className='dashed-total'></div>
                </Fragment>
            )
        })
    }
    return (
        <div className='dialogue-cancel-booking-service'>
            {getCancellationPattern()}
        </div>
    )
}

export default BookingCancellationList
