import { format, isValid, parse } from 'date-fns';
import { BUDGET_RANGE, CLIENTDATA, OFFSET,CURRENCIES, DEFAULT_DATE_FORMAT,getTotalPax,ISO_8601_DATE_FORMAT, MEAL_PLAN_STATUS_NON_AVAILABLE, MORE_OPTIONS_MODE, ROOM_OFFERS_TYPE, TRANSFER_SUPPLEMENTS, PAX_VALIDATION_PARMS, pluralize, capitalizeFirstLetter } from '../constants';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from 'react-redux';


export function hasAgeZero(list) {
    for (const room of list) {
        for (const pax of room.paxAge) {
            if (Object.values(pax).some(value => value === 0)) {
                return true;
            }
        }
    }
    return false;
}

export const scrollToTop = (value=0) => {
    window.scrollTo({ top: value, behavior: 'smooth' });
};

export function calculateNights(fromDateStr, toDateStr) {
    const fromDate = new Date(fromDateStr.split('/').reverse().join('-'));
    const toDate = new Date(toDateStr.split('/').reverse().join('-'));
    const timeDiff = toDate.getTime() - fromDate.getTime();
    return timeDiff / (1000 * 60 * 60 * 24);
}

export function scrollToDependsBoundary(componentRef,block="start",behavior="smooth",addScrollY=0){
    if (componentRef) {
      const rect = componentRef.getBoundingClientRect?componentRef.getBoundingClientRect():componentRef;
      const isInViewport = (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );

      // console.log(isInViewport,rect,window.innerHeight);

      if (!isInViewport) {
        const scrollToEnd = rect.bottom + window.scrollY - window.innerHeight;

        const scrollToX = rect.left + window.scrollX;
        const scrollToY = block==="end"?scrollToEnd:(rect.top + window.scrollY+addScrollY);
        window.scrollTo({
          top: scrollToY,
          left: scrollToX,
          behavior: behavior
        });
      }
    }
}

export function scrollToElement(componentRef,block="start",behavior="smooth",addScrollY=0){
    if (componentRef) {
      const rect = componentRef.getBoundingClientRect?componentRef.getBoundingClientRect():componentRef;
        const scrollToEnd = rect.bottom + window.scrollY - window.innerHeight;

        const scrollToX = rect.left + window.scrollX;
        const scrollToY = block==="end"?scrollToEnd:(rect.top + window.scrollY+addScrollY);
        window.scrollTo({
          top: scrollToY,
          left: scrollToX,
          behavior: behavior
        });
    }
}

export function getOrdinals(num) {
  const ordinals = [
    "first", "second", "third", "fourth", "fifth",
    "sixth", "seventh", "eighth", "ninth", "tenth"
  ];

  if (num >= 1 && num <= 10) {
    return ordinals[num - 1];
  } else {
    return "Number out of range";  
  }
}

export function scrollToSection(sectionName, container, timeout = 300) {
  const scrollContainer = container || document.body;
  const targetElement = scrollContainer === document.body
    ? document.getElementById(sectionName)
    : scrollContainer.querySelector(`#${sectionName}`);

  if (targetElement) {
    setTimeout(() => {
      targetElement.scrollIntoView({
        behavior: 'smooth',  // 'smooth' ou 'auto'
        block: 'start',      // Position du bloc (peut être 'start', 'center', 'end', 'nearest')
        inline: 'nearest'    // Position en ligne (peut être 'start', 'center', 'end', 'nearest')
      });
    }, timeout);
  } else {
    // console.warn(`Élément avec l'ID "${sectionName}" introuvable.`);
  }
}
// Scroll avec Durée d'animation en millisecondes
// const ANIMATION_DURATION = 1000; // 1000 ms = 1 seconde

// export function scrollToSection(sectionName, container = document.body, timeout = 300) {
//   const scrollContainer = container || document.body;
//   const targetElement = scrollContainer === document.body
//     ? document.getElementById(sectionName)
//     : scrollContainer.querySelector(`#${sectionName}`);

//   if (targetElement) {
//     // Utilisation de setTimeout pour s'assurer que l'élément est rendu avant le défilement
//     setTimeout(() => {
//       const start = scrollContainer.scrollTop;
//       const targetPosition = targetElement.getBoundingClientRect().top + scrollContainer.scrollTop;
//       const startTime = performance.now();

//       function scroll(timestamp) {
//         const elapsed = timestamp - startTime;
//         const progress = Math.min(elapsed / ANIMATION_DURATION, 1); // Progression de 0 à 1
//         const easeInOut = progress < 0.5
//           ? 2 * progress * progress
//           : -1 + (4 - 2 * progress) * progress;  // Fonction d'Easing (facultatif)

//         scrollContainer.scrollTop = start + (targetPosition - start) * easeInOut;

//         if (elapsed < ANIMATION_DURATION) {
//           requestAnimationFrame(scroll);
//         }
//       }

//       requestAnimationFrame(scroll);
//     }, timeout);
//   } else {
//     console.warn(`Élément avec l'ID "${sectionName}" introuvable.`);
//   }
// }

export function separateurMillier(number, sep=' ') {
  
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${sep}`) : 0 ;
}

export function capitalizeFirstLetters(hotelName) {
  if (!hotelName) return ''; // Handle falsy values

  return hotelName
    .toLowerCase()
    .split(' ')
    .map(word => {
      if (word.toUpperCase().includes('LUX')) {
        return word.toUpperCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}


export function scrollToSectionv1_old(sectionName, addScrollY = 0, timeout = 300, retries = 10) {
  const targetElement = document.getElementById(sectionName);

  if (targetElement) {
    const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
    setTimeout(() => {
      window.scrollTo({
        top: elementPosition - addScrollY,
        behavior: 'smooth',
      });
    }, timeout);
  } else if (retries > 0) {
    console.warn(`Element "${sectionName}" not found. Retrying... (${10 - retries}/10)`);
    setTimeout(() => scrollToSectionv1(sectionName, addScrollY, timeout, retries - 1), 200);
  } else {
    console.error(`Failed to find element "${sectionName}" after multiple attempts.`);
  }
}

export function scrollToSectionv1(sectionName, addScrollY = 0, timeout = 300, retries = 10) {
  return new Promise((resolve, reject) => {
    const targetElement = document.getElementById(sectionName);

    if (targetElement) {
      const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;

      setTimeout(() => {
        window.scrollTo({
          top: elementPosition - addScrollY,
          behavior: 'smooth',
        });

        // Vérifier quand le scroll est terminé
        const checkIfScrollingStopped = () => {
          let lastPosition = window.scrollY;
          setTimeout(() => {
            if (window.scrollY === lastPosition) {
              resolve(); 
            } else {
              checkIfScrollingStopped();
            }
          }, 100);
        };

        checkIfScrollingStopped();
      }, timeout);
    } else if (retries > 0) {
      console.warn(`Element "${sectionName}" not found. Retrying... (${10 - retries}/10)`);
      setTimeout(() => {
        scrollToSectionv1(sectionName, addScrollY, timeout, retries - 1).then(resolve).catch(reject);
      }, 200);
    } else {
      reject(new Error(`Failed to find element "${sectionName}" after multiple attempts.`));
    }
  });
}


export function sortByDateAsc(data) {
  return [...data].sort((a, b) => {
    const [dayA, monthA, yearA] = a.TransferDate.split('/');
    const [dayB, monthB, yearB] = b.TransferDate.split('/');
    const dateA = new Date(`${yearA}-${monthA}-${dayA}`);  // Convertir en format yyyy-mm-dd
    const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
    return dateA - dateB;
  });
};

export function centerElement(element,overflowParent) {
  if (element && overflowParent) {
    const parent = overflowParent;
    const parentRect = parent.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const top = elementRect.top - parentRect.top - parent.clientHeight / 2 + elementRect.height / 2;
    const left = elementRect.left - parentRect.left - parent.clientWidth / 2 + elementRect.width / 2;

    parent.scrollBy({
      top,
      left,
      behavior: 'smooth',
    });
  }
};

export function isScrollable(element) {
  const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
  const hasHorizontalScrollbar = element.scrollWidth > element.clientWidth;
  return hasVerticalScrollbar || hasHorizontalScrollbar;
}

export function parseDateString(dateString) {
  const [day, month, year] = dateString?.split('/').map(Number);
  return new Date(year, month - 1, day); // month is 0-indexed in JS Date
}

export function getDatesInRange(fromDate, toDate) {
  const start = parseDateString(fromDate);
  const end = parseDateString(toDate);
  const dateArray = [];

  // Normalize the start and end dates to midnight to avoid time issues
  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);

  let currentDate = new Date(start);

  while (currentDate <= end) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1); // Increment by one day
  }

  // Format dates to DD/MM/YYYY
  const range = dateArray.map(date => format(date, 'dd/MM/yyyy'));
  return range.slice(1,range.length-1);
}

//need refactor
export function formatPrice(price){
  const numberPrice = parseFloat(price);
  return separateurMillier(numberPrice.toFixed(2),',');
  return numberPrice.toFixed(2); 
  // return price+"".toLocaleString("en-US",{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export function calcNbOfPax(roomDetail){
  return Object.values(roomDetail)
      .filter(value => typeof value === 'number')
      .reduce((acc, value) => acc + value, 0);
}

export function getDateFromString(datestr,format=DEFAULT_DATE_FORMAT){
  return parse(datestr,format,new Date());
}

export function getNextDays(numberDays=10){
  const tomorrow=new Date();
  tomorrow.setDate(tomorrow.getDate()+1);
  const endDate=new Date(tomorrow);
  endDate.setDate(endDate.getDate() + numberDays);
  return [tomorrow,endDate];
}

export function getNextDaysString(numberDays=10,dateFormat=DEFAULT_DATE_FORMAT){
  let dates=getNextDays(numberDays);
  let dateFormat1 = dates.map(date=>format(date,dateFormat));
  return dateFormat1
}

export function properCase(str) {
  if (!str) return ''; // Check for undefined, null, or empty string
  return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}


export const checkMinTwoChar = (input) =>{
  if(input.length <2){
      return false
  }
  return true

}
export const checkNotEmpty = (input) =>{
  if(input == ''){
      return false
  }
  return true

}

export const getUrlParams=(params) => {
  
  return Object.keys(params)
      .filter(key => params[key] !== null && params[key] !== undefined && params[key] !== '')
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
}



export const getAccomInputObject = ( {dates, clientsInfo, offers = false,budgetRange={from:BUDGET_RANGE.MIN_PRICE,to:BUDGET_RANGE.MIN_PRICE}, star_rating = [], offset,hotelId, OR = [], AND = [], tags = []} ) => {
  const defaultDates = getNextDaysString(10,ISO_8601_DATE_FORMAT);

  return {
    "date_from": dates[0] || defaultDates[0],
    "date_to": dates[1] || defaultDates[1],
    "clients": clientsInfo, 
    "filters": {
      "star_ratings": star_rating,
      "budget": budgetRange,
      ...(hotelId ? { hotel_id: hotelId } : {}),
      "OR": [...OR],
      "AND": [...AND],
      "offers": offers,
    },
    "offset":offset
  }
}


export const buildPayloadQuotation=(transferList,selExcursion,selRooms,cotation_name,first_name,last_name,roomPax=[],actionMode)=>{
      
  let trans_result = transferList?.map(({ otherServices,LocationFromId,LocationToId,TransferDate,Currency,TransferType,TotalPrice,TransferCategoryId,VehicleTypeId,
    TransferTime,Remarks,Flight,LuggageTruck,LuggageCar,CarCount,baby_seat_count,AdultCount,TeenCount,ChildCount,InfantCount }) => ({

      TransferType,
      LocationFromId,
      LocationToId,
      TransferTime,
      TotalPrice,
      Currency,
      Flight,
      Remarks,
      TransferCategoryId,
      VehicleTypeId,
      LuggageTruck,
      LuggageCar,
      CarCount,
      "BabySeat":baby_seat_count,
      "TransferDate":  formatDateString(TransferDate,'dd/MM/yyyy', 'yyyy-MM-dd'),
      AdultCount,
      TeenCount,
      ChildCount,
      InfantCount,
      // "OtherServices":otherServices
    }));
  let excursion_result = selExcursion?.map(({data,location,vehicleType,excursion_name,price,...rest}) => ({...rest}));
console.log("selRooms",selRooms);


  let accomm_result = selRooms.map((data)=>{
    let formattedDate = backenedDates(data.date)
      return{
          "hotel_id": data.hotelId,
          "room_detail_id": data.room_detail_id,
          "meal_plan_id": data.mealId,
          "client_category": data.roomPax[0].clientType[1],
          "checkin_date": formattedDate[0],
          "checkout_date":formattedDate[1],
          "resa_status": data.room_status,
          "adult_pax": data.roomPax[0].adult,
          "teen_pax": data.roomPax[0].teen,
          "infant_pax": data.roomPax[0].infant,
          "child_pax": data.roomPax[0].child,
          "total_price":data.price,
          "currency":data.currency,
          "id_cancellation" :data.id_cancellation,
          "offers":data.selectedOffersID
      }
  })
      
  console.log("actionMode:",actionMode);
  
  let sendObject ={
      "to_ref_code": `${cotation_name}${!actionMode ? generateUniqueRefId() : ""}`, // actionmode is used for quotation updates like add items, remove 
      //"to_ref_code": `${cotation_name}${generateUniqueRefId()}`,
      "client_category": selRooms.length ? selRooms[0].roomPax[0].clientType[1]: "STD",
      "services":{ // in future we need to add all type data
          "transfer":transferList ? trans_result:[], 
          "excursion":selExcursion ? excursion_result:[],
          "accom":selRooms.length ?accomm_result:[],
          "others":[]
      }, 
  }

 return sendObject;
}

export function formatDateString(dateStr, inputFormat, outputFormat) {  
  const parsedDate = parse(dateStr, inputFormat, new Date());  
  return format(parsedDate, outputFormat);  
}
export function formatDate  (date){
  const formattedDate = new Date(date).toLocaleDateString('fr-FR');
  return formattedDate;
};

export function addPatternToResults(results) {
  return results.map(item => {
      let pattern = "";

        // Use a switch statement to assign the pattern based on the name
        switch (item.name) {
          case "Restaurants & Bars":
              pattern = "lrlrrlrlrr";
              break;
          case "Kids & Family":
              pattern = "lrlrrlrlrr";
              break;
          case "Wellness & SPA":
              pattern = "llrrllrrll";
              break;
          case "Activities":
              pattern = "llrrllrrll";
              break;
          case "Golfs":
              pattern = "rlrllr";
              break;
          // Add more cases here if needed
          default:
              pattern = "lr"; // Default value if the name doesn't match any case
      }

      // Return the new object with the pattern field added
      return {
          ...item,
          pattern: pattern
      };
  });
}  

export const selectedCarDetailsTemp = (data,actionMode="")=>{

  let {ind,regionDetails,selectDetails,selid,userData,totalPax}=data;
  let {status,Category,VehicleTypeId,TransferCategoryID,LuggageCar,LuggageTruck
      ,Currency,final_price,Pic_url,baby_seat_count,lug_car_count,lug_truck_count,MaxPax,serviceDetailID,serviceID,VehicleType}=selectDetails;


  
  let selectCar = null;
  status === null ? selectCar = true : selectCar = false
  let amount = status === null ? final_price :0;

  const luggageCount = [
    { ...LuggageCar, units: lug_car_count },
    { ...LuggageTruck, units: lug_truck_count },
  ];

  const luggage = luggageCount.filter(item => item.units > 0).map(({ idTransferCategory, idVehicleType, units }) => ({
    idTransferCategory,
    idVehicleType,
    units,
  }));

  // Update user details
  let transfer_data = userData.map((data1,index) => {

    
  if (index === selid) { 
      return {
      ...data1,
      Vehicle_type: status === null ? Category: "",
      TotalPrice: amount,
      Car_selected:  selectCar, 
      VehicleTypeId:VehicleTypeId, 
      Currency: Currency,
      TransferCategoryId:TransferCategoryID, 
      Pic_url:Pic_url,
      capacity:MaxPax,
      luggage: luggage,
      LuggageTruck: lug_truck_count,
      LuggageCar: lug_car_count,
      baby_seat_count: baby_seat_count,
      VehicleTypeName: Category,  
      vehicleTypeCategory:VehicleType,
      CarCount:Math.ceil(totalPax/MaxPax),
      ...(actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM)&&!data1.isPersisted?{isNew:true}:{}),
      // otherServices:baby_seat_count !== 0 ?[{
      //   serviceDetailID:serviceDetailID,
      //   serviceID:serviceID,
      //   clientRefs:[]
      // }] :[]
      otherServices:[{
        serviceDetailID:serviceDetailID,
        serviceID:serviceID,
        clientRefs:[]
      }] 
      };
  } 
  else{
      return {
      ...data1,
      };
  }
  });

  // /// Update Coast details
  let oldArrayData = [...regionDetails];
  
  let region_details = oldArrayData.map((data, arrInd) => {

      if (arrInd === selid) {
          
          let array_Update = data.map((item, regInd,arr) => {
              if (regInd === ind && status === null) {
                  return { ...item, status: "selected" };
              } 
              else if(status !== null){ return { ...item, status: null }; }
              else{return { ...item, status: "not-selected" };}
          });

          return array_Update ;
      } 
      else { return  data  }
  });
  

  return {transfer_data,region_details}

}

export const selectedSameVehicleTemp = (data)=>{

  let {ind,regionDetails,selectDetails,userData}=data;
  let {status,Category,VehicleTypeId,TransferCategoryID,TransferType,Pic_url
      ,Currency,final_price,baby_seat_count,lug_car_count,lug_truck_count,MaxPax,serviceDetailID,serviceID,LuggageCar,LuggageTruck
  }=selectDetails;
  
  let selectCar = null;
  status === null ? selectCar = true : selectCar = false;

  const luggageCount = [
    { ...LuggageCar, units: lug_car_count },
    { ...LuggageTruck, units: lug_truck_count },
  ];

  const luggage = luggageCount.filter(item => item.units > 0).map(({ idTransferCategory, idVehicleType, units }) => ({
    idTransferCategory,
    idVehicleType,
    units,
  }));



  // Update user details
  let transfer_data = userData.map((data1,index) => {
    const {AdultCount,TeenCount,ChildCount,InfantCount}=data1;
    const totalPax=AdultCount+TeenCount+ChildCount+InfantCount;
      return {
      ...data1,
      Vehicle_type: status === null ? Category: "",
      TotalPrice: calculatePrice(TransferType,selectDetails,totalPax,data1),
      Car_selected:  selectCar, 
      VehicleTypeId:VehicleTypeId, 
      Currency: Currency, // backened need
      TransferCategoryId:TransferCategoryID, 
      Pic_url,
      capacity:MaxPax,
      luggage: luggage,
      LuggageTruck: lug_truck_count,
      LuggageCar: lug_car_count,
      baby_seat_count: baby_seat_count,
      VehicleTypeName: Category,  
      CarCount:Math.ceil(totalPax/MaxPax),
      otherServices:[{
        serviceDetailID:serviceDetailID,
        serviceID:serviceID,
        clientRefs:[]
        }]
      };
  });

  // /// Update Coast details
  let oldArrayData = [...regionDetails];
  
  let region_details = oldArrayData.map((data, arrInd) => {      
    let array_Update = data.map((item, regInd,arr) => {
        if (regInd === ind && status === null) {
            return { ...item, status: "selected" };
        } 
        else if(status !== null){ return { ...item, status: null }; }
        else{return { ...item, status: "not-selected" };}
    });

    return array_Update ;
  });
  

  return {transfer_data,region_details}

}

// Already saved quotation needs to add isNew = true  STARTS............//////////////
// FOR TRANSFER SELECT VEHICLE
export function updateTransferQuotation (data,actionMode){
   let updatedTransfer = data.map(item => ({
    ...item,
    ...(actionMode===MORE_OPTIONS_MODE.ADD_ITEM&&!item.isPersisted?{isNew:true}:{})
  }));
  return updatedTransfer
  return updatedTransfer;
}

// FOR ACCOMODATION SELECT ROOMS
export function buildSelectedRoom(room,selectedMealPlan,hotelId,hotelName,accomDate,idAccom,roomPax,actionMode,HotelCurrency,cancellationId,cancellation_policy,RoomAmenities,other_images,desc,amenities,offersID,offersDetails){
  return {
    main_img:room.MainImage,
    room_detail_id:room.RoomID,
    hotelId:hotelId,
    room_status:room.Status,
    roomname:room.RoomName,
    hotelName:hotelName,
    idAccom:idAccom,
    price:+selectedMealPlan.AmountAfterTax,
    plan:selectedMealPlan.PlanName,
    roomPax:roomPax,
    cancellation_policy:cancellation_policy,
    date:accomDate,
    mealId:selectedMealPlan.PlanID,
    currency:HotelCurrency,
    id_cancellation:cancellationId,
    roomAmenties:RoomAmenities,
    images:other_images,
    desc:desc,
    amenities:amenities,
    Offers:offersDetails,
    selectedOffersID: offersID,
  
    ...(actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM)?{isNew:true}:{})
  }
}

// Already saved quotation needs to add isNew = true  ENDS .................//////////////


export function getRoomsGroupKey(date,hotelId){
  return `${date}_${hotelId}`;
}

export const extractWord = (input) => input ? (input.includes("__") ? input.split("__")[0] : input) : "";

export function buildReservationAccom(data=[],emptyRooms=false){
  
  
  const groupedHotels = {};

  data.forEach((room,index) => {
    // const key = getRoomsGroupKey(room.date,room.hotelId);
    const key = room.hotelId;
console.log("room",room);

      // Grouping rooms by hotelId
      // console.log("buildReservationAccom data",room.roomPax[0].clientType ,room.roomPax[0].clientType[0]);
      // console.log("room.roomPax[0].clientType[0].length",room.roomPax[0].clientType[0].length);
      
      if (!groupedHotels[key]) {
          groupedHotels[key] = {
              "HotelName":room.hotelName,
              "HotelId": room.hotelId,
              "HotelAmenities": room.amenities,
              "Dates":room.date,
              "Total": 0,
              "Images":room.images,
              "Currency": room.currency,
              "Description": room.hasOwnProperty("desc")?  room.desc[0].HotelDesc: "",
              "TitleDescription":room.hasOwnProperty("desc")? room.desc[0].HotelDescTitle:"",
              "Rooms": [],
             
              

          };
      }
      // Totaling the price per hotel
      groupedHotels[key].Total += room.price;

    
      // Creating the room object
      const roomObj = {
          "room_detail_id": room.room_detail_id,
          "Dates": room.date,
          "RoomName": room.roomname,
          "RoomPrice": room.price,
          "RoomAmenities": room.roomAmenties,
          "RoomImage": room.main_img,
          "AdultCount": room.roomPax[0].adult,
          "TeenCount": room.roomPax[0].teen,
          "ChildCount": room.roomPax[0].child,
          "InfantCount": room.roomPax[0].infant,
          "MealPlan": room.plan,
          "Mealsupplement":room.mealId,
          "quotationPrice":room.price,
          "ClientType": room.roomPax[0].clientType[0].length === 1 ? room.roomPax[0].clientType :room.roomPax[0].clientType[0],
          "cancellation_policy": room.cancellation_policy,
          "Offers":room.Offers,
          ...(room.isNew?{isNew:true}:{})
      };
      
      // Pushing the room into the hotel's Rooms array
      groupedHotels[key].Rooms.push(roomObj);
  });

  // Convert groupedHotels into an array
  const result = Object.values(groupedHotels);
  // Format total prices to two decimal places
  result.forEach(hotel => {
      hotel.Total = hotel.Total.toFixed(2);
  });
  return result;

}

export function buildReservationTransfer(data=[],pax){
  let trans_result = transferList.map(item => {
    // we dont need this line add item.LocationFromId
    let {LocationFromId,LocationToId,TransferDate,currency,TransferType,price,TransferCategoryID,VehicleTypeId,
       TransferTime,remarks,flight,vehicle_type,LocationFromName,LocationToName,VehicleTypeName}=item



    return {
    "TransferType": TransferType,
    "LocationFromId": LocationFromId,
    "LocationToId":LocationToId,
    "TransferDate": formatDateString(TransferDate,'dd/MM/yyyy', 'yyyy-MM-dd'),
    "TransferTime":TransferTime,
    "TotalPrice": price,
    "Currency": currency,
    "Flight": flight,
    "Remarks": remarks,
    "TransferCategoryId": TransferCategoryID,
    "VehicleTypeId": VehicleTypeId,
    "AdultCount": adult,
    "TeenCount": child,
    "ChildCount":teen,
    "InfantCount":infant,
    "TransferCategoryName":VehicleTypeName ,
    "VehicleTypeName": vehicle_type,
    "LocationFromNameName": LocationFromName,
    "LocationToNameName": LocationToName

    }
  });

  return trans_result
}



export function backenedDates(dateArray) {
  return dateArray.map(date => {
      if (date.includes('/')) {
          // Assuming the date is in DD/MM/YYYY format
          const [day, month, year] = date.split('/');
          return `${year}-${month}-${day}`;
      } else if (date.includes('-')) {
          // Assuming the date is already in YYYY-MM-DD format
          return date;
      } else {
          throw new Error("Unsupported date format");
      }
  });
}

export function combineAndSort(data) {  
  // Combine accom and transfer arrays
  const combinedArray = [...data.accomm_result, ...data.trans_result];
  
  // Sort combined array by checkinDate
  combinedArray.sort((a, b) => new Date(a.checkinDate) - new Date(b.checkinDate));
  
  return combinedArray;
}


export function generateUniqueId() {  
  return 'id-' + Date.now().toString(36) + '-' + Math.random().toString().slice(2, 9);  
}  

export function generateIdIndexBase(data,keyName="id") {  
  return data.map((item, index) => ({
    ...item,
    [keyName]: index + 1,
  }));
}  

export function generateUniqueRefId() {  
  return '__' + Date.now().toString(36) + '-' + Math.random().toString().slice(2, 9);  
}  

export function getCurrency(currency){
  let curUnicode=CURRENCIES[currency]?.unicode;
  return curUnicode?String.fromCodePoint(parseInt(curUnicode.replace(/&#(\d+);/, '$1'), 10)):"";  
}

export function getCategoryIcon(category){
  let categIcon = ""
  category = category.toLowerCase()
  switch (category) {
    case "bathroom" :
      categIcon = "bathroom.svg"
      break;
    case "bedroom" :
      categIcon = "bedroom.svg"
      break;
    case "entertainment" :
      categIcon = "entertainment.svg"
      break;
    case "other" :
      categIcon = "other.svg"
      break;
    default :
      categIcon = ""
  }
  return categIcon
}
export function getOfferSign(type){
  return ROOM_OFFERS_TYPE[type?.toUpperCase()]?.sign || type;
}

export function areElementsIntersecting(element1, element2) {  
  const rect1 = element1.boundingClientRect||element1.getBoundingClientRect();  
  const rect2 = element2.boundingClientRect||element2.getBoundingClientRect();  

  const areIntersecting = !(  
      rect1.right < rect2.left ||   // element1 is to the left of element2  
      rect1.left > rect2.right ||   // element1 is to the right of element2  
      rect1.bottom < rect2.top ||   // element1 is above element2  
      rect1.top > rect2.bottom      // element1 is below element2  
  );  

  return areIntersecting;  
} 

export function isElementInViewport(el) {  
  const rect = el.boundingClientRect||el.getBoundingClientRect();  

  return (  
      rect.top >= 0 &&  
      rect.left >= 0 &&  
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&  
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)  
  );  
}  

export function isElementPartiallyInViewport(el) {
  const rect = el.boundingClientRect||el.getBoundingClientRect();  

  return (
      rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
      rect.bottom > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect.right > 0
  );
}

export function findMissingValues(arr1, arr2, fieldName) {  
  const valuesInArr2 = arr2.map(obj => obj[fieldName]);  
  const uniqueValuesInArr2 = [...new Set(valuesInArr2)];  
  return arr1.filter(value => !uniqueValuesInArr2.includes(value));  
}  

export function buildNonAvailableMealPlan(name,plan={}){
  return {
    ...plan,
    PlanName:name,
    status:MEAL_PLAN_STATUS_NON_AVAILABLE
  }
}

export function buildNonAvailableMealPlans(planNames){
  return planNames.map((name)=>{
    return buildNonAvailableMealPlan(name);
  })
}

export function lowerAndRemoveWhiteSpace(str){
  return str.toLowerCase().replace(/\s/g, '');  
}

export function isArrayOfObjects(arr) {  
  return arr.every(item => typeof item === 'object' && item !== null && !Array.isArray(item));  
}

export function isArrayOfStrings(arr) {  
  return arr.every(item => typeof item === 'string');  
}
export const calculateMinMaxAndSumAccom = (ReservationAccom=[]) => {
  let minAdults = Infinity, maxAdults = -Infinity;
  let minTeen = Infinity, maxTeen = -Infinity;
  let minChild = Infinity, maxChild = -Infinity;
  let minInfant = Infinity, maxInfant = -Infinity;

  let totalAdults = 0, totalTeens = 0, totalChildren = 0, totalInfants = 0;
  let  totalPax = 0;
  ReservationAccom.forEach((reservation) => {
    reservation.Rooms.forEach((room) => {
      // Adult counts
      minAdults = Math.min(minAdults, room.AdultCount);
      maxAdults = Math.max(maxAdults, room.AdultCount);
      totalAdults += room.AdultCount;

      // Teen counts
      minTeen = Math.min(minTeen, room.TeenCount);
      maxTeen = Math.max(maxTeen, room.TeenCount);
      totalTeens += room.TeenCount;

      // Child counts
      minChild = Math.min(minChild, room.ChildCount);
      maxChild = Math.max(maxChild, room.ChildCount);
      totalChildren += room.ChildCount;

      // Infant counts
      minInfant = Math.min(minInfant, room.InfantCount);
      maxInfant = Math.max(maxInfant, room.InfantCount);
      totalInfants += room.InfantCount;
    });
  });

  totalPax = totalAdults + totalTeens + totalChildren + totalInfants

  return {
    minAdults,
    maxAdults,
    minTeen,
    maxTeen,
    minChild,
    maxChild,
    minInfant,
    maxInfant,
    totalAdults,
    totalTeens,
    totalChildren,
    totalInfants,
    totalPax
  };
}; 

export const calculateMinMaxAndSumAccomAndTransfer = (ReservationAccom = [], ReservationTranfer = []) => {
  let minAdults = Infinity, maxAdults = -Infinity;
  let minTeen = Infinity, maxTeen = -Infinity;
  let minChild = Infinity, maxChild = -Infinity;
  let minInfant = Infinity, maxInfant = -Infinity;

  let totalAdults = 0, totalTeens = 0, totalChildren = 0, totalInfants = 0;
  let minPax = Infinity, maxPax = -Infinity;

  ReservationAccom.forEach((reservation) => {
    reservation.Rooms.forEach((room) => {
      const adults = room?.AdultCount ?? 0;
      const teens = room?.TeenCount ?? 0;
      const children = room?.ChildCount ?? 0;
      const infants = room?.InfantCount ?? 0;
      const totalRoomPax = adults + teens + children + infants;

      totalAdults += adults;
      totalTeens += teens;
      totalChildren += children;
      totalInfants += infants;

      minAdults = Math.min(minAdults, adults);
      maxAdults = Math.max(maxAdults, adults);
      minTeen = Math.min(minTeen, teens);
      maxTeen = Math.max(maxTeen, teens);
      minChild = Math.min(minChild, children);
      maxChild = Math.max(maxChild, children);
      minInfant = Math.min(minInfant, infants);
      maxInfant = Math.max(maxInfant, infants);
      
      minPax = Math.min(minPax, totalRoomPax);
      maxPax = Math.max(maxPax, totalRoomPax);
    });
  });

  ReservationTranfer.forEach((resTransfer) => {
    const adults = resTransfer?.AdultCount ?? 0;
    const teens = resTransfer?.TeenCount ?? 0;
    const children = resTransfer?.ChildCount ?? 0;
    const infants = resTransfer?.InfantCount ?? 0;
    const totalTransferPax = adults + teens + children + infants;

    totalAdults += adults;
    totalTeens += teens;
    totalChildren += children;
    totalInfants += infants;

    minAdults = Math.min(minAdults, adults);
    maxAdults = Math.max(maxAdults, adults);
    minTeen = Math.min(minTeen, teens);
    maxTeen = Math.max(maxTeen, teens);
    minChild = Math.min(minChild, children);
    maxChild = Math.max(maxChild, children);
    minInfant = Math.min(minInfant, infants);
    maxInfant = Math.max(maxInfant, infants);
    
    minPax = Math.min(minPax, totalTransferPax);
    maxPax = Math.max(maxPax, totalTransferPax);
  });

  let totalPax = totalAdults + totalTeens + totalChildren + totalInfants;

  return {
    minAdults: minAdults === Infinity ? 0 : minAdults,
    maxAdults: maxAdults === -Infinity ? 0 : maxAdults,
    minTeen: minTeen === Infinity ? 0 : minTeen,
    maxTeen: maxTeen === -Infinity ? 0 : maxTeen,
    minChild: minChild === Infinity ? 0 : minChild,
    maxChild: maxChild === -Infinity ? 0 : maxChild,
    minInfant: minInfant === Infinity ? 0 : minInfant,
    maxInfant: maxInfant === -Infinity ? 0 : maxInfant,
    totalAdults,
    totalTeens,
    totalChildren,
    totalInfants,
    totalPax,
    minPax: minPax === Infinity ? 0 : minPax,
    maxPax: maxPax === -Infinity ? 0 : maxPax,
  };
};



export function buildEmptyRoom(hotelId,date,hotelName) {
  return {
    hotelId:hotelId,
    date:date,
    hotelName:hotelName,
    currency:undefined,
    isEmpty:true
  }
}

export function formatStrDate(datestr,currentFormat,desiredFormat){
  let dateobj=getDateFromString(datestr,desiredFormat);
  if (isValid(dateobj)) {
    return datestr;
  }
  const parsedDate = parse(datestr, currentFormat, new Date());  
    
    // Check if the parsed date is valid  
    if (!isValid(parsedDate)) {  
        throw new Error("Invalid date in the current format");  
    }  
    
    // Format the date into the desired format  
    const formattedDate = format(parsedDate, desiredFormat);  
    
    return formattedDate;  

}

export function dateToConventionalFormat(datestr){
  return formatStrDate(datestr,ISO_8601_DATE_FORMAT,DEFAULT_DATE_FORMAT);
}

export function getBeforeUnderscore(input) {
  // Validate the input
  if (typeof input !== 'string') {
    return ''; // Return an empty string for invalid input
  }

  // Split the input string at the first underscore and return the first part
  const [beforeUnderscore] = input.split('_');
  return beforeUnderscore;
};



export function buildRoomPaxInfo(roomPax){
  return roomPax.map((item) => ({
    client_type: item.clientType[0].toUpperCase(),
    adult_count: item.adult,
    children:item.paxAge.flatMap(item =>
        Object.entries(item)
        .filter(([key, value]) => key !== 'id' && !isNaN(value))
        .map(([key, value]) => Number(value))
    )
}));
}
export function transformDate(dateString) {
  const yyyyMmDdPattern = /^\d{4}-\d{2}-\d{2}$/;
  
  const ddMmYyyyPattern = /^\d{2}\/\d{2}\/\d{4}$/;
  
  if (yyyyMmDdPattern.test(dateString)) {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  } else if (ddMmYyyyPattern.test(dateString)) {
    return dateString;
  } else {
    throw new Error("Invalid date format");
  }
}


export function areAllDatesArraysSame(arr=[]) {
  if (arr.length === 0) return false;

  console.log("arr:",arr);
  
  // Convert each Dates array into a string representation safely
  const uniqueSets = new Set(
      arr.map(item => (Array.isArray(item.Dates) ? item.Dates.join(',') : ''))
  );

  // If all arrays are the same, the Set should have only 1 value
  return uniqueSets.size === 1;
}


export function getTransferTotalPax(transfer){
  return transfer.AdultCount+ transfer.InfantCount+ transfer.TeenCount+ transfer.ChildCount;
}

export function buildSameVehicleResult(carlist,length){
  // return Array.from({length},()=>(carlist[0]))
  return Array.from({length},()=>([...carlist]))
}



export function transformRegionDetails(data,transfersPayload,actionMode="") {

  const transfersList=actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM)?transfersPayload.filter(item=>!item.isPersisted):transfersPayload;
  return data.map((group,ind) => {
      // Create a map to store luggage information
      const luggageInfo = group.reduce((acc, item) => {
          if (item.Category === "LUGGAGE") {
              acc[item.VehicleType] = {
                  price: item.AdultPrice,
                  capacity: item.MaxPax,
                  idTransferCategory:item.TransferCategoryID,
                  idVehicleType:item.VehicleTypeID

              };
          }
          return acc;
      }, {});

     
      const totalPax=getTransferTotalPax(transfersList[ind])

      
      
      return group
          .filter(item => item.Category !== "LUGGAGE")
          .map(item => ({
              TransferTariffId:item.TransferTariffId,
              TransferCategoryID:item.TransferCategoryID,
              VehicleTypeId: item.VehicleTypeID,
              Category: item.Category,
              VehicleType: item.VehicleType,
              Region: item.Region,
              Currency: item.Currency,
              MinPax: item.MinPax,
              MaxPax: item.MaxPax,
              AdultPrice: item.AdultPrice,
              ChildPrice: item.ChildPrice,
              TeenPrice: item.TeenPrice,
              InfantPrice: item.InfantPrice,
              TransferType: item.TransferType,
              MaxLuggage: item.MaxLuggage,
              MaxAllowedBabySeat: item.MaxAllowedBabySeat,
              BabySeatPrice: item.BabySeatPrice,
              status: null,
              final_price: calculatePrice(item.TransferType,item,totalPax,transfersPayload[ind]),
              baby_seat_count: 0,
              lug_car_count: 0,
              lug_truck_count: 0,
              luggage:[],
              LuggageTruck: luggageInfo["TRUCK"] || null,
              LuggageCar: luggageInfo["CAR"] || null,
              Pic_url:item.VehiclePicture,
              serviceID:item.BabySeatServiceID,
              serviceDetailID:item.BabySeatServiceDetailsID
              

          }));
  });
}


export function calculatePrice(type,data,totalPax,transfersPayload = {}){

  let {
    AdultCount = 0,
    ChildCount = 0,
    InfantCount = 0,
    TeenCount = 0,
  } = transfersPayload;
  
  if(type === "Trip"){
    return Math.ceil(totalPax/data.MaxPax)* data.AdultPrice
  }
  else return (data.AdultPrice *AdultCount) + (data.ChildPrice * ChildCount) + (data.TeenPrice * TeenCount) + (data.InfantPrice *InfantCount)
}

export function buildEmptyRoomsPattern(patterns,rooms,defaultRoom){
  let indRoom=0;
  let patternRooms=[];
  for (const filled of patterns) {
    if (filled) {
      patternRooms.push(rooms[indRoom]);
      indRoom++;
    }else{
      patternRooms.push(defaultRoom);
    }
  }
  return patternRooms;
}

export function getRoomIndexInsertFromBottom(filledPattern,roomNo){
  return filledPattern.slice(roomNo+1).filter(Boolean).length;
}

export function getInitialPendingRooms(resultSearchLength,currPendingRooms,initState=undefined){
  let newPendingRooms={};
  if (resultSearchLength>1) {
    let triggerReload=!currPendingRooms.reloadRoomNumber
    let newRoomsFilled=currPendingRooms.roomsFilled.map(_=>false);
    newPendingRooms={
      ...currPendingRooms,
      nbNewRooms:resultSearchLength,
      requiredNbRooms:resultSearchLength,
      reloadRoomNumber:triggerReload,
      startRoomIndex:1,
      roomsFilled:newRoomsFilled
    };
  }else if(initState){
    newPendingRooms=initState;
  }
  return newPendingRooms;
}

export function getTransferSuplement(transfer) {
  let supplements=[];
  for (const supp of TRANSFER_SUPPLEMENTS) {
    if (transfer[supp.type]&&transfer[supp.type]>0) {
      supplements.push({
        ...supp,
        count:transfer[supp.type]
      })
    }
  }
  return supplements;
}

export function isServiceAccomodation(service){
  return service.hasOwnProperty("HotelId") || service.hasOwnProperty("hotelId");
}

export function isServiceTransfer(service){
  return service.hasOwnProperty("IdTransfer") || service.hasOwnProperty("idTransfer") || service.hasOwnProperty("VehicleTypeId");
}

export function isServiceExcursion(service){
  return false;
}


export const dateUi_format = (dates=[]) => {
  return dates.map(date => {
   
    if (!date) return []// Handle falsey values like null, undefined, or empty strings

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  });
};


// construct redux for updating the saved quote

export const accomodationReduxData = (hotels) => {
  
  return hotels.flatMap((hotel) =>
    hotel.Rooms.map((room) => ({
      hotelId:hotel.HotelId,
      hotelName: hotel.HotelName,
      date: dateUi_format(room.Dates),
      nights: room.Dates.length - 1,
      clientType: room.ClientCategory,
      roomPax: [
        {
          adults: room.AdultCount,
          teens: room.TeenCount,
          children: room.ChildCount,
          infants: room.InfantCount,
          clientType:room.ClientCategory
        },
      ],
      roomname: room.RoomName,
      plan: room.MealPlan,
      price: Number(room.Total),
      currency: hotel.Currency,
      idAccom:generateUniqueRefId()
    }))
  );
};

export const transferReduxData=(data)=>{
  console.log("transferReduxData",data)
  let transferUpdated =  data.map((list)=> {
    return{
      IdTransfer: list.ServiceId,
      TransferDate: formatDateString(list.TransferDate, 'yyyy-MM-dd', 'dd/MM/yyyy' ),
      LocationFromName: list.LocationFromName,
      LocationFromDetails: list.LocationFromDetail,
      LocationToName: list.LocationToName,
      LocationToDetails: list.LocationToDetail,
      TotalPrice: Number(list.TotalPrice),
      CarCount:list.CarCount,
      LuggageTruck:list.LuggageTruck,
      LuggageCar:list.LuggageCar,
      Car_selected: true,
      TransferTime: list.TransferTime,
      Remarks:list.Remarks,
      Flight: list.Flight,
      Currency: list.Currency,
      Pic_url: null,
      TransferType: list.TransferType,
      AdultCount: list.AdultCount,
      TeenCount: list.TeenCount,
      ChildCount: list.ChildCount,
      InfantCount: list.InfantCount,
      IsSearched: false,
      LocationFromId: list.LocationFromId,
      LocationToId: list.LocationToId,
      TransferCategoryName:list.TransferCategoryName,
      isPersisted:true
    }
  })

  return transferUpdated
}


export function getTransferPax(transfer){
  return {
    adult:transfer.AdultCount,
    teen:transfer.TeenCount,
    child:transfer.ChildCount,
    infant:transfer.InfantCount,
  }
}

export function findLastIndex(array,callback){
  for (let i = array.length-1; i >=0; i--) {
    if (callback(array[i])) {
      return i;
    }
  }
  return -1;
}

export function getTransfersVehiclesChoices(transfers,currVehicles,newVehicles=[],changeChoiceMode=false){
  if (changeChoiceMode) {
    return newVehicles;
  }
  let indSelected=0;
  let indNew=0;
  const vehiclesChoices=[];
  for (let i = 0; i < transfers.length; i++) {
    const transfer = transfers[i];
    if (transfer.IsSearched) {
      vehiclesChoices.push(currVehicles[indSelected])
      indSelected++;
    }else{
      vehiclesChoices.push(newVehicles[indNew])
      indNew++;
    }
    
  }
  return vehiclesChoices;
}

export function getClientTypeByAcronyme(acronyme){
  const types=CLIENTDATA.filter(t=>t.value===acronyme);
  if (types.length===1) {
    return types[0].name.toLowerCase();
  }
  return acronyme;
}


export async function exportToPDF(contentId, scale = 1.4, quality = 0.8) {
  const element = document.getElementById(contentId);

  if (!element) {
    console.error(`Element with ID "${contentId}" not found.`);
    return;
  }

  // Appliquer une échelle temporaire à l'élément
  element.style.transform = `scale(${scale})`;
  element.style.transformOrigin = "top left";
  element.style.width = `${100 / scale}%`;

  // Attendre que le style soit appliqué
  await new Promise((resolve) => setTimeout(resolve, 100));

  try {
    // Créer un PDF
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Capturer l'élément en tant qu'image avec qualité optimisée
    const canvas = await html2canvas(element, {
      scale: 2, // Améliorer la qualité de capture sans excès
      useCORS: true, // Résoudre les problèmes de sécurité pour les images externes
    });

    const imgData = canvas.toDataURL("image/jpeg", quality); // Utiliser JPEG pour réduire la taille

    // Calcul des dimensions de l'image pour le PDF
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const imgHeight = (pdfWidth * canvasHeight) / canvasWidth;

    // Ajouter l'image au PDF
    if (imgHeight <= pdfHeight) {
      // Contenu sur une seule page
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, imgHeight);
    } else {
      // Contenu sur plusieurs pages
      let positionY = 0;
      while (positionY < canvasHeight) {
        const sliceHeight = Math.min(canvasHeight - positionY, pdfHeight * (canvasWidth / pdfWidth));
        const canvasPage = document.createElement("canvas");
        canvasPage.width = canvasWidth;
        canvasPage.height = sliceHeight;

        const context = canvasPage.getContext("2d");
        context.drawImage(canvas, 0, positionY, canvasWidth, sliceHeight, 0, 0, canvasWidth, sliceHeight);

        const pageImgData = canvasPage.toDataURL("image/jpeg", quality);

        if (positionY > 0) {
          pdf.addPage();
        }

        pdf.addImage(pageImgData, "JPEG", 0, 0, pdfWidth, (sliceHeight * pdfWidth) / canvasWidth);
        positionY += sliceHeight;
      }
    }

    // Sauvegarde du PDF
    pdf.save("Mautourco_booking.pdf");
  } finally {
    // Réinitialiser les styles
    element.style.transform = "";
    element.style.width = "";
  }
}

export async function exportToPDs(content) {
  const element = document.getElementById(content); // ID de la page ou du contenu à convertir
  // Obtenez les dimensions naturelles de l'élément (sans transformations dues au zoom)
  const originalTransform = document.body.style.transform; // Sauvegarder le style transform
  document.body.style.transform = "scale(1)"; // Désactiver le zoom

  html2canvas(element).then((canvas) => {
    document.body.style.transform = originalTransform; // Restaurer le style transform
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = (canvas.height * pageWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pageWidth, pageHeight);
    pdf.save("Mautourco_booking.pdf");
  });
};
export async function exportToPDFV2(content) {
  const element = document.getElementById(content);

  if (!element) {
    console.error("Element not found!");
    return;
  }

  // Détecter le facteur de zoom de l'écran
  const zoomFactor = window.devicePixelRatio || 1;

  // Obtenir les dimensions physiques de l'élément
  const rect = element.getBoundingClientRect();
  const trueWidth = rect.width * zoomFactor;
  const trueHeight = rect.height * zoomFactor;

  // Options pour html2canvas avec gestion du facteur de zoom
  const options = {
    scale: 1 / zoomFactor, // Inverse du facteur de zoom pour annuler l'effet
    width: trueWidth,
    height: trueHeight,
    useCORS: true,
  };

  // Capture de l'élément
  html2canvas(element, options).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");

    // Création du PDF
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = (canvas.height * pageWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pageWidth, pageHeight);
    pdf.save("Mautourco_booking.pdf");
  });
}




export function checkRoomPaxZero(data) {

  console.log("checkRoomPaxZero:",data)

  // Check if any item has all pax fields as zero
  const hasAnyZeroes = Array.isArray(data) && data.length > 0
      ? data.some(item => item.adult === 0 && item.teen === 0 && item.child === 0 && item.infant === 0)
      : false;

  // Check if there's at least one item with adult > 0
  const minOneAdult = Array.isArray(data) && data.length > 0
      ? data.some(item => item.adult > 0)
      : false;
  
  const isOnlyInfant = Array.isArray(data) && data.length > 0
      ? data.some(item => item.adult === 0 && item.teen === 0 && item.child === 0 && item.infant > 0)
      : false;    

  return { hasAnyZeroes, minOneAdult,isOnlyInfant };
}

export function getNumberOfPaxAccomodation(roomPax) {
  let nbr = 0;
  let pax = " Adult";
  if (roomPax.length) {
    nbr += roomPax[0].adult;
    nbr += roomPax[0].infant;
    nbr += roomPax[0].teen;
    nbr += roomPax[0].child;
    if (roomPax[0].child > 0 || roomPax[0].teen > 0 || roomPax[0].infant > 0) {
      pax = " Pax"
    } else {
      pax = pluralize(nbr, " Adult", " Adults");
    }
  }
  return nbr + pax;
}

export function deepCompare(obj1, obj2) {  
  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {  
    return obj1 === obj2;  
  }  

  const keys1 = Object.keys(obj1);  
  const keys2 = Object.keys(obj2);  

  if (keys1.length !== keys2.length) {  
    return false;  
  }  

  for (let key of keys1) {  
    if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) {  
      return false;  
    }  
  }  

  return true;  
} 

export function deepCompareWithIgnoringFields(obj1, obj2, ignoreFields = []) {  

  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {  
    return obj1 === obj2;  
  }  

  const keys1 = Object.keys(obj1).filter(key => !ignoreFields.includes(key));  
  const keys2 = Object.keys(obj2).filter(key => !ignoreFields.includes(key));  

  if (keys1.length !== keys2.length) {  
    return false;  
  }  


  for (let key of keys1) {  
    if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key], ignoreFields)) {  
      return false;  
    }  
  }  

  return true;  
}

export function deepCompareArrays(arr1, arr2) {  
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {  
    return false;  
  }  

  if (arr1.length !== arr2.length) {  
    return false;  
  }  

  for (let i = 0; i < arr1.length; i++) {  
    if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {  
      if (!deepCompareArrays(arr1[i], arr2[i])) {  
        return false;  
      }  
    } else if (typeof arr1[i] === "object" && typeof arr2[i] === "object") {  
      if (!deepCompare(arr1[i], arr2[i])) {  
        return false;  
      }  
    } else if (arr1[i] !== arr2[i]) {  
      return false;  
    }  
  }  

  return true;  
}  

export function getOnlyNewServices(services){
  const newServices={...services};
  newServices.reservationAccom = services.reservationAccom.map((accommodation) => ({
    ...accommodation,
    Rooms: accommodation.Rooms.filter((room) => room.isNew),
  })).filter((accommodation) => accommodation.Rooms.length > 0);

  newServices.reservationTransfer=services.reservationTransfer?.filter(item=>item.isNew);
  newServices.reservationExcursion=services.reservationExcursion?.filter(item=>item.isNew);
  newServices.reservationOthers=services.reservationOthers?.filter(item=>item.isNew);
  
  return newServices;
}

export function getPaxCategoryByAge(age=0){
    if (age === 0) {  
      return 'Adult';  
    } else if (age >= 1 && age <= 7) {  
      return 'Child';  
    } else if (age >= 8 && age <= 14) {  
      return 'Infant';  
    } else if (age >= 15 && age <= 17) {  
      return 'Teen';  
    }  
}

export function getClientTypes(services){
  const { reservationAccom, reservationTransfer } = services;
  const STANDARD_TYPE = CLIENTDATA[0].name;
  if (!reservationAccom || !reservationAccom.length) {
    return [STANDARD_TYPE];
  }

  const typesSet = new Set();

  mainLoop:
  for (const accom of reservationAccom) {
    for (const room of accom.Rooms) {
      typesSet.add(room.ClientType || room.ClientCategory || STANDARD_TYPE)
      if (typesSet.size === CLIENTDATA.length) {
        break mainLoop;
      }
    }
  }

  return [...typesSet];
}

export function getGlobalClientType (services) {
  const types = getClientTypes(services);

  return types.reduce((prev, curr, index) => {
    const type = capitalizeFirstLetter(getClientTypeByAcronyme(curr), false);
    if (index > 0) {
      return `${prev} & ${type}`
    }
    return type;
  }, "")
}


export const getOfferType=(offer)=>{
  let {OfferId,OfferPrice,OfferValue,OfferName,OfferType}=offer

  switch (OfferType) {
    case "PERCENTAGE":
      return `${OfferValue + "%"}`
    case "GIFT":
      return "Gift"
    case "AMOUNT":
      return `${OfferValue + "%"} Amount` 
    case "NIGHTS":
      return "Nights"    
    default:
      return "%";
  }
}


export const getOffersID=(data)=>{
    
  const offerIds = [...new Set(
     data.flatMap(item =>
         item.RoomTariff.flatMap(room =>
             room.Offers.map(offer => offer.OfferId)
         )
     )
 )];


     return offerIds
 }


 export const getOffersDetails=(data)=>{
    return data.flatMap(item => 
      item.RoomTariff.flatMap(room => room.Offers)
  ).filter((value, index, self) => 
      index === self.findIndex((t) => (
          t.OfferId === value.OfferId
      ))
  );
 }



 export const docketTotalPrice=(data)=>{
  console.log("datta docketTotalPrice", data);
    try {
      if (!data || typeof data !== 'object') throw new Error("Invalid input data");

      const currencySet = new Set();
      const servicePrices = {}; // To store aggregated prices per currency

      // Process reservationTransfer
      if (Array.isArray(data.reservationTransfer)) {
          data.reservationTransfer.forEach(transfer => {
              if (!transfer.Currency || typeof transfer.TotalPrice !== 'number') return;
              currencySet.add(transfer.Currency);
              servicePrices[transfer.Currency] = (servicePrices[transfer.Currency] || 0) + transfer.TotalPrice;
          });
      }

      // Process reservationAccom
      if (Array.isArray(data.reservationAccom)) {
          data.reservationAccom.forEach(hotel => {
              if (!hotel.Currency || !Array.isArray(hotel.Rooms)) return;
              currencySet.add(hotel.Currency);

              const totalRoomPrice = hotel.Rooms.reduce((sum, room) => {
                  let price = 0;
                  if (typeof room.RoomPrice === "number") {
                      price = room.RoomPrice;
                  } else if (typeof room.Total === "string") {
                      price = parseFloat(room.Total);
                  }
                  return sum + price;
              }, 0);

              servicePrices[hotel.Currency] = (servicePrices[hotel.Currency] || 0) + totalRoomPrice;
          });
      }

      // If all services have the same currency, sum everything
      if (currencySet.size === 1) {
          const singleCurrency = [...currencySet][0];
          const totalSum = Object.values(servicePrices).reduce((sum, value) => sum + value, 0);
          return [{ currency: singleCurrency, price: totalSum }];
      }

      // Otherwise, return each currency separately
      return Object.entries(servicePrices).map(([currency, price]) => ({ currency, price }));
  } catch (error) {
      console.error("Error processing data:", error.message);
      return [{ error: "Error processing data" }];
  }


 }
