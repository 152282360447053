import React, { Fragment, useRef,useState } from 'react'
import useOutsideClick from '../../hooks/useOutsideClick';
import LazyImage from '../MiniComponents/LazyImage';
import { getNumberOfPaxAccomodation,getOfferType } from '../../utility/globalFunc';
import DefaultRoomImg from "../../Assets/images/default-img/room.jpg";
import CancellationPolicyItem from "../Accomodation/minicomponents/CancellationPolicyItem"
import {OfferDetails} from "../Accomodation/minicomponents/Atom"

function RoomOfferPopup({ roomName, mealPlan, pax, roomImg, closePopup, amenities,cancellationData }) {
  const popupRef = useRef(null);
  const [showFull, setShowFull] = useState(false);

  useOutsideClick(popupRef, () => {
    closePopup();
  });

  

  const getPaxType=()=>{
    return pax[0].clientType[0]
  }

  return (
    <div className='room-offer-popup' ref={popupRef}>
      <p className='offer-heading'>Applicable cancelation policy for the selected rooms :</p>
      <div className='room-offer-popup-block'>
        <div className='room-details-container'>
          <LazyImage src={roomImg} className={"room-image"} alt={"room-iamge"}  fallBackImg={DefaultRoomImg} hasFallback={true}/>
          <div className='room-info-container'>
            <h4 className='room-name'>{roomName}</h4>
            <div className='clients-info-container'>
              <div className='clients-info pax-info'>{getNumberOfPaxAccomodation(pax)}</div>
              <div className='clients-info'>Client type<span>|</span><span className='light-weight'>{getPaxType()}</span></div>
              <div className='clients-info mealplan-info'>{mealPlan.PlanName}</div>
            </div>
            <div className='amenities-container'>
              <h4 className='amenities-section-title'>Global amenities</h4>
              <div className='amenities-items-container'>
                {
                  amenities.map((amenity, ind) => (
                    <div className='amenities_list_other' style={{ background: `url(${amenity.AmenityIcon})no-repeat 11px,center` }} key={'amenity' + ind}>{amenity.AmenityName}</div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>

        <div className='offers-container'>
          {
            mealPlan.RoomTariff.map((tariff, indT) => (
              tariff.Offers.map((offer, ind) => (
                <OfferDetails key={ind} offer={offer} getOfferType={getOfferType} />
              ))))
          }
        </div>

        <div className='cancellation-container'>
         <i className='icon-dashed-line'></i>
          {
            
              cancellationData?.map((data,ind)=>(
                  <Fragment key={ind+" "+data.ValidFrom}>
                    
                      <CancellationPolicyItem rooms={data.Rooms} measure={data.Measure} dateFrom={data.Measure === "NOSHOW" ? data.ValidFrom : data.Criteria?.apply_from}  dateTo={data.Measure === "NOSHOW" ?data.ValidTo:data.Criteria?.apply_to} description={data.Description}/>
                      <i className='icon-dashed-line'></i>
                  </Fragment>
              ))
          }
        </div>
      </div>
    </div>
  )
}

export default RoomOfferPopup