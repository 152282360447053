import { useCallback, useEffect, useRef, useState } from 'react';

const useGetscrollInfo = (ref,callback,filterRef,handleFixedFilter,loading,stop) => {

    const observerRef = useRef(null);

    const handleObserver = useCallback((entries) => {
        let filtersElement = entries[0];
        handleFixedFilter&&handleFixedFilter(filtersElement)
    }, []);


    useEffect(() => {
        observerRef.current = new IntersectionObserver(handleObserver, {
            root: null,
            threshold: [0],
        });
        const currentObserver = observerRef.current;
        if (filterRef.current) {
            currentObserver.observe(filterRef.current)
        }
        return () => {
            currentObserver.disconnect(); // Clean up observer  
        };
    }, [handleObserver,filterRef.current]);

    useEffect(()=>{
        const observer=new IntersectionObserver((entries)=>{
            const target=entries[0];
            const { bottom } = target.boundingClientRect; 
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;  
            let visible=target.isIntersecting;
            
            if (bottom<=windowHeight&&!loading&&!stop&&visible) {
                callback();
            }        
        },{
            threshold:1,
        })
        const element = ref.current;
        if (element&&!loading) {
            observer.observe(element);
        }
        return ()=>{
            observer.disconnect();
        }
    },[ref.current,loading])


};

export default useGetscrollInfo;
