import {CancellationInfo,QuoteDetailsPolicy,GlobalAmenities} from "../MiniComponents/Atom"
import HotelRating from "../MiniComponents/HotelRating"
import amenitiesStatic from "../../Assets/images/accomodation/staticImages/amenitiesStatic.png"
import{HOTEL_RATING_ACCOM} from "../../constants"
import {SERVICE_TYPE} from "../../constants"

const QuoteCancellationPolicy = ({data}) => {




  return (
    <div className='quote-detail-parent'>
      {
        Object.values(data).map((service, id) =>
          Array.isArray(service) &&  service.length > 0 && service?.map((list,ind)=>(

            <div key={Object.keys(data)[id]+ind+id} className='quote-items-blocks' >
                <div className="quote-info">
                    <CancellationInfo  
                      serviceName={
                        Object.keys(data)[id] === SERVICE_TYPE.ACCOMODATION ? "Accomodation"
                        : Object.keys(data)[id] === SERVICE_TYPE.TRANSFER ?"Transfer" 
                        :""
                      }
                      
                      date={ 
                        Object.keys(data)[id] === SERVICE_TYPE.ACCOMODATION ? list?.Rooms[0]?.Dates
                        :Object.keys(data)[id] === SERVICE_TYPE.TRANSFER ?[list.TransferDate] 
                        :["",""]
                      }

                      
                      location={(list.HotelName) || (list.LocationFromName+ " -> " +list.LocationToName )}
                    />
                </div>
                

                {/* Its for Accommodation cancellation*/}
                {
                  Object.keys(data)[id] === SERVICE_TYPE.ACCOMODATION && list.Rooms?.map((room,id)=>(     
                      <div key={id} className='quote-items-display'>
                          { <img src={room.RoomImage ? room.RoomImage:amenitiesStatic } alt="room image" style={{width:"100%",height:"471px", borderRadius: "25px",boxShadow: "0px 1px 6.7px 0px rgba(0, 0, 0, 0.19)"}}/>}
                          <div>
                              <p className="Accomodationicon" style={{paddingLeft:"24px"}}>{room.RoomName}</p>
                              {room?.cancellation_policy  &&  room?.cancellation_policy.map((list,id)=>( <QuoteDetailsPolicy data={list} key={id}/>)) }
                          </div>
                      </div>
                  ))
                } 

                 {/* Its for Transfer cancellation*/}
                 <>
                    {
                      Object.keys(data)[id] === SERVICE_TYPE.TRANSFER &&(
                        <div>
                          24 hours prior to the Transfer, you are not allowed to create, delete or modify any Transfer Quotes.
                          
                        </div>
                      )
                    }
                 </>

                 {/* Its for Excursion cancellation*/}
                 <>
                    {
                      Object.keys(data)[id] === SERVICE_TYPE.EXCURSION &&(
                        <div>
                          Its applicable for 24 hours
                        </div>
                      )
                    }
                 </>
            </div>

          )) 
        )
      }
    </div>
  )
}

export default QuoteCancellationPolicy
