import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Route, Routes,Navigate} from 'react-router-dom';
import Login from './components/Login/Login.js';
import AccomodationPage from "./pages/AccomodationPage"
import QuotationPage from "./pages/QuotationPage.js"
import TransferPage from './pages/TransferPage.js';
import ExcursionPage from './pages/ExcursionPage';
import MainExcursion from './components/Excursion/MainExcursion';
import MainTransfer from './components/Transfer/MainTransfer.js';
import QuoteInputs from "./components/Quotations/QuoteInputs";
import SelectedExcursion from './components/Excursion/SelectedExcursion';
import AccomSelected from "./components/Accomodation/AccomSelected.js"
import MainAccomodation from "./components/Accomodation/MainAccomodation.js"
import {SaveCotationInputs } from './components/Transfer/SaveCotation.js';
import ViewDetailsPage from './pages/ViewDetailsPage.js';
import SelectedViewDetails from './pages/SelectedViewDetails.js';
import ErrorPage from './pages/ErrorPage.js';
import axios from 'axios';
import usePopupData, { TOKEN_EXPIRED_POPUP } from './hooks/usePopupData.js';
import MainLayout from './components/layout/MainLayout.js';
import { REFRESH_TOKEN_EVENT,ROUTE_PATHS } from './constants.js';
import { NotifSuccessBooking } from './components/MiniComponents/Atom.js';


const MyRoutes = () => {
  const [showNotif, setshowNotif] = useState(false)
  const { isOpen, openPopup, closePopup, getPopupContent } = usePopupData();
  let isLogged = localStorage.getItem("isLoggedIn")


  window.addEventListener('storage', (event) => {
    if (event.key === "isLoggedIn") {
      isLogged = event.newValue; // Update isLogged when it's changed in another tab
    }
  });


  // Response Interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest=error.config;
     
      
      if (isLogged && error.response &&( error.response.status === 401||error.response.message==="Token has expired"||error.response.data.msg==="Token has expired")) {
        originalRequest._retry=true;
        openPopup(TOKEN_EXPIRED_POPUP);
        const newAccessToken=await new Promise((resolve)=>{
          window.addEventListener(REFRESH_TOKEN_EVENT,(e)=>{
            resolve(e.detail.token)
          })
        })
        originalRequest.headers['Authorization']=`Bearer ${newAccessToken}`;
        return axios(originalRequest);
      }
      //else if(!isLogged){ window.location.href = '/'}
      else{
        return Promise.reject(error);
      }
    }
  );

  useEffect(() => {
    setTimeout(() => {
      setshowNotif(false) //false
      setTimeout(() => {
        setshowNotif(false)
      }, 6000);
    }, 4000);
  }, [])
  

  if(showNotif) return <NotifSuccessBooking></NotifSuccessBooking>

  

  return (
   <Router>
        <Routes>
            <Route index element={!isLogged ? <Navigate to={ROUTE_PATHS.HOME} replace = {true} />:<MainLayout footer={false}><AccomodationPage /></MainLayout>} exact /> 
            {/* MAIN SERVICE PAGE.......................... */}
            <Route path={ROUTE_PATHS.HOME} element={<Login />} exact />
            <Route path={ROUTE_PATHS.TRANSFER} element={<TransferPage />} exact />
            <Route path={ROUTE_PATHS.SELECT_VEHICLE} element={<MainLayout footer={true} docket={true}><MainTransfer /></MainLayout>} exact /> // docket was removed as per malikah request
            <Route path={ROUTE_PATHS.EXCURSION} element={<ExcursionPage/>} exact />
            <Route path={ROUTE_PATHS.EXCURSION_SELECT} element={<MainExcursion />} exact />
            <Route path={ROUTE_PATHS.SELECTED_EXCURSION} element={<SelectedExcursion />} exact />
            <Route path={ROUTE_PATHS.ACCOMMODATION} element={<MainLayout footer={false}><AccomodationPage /></MainLayout>} exact />
            {/* <Route path='/accomodation' element={<AccomodationPage />} exact /> */}
            <Route path={ROUTE_PATHS.ACCOMMODATION_SELECT} element={<MainLayout docket={true} footer={true}><MainAccomodation /></MainLayout>} exact />
            <Route path={ROUTE_PATHS.SELECTED_ACCOMMODATION} element={<MainLayout footer={true} docket={true}><AccomSelected /></MainLayout>} exact />     
             
             {/* SUB SERVICE PAGE.......................... */}
            <Route path={ROUTE_PATHS.QUOTATION} element={<MainLayout footer={false}><QuotationPage /></MainLayout>} exact />
            <Route path={ROUTE_PATHS.VIEW_DETAILS} element={<MainLayout footer={false}><ViewDetailsPage /></MainLayout>} exact />
            <Route path={ROUTE_PATHS.SELECTED_VIEW_DETAILS} element={<MainLayout footer={false}><SelectedViewDetails /></MainLayout>} exact />
            <Route path={ROUTE_PATHS.USER_DETAILS} element={<QuoteInputs />} exact />
            {/* <Route path='/booking/recap' element={<BookingRecap />} exact /> */}
            <Route path={ROUTE_PATHS.INTERNAL_ERROR} element={<ErrorPage />} exact />
            <Route path={ROUTE_PATHS.QUOTATION_INPUTS} element={<SaveCotationInputs />} exact />

        </Routes>
        {isOpen&&getPopupContent()}
        </Router>
  )
}

export default MyRoutes