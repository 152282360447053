import React, { Fragment, useState } from 'react';
import arrow from "../../Assets/images/quotation_arrow.png";
import { bookings, FREE_SALES_STATUS, ON_REQUEST_STATUS, ROUTE_PATHS } from "../../constants";
import { useNavigate } from 'react-router-dom';
import { totalList, getTotalPrice, selectedRef,addFileNumber} from '../../store/globalReducer.js';
import { useDispatch, useSelector } from 'react-redux';
import { MoreOptionsThreeDotted } from './MoreOptions.js';
import { B2BPagination, PaxDetailsTags, ServiceNbTags } from '../MiniComponents/Atom.js'
import { dateToConventionalFormat, properCase, scrollToTop,getBeforeUnderscore } from '../../utility/globalFunc.js';
import B2BSkeleton from '../Accomodation/minicomponents/B2BSkeleton.js';
import TABLE_SKELETON from '../../Assets/images/skeleton/table-booking-skeleton.png';
import useQuotationService from '../../hooks/useQuotationService.js';
import {setQuoteBookList} from "../../store/globalReducer"

function Table_booking({ data,loading,nbPages,changeNoPage}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
    const {fetchQuotationDataAndGoToBooking,fetchQuotationData}=useQuotationService();
    const {sortedList} =useSelector((state) => state.globalreducer)  
    const {refCode} =useSelector((state) => state.transfer_Global)  

    const itemsPerPage = 10;
    
    const handlePageChange = (page) => {
        console.log("page",page)
        setCurrentPage(page);
        changeNoPage(page);
        scrollToTop(0);
    };    
    
    // open clients page
    const Booking = (e,data) => {
        e.preventDefault();      
        const payload={"file_nb": data.file_nb,"file_name":data.file_name}
        let { file_name } = data;
        fetchQuotationData(payload,(res) => {
            console.log({res});
            
            let {result} =res.data;
            navigate(ROUTE_PATHS.USER_DETAILS,{state:{service:result,refCode:file_name}});
            //dispatch(totalList({folder:"",services:result}))


        },(err) => {
            alert(err);
            console.log("error",err);
            
        });
    }

    const openViewDetails = (e, data) => {
        e.preventDefault();
        console.log("data quote", data);
        let { file_name,file_nb } = data;
        
        fetchQuotationData(data,(res) => {
            let accomTotal = 0;
            let result =res.data.result;
           
            

            dispatch(selectedRef({ refCode: file_name, bookingId: res.data.id_booking, refpax: result.selectedPax }));
            dispatch(getTotalPrice(accomTotal));
            dispatch(addFileNumber(file_nb))
            result.refCode  = file_name;
              console.log("services",result);
            
            dispatch(setQuoteBookList(result))

            
            let {hostname,protocol,port }=window.location;
            port ?  port = ":"+port : port = ""
            const serverUrl = `${protocol}//${hostname}${port}${ROUTE_PATHS.SELECTED_VIEW_DETAILS}?file_nb=${file_nb}`;
            window.open(serverUrl, '_blank');

        },(err) => {
            alert(err);
            console.log("error",err);
            
        });
    };

    return (
        <div className='table-quotation-parent'>
            <table>
                <thead className='table-quote-header'>
                    <tr className='table-quote-header-row'>
                        {bookings.quotationHeader.map((head, index) => (
                            <td className={`${head.className || ""}`} key={index + head}>
                                {head.label}
                            </td>
                        ))}
                    </tr>
                </thead>

                {(!loading && data.length > 0) ? (
                    <tbody className='table-quote-body'>
                        {data.map((list, index) => (
                            <Fragment key={`${list.file_nb}_${index}`}>
                            <tr
                                className={`quotation-row ${hoveredRowIndex === index ? 'tr-hover' : ''}`}
                                onMouseEnter={() => setHoveredRowIndex(index)}
                                onMouseLeave={() => setHoveredRowIndex(null)}
                                onClick={(e) => openViewDetails(e, list)}
                            >
                                <td className='file-nb'>{list.file_nb.substring(0, 6)}</td>
                                <td>{getBeforeUnderscore(list.file_name)}</td>
                                <td>
                                    <div className={`statuscell ${
                                        list.status === ON_REQUEST_STATUS ? "status_req" :
                                        list.status === FREE_SALES_STATUS ? "status_free" : 
                                        "status_payment"}`}>
                                        {properCase(list.status)}
                                    </div>
                                </td>
                                <td>{list.client_type}</td>
                                <td className='date-cell'>{dateToConventionalFormat(list.date[0])}</td>
                                <td className='arrow-cell'><img src={arrow} alt="arrow" /></td>
                                <td className='date-cell'>{dateToConventionalFormat(list.date[1])}</td>
                                <td className='pax-details'>
                                    <ServiceNbTags serviceCount={list.service_count}/>
                                </td>
                                <td>
                                    {list.created_by},<br />
                                    <b>{list.agency_name}</b><br />
                                    <label style={{ fontWeight: "300" }}>{list.created_date}</label>
                                </td>
                                <td className='action-column'>
                                    <div onClick={(e) => { e.stopPropagation(); Booking(e, list) }} className='quote-btn status_req1'>
                                        Book now
                                    </div>
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <MoreOptionsThreeDotted quotation={list}/>
                                    </div>
                                </td>
                            </tr>
                            {index<data.length-1&&<tr className='hr-container'>
                                <td colSpan={100}>
                                    <hr/>
                                </td>
                            </tr>}
                            </Fragment>
                        ))}
                    </tbody>
                ) : null}
            </table>

            {!loading && data.length === 0 && (
                <div style={{ margin: "30px 0px", fontSize: "22px", width: "100%" }}>
                    No data found...
                </div>
            )}

            {loading && <B2BSkeleton imgUrl={TABLE_SKELETON} style={{ marginTop: "25px" }} />}

            <div className='pagination'>
                <B2BPagination currentPage={currentPage} total={data.length} nbPerPage={itemsPerPage} onPageChange={handlePageChange} nbPages={nbPages}/>
            </div>
        </div>
    );
}

export default Table_booking;
