import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import usePopupData from '../hooks/usePopupData.js'; 
import TimelineView from '../components/MiniComponents/TimelineView.js';
import logo from "../Assets/images/mautourco-logo.png"
import { QuotationActionDropdown } from '../components/Quotations/small_dropdowns.js';
import TableLayoutNew from '../components/MiniComponents/TableLayoutNew.js';
import QuoteCancellationLayout from '../components/Quotations/QuoteCancellationLayout.js';
import {extractWord, getClientTypeByAcronyme, getGlobalClientType} from "../utility/globalFunc.js"
import { populatePdf } from '../store/globalReducer.js';
import { capitalizeFirstLetter, CLIENTDATA, MORE_OPTIONS_MODE, ROUTE_PATHS } from '../constants.js';
import useAddItems from '../components/Quotations/hooks/useAddItems.js';



function ViewDetailsPage() {
    const {agency_name,first_name,last_name,file_name} =useSelector((state) => state.authpath.userData); 
    const {subAgencyAdded} =useSelector((state) => state.authpath);
    const { selRooms, roomPax, isNewHotel, numberOfRoom,pendingRooms } = useSelector((state) => state.accomodationreducer)
    const {refCode,transferList=[]} =useSelector((state) => state.transfer_Global) 
    const {selExcursion=[],excursionTotal} =useSelector((state) => state.excursionreducer)
    const {sortedList,totalPrice,quoteBookList, actionMode,savedFileNumber} =useSelector((state) => state.globalreducer)
    const { isOpen, openPopup, closePopup, getPopupContent } = usePopupData();
    const {addItems,status:quoteStatus,loading:addItemsLoading}=useAddItems();
    const navigate = useNavigate();
    const [popupOpen, setPopupOpen] = useState(false);
    let isSubAgencyHaveValue = Object.keys(subAgencyAdded).length > 0
    let totalClients = quoteBookList?.Clients?.length

    const confirmSave=(e)=>{
        openPopup("info")
    }
    

// make a global update function with payload [1]
const updateQuotation= async (e)=>{
    e?.preventDefault();
    console.log("update coation:",selRooms,refCode);

    // quote booklist is showing empty array in redux dev tools
    
  
    await addItems(transferList,selExcursion,selRooms,refCode,first_name,last_name,roomPax,savedFileNumber);

    // if(status !== null){
   
    // }
    // const data = {"selRooms":selRooms,"pax":roomPax,"refCode":refCode}
    // setIsUpdatedData(data)
    }

useEffect(()=>{
    if(quoteStatus !== null){
        openPopup("success-quote")
    }
},[quoteStatus])


    const quotationOrBooking = () => {
        return actionMode?.startsWith(MORE_OPTIONS_MODE.ADD_ITEM_BOOKING) ? " Booking" : " Quotation";
    }

    const confirmSaveV1=(e)=>{
        let {services}=sortedList
        let {reservationTransfer,reservationAccom,reservationExcursion}=services
        navigate(ROUTE_PATHS.USER_DETAILS,{state:{service:{reservationAccom,reservationTransfer,reservationExcursion},refCode:refCode}});
    }

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
        
    }, []);

    return (
        <>
        <div className='view-quotationPage'>
                <div id="toPdf" className="save_quotaion_popup">
                <>
                    <div className="save_quotaion_head">
                        <div className="save_quotaion_headlft">
                            <p className='save_quotaion_headlft_num'>{refCode === null ? "Quotation n° 20825" :extractWord(refCode) + quotationOrBooking() }</p>
                            <p className='save_quotaion_headlft_orange'>{refCode === null ? "Quotation n° 20825" :extractWord(refCode) + quotationOrBooking() } <span className='not-saved'>(Not saved)</span> </p>
                            <p className='save_quotaion_headlft_name'>{first_name}  {last_name}, {agency_name}</p>
                            <div className='timeline-container'><TimelineView list={sortedList.services}/></div>
                        </div>
                        <div className="save_quotaion_headryt">
                            <img src={logo}/>
                            <div className='cotDocdetails'>
                                <p>Document details</p>
                                {refCode !== null ? (
                                    <div>
                                        <span className="label">Client’s name:</span> <span className="value"><b>{extractWord(refCode)}</b></span>
                                    </div>
                                ) : null}
                                <div><span className="label">Agency:</span> <span className="value">{isSubAgencyHaveValue ? subAgencyAdded.agency_name: agency_name}</span></div>
                                <div><span className="label">Client-Type:</span> <span className="value">{getGlobalClientType(sortedList.services)}</span></div>
                                  {quoteBookList?.Clients && <div><span className="label">Number of pax:</span> <span className="value">{totalClients}</span></div>}
                            </div>
                            <div className='dropdownContainer'>
                                <div className='buttonDrop' onClick={() => setPopupOpen(prev => !prev)}><label>More options</label></div>
                                {
                                    popupOpen && <QuotationActionDropdown isView  data={quoteBookList} openPopup={openPopup}/>
                                }
                            </div>

                        </div>
                    </div>

                    {/* time line part */}


                    <TableLayoutNew lists={sortedList.services} total={totalPrice} />
                    <QuoteCancellationLayout data={sortedList.services}/>
                    <div className='save_quotaion_button'>
                        {!refCode&&<span className='warning'>Caution : Please save your quotation to apply latest changes</span>}
                       { refCode !== null ?<button onClick={(e)=>{updateQuotation(e)}} className='finsavecotation' disabled={addItemsLoading?true:false}>Update Quotation</button>  :
                        <button onClick={(e)=>{confirmSave(e)}} className='finsavecotation'>Save Quotation</button>  }
                        <button onClick={(e)=>{confirmSaveV1(e)}} className={'finbooknow '}>Book Now</button>   
                    </div>     
                </>
                {isOpen ? getPopupContent():<></>}
            </div> 
           
                     
        </div>
        </>
    );
}

export default ViewDetailsPage;